import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { imgUrl } from "src/Helpers/utils/Constant";
import { ITeacher } from "src/Types/module/teacher/teacherTypes";
import Label from "src/components/Label";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  teacher: ITeacher;
}

const SingleTeacher = ({ teacher }: IProps) => {
  return (
    <div>
      <Box sx={{ mt: 3 }}>
        <Grid container>
          <Grid item lg={4} sm={12} md={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                width={200}
                height={250}
                style={{
                  borderRadius: "5px",
                }}
                src={
                  teacher.training_teacher_photo
                    ? `${imgUrl}/${teacher.training_teacher_photo}`
                    : "/static/images/avatars/avatar2.png"
                }
                alt=""
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={4} md={4}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {/* <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  ID:
                </Typography>
                {teacher.training_teacher_id}
              </Grid> */}
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Email:
                </Typography>
                {teacher.training_teacher_email}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Phone:
                </Typography>
                {teacher.training_teacher_phone}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Name:
                </Typography>
                {teacher.training_teacher_name}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  gutterBottom
                  noWrap
                >
                  Status: {getStatusLabel(teacher.training_teacher_status)}
                </Typography>{" "}
              </Grid>

              <Grid item lg={12} sm={12} md={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Details:
                </Typography>
                {teacher.training_teacher_details}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SingleTeacher;
