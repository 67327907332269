import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import {
  ICompanyDocument,
  ICompanyDocumentResponse,
} from "src/Types/module/member/CompanyInformation";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import OrganizatonOtherinput from "../../../CompanyAddInfo/CompanyAddInfoComponents/OrganizatonOtherinput";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import UpdateCompanyDocument from "../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyDocument";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import { HttpClient } from "src/Helpers/http/http";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

function CompanyDocumentInfo() {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [companyDocument, setCompanyDocument] = useState<ICompanyDocument>(
    {} as ICompanyDocument
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const data: ICompanyDocumentResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/documents/${memberId}`
        );
        if (data.success) {
          setCompanyDocument(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    company_ownership_status,
    company_staff_list_file,
    company_office_photographs,
    company_agreement_file,
    company_partnership_deed_file,
    company_incorporation_certificate,
    company_memorandum_file,
    company_one_staff_certificate,
    company_office_agreement,
    company_sales_statement,
    company_bank_statement,
  } = companyDocument || {};

  return (
    <>
      {/* {applied ? ( */}
      <>
        <PageTitleWrapper>
          <PageTitle
            heading="Company Document"
            actionButtonTitle={company_ownership_status ? "Update" : "Add"}
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {company_ownership_status ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Ownership status:
                          </TableCell>
                          <TableCell align="right">
                            {company_ownership_status
                              ? company_ownership_status
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Staff list:
                          </TableCell>
                          <TableCell align="right">
                            {company_staff_list_file ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(company_staff_list_file);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Agreement type:
                          </TableCell>
                          <TableCell align="right">
                            {company_office_agreement
                              ? company_office_agreement
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Agreement file:
                          </TableCell>
                          <TableCell align="right">
                            {company_agreement_file ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(company_agreement_file);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Partnership deed file:
                          </TableCell>
                          <TableCell align="right">
                            {company_partnership_deed_file ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(company_partnership_deed_file);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Incorporation certificate:
                          </TableCell>
                          <TableCell align="right">
                            {company_incorporation_certificate ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(
                                    company_incorporation_certificate
                                  );
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Memorandum file:
                          </TableCell>
                          <TableCell align="right">
                            {company_memorandum_file ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(company_memorandum_file);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            One staff GDS certificate:
                          </TableCell>
                          <TableCell align="right">
                            {company_one_staff_certificate ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(company_one_staff_certificate);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Bank statement:
                          </TableCell>
                          <TableCell align="right">
                            {company_bank_statement ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(company_bank_statement);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Sales statement:
                          </TableCell>
                          <TableCell align="right">
                            {company_sales_statement ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(company_sales_statement);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Office document:
                          </TableCell>
                          <TableCell align="right">
                            <ul>
                              {company_office_photographs?.length ? (
                                <>
                                  {company_office_photographs?.map(
                                    (img, index) => {
                                      return (
                                        <li
                                          key={index}
                                          style={{
                                            listStyle: "none",
                                          }}
                                        >
                                          <CommonTypoGraphy
                                            name="View"
                                            onClick={() => {
                                              SetImageViewOpen(true);
                                              setImageFile(img);
                                            }}
                                          />
                                        </li>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                "Not provided"
                              )}
                            </ul>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="h3"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>
      {/* ) : (
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
          No data found
        </Typography>
      )} */}

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: company_ownership_status
            ? "Update company document"
            : "Add company document",
        }}
      >
        {company_ownership_status ? (
          <UpdateCompanyDocument
            memberId={memberId}
            companyDocument={companyDocument}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <OrganizatonOtherinput
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
}

export default CompanyDocumentInfo;
