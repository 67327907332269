import { ChangeEvent, useState } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
} from "@mui/material";
import StallApplicationTableRow from "./StallApplicationTableRow";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { IStallFairApplication } from "src/Utils/type";

const applyPagination = (
  fairAllMember: IStallFairApplication[],
  page: number,
  limit: number
): IStallFairApplication[] => {
  return fairAllMember.slice(page * limit, page * limit + limit);
};

const StallApplicationTable = ({ fairAllMember, loading, setReload }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(fairAllMember, page, limit);

  return (
    <Card>
      <CardHeader title="Stall application list" />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Member name</TableCell>
              <TableCell>Application ID</TableCell>
              <TableCell>Member contact name</TableCell>
              <TableCell>Application member ID</TableCell>
              <TableCell>Start date</TableCell>
              <TableCell>End date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : paginatedInvoice.length ? (
              <>
                {paginatedInvoice.map((allAdmin: IStallFairApplication) => {
                  return (
                    <StallApplicationTableRow
                      key={allAdmin.fair_stall_application_id}
                      allAdmin={allAdmin}
                      setReload={setReload}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={fairAllMember.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default StallApplicationTable;
