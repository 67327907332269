import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { IInspection, IInspectionResponse } from "./type/inspectionType";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import AddInspectionInformation from "./AddInspectionInformation";
import ViesInspection from "./ViesInspection";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import UpdateInspectionInformation from "./UpdateInspectionInformation";

const Inspection = ({ forwarderId }: { forwarderId: number }) => {
  const { user } = useAuthContext();
  const { memberId } = useParams();
  const [inspectionData, setInspectionData] = useState({} as IInspection);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `inspection of ${inspectionData?.office_name}`,
  });
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const data = await HttpClient.get<IInspectionResponse>(
          `/api/inspcetion-report/${memberId}`
        );
        if (data.success) {
          setInspectionData(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const getActionButtonTitle = () => {
    if (
      user?.user_admin_role === "Super Admin" ||
      user?.user_admin_role === "Admin"
    ) {
      if (!inspectionData?.inspection_report_date) {
        return "";
      }
    } else if (!forwarderId) {
      return "";
    }
    return user?.user_admin_role === "Super Admin"
      ? "Update information"
      : "Add Information";
  };
  return (
    <div>
      <PageTitleWrapper>
        <PageTitle
          heading="Member inspection"
          actionButtonTitle={getActionButtonTitle()}
          setAction={setOpen}
        />
      </PageTitleWrapper>

      <>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {" "}
            {inspectionData?.inspection_report_date ? (
              <div style={{ position: "relative" }}>
                {" "}
                <ViesInspection
                  inspectionData={inspectionData}
                  componentRef={componentRef}
                />{" "}
                <div style={{ position: "absolute", top: 0, right: 0 }}>
                  {" "}
                  <Tooltip title="Print information form">
                    <IconButton onClick={handlePrint}>
                      <PrintIcon
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <Typography
                variant="h3"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title:
            user?.user_admin_role === "Super Admin"
              ? "Update inspection information"
              : "Add inspection information",
        }}
      >
        {user?.user_admin_role === "Super Admin" ? (
          <UpdateInspectionInformation
            memberId={memberId}
            inspectionData={inspectionData}
            handelClose={() => setOpen(!open)}
            reload={() => setRefresh(!refresh)}
          />
        ) : (
          <AddInspectionInformation
            forwarderId={forwarderId}
            memberId={memberId}
            handelClose={() => setOpen(!open)}
          />
        )}
      </ModalsWrapper>
    </div>
  );
};

export default Inspection;
