import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader/GlobalLoader";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { IsingleTrainee } from "src/Types/module/courses/singleTrainee";
import { ICourseApplication } from "src/Types/module/courses/courseList";
import { IBatchList } from "src/Types/module/courses/trainee";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Label from "src/components/Label";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateTrainee from "./UpdateTrainee";
import TraineeJoinedBatch from "./TraineeJoinedBatch";
import { TbCertificate } from "react-icons/tb";
import CreateTraineeCertificate from "./CreateTraineeCertificate";
import { ISingleTrainee } from "./types/singleTraineeTypes";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TraineeCertificateList from "./TraineeCertificateList";

function SingleTrainee() {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [traineeInfo, setTraineeInfo] = useState<ISingleTrainee>(
    {} as ISingleTrainee
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState({
    update: false,
    create: false,
  });
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/trainee/get/single/for-admin/${id}`
        );
        if (data.success) {
          setTraineeInfo(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id, reload]);
  const {
    user_training_trainee_name,
    user_training_trainee_email,
    user_training_trainee_phone,
    user_training_trainee_photo,
    user_training_trainee_account_status,
    user_training_trainee_created_at,
  } = traineeInfo || {};
  return (
    <>
      <Helmet>
        <title>Atab - Trainee Information</title>
      </Helmet>

      {loading ? (
        <SuspenseLoader />
      ) : (
        <>
          {" "}
          <Container maxWidth="lg">
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  textAlign="center"
                  gutterBottom
                  variant="h2"
                  component="div"
                >
                  {user_training_trainee_name}
                </Typography>
                <Box>
                  {user?.user_admin_role === "Super Admin" && (
                    <Tooltip title="Create certificate">
                      <IconButton
                        onClick={() => setOpen({ ...open, create: true })}
                      >
                        <TbCertificate />
                      </IconButton>
                    </Tooltip>
                  )}

                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => setOpen({ ...open, update: true })}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <CardContent sx={{ mt: 2 }}>
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* : "/static/images/avatars/avatar2.png" */}

                    <img
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "50%",
                        border: "5px solid #dedede",
                      }}
                      src={
                        user_training_trainee_photo
                          ? `${imgUrl}/${user_training_trainee_photo}`
                          : "/static/images/avatars/avatar2.png"
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={{ xs: 1 }}>
                      {/* <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Trainee id:
                        </Typography>
                        {user_training_trainee_id}
                      </Grid> */}

                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Registration date:
                        </Typography>
                        {moment(user_training_trainee_created_at).format(
                          "Do MMMM YYYY"
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Phone:
                        </Typography>
                        {user_training_trainee_phone
                          ? user_training_trainee_phone
                          : "Phone Not Provided"}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Email:
                        </Typography>
                        {user_training_trainee_email
                          ? user_training_trainee_email
                          : "Email Not Provided"}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Status:
                        </Typography>
                        {user_training_trainee_account_status ? (
                          <Label color="success">Active</Label>
                        ) : (
                          <Label color="error">Blocked</Label>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Box mt={4}>
              {/* <Card> */}
              <CardContent className="trainee-tab">
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={(
                        _event: React.SyntheticEvent,
                        newValue: string
                      ) => setValue(newValue)}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Batch" value="1" />
                      <Tab label="Certificate" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <TraineeJoinedBatch traineeInfo={traineeInfo} />
                  </TabPanel>
                  <TabPanel value="2">
                    <TraineeCertificateList traineeInfo={traineeInfo} />
                  </TabPanel>
                </TabContext>
              </CardContent>
              {/* </Card> */}
            </Box>
          </Container>
        </>
      )}

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open.update}
        modalData={{ title: "Edit Trainee " }}
      >
        <UpdateTrainee
          traineeInfo={traineeInfo}
          handelClose={() => setOpen({ ...open, update: false })}
          setReload={setReload}
        />
      </ModalsWrapper>

      {/* single trainee certificate  */}

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open.create}
        modalData={{ title: "Create certificate" }}
      >
        <CreateTraineeCertificate
          traineeInfo={traineeInfo}
          handelClose={() => setOpen({ ...open, create: false })}
          Reload={() => setReload(!reload)}
        />
      </ModalsWrapper>
    </>
  );
}

export default SingleTrainee;
