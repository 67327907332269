import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { ICertificate } from "src/Types/module/member/memberTypes";
import ShowCertificate from "./ShowCertificate";

const MemberCertificates = () => {
  const { memberId } = useParams();
  const [Certificates, setCertificates] = useState<ICertificate[]>([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    try {
      (async function () {
        const { data } = await axios.get(
          `${url}/api/atab/user-member/certificate/${memberId}?user=admin`
        );

        if (data.success) {
          setCertificates(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [memberId, refresh]);
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SL</TableCell>
              <TableCell>date</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : Certificates.length ? (
              <>
                {Certificates.map((certificate, index: number) => {
                  return (
                    <ShowCertificate
                      key={certificate.c_id}
                      certificate={certificate}
                      index={index}
                      setRefresh={setRefresh}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MemberCertificates;
