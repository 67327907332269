import axios from "axios";
import { TOKEN_KEY, url } from "../utils/Constant";

const http = axios.create({
  baseURL: url,
  timeout: 200000,
});

// Change request data/error here
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_KEY);
    config.headers.Authorization = `Bearer ${token ? token : ""}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const retryRequest = async (error, retries = 3) => {
  if (retries <= 0) {
    return Promise.reject(error);
  }
  await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before retrying
  return http(error.config).catch((err) => retryRequest(err, retries - 1));
};

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log("Request cancelled:", error.message);
    } else if (
      error.code === "ECONNABORTED" &&
      error.message.indexOf("timeout") !== -1
    ) {
      console.log("Request timeout:", error);
      return retryRequest(error);
    }
    return Promise.reject(error);
  }
);

export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await http.get<T>(url, { params });
    return response.data;
  }
  static async getWithSignal<T>(
    url: string,
    config?: { params?: unknown; signal?: AbortSignal }
  ) {
    const response = await http.get<T>(url, config);
    return response.data;
  }
  static async post<T>(url: string, data: unknown, options?: any) {
    const response = await http.post<T>(url, data, options);
    return response.data;
  }

  static async put<T>(url: string, data?: unknown) {
    const response = await http.put<T>(url, data);
    return response.data;
  }
  static async patch<T>(url: string, data: unknown) {
    const response = await http.patch<T>(url, data);
    return response.data;
  }

  static async delete<T>(url: string) {
    const response = await http.delete<T>(url);
    return response.data;
  }
}
