import React, { useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { INotice } from 'src/Types/module/member/Notice';

interface IProps {
  setOpen: (value: boolean) => void;
  allNotice: INotice[];
  setAllNotice: Function;
}
const CreateNotice = ({ setOpen, allNotice, setAllNotice }: IProps) => {
  const { user } = useAuthContext();
  const [inputData, setInputData] = useState({ title: '', file: '' });
  const [loading, setLoading] = useState(false);
  const date = new Date();

  const onSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('member_notice_file', inputData.file);
    formData.append('member_notice_title', inputData.title);
    formData.append('member_notice_created_by', user.user_admin_id.toString());

    try {
      const { data } = await axios.post(`${url}/api/member/notice`, formData);
      if (data.success) {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setAllNotice([
          {
            member_notice_id: data.data.member_notice_id,
            member_notice_file: data.data.member_notice_file,
            member_notice_title: inputData.title,
            member_notice_created_at: date.toISOString(),
            member_notice_created_by_id: user.user_admin_id,
            member_notice_created_by_name: user.user_admin_name,
          },
          ...allNotice,
        ]);
        setOpen(false);
        setLoading(false);
      } else {
        Toaster().fire({
          icon: 'success',
          title: data.message,
        });
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: 'error',
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };
  return (
    <Box p={2}>
      <Grid spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            id='outlined-basic'
            placeholder='Enter notice title'
            label='Enter notice title'
            required
            onChange={(e) =>
              setInputData({ ...inputData, title: e.target.value })
            }
            variant='outlined'
            type='text'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          <TextField
            id='outlined-basic'
            placeholder='Upload notice file'
            label='Upload notice file'
            required
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: any) =>
              setInputData({ ...inputData, file: e.target.files[0] })
            }
            variant='outlined'
            type='file'
            fullWidth
          />
        </Grid>
      </Grid>
      {loading ? (
        <Button
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Loading ...
        </Button>
      ) : (
        <Button
          onClick={onSubmit}
          fullWidth
          variant='contained'
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Create Notice
        </Button>
      )}
    </Box>
  );
};

export default CreateNotice;
