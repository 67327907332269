import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import moment from "moment";
import { ISingleEvent } from "src/Types/module/member/Event";
import { getStatusLabel } from "src/Utils/Combined";
import { Link } from "react-router-dom";
import { TableNotFound } from "src/Utils/TableLoader";

const EventJoinedMember = ({ singleEvent }: { singleEvent: ISingleEvent }) => {
  const theme = useTheme();
  return (
    <>
      {" "}
      <Typography
        sx={{ textDecoration: "underline" }}
        mt={8}
        mb={3}
        color="text."
        align="center"
        variant="h4"
      >
        Joined member list
      </Typography>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Member ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Company Name
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Joined Date
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {singleEvent?.joinedMember?.length ? (
              <>
                {" "}
                {singleEvent?.joinedMember?.map((details) => (
                  <TableRow>
                    <TableCell>{details?.member_id}</TableCell>

                    <TableCell align="center">
                      <Link to={`/member-list/${details?.member_id}`}>
                        {" "}
                        {details?.member_company_name}
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {moment(details?.joined_date).format("LLL")}
                    </TableCell>
                    <TableCell align="center">
                      {getStatusLabel(details?.joined_status)}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EventJoinedMember;
