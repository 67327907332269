import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { getStatusLabelInvoiceStatus } from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import { IVisitorInvoiceList } from "src/Utils/type";

type IProps = {
  visitorInvoice: IVisitorInvoiceList;
};

const VisitorInvoiceTableRow = ({ visitorInvoice }: IProps) => {
  const theme = useTheme();

  // const { user } = useAuthContext();
  // const Toast = Toaster();
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleDelete = async (itemId: any) => {
  //   if (visitorInvoice?.visitor_payment_invoice_id && user.user_admin_id) {
  //     try {
  //       const { data } = await axios.delete(
  //         `${url}/api/fair/stall/delete/${visitorInvoice?.visitor_payment_invoice_id}`
  //       );
  //       ({ data });
  //       if (data.success) {
  //         Toast.fire({
  //           icon: "success",
  //           title: "Fair stall successfully deleted",
  //         });
  //         setReload((prev: Boolean) => !prev);
  //         setOpen(false);
  //       }
  //     } catch (err) {
  //       Toast.fire({
  //         icon: "error",
  //         title: `Fair stall doesn't delete`,
  //       });
  //     }
  //   }
  // };

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitorInvoice?.visitor_payment_invoice_id}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {moment(visitorInvoice?.visitor_payment_invoice_issue_date).format(
            "LL"
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitorInvoice?.visitor_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitorInvoice?.visitor_first_name
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}{" "}
          {visitorInvoice?.visitor_last_name}
        </Typography>
      </TableCell>
      <TableCell>
        {getStatusLabelInvoiceStatus(
          visitorInvoice?.visitor_payment_invoice_status
        )}
      </TableCell>
      <TableCell>
        <Tooltip title="view" arrow>
          <Link
            to={`/visitor/visitor-invoice/${visitorInvoice?.visitor_payment_invoice_id.toString()}`}
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
        {/* <Tooltip title="Delete" arrow>
          <IconButton
            onClick={handleClickOpen}
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <DeleteConfirmation
          open={open}
          handleClose={handleClose}
          text="Are you want delete this fair invoice?"
          handleDeleteFun={handleDelete}
        /> */}
      </TableCell>
    </TableRow>
  );
};

export default VisitorInvoiceTableRow;
