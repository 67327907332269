import { Box, Grid } from "@mui/material";
import { CssTextField } from "./CustomTextFiled";
import { ISingleTraineeApplication } from "src/Utils/type";

const TraineeGuardian = ({
  register,
  singleTraineeApplication,
}: {
  singleTraineeApplication: ISingleTraineeApplication;
  register: any;
}) => {
  return (
    <>
      <Box px={2} py={2}>
        <Grid container spacing={2}>
          <Grid xs={12} md={4} lg={3} item>
            {" "}
            <CssTextField
              id="outlined-basic19"
              variant="outlined"
              label="Enter your fathers name"
              type="text"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_fathers_name
              }
              {...register("training_course_application_fathers_name")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            {" "}
            <CssTextField
              id="outlined-basic63"
              label="Enter your father occupation"
              variant="outlined"
              type="text"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_father_occupation
              }
              {...register("training_course_application_father_occupation")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            <CssTextField
              id="outlined-basic25"
              variant="outlined"
              type="text"
              label="Enter your mother name"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_mothers_name
              }
              {...register("training_course_application_mothers_name")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            <CssTextField
              id="outlined-basic63"
              label="Enter your mother occupation"
              variant="outlined"
              type="text"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_mother_occupation
              }
              {...register("training_course_application_mother_occupation")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            <CssTextField
              id="outlined-basic53"
              variant="outlined"
              type="number"
              label="Enter parents income"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_parents_income
              }
              {...register("training_course_application_parents_income")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            <CssTextField
              id="outlined-basic35"
              label="Enter local guardian name"
              variant="outlined"
              type="text"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_guardian_name
              }
              {...register("training_course_application_guardian_name")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            <CssTextField
              id="outlined-basic42"
              variant="outlined"
              type="text"
              label="Relationship with guardian"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_guardian_relation
              }
              {...register("training_course_application_guardian_relation")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            <CssTextField
              id="outlined-basic53"
              variant="outlined"
              type="text"
              label="Enter guardian mobile number"
              fullWidth
              defaultValue={
                singleTraineeApplication?.training_course_application_guardian_phone
              }
              {...register("training_course_application_guardian_phone")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={3} item>
            <CssTextField
              id="outlined-basic63"
              label="Enter guardian full address"
              variant="outlined"
              type="text"
              defaultValue={
                singleTraineeApplication?.training_course_application_guardian_address
              }
              fullWidth
              {...register("training_course_application_guardian_address")}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TraineeGuardian;
