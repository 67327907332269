import { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import CurrentCivilAviationInfo from "./CurrentCivilAviationInfo";
import UpdateCivilAviationInfo from "./UpdateCivilAviationInfo";
import { ISingleCivilAviation } from "src/Types/module/UpdateRequest/RequestCivilAviation";
import { useParams, useSearchParams } from "react-router-dom";
import CivilAviationAppReject from "./CivilAviationAppReject";

const RequestSingleCivilAviationInfo = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const status = query.get("status");
  const memberID = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestCivilAviationInfo, setRequestCivilAviationInfo] =
    useState<ISingleCivilAviation>({} as ISingleCivilAviation);
  const [preCivilAviationInfo, setPreCivilAviationInfo] =
    useState<ISingleCivilAviation>({} as ISingleCivilAviation);

  const urls = [
    `${url}/api/atab/member-company/update-request/civil/aviation/${id}?status=${status}`,
    `${url}/api/atab/member-company/civil/aviation/${memberID}`,
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const data = await Promise.all(urls.map((url) => axios.get(url)));
        if (data?.length) {
          setRequestCivilAviationInfo(data[0]?.data?.data);
          setPreCivilAviationInfo(data[1]?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestCivilAviationInfo.status === "pending" ? (
            <Container maxWidth="xl" sx={{ mt: 5 }}>
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CurrentCivilAviationInfo
                    preCivilAviationInfo={preCivilAviationInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UpdateCivilAviationInfo
                    requestCivilAviationInfo={requestCivilAviationInfo}
                    id={id}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <CivilAviationAppReject
              requestCivilAviationInfo={requestCivilAviationInfo}
            />
          )}
        </>
      )}
    </>
  );
};

export default RequestSingleCivilAviationInfo;
