import React from "react";
import { HelmetProvider } from "react-helmet-async";
import "nprogress/nprogress.css";
import App from "src/App";
import { SidebarProvider } from "src/components/Context/SidebarContext";
import * as serviceWorker from "src/serviceWorker";
import { AuthContextProvider } from "./components/Context/AuthContext/AuthContext";
import { ModalContextProvider } from "./components/Context/ModalContext";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ModalContextProvider>
        <SidebarProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </SidebarProvider>
      </ModalContextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
