import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";

import axios from "axios";
import Toaster from "../../../../../Toaster/Toaster";
import { url } from "../../../../../Helpers/utils/Constant";
import { Helmet } from "react-helmet-async";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { IPermission } from "src/Types/module/roleAndPermission/roleAndPermissionType";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import MultiSelectCheckbox from "./MultiSelectCheckbox";

interface IProps {
  handleClose: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const CreateRole = ({ handleClose, setReload }: IProps) => {
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [allPermission, setAllPermission] = useState<IPermission[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

  const { user } = useAuthContext();

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/permissions`)
          .then(function (res) {
            if (res.data.success) {
              setAllPermission(res.data.result);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllPermission([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (inputs: any) => {
    setLoading(true);

    try {
      inputs.roles_created_by = user.user_admin_id;
      inputs.permissions = selectedPermissions;

      const { data } = await axios.post(
        `${url}/api/atab/admin/create/role`,
        inputs
      );

      if (data.success) {
        Toast.fire({
          icon: "success",
          title: `Role Created Successfully`,
        });
        setReload(true);
        reset();
        handleClose(false);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);

      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Atab - Create Role</title>
      </Helmet>

      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          m: 4,
          width: 650,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='name'
              label='Role Title'
              {...register("roles_name")}
              type='text'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Grid
              container
              spacing={1}
              height={400}
              sx={{
                overflow: "scroll",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: 10,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#efefef",
                  borderRadius: 2,
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#01579b",
                  borderRadius: 2,
                },
              }}
            >
              <MultiSelectCheckbox
                myArray={allPermission}
                selectedPermissions={selectedPermissions}
                setSelectedPermissions={setSelectedPermissions}
              />
            </Grid>
          </Grid>
        </Grid>

        {!loading ? (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              py: 1,
            }}
          >
            Create Role
          </Button>
        ) : (
          <LoadingButton
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              py: 1,
            }}
          >
            Creating...
          </LoadingButton>
        )}
      </Box>
    </>
  );
};

export default CreateRole;
