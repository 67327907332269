import { useState, useEffect } from "react";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import {
  IOtherBusinessLicense,
  IOtherBusinessLicenseResponse,
} from "src/Types/module/member/CompanyInformation";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateCompanyOtherBusinessLicense from "../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyOtherBusinessLicense";
import OtherAssociationMember from "../../../CompanyAddInfo/CompanyAddInfoComponents/OtherAssociationMember";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import { HttpClient } from "src/Helpers/http/http";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

const OtherBusinessLicense = () => {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [otherBusiness, setOtherBusiness] = useState<IOtherBusinessLicense>(
    {} as IOtherBusinessLicense
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const data: IOtherBusinessLicenseResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/association/${memberId}`
        );
        if (data.success) {
          setOtherBusiness(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    baira_membership,
    baira_membership_file,
    haab_membership,
    haab_membership_file,
    iata_membership,
    iata_membership_file,
    toab_membership,
    toab_membership_file,
    tdab_membership,
    tdab_membership_file,
  } = otherBusiness || {};
  return (
    <>
      {/* {applied ? ( */}
      <>
        <PageTitleWrapper>
          <PageTitle
            heading="Other business license"
            actionButtonTitle={
              baira_membership ||
              haab_membership ||
              iata_membership ||
              toab_membership ||
              tdab_membership
                ? "Update"
                : "Add"
            }
            setAction={setOpen}
          />
        </PageTitleWrapper>

        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {" "}
            {baira_membership ||
            haab_membership ||
            iata_membership ||
            toab_membership ||
            tdab_membership ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Membership of BAIRA:
                          </TableCell>
                          <TableCell align="right">
                            {baira_membership ? "Yes" : "No"}{" "}
                          </TableCell>
                        </TableRow>

                        {baira_membership ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of BAIRA :
                            </TableCell>
                            <TableCell align="right">
                              {baira_membership_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(baira_membership_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Membership of HAAB:
                          </TableCell>
                          <TableCell align="right">
                            {haab_membership ? "Yes" : "No"}{" "}
                          </TableCell>
                        </TableRow>

                        {haab_membership ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of HAAB:
                            </TableCell>
                            <TableCell align="right">
                              {haab_membership_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(haab_membership_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}{" "}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Membership of IATA:
                          </TableCell>
                          <TableCell align="right">
                            {iata_membership ? "Yes" : "No"}{" "}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        {iata_membership ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of IATA:
                            </TableCell>
                            <TableCell align="right">
                              {iata_membership_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(iata_membership_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}{" "}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Membership of TOAB :
                          </TableCell>
                          <TableCell align="right">
                            {toab_membership ? "Yes" : "No"}{" "}
                          </TableCell>
                        </TableRow>
                        {toab_membership ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of TOAB:
                            </TableCell>
                            <TableCell align="right">
                              {toab_membership_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(toab_membership_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}{" "}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Membership of TDAB :
                          </TableCell>
                          <TableCell align="right">
                            {tdab_membership ? "Yes" : "No"}{" "}
                          </TableCell>
                        </TableRow>
                        {tdab_membership ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of TOAB:
                            </TableCell>
                            <TableCell align="right">
                              {tdab_membership_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(tdab_membership_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}{" "}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="h3"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>
      {/* ) : (
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
          No data found
        </Typography>
      )} */}
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title:
            baira_membership ||
            haab_membership ||
            iata_membership ||
            toab_membership ||
            tdab_membership
              ? "Update Other business license"
              : "Add other business license",
        }}
      >
        {baira_membership ||
        haab_membership ||
        iata_membership ||
        toab_membership ||
        tdab_membership ? (
          <UpdateCompanyOtherBusinessLicense
            memberId={memberId}
            otherBusiness={otherBusiness}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <OtherAssociationMember
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>{" "}
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default OtherBusinessLicense;
