import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Link } from "react-router-dom";
import { imgUrl } from "src/Helpers/utils/Constant";
import Label from "src/components/Label";

const TraineeTableRow = ({ allMembers }: any) => {
  const theme = useTheme();
  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allMembers.user_training_trainee_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ display: "flex" }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
              }}
              src={
                allMembers.user_training_trainee_photo
                  ? `${imgUrl}/${allMembers.user_training_trainee_photo}`
                  : "/static/images/avatars/avatar2.png"
              }
              alt=""
            />
            <Link
              to={`/courses/trainee/${allMembers.user_training_trainee_id.toString()}`}
            >
              <Typography
                variant="body1"
                color="text.primary"
                gutterBottom
                noWrap
              >
                {allMembers.user_training_trainee_name
                  .toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </Typography>
            </Link>
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allMembers.user_training_trainee_email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allMembers.user_training_trainee_account_status ? (
              <Label color="success">Active</Label>
            ) : (
              <Label color="error">Blocked</Label>
            )}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            to={`/training-trainee/details/${allMembers.user_training_trainee_id}`}
          >
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TraineeTableRow;
