import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IBatchOfCourse } from "src/Utils/type";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { getStatusLabel } from "src/Utils/Combined";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableNotFound } from "src/Utils/TableLoader";

const applyPagination = (
  students: IBatchOfCourse[],
  page: number,
  limit: number
): IBatchOfCourse[] => {
  return students.slice(page * limit, page * limit + limit);
};

function BatchOfCourse() {
  const [batch, setBatch] = useState<IBatchOfCourse[] | null>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [loading, setLoading] = useState<boolean>(false);
  const { courseId } = useParams();
  const [query] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/training/course/get/all/batch/by/course-id/${courseId}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setBatch(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [courseId]);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedBatchList = applyPagination(batch, page, limit);

  const theme = useTheme();
  return !loading ? (
    <>
      <Helmet>
        <title>Batch list of this course</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          pt={5}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title={`Batch list of ${query.get("course")}`} />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Batch name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Batch start date</TableCell>
                      <TableCell>Batch end date</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  {paginatedBatchList.length ? (
                    paginatedBatchList.map((batch: IBatchOfCourse) => {
                      return (
                        <TableBody key={batch.training_batch_id}>
                          <TableRow hover>
                            <TableCell>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {batch.training_batch_id}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {batch.training_batch_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {getStatusLabel(batch.training_batch_status)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {moment(batch.training_batch_start_date).format(
                                  "LL"
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                              >
                                {moment(batch.training_batch_start_date).format(
                                  "LL"
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/training-batch/details/${batch.training_batch_id}`}
                              >
                                <Tooltip title="view" arrow>
                                  <IconButton
                                    sx={{
                                      "&:hover": {
                                        background:
                                          theme.colors.primary.lighter,
                                      },
                                      color: theme.palette.primary.main,
                                    }}
                                    color="inherit"
                                    size="small"
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableNotFound />
                  )}
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={batch.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25, 30]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  ) : (
    <GlobalLoader />
  );
}

export default BatchOfCourse;
