import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";
import { IFairInvoiceByFairMember } from "src/Types/module/fairInvoice/fairInvoiceByFairMemberType";
import FairInvoiceByFairMemberTable from "./FairInvoiceByFairMemberTable";

const FairInvoiceByFairMemberShow = () => {
  const { fairMemberId } = useParams();
  const [fairInvoiceByFairMember, setFairInvoiceByFairMember] = useState<
    IFairInvoiceByFairMember[]
  >([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>("all");
  const [reload, setReload] = useState<Boolean>(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        axios
          .get(
            `${url}/api/atab/fair-invoice/get/all/by/user-member/status/${fairMemberId}/${status}`
          )
          .then(function (res) {
            if (res.data.success) {
              setFairInvoiceByFairMember(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setFairInvoiceByFairMember([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => setLoading(false);
  }, [status, reload]);

  const allAdmin: IFairInvoiceByFairMember[] = fairInvoiceByFairMember;
  return (
    <>
      <Card>
        <FairInvoiceByFairMemberTable
          loading={loading}
          allAdmin={allAdmin}
          setStatus={setStatus}
          status={status}
          setReload={setReload}
        />
      </Card>
    </>
  );
};

export default FairInvoiceByFairMemberShow;
