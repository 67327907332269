import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import moment from "moment";
import { imgUrl } from "src/Helpers/utils/Constant";

const CurrentCompanyInfo = ({ prvCompanyInfo }: any) => {
  const {
    member_company_name,
    member_company_type,
    member_company_stablished_date,
    member_company_status,
    member_company_verified_by,
    member_company_verified_date,
    member_company_created_at,
    member_company_website,
    member_company_logo,
    phone,
    cellphone,
    email,
    addressline1,
    addressline2,
    addressline3,
    postalcode,
    police_station,
    zone_name,
    area,
    thana,
    district,
    division,
  } = prvCompanyInfo || {};
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={21}>
            Current information
          </Typography>
        }
      />

      <TableContainer>
        <Table aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Company name:
              </TableCell>
              <TableCell align="right">
                {member_company_name ? member_company_name : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company status:
              </TableCell>
              <TableCell align="right">
                {member_company_status ? member_company_status : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company type:
              </TableCell>
              <TableCell align="right">
                {member_company_type ? member_company_type : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company phone number:
              </TableCell>
              <TableCell align="right">{phone ? phone : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company cell phone number:
              </TableCell>
              <TableCell align="right">{cellphone ? cellphone : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company email:
              </TableCell>
              <TableCell align="right">{email ? email : ""}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Company address 1:
              </TableCell>
              <TableCell align="right">
                {addressline1 ? addressline1 : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company address 2:
              </TableCell>
              <TableCell align="right">
                {addressline2 ? addressline2 : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company address 3:
              </TableCell>
              <TableCell align="right">
                {addressline3 ? addressline3 : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company website:
              </TableCell>
              <TableCell align="right">
                {member_company_website ? (
                  <a href={member_company_website} target="_blank">
                    {member_company_website}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company logo:
              </TableCell>
              <TableCell align="right">
                {member_company_logo ? (
                  <a
                    href={`${imgUrl}/member_${member_company_logo}`}
                    target="_blank"
                  >
                    {member_company_logo}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company created date:
              </TableCell>
              <TableCell align="right">
                {member_company_created_at &&
                  moment(member_company_created_at).format("Do MMM YYYY")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company stablish date :
              </TableCell>
              <TableCell align="right">
                {member_company_stablished_date
                  ? moment(member_company_stablished_date).format("Do MMM YYYY")
                  : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company verify by:
              </TableCell>
              <TableCell align="right">
                {member_company_verified_by ? member_company_verified_by : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company verify date:
              </TableCell>
              <TableCell align="right">
                {member_company_verified_date &&
                  moment(member_company_verified_date).format("Do MMM YYYY")}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Company police station:
              </TableCell>
              <TableCell align="right">
                {police_station ? police_station : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company post code:
              </TableCell>
              <TableCell align="right">
                {postalcode ? postalcode : ""}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company zone name:
              </TableCell>
              <TableCell align="right">{zone_name ? zone_name : ""}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Company division:
              </TableCell>
              <TableCell align="right">{division ? division : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company district:
              </TableCell>
              <TableCell align="right">{district ? district : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company thana:
              </TableCell>
              <TableCell align="right">{thana ? thana : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Company area:
              </TableCell>
              <TableCell align="right">{area ? area : ""}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CurrentCompanyInfo;
