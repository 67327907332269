import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import moment from "moment";
import { Link } from "react-router-dom";
import { imgUrl } from "src/Helpers/utils/Constant";
import { ISingleAttendance } from "src/Utils/type";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Label from "src/components/Label";
import { useState } from "react";
import UpdateAttendance from "./UpdateAttendance";
import EditIcon from "@mui/icons-material/Edit";

const AttendanceList = ({
  attendance,
}: {
  attendance: ISingleAttendance[];
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Trainee ID
              </TableCell>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Trainee name
              </TableCell>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Exam mark
              </TableCell>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Attendance time
              </TableCell>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Status
              </TableCell>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendance?.map((details: ISingleAttendance) => (
              <TableRow key={details.user_training_trainee_id}>
                <TableCell>{details.user_training_trainee_id}</TableCell>
                <TableCell>
                  <Typography sx={{ display: "flex" }}>
                    <img
                      width={30}
                      height={30}
                      style={{
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                      src={
                        details.user_training_trainee_photo
                          ? `${imgUrl}/${details?.user_training_trainee_photo}`
                          : "/static/images/avatars/avatar2.png"
                      }
                      alt=""
                    />
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {details.user_training_trainee_name}
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell>{details.training_exam_result_marks}</TableCell>
                <TableCell>
                  {moment(details.training_exam_attendance_time).format("LT")}
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    <Label
                      color={
                        details.training_exam_attendance ? "success" : "error"
                      }
                    >
                      {details.training_exam_attendance ? "Attend" : "Absent"}
                    </Label>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      sx={{
                        "&:hover": {
                          background: theme.colors.primary.lighter,
                        },
                        color: theme.palette.primary.main,
                      }}
                      color="inherit"
                      size="small"
                      onClick={() => setOpen(true)}
                    >
                      <EditIcon sx={{ cursor: "pointer" }} />
                    </IconButton>
                  </Tooltip>
                  <Link
                    to={`/training-trainee/details/${details.user_training_trainee_id}`}
                  >
                    <Tooltip title="view" arrow>
                      <IconButton
                        sx={{
                          "&:hover": {
                            background: theme.colors.primary.lighter,
                          },
                          color: theme.palette.primary.main,
                        }}
                        color="inherit"
                        size="small"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </TableCell>
                <Dialog
                  open={open}
                  maxWidth="md"
                  onClose={() => setOpen(false)}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogContent>
                    <UpdateAttendance
                      handleClose={() => setOpen(false)}
                      attendance={details}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)}>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AttendanceList;
