import { useContext } from "react";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ListSubheader,
  List,
  Button,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/components/Context/SidebarContext";
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { menuWrapper, submenuWrapper } from "src/Utils/DashboardUtils";
import AccountsSideBar from "./AccountsSideBar";

const MenuWrapper = menuWrapper();
const SubMenuWrapper = submenuWrapper();

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useAuthContext();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expandedSub, setExpandedSub] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const handleSubChange =
    (panelSub: string) =>
    (_event: React.SyntheticEvent, isExpandedSub: boolean) => {
      setExpandedSub(isExpandedSub ? panelSub : false);
    };

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboard"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Dashboard
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {user.permissions.modules.includes("all-admin") && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                ADMIN
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className="accordion-submodule" component="div">
                <Accordion
                  className="accordion"
                  expanded={expanded === "panel9"}
                  onChange={handleChange("panel9")}
                >
                  {user.permissions.modules.includes("role-permission") && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Admin
                      </Typography>
                    </AccordionSummary>
                  )}

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component="div">
                      {user.permissions.subModules.includes(
                        "role-permission"
                      ) && (
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/admin-list/list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Admin list
                          </Button>
                        </ListItem>
                      )}
                      {user.permissions.subModules.includes("audit-trail") && (
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/admin-list/audit-trail"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Audit Trail
                          </Button>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className="accordion"
                  expanded={expanded === "panel10"}
                  onChange={handleChange("panel10")}
                >
                  {user.permissions.modules.includes("role-permission") && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Role & Permission
                      </Typography>
                    </AccordionSummary>
                  )}
                  {user.permissions.modules.includes("role-permission") && (
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component="div">
                        {user.permissions.modules.includes(
                          "role-permission"
                        ) && (
                          <>
                            <ListItem component="div">
                              <Button
                                sx={{ paddingLeft: "18px!important" }}
                                disableRipple
                                component={RouterLink}
                                className="submodule-name"
                                onClick={closeSidebar}
                                to="/admin-list/role"
                                startIcon={<TableChartTwoToneIcon />}
                              >
                                Role List
                              </Button>
                            </ListItem>
                            <ListItem component="div">
                              <Button
                                sx={{ paddingLeft: "18px!important" }}
                                disableRipple
                                component={RouterLink}
                                className="submodule-name"
                                onClick={closeSidebar}
                                to="/admin-list/permission"
                                startIcon={<TableChartTwoToneIcon />}
                              >
                                Permission List
                              </Button>
                            </ListItem>
                          </>
                        )}
                      </List>
                    </AccordionDetails>
                  )}
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {user.permissions.modules.includes("member-list") && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Member
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className="accordion-submodule" component="div">
                {user.permissions.subModules.includes("member-list") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel12"}
                    onChange={handleChange("panel12")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Member List
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      {/* <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/member-list/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All Member
                        </Button>
                      </ListItem> */}

                      {user?.user_admin_role === "Application Inspection" &&
                      user.permissions.modules.includes(
                        "Application Inspection"
                      ) ? (
                        <>
                          {" "}
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/checker-list"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              Application Forward
                            </Button>
                          </ListItem>
                        </>
                      ) : (
                        <>
                          {" "}
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/pending"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              New member application
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/active"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              Active Member
                            </Button>
                          </ListItem>{" "}
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/checker-list"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              Application Forward
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/complete-inspection"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              Completed Inspection
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/member-inprogress"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              Member InProgress
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/rejected"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              Rejected Member
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button
                              className="submodule-name"
                              disableRipple
                              component={RouterLink}
                              onClick={closeSidebar}
                              to="/member-list/blocked"
                              startIcon={<TableChartTwoToneIcon />}
                            >
                              Blacklisted Member
                            </Button>
                          </ListItem>
                        </>
                      )}

                      {/* <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/member-list/application-checker"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Application Forward List
                        </Button>
                      </ListItem> */}
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions.subModules.includes(
                  "update-applications"
                ) && (
                  <Accordion
                    expanded={expanded === "panel32"}
                    onChange={handleChange("panel32")}
                    className="accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Applications
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/update-applications/id-card"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          ID Card Applications
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/update-applications/renewal-application"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Renewal Application
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/update-applications/address-change-application"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Address Change Application
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/update-applications/ownership-change-application"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Ownership Change Application
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/update-applications/duplicate-certificate"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Duplicate certificate
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/update-applications/agency-info-update"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Agency information update application
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* {user.permissions.subModules.includes('update-request') && (
                  <Accordion
                    expanded={expanded === 'panel29'}
                    onChange={handleChange('panel29')}
                    className='accordion'
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className='expand-icon' />}
                      aria-controls='panel2bh-content'
                      id='panel2bh-header'
                    >
                      <Typography className='accordion-module-name'>
                        Update Request
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/company-information'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Company Information
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/owner-info'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Owner Information
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/company-docs'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Company Documents
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/trade-license'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Trade License
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/business-license'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Business License
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/other-business-license'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Other Business License
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/civil-aviation'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Civil Aviation
                        </Button>
                      </ListItem>
                      <ListItem component='div'>
                        <Button
                          className='submodule-name'
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to='/update-request/proposer-info'
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Proposer Info
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )} */}
                {user.permissions.subModules.includes("member-invoice") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Invoice
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/invoices/transactions"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Invoice list
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component="div">
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/paymentable-item/list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Invoice Item List
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions.subModules.includes(
                  "member-money-receipt"
                ) && (
                  <Accordion
                    expanded={expanded === "pane41"}
                    onChange={handleChange("pane41")}
                    className="accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Money Receipt
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/money-receipt/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Money Receipt List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions.subModules.includes("account") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel30"}
                    onChange={handleChange("panel30")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name" sx={{}}>
                        Member Account
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/account/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Account List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/account/chart-of-account"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Chart of Account
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    {/* <AccordionDetails sx={{ padding: 0 }}>
                    <ListItem component="div">
                      <Button
                        className="submodule-name"
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/cheque-management/list"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Cheque Management
                      </Button>
                    </ListItem>
                  </AccordionDetails> */}
                  </Accordion>
                )}
                {user.permissions.subModules.includes("member-expense") && (
                  <Accordion
                    expanded={expanded === "pane441"}
                    onChange={handleChange("pane441")}
                    className="accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Expense
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/expense/head/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Expense Head List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/expense/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Expense List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user.permissions.subModules.includes("others") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name" sx={{}}>
                        Others
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/member-list/meeting"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Meeting
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/member-list/event"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Event
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/member-list/notice"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Notice
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions.subModules.includes("member-report") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel31"}
                    onChange={handleChange("panel31")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name" sx={{}}>
                        Report
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/receive"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Receive Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/due"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Due List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/fee-wise"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Fee Wise Member list
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/subscription"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Subscription Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/journal"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Journal Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/ledger"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Ledger Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/member-ledger"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Member Ledger Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/trail-balance"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Trail Balance Report
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/income-statement"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Income Statement
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/report/balance-sheet"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Balance Sheet
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {user?.permissions?.modules?.includes("trainee") && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Training
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className="accordion-submodule" component="div">
                {user.permissions.subModules.includes("trainee") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel16"}
                    onChange={handleChange("panel16")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Trainee
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-trainee/applications"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Trainee Applications
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-trainee/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Trainee List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user?.permissions?.subModules.includes("batch") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel17"}
                    onChange={handleChange("panel17")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Batch
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-batch/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All Batch
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-batch/notice-list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All Notice
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-batch/material-list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All Material
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions.subModules.includes("course") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel19"}
                    onChange={handleChange("panel19")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name" sx={{}}>
                        Course
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-course/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Course List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions.subModules.includes("teacher") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel20"}
                    onChange={handleChange("panel20")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Teacher
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component="div">
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/training-teacher/list"
                          >
                            Teacher list
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user.permissions.modules.includes("training-invoice") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel22"}
                    onChange={handleChange("panel22")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Invoice
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-invoice/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Invoice list
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component="div">
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/training-invoice-item/list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Training invoice item List
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user.permissions.modules.includes(
                  "training-money-receipt"
                ) && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel21"}
                    onChange={handleChange("panel21")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name" sx={{}}>
                        Money Receipt
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/trainee-money-receipt/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Money Receipt List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user.permissions.modules.includes("training-account") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel39"}
                    onChange={handleChange("panel39")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name" sx={{}}>
                        Trainee Account
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/trainee-account/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Account List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/trainee-account/chart-of-account"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Chart of Account
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    {/* <AccordionDetails sx={{ padding: 0 }}>
                    <ListItem component="div">
                      <Button
                        className="submodule-name"
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/trainee-cheque-management/list"
                        startIcon={<TableChartTwoToneIcon />}
                      >
                        Cheque Management
                      </Button>
                    </ListItem>
                  </AccordionDetails> */}
                  </Accordion>
                )}
                {user.permissions.modules.includes("training-expense") && (
                  <Accordion
                    expanded={expanded === "pane412"}
                    onChange={handleChange("pane412")}
                    className="accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Expense
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/trainee-expense-head/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Expense Head List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/trainee-expense/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Expense List
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user.permissions.subModules.includes("service-request") && (
                  <Accordion
                    expanded={expanded === "panel34"}
                    onChange={handleChange("panel34")}
                    className="accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Service request
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      {/* <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/service-request/id-card"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Re-assessment
                        </Button>
                      </ListItem> */}
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/service-request/trainee-duplicate-certificate"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Duplicate certificate
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/service-request/books"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Books
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/service-request/trainee-duplicate-id"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Duplicate id card
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user.permissions.subModules.includes("trainee-report") && (
                  <Accordion
                    expanded={expanded === "panel35"}
                    onChange={handleChange("panel35")}
                    className="accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Report
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Trainee Report
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/agency-wise"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Agency Wise Report
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/batch-wise"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Batch Wise Report
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/journal"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Journal Report
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/ledger"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Ledger Report
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/trainee-ledger"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Trainee Ledger Report
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/trial-balance"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Trial Balance
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/income-statement"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Income Statement
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/training-report/balance-sheet"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Balance Sheet
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* fair module */}
        {user.permissions.modules.includes("fair") && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Fair
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className="accordion-submodule" component="div">
                {user.permissions.subModules.includes("exhibitor") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel23"}
                    onChange={handleChange("panel23")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Exhibitor
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component="div">
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/exhibitor/list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Exhibitor list
                          </Button>
                        </ListItem>
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/exhibitor/invoice-list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Exhibitor invoice list
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}

                {user.permissions.modules.includes("fair") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel24"}
                    onChange={handleChange("panel24")}
                  >
                    {user.permissions.subModules.includes("fair") && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="expand-icon" />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography className="accordion-module-name">
                          Fair
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <List component="div">
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/fair/list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Fair list
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            className="submodule-name"
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/fair/stall-list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Stall list
                          </Button>
                        </ListItem>
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/fair/stall-application"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Stall application
                          </Button>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                )}
                {user.permissions.modules.includes("visitor") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel25"}
                    onChange={handleChange("panel25")}
                  >
                    {user.permissions.subModules.includes("visitor") && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="expand-icon" />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className="accordion-module-name">
                          Visitor
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/visitor/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All visitor list
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/visitor/visitor-invoice"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          All visitor invoice
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* event  */}
                {user.permissions.modules.includes("event") && (
                  <>
                    <Accordion
                      className="accordion"
                      expanded={expanded === "panel26"}
                      onChange={handleChange("panel26")}
                    >
                      {user.permissions.subModules.includes("event") && (
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-icon" />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className="accordion-module-name">
                            Event
                          </Typography>
                        </AccordionSummary>
                      )}
                      <AccordionDetails sx={{ padding: 0 }}>
                        <ListItem>
                          <Button
                            className="submodule-name"
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/fair-event/all-event-list"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            All Event
                          </Button>
                        </ListItem>
                      </AccordionDetails>

                      {user.permissions.subModules.includes(
                        "guest-speaker"
                      ) && (
                        <AccordionDetails sx={{ padding: 0 }}>
                          <List component="div">
                            <ListItem component="div">
                              <Button
                                sx={{ paddingLeft: "18px!important" }}
                                disableRipple
                                component={RouterLink}
                                className="submodule-name"
                                onClick={closeSidebar}
                                to="/guest-speaker/guest-list"
                                startIcon={<TableChartTwoToneIcon />}
                              >
                                Guest & Speaker List
                              </Button>
                            </ListItem>
                          </List>
                        </AccordionDetails>
                        // <AccordionSummary
                        //   expandIcon={
                        //     <ExpandMoreIcon className="expand-icon" />
                        //   }
                        //   aria-controls="panel2bh-content"
                        //   id="panel2bh-header"
                        // >
                        //   <Typography className="accordion-module-name">
                        //     Guest & Speaker
                        //   </Typography>
                        // </AccordionSummary>
                      )}

                      {/* Guest and Speaker */}
                      {/* {user.permissions.modules.includes("guest-speaker") && (
                        <Accordion
                          className="accordion"
                          expanded={expanded === "panel27"}
                          onChange={handleChange("panel27")}
                        ></Accordion>
                      )} */}
                    </Accordion>
                  </>
                )}

                {user.permissions.modules.includes("entry-fair") && (
                  <Accordion
                    className="accordion"
                    expanded={expanded === "panel28"}
                    onChange={handleChange("panel28")}
                  >
                    {user.permissions.subModules.includes("entry-fair") && (
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className="expand-icon" />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className="accordion-module-name">
                          Entry fair
                        </Typography>
                      </AccordionSummary>
                    )}

                    <AccordionDetails sx={{ padding: 0 }}>
                      <ListItem>
                        <Button
                          className="submodule-name"
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/entry-fair/list"
                          startIcon={<TableChartTwoToneIcon />}
                        >
                          Entry fair list
                        </Button>
                      </ListItem>
                    </AccordionDetails>
                  </Accordion>
                )}
              </List>
            </SubMenuWrapper>
          </List>
        )}

        {/* Accounts  */}
        {/* {user.permissions.modules.includes("pure-accounting") && (
          <AccountsSideBar
            handleChange={handleChange}
            expanded={expanded}
            closeSidebar={closeSidebar}
            RouterLink={RouterLink}
          />
        )} */}

        {/* support  */}
        {user.permissions.modules.includes("conversations") && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Support
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className="accordion-submodule" component="div">
                <Accordion
                  className="accordion"
                  expanded={expanded === "panel32"}
                  onChange={handleChange("panel32")}
                >
                  {user.permissions.modules.includes("conversations") && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        Support section
                      </Typography>
                    </AccordionSummary>
                  )}

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component="div">
                      {user.permissions.subModules.includes(
                        "conversations"
                      ) && (
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/support/conversations"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Conversations
                          </Button>
                        </ListItem>
                      )}
                      {/* {user.permissions.subModules.includes("sms") && (
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/sms"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            SMS
                          </Button>
                        </ListItem>
                      )} */}
                      {/* {user.permissions.subModules.includes("sms") && (
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/email"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Email
                          </Button>
                        </ListItem>
                      )} */}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}
        {user.permissions.modules.includes("user-profile") && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Setting
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List className="accordion-submodule" component="div">
                <Accordion
                  className="accordion"
                  expanded={expanded === "panel33"}
                  onChange={handleChange("panel33")}
                >
                  {user.permissions.modules.includes("user-profile") && (
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expand-icon" />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className="accordion-module-name">
                        User setting
                      </Typography>
                    </AccordionSummary>
                  )}

                  <AccordionDetails sx={{ padding: 0 }}>
                    <List component="div">
                      {user.permissions.subModules.includes("user-profile") && (
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/profile"
                            startIcon={<AccountCircleTwoToneIcon />}
                          >
                            User Profile
                          </Button>
                        </ListItem>
                      )}
                      {user.permissions.subModules.includes("sms") && (
                        <ListItem component="div">
                          <Button
                            sx={{ paddingLeft: "18px!important" }}
                            disableRipple
                            component={RouterLink}
                            className="submodule-name"
                            onClick={closeSidebar}
                            to="/address"
                            startIcon={<TableChartTwoToneIcon />}
                          >
                            Address
                          </Button>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </SubMenuWrapper>
          </List>
        )}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
