import { useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { ISingleMaterialDetails } from "src/Types/module/batch/allTypeOfBatch";
import moment from "moment";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";

interface IProps {
  material: ISingleMaterialDetails;
}
function SingleMaterialDetails({ material }: IProps) {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  const {
    training_batch_id,
    training_batch_materials_date,
    training_batch_materials_file,

    training_batch_materials_title,
    training_batch_name,
  } = material || {};

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Material Date:
            </Typography>
            {moment(training_batch_materials_date).format("LLL")}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Batch Name:
            </Typography>
            <Link top={`/training-batch/details/${training_batch_id}`}>
              {training_batch_name}
            </Link>
          </Grid>
          {/* <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Batch ID:
            </Typography>
            {training_batch_id}
          </Grid> */}
          <Grid item xs={12} sm={4} md={4}>
            <CommonTypoGraphy
              name="View Material"
              onClick={() => {
                SetImageViewOpen(true);
                setImageFile(training_batch_materials_file);
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Title:
            </Typography>
            {training_batch_materials_title}
          </Grid>
        </Grid>
      </Box>
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
}

export default SingleMaterialDetails;
