import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ISingleClassDetails } from "src/Types/module/batch/allTypeOfBatch";
import moment from "moment-timezone";
import Label from "src/components/Label";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  classRow: ISingleClassDetails;
}

const AllClassTableRow = ({ classRow }: IProps) => {
  const theme = useTheme();

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {classRow.training_class_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {classRow.training_batch_name
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Typography>
      </TableCell>
      <TableCell>
        {moment
          .tz(classRow.training_class_date, "Asia/Dhaka")
          .format("MMM Do YY")}
      </TableCell>
      <TableCell>
        {moment(classRow.training_class_start_time, "HH:mm:ss").format(
          "hh:mm A"
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {getStatusLabel(classRow.training_class_status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link to={`/training-batch/class/${classRow.training_class_id}`}>
          <Tooltip title="view" arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default AllClassTableRow;
