import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { url } from "src/Helpers/utils/Constant";
import {
  IFairMemberInfo,
  invoiceByFairMember,
  IProductByExhibitor,
  IVisitorByExhibitor,
} from "src/Utils/type";
import InvoiceByFairMember from "./FairMemberByInvoice";
import FairMemberInfo from "./FairMemberInfo";

const SingleFairMemberPage = () => {
  const [singleInvoiceInfo, setSingleInvoiceInfo] =
    useState<IFairMemberInfo | null>(null);

  const [singleExhibitorValue, setSingleExhibitorValue] = useState<
    invoiceByFairMember[]
  >([]);

  const [visitorByExhibitor, setVisitorByExhibitor] = useState<
    IVisitorByExhibitor[]
  >([]);

  const [productByExhibitor, setProductByExhibitor] = useState<
    IProductByExhibitor[]
  >([]);

  const [loading, setLoading] = useState<Boolean>(false);
  const [commonLoading, setCommonLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);
  const { singleMemberFairId } = useParams();
  const [value, setValue] = useState<string>("invoice");

  useEffect(() => {
    setCommonLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/fair/member/get/fair-member/single/${singleMemberFairId}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setSingleInvoiceInfo(data.data.data);
        setCommonLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setCommonLoading(false);
        } else {
          // handle error
          setCommonLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [singleMemberFairId, reload]);

  useEffect(() => {
    const controller = new AbortController();
    (async function () {
      setLoading(true);
      try {
        if (value === "invoice") {
          setSingleExhibitorValue([]);
          const { data } = await axios.get(
            `${url}/api/atab/fair-invoice/get/all/by/user-member/status/${singleMemberFairId}/all`
          );
          if (data.success) {
            setSingleExhibitorValue(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } else if (value === "product") {
          const { data } = await axios.get(
            `${url}/api/fair/get/all/fair-member-product/by/${singleMemberFairId}/all`
          );
          if (data.success) {
            setLoading(false);
            setProductByExhibitor(data.data);
          } else {
            setLoading(false);
          }
        } else if (value === "visitor") {
          const { data } = await axios.get(
            `${url}/api/fair/member/get/visitor-of-member/by/${singleMemberFairId}/all`
          );
          if (data.success) {
            setVisitorByExhibitor(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [singleMemberFairId, value]);

  return (
    <div>
      <Helmet>
        <title>Atab - Single exhibitor information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!commonLoading ? (
            <>
              {singleInvoiceInfo && (
                <FairMemberInfo
                  setReload={setReload}
                  fairInfo={singleInvoiceInfo}
                />
              )}
              <InvoiceByFairMember
                memberInvoice={singleExhibitorValue}
                visitorByExhibitor={visitorByExhibitor}
                productByExhibitor={productByExhibitor}
                setValue={setValue}
                value={value}
                loading={loading}
              />
            </>
          ) : (
            <GlobalLoader />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default SingleFairMemberPage;
