import { useState, ChangeEvent } from "react";
import {
  Button,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { ISingleAttendance } from "src/Utils/type";
import { url } from "src/Helpers/utils/Constant";

type IProps = {
  handleClose: Function;
  attendance: ISingleAttendance;
};

const UpdateAttendance = ({
  handleClose,
  attendance,
}: IProps) => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [mark, setMark] = useState<number>(attendance?.training_exam_result_marks || 0)
  const [status, setStatus] = useState<string>(attendance?.training_exam_attendance ? "attend" : "absent" || null)


  const handleSubmit = async (inputs: ChangeEvent<HTMLInputElement>) => {
    inputs.preventDefault()
    setLoading(true);
    try {
      const body = {
        training_exam_attendance_id: attendance.training_exam_attendance_id,
        training_exam_result_marks: mark,
        training_exam_attendance: status == "attend" ? true : false
      }
      const { data } = await axios.put(
        `${url}/api/training/exam/single/trainee/exam-mark-or-attendance/update`,
        body
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setLoading(false);
        handleClose();
      } else {
        Toaster().fire({
          icon: "failed",
          title: `${data.message}`,
        });
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };
  const statusOptions = [
    {
      id: "absent",
      name: "Absent",
    },
    {
      id: "attend",
      name: "Attend",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Atab - Update attendance</title>
      </Helmet>
      <Container maxWidth='sm'>
        <CardHeader title='Update attendance' />
        <Divider />
        <CardContent>
          <Box
            onSubmit={handleSubmit}
            component='form'
            sx={{ pt: 2 }}
          >
            <Grid
              container
              spacing={2}
              columnSpacing={{ xs: 2, sm: 2, md: 3 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid xs={12} md={6} item sx={{ marginTop: "10px" }}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label='Status'
                    autoWidth
                  >
                    {statusOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6} item>
                <TextField
                  margin='normal'
                  fullWidth
                  label='Exam mark'
                  value={mark}
                  onChange={(e: any) => setMark(e.target.value)}
                  type='number'
                />
              </Grid>
            </Grid>

            {loading ? (
              <Button
                disabled
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                updating...
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                Update attendance
              </Button>
            )}
          </Box>
        </CardContent>
      </Container>
    </>
  );
};

export default UpdateAttendance;
