import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import moment from "moment";
import { Link } from "react-router-dom";
import { imgUrl } from "src/Helpers/utils/Constant";

import {
  IjoinedMember,
  IsingleMemberMeeting,
} from "src/Types/module/meeting/singleMemberMeeting";
import { TableNotFound } from "src/Utils/TableLoader";
import CommonExcelGenerator from "src/common/CommonExcelGenerator";
import CommonPdfGenerator from "src/common/CommonPdfGenerator";

const MeetingItems = ({
  singleInvoiceDetails,
}: {
  singleInvoiceDetails: IsingleMemberMeeting;
}) => {
  const theme = useTheme();

  return (
    <>
      {singleInvoiceDetails?.member_meeting_type === "all" ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            color="text.primary"
            display="inline"
            sx={{ pr: 1 }}
            gutterBottom
          >
            All member are invited
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 1,
            }}
          >
            <CommonExcelGenerator
              excelName={"meeting_members"}
              excelTableHead={["ID", "Member Name", "Joining Time"]}
              excelData={
                singleInvoiceDetails?.joinedMember?.length
                  ? singleInvoiceDetails?.joinedMember?.map((sData) => {
                      const data = {
                        ID: sData.member_meeting_joined_member_id,
                        "Member Name": sData.user_member_company_name,
                        "Joining Time": moment(
                          sData?.member_meeting_joined_member_joined_time
                        ).format("LLL"),
                      };
                      return data;
                    })
                  : []
              }
            />
            <CommonPdfGenerator
              PDFFileName="meeting_members"
              fileHeader="Meeting member list of (ATAB)"
              PDFHeader={["ID", "Member Name", "Joining Time"]}
              name={singleInvoiceDetails?.member_meeting_title}
              PDFData={
                singleInvoiceDetails?.joinedMember?.length
                  ? singleInvoiceDetails?.joinedMember?.map((sData) => {
                      const data = {
                        ID: sData.member_meeting_joined_member_id,
                        "Member Name": sData.user_member_company_name,
                        "Joining Time": moment(
                          sData?.member_meeting_joined_member_joined_time
                        ).format("LLL"),
                      };
                      return data;
                    })
                  : []
              }
            />
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: theme.colors.alpha.black[100] }}
                    size="small"
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{ color: theme.colors.alpha.black[100] }}
                    align="center"
                    size="small"
                  >
                    Member name
                  </TableCell>

                  <TableCell
                    sx={{ color: theme.colors.alpha.black[100] }}
                    align="center"
                    size="small"
                  >
                    Joining time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {singleInvoiceDetails?.joinedMember?.length ? (
                  <>
                    {singleInvoiceDetails?.joinedMember?.map(
                      (details: IjoinedMember, index: number) => (
                        <TableRow key={index}>
                          <TableCell size="small">
                            {details?.member_meeting_joined_member_id}
                          </TableCell>
                          <TableCell align="center" size="small">
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <img
                                width={30}
                                height={30}
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                                src={
                                  details?.joined_member_photo
                                    ? `${imgUrl}/${details.joined_member_photo}`
                                    : "/static/images/avatars/avatar2.png"
                                }
                                alt="Meeting Member Photo"
                              />

                              {details?.user_member_company_name && (
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  <Link
                                    to={`/member-list/${details?.joined_member_id}`}
                                  >
                                    {" "}
                                    {details?.user_member_company_name}
                                  </Link>
                                </Typography>
                              )}
                            </Box>
                          </TableCell>

                          <TableCell align="center" size="small">
                            {moment(
                              details?.member_meeting_joined_member_joined_time
                            ).format("LLL")}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default MeetingItems;
