import React, { useState } from "react";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { INotice } from "../../../../Types/module/member/Notice";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IProps {
  sNotice: INotice;
  setAllNotice: Function;
  allNotice: INotice[];
}
const NoticeTableRow = ({ sNotice, setAllNotice, allNotice }: IProps) => {
  const { user } = useAuthContext();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [noticeId, setNoticeId] = useState<number>(0);

  const handelDelete = async () => {
    try {
      const { data } = await axios.delete(
        `${url}/api/member/notice/${noticeId}?user_admin_id=${user.user_admin_id}`
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        const filterData = allNotice.filter(
          (sNotice) => sNotice.member_notice_id !== noticeId
        );
        setAllNotice(filterData);
      } else {
        Toaster().fire({
          icon: "error",
          title: data.message,
        });
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{sNotice.member_notice_id}</TableCell>
        <TableCell>
          <Typography
            sx={{
              width: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {sNotice.member_notice_title
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </TableCell>
        <TableCell>
          {moment(sNotice.member_notice_created_at).format(
            "MMMM Do YYYY, h:mm a"
          )}
        </TableCell>
        <TableCell>{sNotice.member_notice_created_by_name}</TableCell>

        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Link to={`/member-list/notice/${sNotice.member_notice_id}`}>
                <Tooltip title="view" arrow>
                  <IconButton
                    sx={{
                      "&:hover": {
                        background: theme.colors.primary.lighter,
                      },
                      color: theme.palette.primary.main,
                    }}
                    color="inherit"
                    size="small"
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Link>
            </Box>
            <Box>
              <Tooltip title="Delete" arrow>
                <IconButton
                  sx={{
                    "&:hover": {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(true);
                    setNoticeId(sNotice.member_notice_id);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
      <ModalsWrapper
        modalData={{ title: "Delete notice" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this notice?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            size="small"
            onClick={handelDelete}
            variant="outlined"
          >
            Yes
          </Button>
          <Button
            size="small"
            color="error"
            onClick={() => setOpen(false)}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </ModalsWrapper>
    </>
  );
};

export default NoticeTableRow;
