import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Tooltip,
  useTheme,
  IconButton,
} from "@mui/material";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { ICompanyBusinessLicense } from "src/Types/module/UpdateRequest/RequestBusinessLicense";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  isLoading: boolean;
  businessLicenseInfo: ICompanyBusinessLicense[];
}

const BusinessLicenseIndex = ({ isLoading, businessLicenseInfo }: IProps) => {
  const theme = useTheme();
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : businessLicenseInfo.length ? (
              <>
                {businessLicenseInfo.map(
                  (sBusinessLicense: ICompanyBusinessLicense) => {
                    return (
                      <TableRow hover key={sBusinessLicense.request_id}>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {sBusinessLicense.member_company_id}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            variant="body1"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {sBusinessLicense.member_company_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {getStatusLabel(sBusinessLicense.status)}
                        </TableCell>

                        <TableCell>
                          <Link
                            to={`/update-request/business-license/${sBusinessLicense.request_id}?memberid=${sBusinessLicense.user_member_id}`}
                          >
                            <Tooltip title="view" arrow>
                              <IconButton
                                sx={{
                                  "&:hover": {
                                    background: theme.colors.primary.lighter,
                                  },
                                  color: theme.palette.primary.main,
                                }}
                                color="inherit"
                                size="small"
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BusinessLicenseIndex;
