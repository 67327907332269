import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { IAccountType } from "../types/AccountTypes";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import Toaster from "src/Toaster/Toaster";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { HttpClient } from "src/Helpers/http/http";

type IProps = {
  accountData: IAccountType;
  allAccount: IAccountType[];
  setAllAccount: any;
};
const AccountTableRow = ({
  accountData,
  allAccount,
  setAllAccount,
}: IProps) => {
  const {
    account_name,
    account_number,
    account_type,
    bank_name,
    branch_name,
    balance,
    id,
  } = accountData || {};
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/atab/common-account/${id}`;
        const data = await HttpClient.delete(uri);

        if (data) {
          const filterData = allAccount.filter((item) => item.id !== id);

          setAllAccount(filterData);

          Toaster().fire({
            icon: "success",
            title: "Deleted successfully!",
          });

          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };
  return (
    <TableRow hover>
      <TableCell>{account_name}</TableCell>
      <TableCell>{account_number}</TableCell>
      <TableCell>{account_type}</TableCell>
      <TableCell>{bank_name}</TableCell>
      <TableCell>{branch_name}</TableCell>
      <TableCell>{balance}</TableCell>
      <TableCell>
        {/* <Tooltip title="View" arrow>
          <Link to={`${id}`}>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <Visibility fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip> */}
        {/* <Tooltip title="Delete account" arrow>
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}

        {/* Delele Confirmation modal  */}
        <DeleteConfirmation
          open={open}
          handleClose={() => setOpen(false)}
          text="Want to delete this account?"
          handleDeleteFun={() => handleDelete(id)}
          loading={isLoading}
        />
      </TableCell>
    </TableRow>
  );
};

export default AccountTableRow;
