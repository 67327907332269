import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { TableNotFound } from "src/Utils/TableLoader";
import { getStatusLabel } from "src/Utils/Combined";
import { ISingleTrainee } from "./types/singleTraineeTypes";

const TraineeJoinedBatch = ({
  traineeInfo,
}: {
  traineeInfo: ISingleTrainee;
}) => {
  return (
    <>
      <Card>
        <CardHeader title="Batch list" />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Batch No</TableCell>
                <TableCell align="center">Batch name</TableCell>
                <TableCell align="center">Course name</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {traineeInfo?.batchList?.length ? (
                <>
                  {traineeInfo?.batchList?.map((batch, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {batch.training_batch_id}
                      </TableCell>
                      <TableCell align="center">
                        {batch.training_batch_name}
                      </TableCell>
                      <TableCell width={600}>
                        {batch.training_batch_course_name}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {getStatusLabel(batch.training_batch_status)}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default TraineeJoinedBatch;
