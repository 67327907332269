import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { Helmet } from "react-helmet-async";
import {
  ISingleTraineeReport,
  IsingleTrainee,
} from "src/Types/module/courses/singleTrainee";
import SuspenseLoader from "src/components/SuspenseLoader";

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import Label from "src/components/Label";

const SingleTraineeReport = () => {
  const { id } = useParams();
  const [singleReport, setSingleReport] = useState<ISingleTraineeReport>(
    {} as ISingleTraineeReport
  );
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/trainee/get/single/${id}`
        );

        if (data.success) {
          setSingleReport(data.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id]);

  const {
    user_training_trainee_id,
    user_training_trainee_name,
    user_training_trainee_email,
    user_training_trainee_phone,
    user_training_trainee_photo,
    user_training_trainee_account_status,
    user_training_trainee_created_at,
    batchStatus,
    invoiceStatus,
  } = singleReport || {};
  return (
    <>
      {" "}
      <Helmet>
        <title>Atab - Trainee Report information</title>
      </Helmet>
      {loading ? (
        <SuspenseLoader />
      ) : (
        <>
          {" "}
          <Container maxWidth="xl">
            {singleReport && (
              <Card sx={{ p: 3 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    textAlign="center"
                    gutterBottom
                    variant="h2"
                    component="div"
                  >
                    {user_training_trainee_name}
                  </Typography>
                  <Typography
                    textAlign="center"
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                  >
                    Trainee Information
                  </Typography>
                </Box>
                <CardContent sx={{ mt: 2 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "50%",
                          border: "5px solid #dedede",
                        }}
                        src={
                          user_training_trainee_photo
                            ? `${imgUrl}/training_trainee_files/${user_training_trainee_photo}`
                            : "/static/images/avatars/avatar2.png"
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={{ xs: 1 }}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Trainee id:
                          </Typography>
                          {user_training_trainee_id}
                        </Grid>

                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Registration date:
                          </Typography>
                          {moment(user_training_trainee_created_at).format(
                            "Do MMMM YYYY"
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Phone:
                          </Typography>
                          {user_training_trainee_phone
                            ? user_training_trainee_phone
                            : "Phone Not Provided"}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Email:
                          </Typography>
                          {user_training_trainee_email
                            ? user_training_trainee_email
                            : "Email Not Provided"}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Status:
                          </Typography>

                          {user_training_trainee_account_status ? (
                            <Label color="success">Active</Label>
                          ) : (
                            <Label color="error">Blocked</Label>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Batch status:
                          </Typography>
                          {getStatusLabel(
                            batchStatus?.training_batch_student_status
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            display="inline"
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Invoice status:
                          </Typography>

                          {getStatusLabel(
                            invoiceStatus?.training_payment_invoice_status
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default SingleTraineeReport;
