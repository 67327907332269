import CommonMemberTable from "./commonModule/CommonMemberTable";

const CompletedInspection = () => {
  return (
    <CommonMemberTable
      status="Inspection Completed"
      title="Completed Inspection"
    />
  );
};

export default CompletedInspection;
