import { Box, Container } from "@mui/system";
import { MutableRefObject } from "react";
import InvoiceDemoForTrainee from "./InvoiceDemoForTrainee";

const PrintSingleInvoiceForTrainee = ({
  componentRef,
  singleInvoice,
}: {
  componentRef: MutableRefObject<any>;
  singleInvoice: any;
}) => {
  return (
    <Box ref={componentRef} sx={{ pt: 2 }}>
      <Container
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        maxWidth="md"
      >
        <InvoiceDemoForTrainee singleInvoice={singleInvoice} />
      </Container>
    </Box>
  );
};

export default PrintSingleInvoiceForTrainee;
