import React, { useEffect, useState } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  TextField,
} from "@mui/material";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import { IAgencyInfo, IReport } from "src/Types/module/trainingReport";

import { useSearchParams } from "react-router-dom";
import AgencyWiseReportCen from "./AgencyWiseReportCen";

const AgencyWiseReport = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    limit: "",
    page: "",
  });
  const [agencyReport, setAgencyReport] = useState<IAgencyInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [agencyName, setAgencyName] = useState("");
  const [total, setTotal] = useState<number>(0);
  const page = searchParams.get("page");
  const limit = searchParams.get("limit") || 25;

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/services/agency-wise?limit=${limit}&skip=${
            Number(limit) * Number(page)
          }&name=${agencyName}`
        );
        if (data.success) {
          setAgencyReport(data.agencyInfo);
          setTotal(data.total);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [limit, page, agencyName]);
  const getDownloadData = async () => {
    try {
      const response = await axios.get(
        `${url}/api/training/services/agency-wise?name=${agencyName}`
      );

      if (response.data.success) {
        return { total: response.data.total, data: response.data.agencyInfo };
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      return { total: 0, data: [] };
    }
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          action={
            <>
              {" "}
              <TextField
                // style={{ height: "10px" }}0
                size="small"
                id="outlined-basic"
                label="Search by agency name"
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTimeout(() => {
                    setAgencyName(e.target.value);
                  }, 1000)
                }
              />
              <AgencyWiseReportCen
                getDownloadData={getDownloadData}
                fileName={"agency_wise_report"}
              />
            </>
          }
          title="Agency wise report"
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Agency name </TableCell>
                <TableCell>Total student</TableCell>
                <TableCell>Student's name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : agencyReport?.length ? (
                <>
                  {agencyReport?.map((sReport, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{sReport.agency_name}</TableCell>
                        <TableCell>{sReport.student_count}</TableCell>

                        <TableCell>
                          {sReport?.student_names?.map(
                            (name, index: number) => (
                              <span key={index}>
                                {index + 1}. {name}
                                <br />
                              </span>
                            )
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={total}
            onPageChange={(_event, newPage) => {
              setSearchParams((prev) => {
                prev.set("page", String(newPage));
                return prev;
              });
            }}
            onRowsPerPageChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setSearchParams((prev) => {
                prev.set("limit", e.target.value);
                return prev;
              });
            }}
            page={Number(page) && Number(page)}
            rowsPerPage={Number(limit)}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default AgencyWiseReport;
