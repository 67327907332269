import { useState, useEffect } from 'react';
import {
  Divider,
  Card,
  CardHeader,
  Container,
  Grid,
  Tab,
  Box,
} from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { IProposerList } from 'src/Types/module/UpdateRequest/RequestProposerInfo';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProposerIndex from './ProposerIndex';

const RequestProposerInfo = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [proposerInfo, setProposerInfo] = useState<IProposerList[]>([]);
  const [value, setValue] = useState('pending');

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const { data } = await axios.get(
          `${url}/api/atab/member-company/update-request/get/proposer/for/requester?status=${value}`
        );

        if (data.success) {
          setProposerInfo(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [value]);

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <TabContext value={value}>
            <Card>
              <CardHeader
                title='Proposer information'
                action={
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderColor: 'divider' }}>
                      <TabList
                        onChange={(_event, newValue) => setValue(newValue)}
                        aria-label='lab API tabs example'
                      >
                        <Tab label='Pending' value='pending' />
                        <Tab label='Approved' value='approved' />
                        <Tab label='Rejected' value='rejected' />
                      </TabList>
                    </Box>
                  </Box>
                }
              />
              <Divider />
              <TabPanel value='pending'>
                <ProposerIndex
                  isLoading={isLoading}
                  proposerInfo={proposerInfo}
                />
              </TabPanel>
              <TabPanel value='approved'>
                <ProposerIndex
                  isLoading={isLoading}
                  proposerInfo={proposerInfo}
                />
              </TabPanel>
              <TabPanel value='rejected'>
                <ProposerIndex
                  isLoading={isLoading}
                  proposerInfo={proposerInfo}
                />
              </TabPanel>
              {/* <Box p={2}>
              <TablePagination
                component='div'
                count={companyInfo?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box> */}
            </Card>
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RequestProposerInfo;
