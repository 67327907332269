import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import Toaster from "src/Toaster/Toaster";
import { fetcherPatch } from "src/Utils/fetcher";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";

const UpdateModal = ({
  setShowModal,
  showModal,

  application,
  setApplication,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    address_change_application_status:
      application?.address_change_application_status,
    address_change_application_reject_reason:
      application?.address_change_application_reject_reason,
  });

  useEffect(() => {
    setInputs({
      address_change_application_status:
        application.address_change_application_status,
      address_change_application_reject_reason:
        application.address_change_application_reject_reason,
    });
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = await fetcherPatch(
      `/member/address-change-application/${id}`,
      inputs
    );

    if (data.success) {
      Toaster().fire({
        icon: "success",
        title: "Application updated",
      });
      setApplication({
        ...application,
        address_change_application_status:
          inputs.address_change_application_status,
        address_change_application_reject_reason:
          inputs.address_change_application_reject_reason,
      });
      setShowModal(false);
      setLoading(false);
    } else {
      setLoading(false);
      alert(data.message);
    }
  };

  return (
    <ModalsWrapper
      setShowModal={setShowModal}
      showModal={showModal}
      modalData={{
        title: "Update address change application",
      }}
    >
      <Box p={2}>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.address_change_application_status}
                  label="Select Status"
                  onChange={(e: any) =>
                    setInputs({
                      ...inputs,
                      address_change_application_status: e.target.value,
                    })
                  }
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Enter note"
                  variant="outlined"
                  value={inputs.address_change_application_reject_reason}
                  multiline
                  type="text"
                  rows={4}
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      address_change_application_reject_reason: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
            {loading ? (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="add-remove-button"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="add-remove-button"
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ModalsWrapper>
  );
};

export default UpdateModal;
