import React from "react";
import { useState } from "react";
import { Button, CssBaseline, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import East from "@mui/icons-material/East";
import axios from "axios";

import { Helmet } from "react-helmet-async";
import { muiStyles } from "src/Utils/Styles";
import { LottiedefaultOptions } from "src/Utils/Combined";
import Toaster from "src/Toaster/Toaster";
import { url } from "src/Helpers/utils/Constant";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = muiStyles;
const OtpSend = () => {
  const Toast = Toaster();
  const classes = useStyles();
  const [otpCode, setOtpCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultOptions = LottiedefaultOptions;
  const [query] = useSearchParams();
  const email = query.get("email");

  const navigate = useNavigate();

  const handleOtpSubmit = async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
    if (email) {
      try {
        const body = { email, otp: otpCode, type: "forget_admin" };
        const { data } = await axios.post(
          `${url}/api/atab/common/match-email-otp`,
          body
        );

        if (data.success) {
          localStorage.setItem("otpToken", data.token);
          setIsLoading(false);
          navigate(`/change-password?email=${email}`);
        } else {
          setIsLoading(false);
          Toast.fire({
            icon: "error",
            title: `Otp does not valid`,
          });
        }
      } catch (err) {
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: err.response.data.message,
        });
      }
    }
  };
  return (
    <div>
      <Helmet>
        <title>Atab - Forget Password</title>
      </Helmet>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-right-col">
            <Container maxWidth="sm" component="main">
              <CssBaseline />

              <div className="lottie-img">
                <Lottie options={defaultOptions} height={300} width={300} />
              </div>
              <Box>
                <Box component="form" onSubmit={handleOtpSubmit}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="otp"
                    id="otp"
                    label="Enter OTP code"
                    value={otpCode}
                    onChange={(e: any) => setOtpCode(e.target.value)}
                    autoComplete="otp"
                    autoFocus
                    variant="filled"
                    className={classes.inputText}
                    InputProps={{ classes }}
                  />

                  {!isLoading ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                        background: "#1113a2",
                      }}
                      className={classes.signInButton}
                    >
                      Submit OTP
                    </Button>
                  ) : (
                    <LoadingButton
                      loading={isLoading}
                      fullWidth
                      color="secondary"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                      style={{
                        background: "#1113a2!important",
                        color: "white!important",
                      }}
                      className={classes.signInButton}
                      loadingPosition="end"
                      variant="contained"
                    >
                      Submitting...
                    </LoadingButton>
                  )}
                </Box>

                <Grid container>
                  <Grid item xs={12} sm={6} md={12}>
                    <Link to="/forget-password">
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                        className="text-main"
                      >
                        {"Go Back"}
                        <East sx={{ marginLeft: "5px" }} fontSize="small" />
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpSend;
