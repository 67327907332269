import { TableCell, TableRow } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Expense } from "./incomeStatementReportTypes";
type IProps = {
  singleIncomeStatementData: Expense[];
};
const IncomeStatementReportTableRow = ({
  singleIncomeStatementData,
}: IProps) => {
  return (
    <TableRow hover>
      <TableCell>
        <SimpleTreeView>
          {singleIncomeStatementData?.map((item) => {
            if (item.AccHeadGroup?.length) {
              return (
                <TreeItem
                  key={item.Id}
                  itemId={`item-${item.Id}`}
                  label={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{item.Name}</span>
                      <span>{item.Balance}</span>
                    </div>
                  }
                >
                  {item.AccHeadGroup.map((headGroup) => (
                    <TreeItem
                      key={headGroup.Id}
                      itemId={`headGroup-${item.Id}-${headGroup.Id}`}
                      label={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{headGroup.Name}</span>
                          <span>{headGroup.Balance}</span>
                        </div>
                      }
                    >
                      {headGroup.AccHeadAndSubGroup?.map((subGroup) => (
                        <TreeItem
                          key={subGroup.Id}
                          itemId={`subGroup-${headGroup.Id}-${subGroup.Id}`}
                          label={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{subGroup.Name}</span>
                              <span>{subGroup.Balance}</span>
                            </div>
                          }
                        >
                          {subGroup.AccHead?.map((head) => (
                            <TreeItem
                              key={head.Id}
                              itemId={`head-${subGroup.Id}-${head.Id}`}
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span>{head.Name}</span>
                                  <span>{head.Balance}</span>
                                </div>
                              }
                            />
                          ))}
                        </TreeItem>
                      ))}
                    </TreeItem>
                  ))}
                </TreeItem>
              );
            } else {
              return (
                <TreeItem
                  key={item.Id}
                  itemId={`item-${item.Id}`}
                  label={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{item.Name}</span>
                      <span>{item.Balance}</span>
                    </div>
                  }
                />
              );
            }
          })}
        </SimpleTreeView>
      </TableCell>
    </TableRow>
  );
};

export default IncomeStatementReportTableRow;
