import { useState, useEffect, ChangeEvent, Suspense } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  TableBody,
  Typography,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import {
  IAllClassList,
  ISingleClassDetails,
} from "src/Types/module/batch/allTypeOfBatch";
import { RefreshButton } from "src/Utils/CommonAllShowUtils";
import CreateClass from "./CreateClass";
import { useParams } from "react-router-dom";
import AllClassTableRow from "./AllClassTableRow";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { useSearchParams } from "react-router-dom";

const AllClass = () => {
  const [allClass, setAllClass] = useState<IAllClassList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [status, setStatus] = useState<string>("all");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  // Modal state
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        if (id) {
          let uri = `${url}/api/training/class/get/all/batch/status/${id}/${status}`;
          const { data } = await axios.get(uri);

          if (data.success) {
            setAllClass(data.data);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [refresh, id, status, open]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <Box display="flex" alignItems="center" gap={2}>
                    <FormControl sx={{ width: "150px" }} variant="outlined">
                      <InputLabel>Select status</InputLabel>
                      <Select
                        size="small"
                        onChange={(e: any) => setStatus(e.target.value)}
                        label="Select status"
                        autoWidth
                        defaultValue="all"
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="running">Running</MenuItem>
                      </Select>
                    </FormControl>
                    <Button onClick={() => handleOpen()} variant="contained">
                      Create Class
                    </Button>
                    <RefreshButton action={setRefresh} />{" "}
                  </Box>
                }
                title={`All classes of ${searchParams.get("batch")}`}
              ></CardHeader>
              <Divider />

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Batch Name</TableCell>
                      <TableCell>Class Start Date</TableCell>
                      <TableCell>Class Start Time</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? (
                    <TableLoader />
                  ) : allClass.length ? (
                    <TableBody>
                      {allClass.map((singleClass: ISingleClassDetails) => {
                        return (
                          <AllClassTableRow
                            key={singleClass.training_class_id}
                            classRow={singleClass}
                          />
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableNotFound />
                  )}
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={allClass.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ mb: 4 }}>
            <Typography sx={{ mb: 2 }}> Create Class</Typography>
            <Divider />
          </Box>

          <CreateClass handleClose={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllClass;
