import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import CardEvent from "./CardEvent";
import { ISingleEvent } from "src/Types/module/member/Event";

const SingleEventPage = () => {
  const { eventId } = useParams();
  const [singleEvent, setSingleEvent] = useState<ISingleEvent>(
    {} as ISingleEvent
  );
  const [loading, setLoading] = useState<Boolean>(false);
  const [editMode, setEditMode] = useState(false);

  const handleEditModeChange = () => {
    setEditMode((prev) => !prev);
  };

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(`${url}/api/member/event/${eventId}`);
        if (data.success) {
          setSingleEvent(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setSingleEvent(null);
      }
    })();
  }, [eventId, editMode]);

  return (
    <div>
      <Helmet>
        <title>Atab - Single Meeting</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <CardEvent
            singleEvent={singleEvent}
            handleEditModeChange={handleEditModeChange}
            editMode={editMode}
          />
        </Grid>
      </Container>
    </div>
  );
};

export default SingleEventPage;
