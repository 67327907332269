import React, { useState } from "react";
import axios from "axios";
import { Grid, Button, TextField, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { ICivilAviation } from "src/Types/module/member/CompanyInformation";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
import { IResponse } from "src/Types/module/common/commonTypes";

interface IDate {
  certificate_expiry_date: string;
  certificate_issue_date: string;
}

interface IProps {
  civilAviation: ICivilAviation;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const UpdateCivilAviationInfo = ({
  memberId,
  civilAviation,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [date, setDate] = useState<IDate>({
    certificate_expiry_date: "",
    certificate_issue_date: "",
  });
  const [certificateFile, setCertificateFile] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    setIsLoading(true);
    const formData = new FormData();

    if (date.certificate_issue_date) {
      formData.append("certificate_issue_date", date.certificate_issue_date);
    }
    if (date.certificate_expiry_date) {
      formData.append("certificate_expiry_date", date.certificate_expiry_date);
    }

    if (certificateFile) {
      formData.append("certificate_file", certificateFile);
    }
    Object.keys(inputsData).forEach((key) => {
      formData.append(key, inputsData[key]);
    });
    try {
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/civil/aviation/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      } else {
        setOpen(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Civil aviation tourism certificate number"
                placeholder="Civil aviation tourism certificate number"
                fullWidth
                defaultValue={civilAviation.certificate_number}
                {...register("certificate_number")}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="outlined-basic"
                label="Civil aviation tourism certificate"
                placeholder="Civil aviation tourism certificate"
                variant="outlined"
                type="file"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => setCertificateFile(e.target.files[0])}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="date"
                type="date"
                label="Civil aviation tourism issue date"
                placeholder="Civil aviation tourism issue date"
                onChange={(e) =>
                  setDate({
                    ...date,
                    certificate_issue_date: e.target.value,
                  })
                }
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <Box sx={{ mt: 2 }}>
              <TextField
                id="date"
                placeholder="Civil aviation tourism certificate expiry date"
                label="Civil aviation tourism certificate expiry date"
                type="date"
                onChange={(e) =>
                  setDate({
                    ...date,
                    certificate_expiry_date: e.target.value,
                  })
                }
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCivilAviationInfo;
