import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { debounce } from "lodash";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { DatePicker } from "@mui/x-date-pickers";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { ITraineeExpenseHeadType } from "../types/traineeExpenseTypes";
import ExpenseHeadTableRow from "../components/TraineeExpenseHeadTableRow";
import CreateExpenseHead from "../components/CreateTraineeExpenseHead";

const TraineeExpenseHeadList = () => {
  const [limit, setLimit] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [allExpenseHead, setAllExpenseHead] = useState<
    ITraineeExpenseHeadType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [_searchInvoiceVal, _setSearchInvoiceVal] = useState<string>("");
  // const searchQuery = useDebounce(searchInvoiceVal, 1000);
  const [refresh, setRefresh] = useState(false);
  const [filterItem, setFilterItem] = useState({
    name: "",
  });
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
  });

  const page = parseInt(searchParams.get("page"));

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/expense-head?CompanyProfileId=2&limit=${limit}&skip=${limit * page}`;
        if (filterItem.name) {
          uri += `&name=${filterItem?.name}`;
        }
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAllExpenseHead(res.data);
          setLoading(false);
          setTotal(res.total);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page, limit, filterItem.name, refresh]);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };
  // const handleStatus = (e: SelectChangeEvent<string>) => {
  //   searchParams.set("status", e.target.value);
  //   setSearchParams(searchParams);
  // };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  // const paginatedInvoice = applyPagination(allExpenseHead, page, limit);

  const handelInputChange = debounce((value: string, key: string) => {
    setFilterItem((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  }, 2000);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <TextField
                    label="Search by name"
                    id="standard-search"
                    type="search"
                    size="small"
                    variant="outlined"
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) =>
                        handelInputChange(e.target.value, "name")
                      // setSearchInvoiceVal(e.target.value)
                    }
                  />

                  {/* <Button onClick={() => setOpen(true)} variant="contained">
                    Create ExpenseHead
                  </Button> */}
                </Box>
              }
              title="Expense Head List"
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Serial No</TableCell>
                      <TableCell>Expense Head Name</TableCell>
                      {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : allExpenseHead.length ? (
                      <>
                        {allExpenseHead.map((item, index) => (
                          <ExpenseHeadTableRow
                            key={item.id}
                            expenseHeadData={item}
                            setAllExpenseHead={setAllExpenseHead}
                            allExpenseHead={allExpenseHead}
                            setRefresh={setRefresh}
                            index={index}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: "Create Expense Head" }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateExpenseHead
                setRefresh={setRefresh}
                handelClose={() => setOpen(false)}
              />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TraineeExpenseHeadList;
