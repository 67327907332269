import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useForm, useFieldArray } from "react-hook-form";
import { useEffect, useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { ITraineeExpenseHeadType } from "../types/traineeExpenseTypes";
import { ITraineeAccountType } from "../../Account/types/TraineeAccountTypes";
import moment from "moment";

const UpdateTraineeExpense = ({
  setRefresh,
  handelClose,
  singleData,
  id,
}: any) => {
  const {
    expense_head_id,
    expense_head_name,
    expense_date,
    note,
    amount,
    account_id,
    account_name,
    cheque_number,
    method,
  } = singleData || {};
  const { register, handleSubmit, watch } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expenseHead, setExpenseHead] = useState<ITraineeExpenseHeadType[]>([]);
  const [accountData, setAccountData] = useState<ITraineeAccountType[]>([]);
  const [loading, setLoading] = useState(false);
  const paymentMethod = watch("method");

  // const filterAccountData = accountData?.filter(
  //   (item) => item?.account_type === paymentMethod
  // );

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/expense-head?CompanyProfileId=2`)
          .then(function (res: any) {
            if (res.success) {
              setExpenseHead(res.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(function (err) {
            setExpenseHead([]);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/common-account?CompanyProfileId=2`)
          .then(function (res: any) {
            if (res.success) {
              setAccountData(res.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(function (err) {
            setAccountData([]);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const submitData = { CompanyProfileId: 2, ...inputs };
    // console.log("Form Data:", submitData); // Logs form data to console
    try {
      const res: any = await HttpClient.patch(
        `${url}/api/atab/expense/${id}`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        setLoading(false);
        handelClose();
        setRefresh((prv) => !prv);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Box sx={{ p: 2 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              md={12}
              lg={6}
            >
              <FormControl sx={{ mt: 0 }} fullWidth required margin="normal">
                <InputLabel id="demo-simple-select-label">
                  Expense Head
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register(`expense_head_id`)}
                  label="Expense Head"
                  defaultValue={expense_head_id}
                >
                  {expenseHead?.map((sExpense) => {
                    return (
                      <MenuItem key={sExpense.id} value={sExpense.id}>
                        {sExpense.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                required
                defaultValue={amount}
                label="Amount"
                type="number"
                variant="outlined"
                {...register(`amount`)}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                required
                id="expense_date"
                label="Expense Date"
                defaultValue={moment(expense_date).format("YYYY-MM-DD")}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                {...register("expense_date")}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                fullWidth
                select
                required
                defaultValue={method}
                id="method"
                label="Payment Method"
                variant="outlined"
                {...register("method")}
              >
                {["Mobile Banking", "Cash", "Bank", "Cheque"].map((method) => (
                  <MenuItem key={method} value={method}>
                    {method}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormControl fullWidth required margin="normal">
                <InputLabel id="demo-simple-select-label">Account</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("account_id")}
                  label="Account"
                  defaultValue={account_id}
                >
                  {accountData?.map((account) => {
                    return (
                      <MenuItem key={account.id} value={account.id}>
                        {account.account_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {paymentMethod === "Cheque" && (
              <>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="bank_name"
                    label="Bank Name"
                    type="text"
                    variant="outlined"
                    {...register("bank_name")}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="cheque_no"
                    label="Cheque Number"
                    type="text"
                    defaultValue={cheque_number}
                    variant="outlined"
                    {...register("cheque_no")}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    id="withdraw_date"
                    label="Withdraw Date"
                    type="date"
                    required
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    {...register("withdraw_date")}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="note"
                label="Note"
                type="text"
                multiline
                defaultValue={note}
                rows={2}
                variant="outlined"
                {...register("note")}
              />
            </Grid>
          </Grid>
          <Box textAlign={"center"} mt={3}>
            {loading ? (
              <LoadingButton
                loading={loading}
                fullWidth
                color="secondary"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                variant="contained"
              >
                Loading...
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateTraineeExpense;
