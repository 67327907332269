import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ICourseList } from "src/Types/module/courses/courseList";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  course: ICourseList;
}

const CourseTableRow = ({ course }: IProps) => {
  const theme = useTheme();
  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {course.atab_training_course_id}
          </Typography>
        </TableCell>
        <TableCell>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "35rem",
            }}
          >
            <Typography
              title={course.atab_training_course_name}
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
            >
              {course.atab_training_course_name
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {course.atab_training_course_amount}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {course.atab_training_course_duration}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {getStatusLabel(course.atab_training_course_status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            to={`/training-course/details/${course.atab_training_course_id}`}
          >
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CourseTableRow;
