import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { getStatusLabel } from "src/Utils/Combined";

const MeetingTableRow = ({ allMembers }: any) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell size="small">{allMembers.member_meeting_id}</TableCell>
      <TableCell size="small">
        {allMembers.member_meeting_title
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
      </TableCell>

      <TableCell size="small">
        {moment(allMembers.member_meeting_date).format("LL")}
      </TableCell>
      <TableCell size="small">
        {moment(allMembers.member_meeting_time, "HH:mm").format("hh:mm A")}
      </TableCell>

      <TableCell size="small">
        <Link
          to={`/admin-list/single-admin/${allMembers.meeting_created_by_id}`}
          style={{ color: "black" }}
        >
          {allMembers.meeting_created_by_name}
        </Link>
      </TableCell>
      <TableCell size="small">
        {getStatusLabel(allMembers.member_meeting_status)}
      </TableCell>
      <TableCell size="small">
        <Link
          to={`/member-list/meeting/${allMembers.member_meeting_id.toString()}`}
        >
          <Tooltip title="view" arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default MeetingTableRow;
