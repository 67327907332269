import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, ReactNode, SetStateAction } from "react";
import {
  IJoinedExam,
  ISingleAttendance,
} from "src/Utils/type";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import InventoryTwoToneIcon from "@mui/icons-material/InventoryTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import AttendanceList from "./AttendanceList";
import StudentOfExam from "./StudentOfExam";
import Spinner from "src/components/Spinner/Spinner";

type IProps = {
  attendance: ISingleAttendance[];
  joinedExam: IJoinedExam[]
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
  loading: Boolean;
};

const FilterOfSingleExam = ({
  attendance = [],
  joinedExam,
  setValue,
  value,
  loading,
}: IProps) => {
  const notFound = <Typography variant="h2" textAlign="center" >Not found</Typography>
  let content: ReactNode;
  if (loading) {
    content = <Spinner />
  } else if (value === "attendance" && !attendance.length) {
    content = notFound
  } else if (value === "joined" && !joinedExam.length) {
    content = notFound
  } else if (value === "attendance" && attendance.length) {
    content = (
      <AttendanceList attendance={attendance} />
    );
  } else if (value === "joined") {
    content = (
      <StudentOfExam joinedExam={joinedExam} />
    );
  }
  return (
    <Container maxWidth="lg">
      <>
        <Box sx={{ py: 3 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction
              value="attendance"
              label="Attendance"
              icon={<ReceiptTwoToneIcon />}
            />
            <BottomNavigationAction
              value="joined"
              label="Student of exam"
              icon={<InventoryTwoToneIcon />}
            />
          </BottomNavigation>
        </Box>
        <Box >{content}</Box>
      </>
    </Container>
  );
};

export default FilterOfSingleExam;
