import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { getStatusLabel, getStatusLabelAdminFun } from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import moment from "moment";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { Dispatch, SetStateAction, useState } from "react";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import { IFairSingleMemberApplication } from "src/Types/module/fairSingleMemberApplication/fairSingleMemberApplication";
import SingleFairApplicationUpdateForm from "./ApplicationUpdateForm";
import ApplicationUpdateForm from "./ApplicationUpdateForm";

interface IProps {
  allAdmin: IFairSingleMemberApplication;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const SingleFairApplicationTableRow = ({ allAdmin, setReload }: IProps) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const Toast = Toaster();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <TableRow hover key={allAdmin.fair_stall_application_id}>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_stall_application_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
              }}
              src={
                allAdmin.user_fair_member_company_logo
                  ? `${imgUrl}/${allAdmin.user_fair_member_company_logo}`
                  : "/static/images/avatars/avatar2.png"
              }
              alt=""
            />

            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
            >
              {allAdmin.user_fair_member_contact_name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.user_fair_member_company_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.user_fair_member_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {moment(allAdmin.fair_stall_application_date).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {getStatusLabel(allAdmin.fair_stall_application_status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title="Edit" arrow>
            <IconButton
              onClick={handleOpen}
              sx={{
                "&:hover": { background: theme.colors.error.lighter },
                color: theme.palette.error.main,
              }}
              color="inherit"
              size="small"
            >
              <EditIcon sx={{ cursor: "pointer" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h3">
            Status change
          </Typography>
          <ApplicationUpdateForm
            details={allAdmin}
            handleClose={handleClose}
            setReload={setReload}
          />
        </Box>
      </Modal>
    </>
  );
};

export default SingleFairApplicationTableRow;
