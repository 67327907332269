import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import {
  IRepresentativeFormInput,
  IResponse,
} from "src/Types/module/common/commonTypes";
import { Button, Grid, Box, TextField } from "@mui/material";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
interface IProps {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const AddRepresentativeInfo = ({ memberId, setRefresh, setOpen, refresh }) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm<IRepresentativeFormInput>();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    setIsLoading(true);

    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (inputsData[key]) {
        if (typeof inputsData[key] === "object") {
          if (inputsData[key].length) {
            formData.append(key, inputsData[key][0]);
          }
        } else {
          formData.append(key, inputsData[key]);
        }
      }
    });

    try {
      const data: IResponse = await HttpClient.post(
        `/api/atab/admin/member-company/representative/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });

      setOpen(false);
      setIsLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor="">
              Enter representative name <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              placeholder="Enter representative name"
              variant="outlined"
              type="text"
              fullWidth
              {...register("user_member_representative_name")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor="">
              Enter representative full address{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              label="Enter representative full address"
              variant="outlined"
              type="text"
              fullWidth
              {...register("user_member_representative_full_address")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor="">
              {" "}
              Enter representative phone number
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              label="Enter representative Phone number"
              variant="outlined"
              type="number"
              fullWidth
              {...register("user_member_representative_phone")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor="">
              Enter representative designation{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              label="Enter representative designation"
              variant="outlined"
              type="text"
              fullWidth
              {...register("user_member_representative_designation")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor="">
              Enter representative NID number{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              label="Enter representative nid number"
              variant="outlined"
              type="number"
              fullWidth
              {...register("user_member_representative_nid_no")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor="">
              Upload representative NID photo{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="file"
              fullWidth
              {...register("user_member_representative_nid_file")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <label htmlFor="">
              {" "}
              Upload representative photo{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="file"
              fullWidth
              {...register("user_member_representative_photo")}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddRepresentativeInfo;
