import { useForm } from 'react-hook-form';
import { Box, Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Toaster from 'src/Toaster/Toaster';
import { url } from 'src/Helpers/utils/Constant';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

interface IProps {
  handleClose: Function;
  setAllPayments: Function;
}
const CreatePaymentableItem = ({ handleClose, setAllPayments }: IProps) => {
  const Toast = Toaster();
  const { user } = useAuthContext();
  const { register, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    if (user.user_admin_id) {
      try {
        const {
          atab_paymentable_item_name,
          atab_paymentable_item_bdt_amount,
          atab_paymentable_item_usd_amount,
        } = inputs;

        const { data } = await axios.post(
          `${url}/api/atab/payment/create/paymentable/item?user_admin_id=${user.user_admin_id}`,
          {
            atab_paymentable_item_name,
            atab_paymentable_item_bdt_amount,
            atab_paymentable_item_usd_amount,
          }
        );

        if (data.success) {
          setAllPayments((prev) => [
            {
              atab_paymentable_item_id: data.data.atab_paymentable_item_id,
              atab_paymentable_item_name,
              atab_paymentable_item_bdt_amount,
              atab_paymentable_item_usd_amount,
            },
            ...prev,
          ]);
          handleClose();
          setLoading(false);
          Toast.fire({
            icon: 'success',
            title: `Admin Created Successfully`,
          });
          reset();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>Create Invoice Item</title>
      </Helmet>
      <Box sx={{ p: 2 }} className=''>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <div className='form-grid'>
            <TextField
              margin='normal'
              required
              fullWidth
              {...register('atab_paymentable_item_name')}
              label='Invoice item name'
              id='atab_paymentable_item_name'
              type='text'
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='name'
                label='Invoice item BDT amount'
                {...register('atab_paymentable_item_bdt_amount')}
                type='number'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='phone'
                label='Invoice item USD amount'
                {...register('atab_paymentable_item_usd_amount')}
                type='number'
              />
            </Grid>
          </Grid>

          {!loading ? (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create Invoice Item
            </Button>
          ) : (
            <LoadingButton
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Creating...
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreatePaymentableItem;
