import React from "react";
import { ISingleOtherBusinessLicense } from "src/Types/module/UpdateRequest/RequestOtherBusinessLicense";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getStatusLabel } from "src/Utils/Combined";
import { imgUrl } from "src/Helpers/utils/Constant";

const useStyles = makeStyles({
  TableCell: {
    fontWeight: "bold !important",
  },
});
interface IProps {
  requestOtherBusinessLicenseInfo: ISingleOtherBusinessLicense;
}
const OtherBusinessLicenseAppReject = ({
  requestOtherBusinessLicenseInfo,
}: IProps) => {
  const classes = useStyles();

  return (
    <Container>
      <Card>
        <CardHeader
          title={
            requestOtherBusinessLicenseInfo.status === "approved"
              ? "Approved information"
              : "Rejected information"
          }
        />
        <TableContainer sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.TableCell}>Status</TableCell>
                <TableCell align="right">
                  {getStatusLabel(requestOtherBusinessLicenseInfo.status)}
                </TableCell>
              </TableRow>

              {requestOtherBusinessLicenseInfo.reject_reason && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Reject reason
                  </TableCell>
                  <TableCell align="right">
                    {requestOtherBusinessLicenseInfo.reject_reason}
                  </TableCell>
                </TableRow>
              )}

              {requestOtherBusinessLicenseInfo.baira_membership ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Membership of BAIRA
                  </TableCell>
                  <TableCell align="right">
                    {requestOtherBusinessLicenseInfo.baira_membership
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              {requestOtherBusinessLicenseInfo.baira_membership_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate of BAIRA
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestOtherBusinessLicenseInfo.baira_membership_file}`}
                    >
                      {requestOtherBusinessLicenseInfo.baira_membership_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestOtherBusinessLicenseInfo.haab_membership ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Membership of HAAB
                  </TableCell>
                  <TableCell align="right">
                    {requestOtherBusinessLicenseInfo.haab_membership
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              {requestOtherBusinessLicenseInfo.haab_membership_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate of HAAB
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestOtherBusinessLicenseInfo.haab_membership_file}`}
                    >
                      {requestOtherBusinessLicenseInfo.haab_membership_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestOtherBusinessLicenseInfo.iata_membership ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Membership of IATA
                  </TableCell>
                  <TableCell align="right">
                    {requestOtherBusinessLicenseInfo.iata_membership
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              {requestOtherBusinessLicenseInfo.iata_membership_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate of IATA
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestOtherBusinessLicenseInfo.iata_membership_file}`}
                    >
                      {requestOtherBusinessLicenseInfo.iata_membership_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestOtherBusinessLicenseInfo.tdab_membership ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Membership of TDAB
                  </TableCell>
                  <TableCell align="right">
                    {requestOtherBusinessLicenseInfo.tdab_membership
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              {requestOtherBusinessLicenseInfo.tdab_membership_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate of TDAB
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestOtherBusinessLicenseInfo.tdab_membership_file}`}
                    >
                      {requestOtherBusinessLicenseInfo.tdab_membership_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestOtherBusinessLicenseInfo.toab_membership ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Membership of TOAB
                  </TableCell>
                  <TableCell align="right">
                    {requestOtherBusinessLicenseInfo.toab_membership
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              {requestOtherBusinessLicenseInfo.toab_membership_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate of TOAB
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestOtherBusinessLicenseInfo.toab_membership_file}`}
                    >
                      {requestOtherBusinessLicenseInfo.toab_membership_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default OtherBusinessLicenseAppReject;
