import { useContext, useEffect, useState } from "react";
import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  ToggleButton,
} from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { SidebarContext } from "src/components/Context/SidebarContext";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HeaderUserbox from "./Userbox";
import { useNavigate } from "react-router-dom";
import { socket } from "src/Helpers/utils/socket";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CLEAR_NOTIFICATION,
  FETCH_NOTIFICATION,
  NEW_NOTIFICATION,
  READ_NOTIFICATION,
  url,
} from "src/Helpers/utils/Constant";
import HeaderNotifications from "./Buttons/Notifications";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { Howl } from "howler";
import { HttpClient } from "src/Helpers/http/http";
// import notificationSound from "/notification-sound.mp3";

interface INotification {
  notification_id: number;
  notification_type: string;
  related_id: number;
  message: string;
  read_status: number;
  created_at: string;
  member_id?: number;
}

// interface INotifications {
//   notification: INotification[];
//   count: number;
// }

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { user } = useAuthContext();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { notification, dispatch } = useAuthContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState({
    dataLoading: false,
    clearLoading: false,
  });

  // const sound = new Howl({
  //   src: ["/notification-sound.mp3"],
  // });
  useEffect(() => {
    setLoading({ ...loading, dataLoading: true });
    try {
      if (!notification.notification.length) {
        (async () => {
          const data: any = await HttpClient.get(
            `${url}/api/notification/admin`
          );

          if (data.success) {
            let count = 0;
            data.data.forEach((item: INotification) => {
              if (!item.read_status) {
                count++;
              }
            });
            dispatch({
              type: FETCH_NOTIFICATION,
              payload: { notification: data.data, count },
            });
            setLoading({ ...loading, dataLoading: false });
          } else {
            setLoading({ ...loading, dataLoading: false });
          }
        })();
      } else {
        setLoading({ ...loading, dataLoading: false });
      }
    } catch (error) {
      setLoading({ ...loading, dataLoading: false });
    }

    socket.on("new_notification", (data) => {
      dispatch({ type: NEW_NOTIFICATION, payload: data });
      // sound.play();

      toast.info(data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }, []);

  const clearData = async (
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoading({ ...loading, clearLoading: true });
    try {
      const data: any = await HttpClient.delete(
        `${url}/api/notification/admin?user_admin_id=${user.user_admin_id}`
      );

      if (data.success) {
        dispatch({ type: CLEAR_NOTIFICATION });
        setLoading({ ...loading, clearLoading: false });
        setOpenModal(false);
      } else {
        setLoading({ ...loading, clearLoading: false });
      }
    } catch (error) {
      setLoading({ ...loading, clearLoading: false });
    }
  };

  const readNoti = async (
    id: number,
    read_status: number,
    type: string,
    related_id: number,
    memberId: number
  ) => {
    socket.emit("read_admin_notification", { id });
    let route = ``;
    switch (type) {
      case "member":
        route = `/member-list/${related_id}`;
        break;
      case "company_update":
        route = `/update-request/company-information/${related_id}?memberid=${memberId}`;
        break;
      case "company_doc_update":
        route = `/update-request/company-docs/${related_id}?memberid=${memberId}`;
        break;
      case "company_owner_update":
        route = `/update-request/owner-info/${related_id}?memberid=${memberId}`;
        break;
      case "company_license_update":
        route = `/update-request/trade-license/${related_id}?memberid=${memberId}`;
        break;
      case "civil_aviation_update":
        route = `/update-request/civil-aviation/${related_id}?memberid=${memberId}`;
        break;
      case "company_business_license_update":
        route = `/update-request/business-license/${related_id}?memberid=${memberId}`;
        break;
      case "company_association_update":
        route = `/update-request/other-business-license/${related_id}?memberid=${memberId}`;
        break;
      case "proposer_update":
        route = `/update-request/proposer-info/${related_id}?memberid=${memberId}`;
        break;
      case "meeting":
        route = `/member-list/meeting/${related_id}`;
        break;
      case "event":
        route = `/member-list/event/${related_id}`;
        break;
      case "id_card":
        route = `/update-applications/id-card/${related_id}`;
        break;
      case "address_change_application":
        route = `/update-applications/address-change-application/${related_id}`;
        break;
      case "duplicate_certificate_application":
        route = `/update-applications/duplicate-certificate/${related_id}`;
        break;
      case "owner_change_application":
        route = `/update-applications/ownership-change-application/${related_id}`;
        break;
      case "renewal_application":
        route = `/update-applications/renewal-application/${related_id}`;
        break;
      // case "invoice":
      //   route = `/invoices/transactions/${related_id}`;
      //   break;
      case "conversation":
        route = `/support/conversations?id=${related_id}`;
        break;
      case "training_application":
        route = `/training-trainee/application/details/${related_id}`;
        break;
      case "trainee_books":
        route = `/service-request/books/${related_id}`;
        break;
      case "trainee_duplicateID_card":
        route = `/service-request/trainee-duplicate-id/${related_id}`;
        break;
      case "trainee_duplicate_certificate":
        route = `/service-request/trainee-duplicate-certificate/${related_id}`;
        break;

      default:
        break;
    }

    if (!read_status) {
      const { data } = await axios.patch(`${url}/api/notification/admin`, {
        id,
      });
      if (data.success) {
        const filterData = notification.notification.filter(
          (sNoti) => sNoti.notification_id === id
        );
        filterData[0].read_status = 1;
        const newNoti = notification.notification.map((noti) =>
          noti.notification_id === id ? filterData[0] : noti
        );

        dispatch({
          type: READ_NOTIFICATION,
          payload: newNoti,
        });
      }
    }
    navigate(route);
    setOpen(false);
  };

  return (
    <>
      <HeaderWrapper
        display="flex"
        alignItems="center"
        sx={{
          boxShadow:
            theme.palette.mode === "dark"
              ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 8px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.2
                )}, 0px 5px 22px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}`,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <ToggleButton onClick={() => navigate(-1)} value="check">
            <ArrowBackIosNewIcon sx={{ fontSize: "15px" }} />
          </ToggleButton>
          <ToggleButton onClick={() => navigate(1)} value="check">
            <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
          </ToggleButton>
        </Box>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          alignItems="center"
          spacing={2}
        >
          {/* <HeaderMenu /> */}
        </Stack>
        <Box display="flex" alignItems="center">
          <Box sx={{ mx: 0.5 }} component="span">
            <HeaderNotifications
              notification={notification}
              clearData={clearData}
              readNoti={readNoti}
              setOpen={setOpen}
              isOpen={isOpen}
              loading={loading}
            />
          </Box>
          <HeaderUserbox />
          <Box
            component="span"
            sx={{
              ml: 2,
              display: { lg: "none", xs: "inline-block" },
            }}
          >
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? (
                  <MenuTwoToneIcon fontSize="small" />
                ) : (
                  <CloseTwoToneIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </HeaderWrapper>
      <ToastContainer />;
    </>
  );
}

export default Header;
