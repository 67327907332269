import { useState, ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  Button,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useForm } from "react-hook-form";
import { ISingleExamInfo } from "src/Utils/type";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

type IProps = {
  handleClose: Function;
  singleExamInfo: ISingleExamInfo;
  setSingleExamInfo: any;
};

const UpdateExam = ({
  handleClose,
  singleExamInfo,
  setSingleExamInfo,
}: IProps) => {
  const { handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState<Boolean>(false);
  const { user } = useAuthContext();
  const [newDate, setNewDate] = useState<Dayjs | null>(
    dayjs(singleExamInfo.training_exam_date)
  );
  const [image, setImage] = useState("");
  const [timeValue, setTimeValue] = useState<Dayjs | null>(() => {
    const date = singleExamInfo?.training_exam_date;
    const time = singleExamInfo?.training_exam_time;

    if (date && time) {
      // Combine date and time in a standard format
      const dateTimeString = `${date.split("T")[0]}T${time}`;
      return dayjs(dateTimeString);
    } else {
      return null;
    }
  });

  // const [timeValue, setTimeValue] = useState<<Dayjs | null>(
  //   new Date(
  //     singleExamInfo?.training_exam_date?.split("T")[0] +
  //       ":" +
  //       singleExamInfo.training_exam_time
  //   )
  // );

  const [status, setStatus] = useState<string>(
    singleExamInfo.training_exam_status
  );

  const onSubmit = async (inputs: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append(
      "training_exam_id",
      singleExamInfo.training_exam_id.toString()
    );
    formData.append(
      "training_exam_batch",
      singleExamInfo.training_batch_id.toString()
    );
    formData.append("training_exam_date", newDate.toISOString().split("T")[0]);
    formData.append("training_exam_time", dayjs(timeValue).format("hh:mm:ss"));
    formData.append(
      "training_exam_material_file",
      image || singleExamInfo.training_exam_material_file
    );
    formData.append("training_exam_status", status);
    setLoading(true);
    try {
      const { data } = await axios.put(
        `${url}/api/training/exam/update?user_admin_id=${user.user_admin_id}`,
        formData
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setSingleExamInfo({
          ...singleExamInfo,
          training_exam_status: status,
        });
        setLoading(false);
        handleClose();
      } else {
        Toaster().fire({
          icon: "failed",
          title: `${data.message}`,
        });
        setLoading(false);
      }
      reset();
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
    reset();
  };
  const statusOptions = [
    {
      id: "upcoming",
      name: "Upcoming",
    },
    {
      id: "finished",
      name: "Finished",
    },
    {
      id: "cancelled",
      name: "Cancelled",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Atab - Update exam</title>
      </Helmet>
      <Container maxWidth="sm">
        <CardHeader title="Update exam" />
        <Divider />
        <CardContent>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            sx={{ pt: 2 }}
          >
            <Grid
              container
              spacing={2}
              columnSpacing={{ xs: 2, sm: 2, md: 3 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid xs={12} md={6} item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Update exam date"
                    format="MM/DD/YYYY"
                    value={newDate}
                    onChange={(e) => setNewDate(e)}
                  />
                </LocalizationProvider>
                {/* <DatePicker
                  label="Select update date"
                  value={newDate}
                  onChange={(e: any) => setNewDate(e)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                /> */}
              </Grid>
              <Grid xs={12} md={6} item>
                <TimePicker
                  label="Update time"
                  value={timeValue}
                  onChange={(e: any) => setTimeValue(e)}
                  // renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid xs={12} md={6} item sx={{ marginTop: "10px" }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="Status"
                    autoWidth
                  >
                    {statusOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6} item>
                <TextField
                  margin="normal"
                  fullWidth
                  id="file"
                  label="Choose exam file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: any) => setImage(e.target.files[0])}
                  autoComplete="file"
                  type="file"
                />
              </Grid>
            </Grid>

            {loading ? (
              <Button
                disabled
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                updating...
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                Update exam
              </Button>
            )}
          </Box>
        </CardContent>
      </Container>
    </>
  );
};

export default UpdateExam;
