import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { imgUrl } from "src/Helpers/utils/Constant";
import { ISingleFair } from "src/Types/module/meeting/singleMemberMeeting";
import { getStatusLabel, getStatusLabelAdminFun } from "src/Utils/Combined";

const SingleFairInfo = ({ fairInfo }: { fairInfo: ISingleFair }) => {
  return (
    <Card sx={{ marginBottom: "45px" }}>
      <CardContent>
        <div
          style={{
            backgroundImage:
              fairInfo.fair_main_banner &&
              `url(${imgUrl}/${fairInfo.fair_main_banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: "450px",
            backgroundPositionY: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            boxShadow: "inset 0 0 0 2000px rgba(34,51,84, 0.7)",
          }}
        >
          <Typography
            textAlign="center"
            gutterBottom
            variant="h2"
            component="div"
            sx={{ opacity: 1, color: "#dee0e3" }}
          >
            Single fair information
          </Typography>
          <Typography
            sx={{ opacity: 1, color: "#dee0e3" }}
            textAlign="center"
            variant="body2"
            color="text.secondary"
          >
            {fairInfo?.fair_details}
          </Typography>
        </div>
        <Box sx={{ m: 3 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Fair ID:
              </Typography>
              {fairInfo?.fair_id}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Fair name:
              </Typography>
              {fairInfo?.fair_name}
            </Grid>
            {fairInfo.fair_location && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Fair location:
                </Typography>
                {fairInfo.fair_location}
              </Grid>
            )}
            {fairInfo.fair_start_date && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Fair start date:
                </Typography>
                {moment(fairInfo.fair_start_date).format("LL")}
              </Grid>
            )}
            {fairInfo.fair_end_date && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Fair end date:
                </Typography>
                {moment(fairInfo.fair_end_date).format("LL")}
              </Grid>
            )}
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Fair status:
              </Typography>
              {getStatusLabel(fairInfo?.fair_status)}
            </Grid>
            {fairInfo.fair_updated_by && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Fair updated by:
                </Typography>
                {moment(fairInfo.fair_updated_by).format("LL")}
              </Grid>
            )}
            {fairInfo.fair_updated_at && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Fair updated at:
                </Typography>
                {moment(fairInfo.fair_updated_at).format("LL")}
              </Grid>
            )}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SingleFairInfo;
