import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";

import { ISingleProposer } from "src/Types/module/UpdateRequest/RequestProposerInfo";

interface IProps {
  preProposerInfo: ISingleProposer[];
}
const CurrentProposerInfo = ({ preProposerInfo }: IProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={21}>
            Current information
          </Typography>
        }
      />
      {preProposerInfo.length &&
        preProposerInfo.map((sPreProposer: ISingleProposer) => {
          return (
            <TableContainer
              sx={{ mb: 3 }}
              key={sPreProposer.proposer_request_id}
            >
              <Table aria-label="custom pagination table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Company Name
                    </TableCell>
                    <TableCell align="right">
                      {sPreProposer?.proposer_request_to_company_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Representative Name
                    </TableCell>
                    <TableCell align="right">
                      {sPreProposer?.proposer_request_to_representative_name}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
    </Card>
  );
};

export default CurrentProposerInfo;
