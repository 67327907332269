import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import ModalsWrapper from "../Modals/ModalsWrapper";
import { imgUrl } from "src/Helpers/utils/Constant";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

const PdfAndImageView = ({ open, setOpen, file }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const type = file?.split(".")?.[1];
  const docs = [{ uri: `${imgUrl}/${file}`, fileType: type }];
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `document`,
  });

  return (
    <>
      <ModalsWrapper
        modalData={{ title: "View document" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <div style={{ padding: "0px 10px" }}>
          {file && type ? (
            <>
              {type === "pdf" ? (
                <div style={{ width: "800px" }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                    <div
                      style={{
                        height: "550px",
                      }}
                    >
                      <Viewer
                        fileUrl={`${imgUrl}/${file}`}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </div>
                  </Worker>
                </div>
              ) : (
                <>
                  {type === "docx" ? (
                    <>
                      <DocViewer
                        documents={docs}
                        style={{ height: "600px", width: "800px" }}
                        pluginRenderers={DocViewerRenderers}
                      />
                      <Box>
                        <a
                          href={`${imgUrl}/${file}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                      </Box>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Box textAlign={"right"}>
                        <a
                          href={`${imgUrl}/${file}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                        <Tooltip title="Print information form">
                          <IconButton onClick={handlePrint}>
                            <PrintIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box textAlign={"center"} ref={componentRef}>
                        {" "}
                        <img
                          className="print-image"
                          src={`${imgUrl}/${file}`}
                          alt=""
                          height={550}
                          width={550}
                          style={{ objectFit: "contain" }}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <Box py={4} alignItems={"center"} justifyContent={"center"}>
              No file found
            </Box>
          )}
        </div>
      </ModalsWrapper>
    </>
  );
};

export default PdfAndImageView;
