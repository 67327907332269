import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { IFairStall } from "src/Types/module/fairStall/fairStallType";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import Toaster from "src/Toaster/Toaster";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { LoadingButton } from "@mui/lab";

type data = {
  stall_type: string;
  stall_status: string;
  air_condition: string;
  hall_name: string;
};
const UpdateFairStall = ({
  handleClose,
  singleData,
  setRefetch,
}: {
  handleClose: any;
  singleData: IFairStall;
  setRefetch: any;
}) => {
  const { user } = useAuthContext();
  const { handleSubmit } = useForm();
  const [dData, setData] = useState<data>({
    stall_type: singleData?.fair_stall_type,
    stall_status: singleData?.fair_stall_available_status,
    air_condition: String(singleData?.fair_stall_air_conditioned),
    hall_name: singleData?.fair_stall_hall_name,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (inputs: any) => {
    // setLoading(true);

    try {
      const { data } = await axios.patch(
        `${url}/api/fair/stall/update/${singleData?.fair_stall_id}?user_admin_id=${user.user_admin_id}`,
        {
          fair_stall_type: dData.stall_type,
          fair_stall_hall_name: dData.hall_name,
          fair_stall_air_conditioned: Number(dData.air_condition),
          fair_stall_available_status: dData.stall_status,
        }
      );
      if (data.success) {
        setLoading(false);
        Toaster().fire({
          icon: "success",
          title: `Successfully update`,
        });
        setRefetch((prv) => !prv);
        handleClose();
      }
    } catch (err: any) {
      setLoading(false);
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>Atab - Update Fair stall</title>
      </Helmet>

      <Box sx={{ p: 2, width: "400px" }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl required margin="normal" fullWidth>
                <InputLabel required id="demo-simple-select-label">
                  Stall Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dData.stall_type}
                  label="Stall type"
                  required
                  onChange={(e: SelectChangeEvent) =>
                    setData({ ...dData, stall_type: e.target.value })
                  }
                >
                  <MenuItem value="STALL">STALL</MenuItem>
                  <MenuItem value="CORNER STALL">CORNER STALL</MenuItem>
                  <MenuItem value="PAVILLION">PAVILLION</MenuItem>
                  <MenuItem value="SEMI-PAVILLION">SEMI-PAVILLION</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl required margin="normal" fullWidth>
                <InputLabel required id="demo-simple-select-label">
                  Stall status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dData.stall_status}
                  label="Stall type"
                  required
                  onChange={(e: SelectChangeEvent) =>
                    setData({ ...dData, stall_status: e.target.value })
                  }
                >
                  <MenuItem value="available">Available</MenuItem>
                  <MenuItem value="booked">Booked</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl required margin="normal" fullWidth>
                <InputLabel required id="demo-simple-select-label">
                  Air condition
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dData.air_condition}
                  label="Stall type"
                  required
                  onChange={(e: SelectChangeEvent) =>
                    setData({ ...dData, air_condition: String(e.target.value) })
                  }
                >
                  <MenuItem value={"1"}>Yes</MenuItem>
                  <MenuItem value={"0"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FormControl required margin="normal" fullWidth>
                <InputLabel required id="demo-simple-select-label">
                  Hall name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dData.hall_name}
                  label="Hall name"
                  required
                  onChange={(e) => {
                    setData({ ...dData, hall_name: e.target.value });
                  }}
                >
                  {dData.air_condition === "1"
                    ? [
                        <MenuItem key="Hormone" value="Hormone">
                          Harmony
                        </MenuItem>,
                        <MenuItem key="Carnival" value="Carnival">
                          Carnival
                        </MenuItem>,
                      ]
                    : [
                        <MenuItem key="Hormone" value="Hormone">
                          Harmony
                        </MenuItem>,
                        <MenuItem key="MilkyWay" value="Milky Way">
                          Milky Way
                        </MenuItem>,
                        <MenuItem key="Carnival" value="Carnival">
                          Carnival
                        </MenuItem>,
                      ]}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <FormControlLabel
                label="Air condition"
                control={
                  <Checkbox
                    checked={airChecked}
                    onChange={() => setAirChecked((prev) => !prev)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            </Grid> */}
          </Grid>

          {!loading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              size="small"
            >
              Update
            </Button>
          ) : (
            <LoadingButton
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Updating...
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UpdateFairStall;
