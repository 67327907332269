import { Box, Button, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";

const UpdateTraineeExpenseHead = ({
  setRefresh,
  handelClose,
  id,
  singleData,
}: any) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const submitData = { type: 2, ...inputs };
    try {
      const res: any = await HttpClient.patch(
        `${url}/api/atab/expense-head/${id}`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        setLoading(false);
        handelClose();
        setRefresh((prv) => !prv);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Box sx={{ p: 2 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                style={{ width: "400px" }}
                required
                defaultValue={singleData?.name}
                id="name"
                label="Expense Head Name"
                type="text"
                variant="outlined"
                {...register("name")}
              />
            </Grid>
          </Grid>
          <Box textAlign={"center"} mt={3}>
            {loading ? (
              <LoadingButton
                loading={loading}
                fullWidth
                color="secondary"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                variant="contained"
              >
                Loading...
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default UpdateTraineeExpenseHead;
