import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import { ISingleRenewalApplication } from "src/Types/module/applications/applications";
import { getStatusLabel } from "src/Utils/Combined";
import UpdateModal from "./UpdateModal";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

const SingleRenewalApplication = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [application, setApplication] =
    useState<ISingleRenewalApplication | null>(null);
  const navigate = useNavigate();
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  useEffect(() => {
    (async () => {
      try {
        const data = await axios.get(
          `${url}/api/member/renewal-application/${id}`
        );
        if (data.data.success) {
          setApplication(data.data.data);
        }
      } catch (err) {
        alert("No application found");
        navigate("/update-applications/renewal-application");
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Atab - Renewal application</title>
      </Helmet>
      <Container maxWidth="xl">
        <Card>
          <CardHeader
            title="Renewal application"
            action={
              <Button onClick={() => setShowModal(true)} variant="outlined">
                Update
              </Button>
            }
          />
          <Divider />
          <CardContent>
            {application ? (
              <Grid
                container
                pt={2}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Application Date:
                  </Typography>
                  {moment(application.renewal_application_created_at).format(
                    "MMM Do YY"
                  )}
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Company name:
                  </Typography>
                  {application.user_member_company_name}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Status:
                  </Typography>
                  {getStatusLabel(application.renewal_application_status)}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {" "}
                  <CommonTypoGraphy
                    name="View Civil Aviation"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.renewal_application_updated_civil_aviation_file
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {" "}
                  <CommonTypoGraphy
                    name="View Trade License"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.renewal_application_updated_trade_license_file
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {" "}
                  <CommonTypoGraphy
                    name="View Tax Return"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.renewal_application_tax_return_certificate_file
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  {" "}
                  <CommonTypoGraphy
                    name="View Previous Certificate"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(
                        application.renewal_application_prev_atab_certificate_file
                      );
                    }}
                  />
                </Grid>
                {application.under_taking_file && (
                  <Grid item xs={2} sm={4} md={4}>
                    {" "}
                    <CommonTypoGraphy
                      name="View Undertaking File"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(application.under_taking_file);
                      }}
                    />
                  </Grid>
                )}
                {application.office_deed_file && (
                  <Grid item xs={2} sm={4} md={4}>
                    {" "}
                    <CommonTypoGraphy
                      name="View Office Deed File"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(application.office_deed_file);
                      }}
                    />
                  </Grid>
                )}
                {application.ltd_docs && (
                  <Grid item xs={2} sm={4} md={4}>
                    {" "}
                    <CommonTypoGraphy
                      name="View Ltd. Co. all Docs File"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(application.ltd_docs);
                      }}
                    />
                  </Grid>
                )}
                {application.schedule_10_12 && (
                  <Grid item xs={2} sm={4} md={4}>
                    {" "}
                    <CommonTypoGraphy
                      name="View Schedule 10/12 File"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(application.schedule_10_12);
                      }}
                    />
                  </Grid>
                )}

                {application.renewal_application_reject_reason ? (
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Note:
                    </Typography>
                    {application.renewal_application_reject_reason}
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            ) : (
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                No Data found
              </Typography>
            )}
          </CardContent>
        </Card>
      </Container>
      {application && (
        <UpdateModal
          loading={loading}
          setShowModal={setShowModal}
          showModal={showModal}
          setLoading={setLoading}
          application={application}
          setApplication={setApplication}
        />
      )}

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default SingleRenewalApplication;
