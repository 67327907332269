import { Dispatch, SetStateAction, useState } from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";

import { Helmet } from "react-helmet-async";
import { url } from "../../../../../Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { ISingleClassWithAttendance } from "src/Types/module/batch/allTypeOfBatch";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type IProps = {
  singleClass: ISingleClassWithAttendance;
  setSingleClass: Dispatch<SetStateAction<any>>;
  handleClose: Function;
  setReload: Dispatch<SetStateAction<boolean>>;
};

const UpdateClass = ({
  singleClass,
  handleClose,
  setSingleClass,
  setReload,
}: IProps) => {
  const { handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>(
    singleClass.training_class_status
  );
  const [remark, setRemark] = useState<string>();

  const [timeValue, setTimeValue] = useState<Dayjs | null>(() => {
    const date = singleClass?.training_class_date;
    const time = singleClass?.training_class_end_time;

    if (date && time) {
      // Combine date and time in a standard format
      const dateTimeString = `${date.split("T")[0]}T${time}`;
      return dayjs(dateTimeString);
    } else {
      return null;
    }
  });

  // new Date(
  //   singleClass?.training_class_date?.split("T")[0] +
  //     ":" +
  //     singleClass?.training_class_end_time
  // );

  const { user } = useAuthContext();
  const onSubmit = async () => {
    const inputs = {
      training_class_id: singleClass.training_class_id,
      training_class_status: status,
      training_class_end_time: dayjs(timeValue).format("hh:mm:ss"),
      training_class_remark: remark,
    };

    if (status === "ended" && !timeValue)
      return Toaster().fire({
        icon: "error",
        title: "Time is required",
      });

    setLoading(true);
    try {
      const { data } = await axios.put(
        `${url}/api/training/class/update?user_admin_id=${user.user_admin_id}`,
        inputs
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setSingleClass({ ...singleClass, training_class_status: status });
        setLoading(false);
        handleClose();
        setReload(true);
      } else {
        Toaster().fire({
          icon: "failed",
          title: `${data.message}`,
        });
        setLoading(false);
      }

      reset();
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
    reset();
  };

  return (
    <>
      <Helmet>
        <title>Atab - Update Class</title>
      </Helmet>

      {singleClass && (
        <Box onSubmit={handleSubmit(onSubmit)} component="form">
          <Grid container spacing={2}>
            <Grid lg={6} md={6} sm={12} item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Class End Time"
                  value={timeValue}
                  sx={{ width: "100%" }}
                  onChange={(e: any) => setTimeValue(e)}
                />
              </LocalizationProvider>
              {/* <TimePicker
                label="Class End Time"
                value={timeValue && timeValue}
                onChange={(e: any) => setTimeValue(e)}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              /> */}
            </Grid>
            <Grid lg={6} md={6} sm={12} item>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel>Select status</InputLabel>
                <Select
                  onChange={(e: any) => setStatus(e.target.value)}
                  label="Select status"
                  fullWidth
                  defaultValue={singleClass?.training_class_status}
                >
                  <MenuItem value="running">Running</MenuItem>
                  <MenuItem value="ended">Ended</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid lg={12} md={12} item>
              <TextField
                label="Class Remark"
                defaultValue={singleClass && singleClass.training_class_remark}
                multiline
                onChange={(event) => {
                  setRemark(event.target.value);
                }}
                rows={6}
                maxRows={10}
                fullWidth
              />
            </Grid>
          </Grid>

          {loading ? (
            <Button
              disabled
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Updating...
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Update Class
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default UpdateClass;
