import { SimCardDownload } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { SetStateAction, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BillingInfo from "./BillingInfo";
import useTheme from "@mui/material/styles/useTheme";
import logo from "../../../../assets/image/logo.png";
import EditIcon from "@mui/icons-material/Edit";
import { visitorSingleInvoiceInfo } from "src/Utils/type";
import { Box } from "@mui/system";

type IProps = {
  singleInvoiceInfo: visitorSingleInvoiceInfo;
  handleEditModeChange: Function;
  editMode: boolean;
};
const CardInvoice = ({
  singleInvoiceInfo,
  handleEditModeChange,
  editMode,
}: IProps) => {
  const theme = useTheme();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Box>
      <Card ref={componentRef} className="invoice_print_form">
        <CardContent>
          <Box
            className="invoice-card-header"
            sx={{ display: "flex", justifyContent: "spece-between" }}
          >
            <Typography variant="h4" color="text.primary">
              Visitor Information
            </Typography>
            <Box className="invoice_icon_section">
              <Box className="hide_from_invoice">
                {editMode ? (
                  <Tooltip title="Close edit">
                    <IconButton
                      onClick={() => handleEditModeChange()}
                      sx={{
                        cursor: "pointer",
                        color: theme.colors.primary.main,
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Invoice">
                    <IconButton
                      onClick={() => handleEditModeChange()}
                      sx={{
                        cursor: "pointer",
                        color: theme.colors.primary.main,
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!editMode && (
                  <Tooltip title="Print Invoice">
                    <IconButton
                      onClick={handlePrint}
                      sx={{
                        cursor: "pointer",
                        color: theme.colors.primary.main,
                      }}
                    >
                      <SimCardDownload />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <img
                src="/static/images/logo/atab-logo.png"
                alt="Atab Logo"
                className="invoice_image"
              />
            </Box>
          </Box>
          <Divider />
          {singleInvoiceInfo && (
            <BillingInfo
              editMode={editMode}
              handleEditModeChange={handleEditModeChange}
              singleInvoiceDetails={singleInvoiceInfo}
            />
          )}
        </CardContent>
        <div className="invoice_footer_form">
          <h2>Received with terms</h2>
          <h2>Authority</h2>
        </div>
      </Card>
    </Box>
  );
};

export default CardInvoice;
