import { Box } from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';

const Spinner = () => {
  return (
    <Box className='spinner-wrapper' sx={{ textAlign: 'center' }}>
      <ScaleLoader color='#5E60FF' />
    </Box>
  );
};

export default Spinner;
