import React from 'react';
import CommonMemberTable from '../commonModule/CommonMemberTable';

const BlockedMemberTable = () => {
  return (
    <div>
      <CommonMemberTable status='blocked' title='Blacklisted Members' />
    </div>
  );
};

export default BlockedMemberTable;
