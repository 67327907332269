import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Container } from "@mui/material";
import { useForm } from "react-hook-form";
import { muiStyles } from "src/Utils/Styles";
import Toaster from "src/Toaster/Toaster";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { ISingleFairEvent } from "src/Utils/type";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

const useStyles = muiStyles;

interface IProps {
  singleEvent: ISingleFairEvent;
  handleEditModeChange: Function;
  handleClose: Function;
}
const EventInfoUpdate = ({
  handleEditModeChange,
  handleClose,
  singleEvent,
}: IProps) => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const Toast = Toaster();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(singleEvent?.event_date)
  );

  const [success, setSuccess] = useState(false);

  const handleDateChange = (event: any) => {
    setSelectedDate(event);
  };

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    inputs.event_updated_by = user.user_admin_id;
    inputs.event_id = singleEvent?.event_id;
    inputs.event_date = selectedDate
      ? selectedDate.toISOString().slice(0, 10)
      : singleEvent?.event_date;

    const formData = new FormData();

    try {
      for (const key in inputs) {
        if (inputs[key]) {
          if (typeof inputs[key] === "object") {
            if (inputs[key].length) {
              formData.append(key, inputs[key][0]);
            }
          } else {
            formData.append(key, inputs[key]);
          }
        }
      }

      const { data } = await axios.put(
        `${url}/api/fair/event/for-admin/update`,
        formData
      );
      if (data.success) {
        Toast.fire({
          icon: "success",
          title: data.message,
        });
        setLoading(false);
        setSuccess((prv) => !prv);
        handleClose();
      } else {
        Toast.fire({
          icon: "error",
          title: data.message,
        });
        setLoading(false);
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: `${error.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      handleEditModeChange();
    }
  }, [success]);
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <TextField
            margin="normal"
            fullWidth
            id="event title"
            defaultValue={singleEvent?.event_title}
            label="Enter event title"
            {...register("event_title")}
            type="text"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            margin="normal"
            fullWidth
            id="event type"
            label="Enter event type"
            defaultValue={singleEvent?.event_type}
            {...register("event_type")}
            type="text"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TextField
            margin="normal"
            fullWidth
            id="event venue"
            label="Enter event venue"
            defaultValue={singleEvent?.event_venu}
            {...register("event_venu")}
            type="text"
            autoFocus
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            margin="normal"
            fullWidth
            id="file"
            label="Choose event Photo"
            InputLabelProps={{ shrink: true }}
            {...register("event_photo")}
            type="file"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} mt={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Update meeting date"
              format="MM/DD/YYYY"
              value={selectedDate}
              onChange={handleDateChange}
              // renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={12} mt={2}>
          <TextField
            rows={6}
            maxRows={8}
            multiline
            defaultValue={singleEvent?.event_description}
            {...register("event_description")}
            label="Enter event description"
            fullWidth
          />
        </Grid>
      </Grid>
      {loading ? (
        <Button
          disabled
          fullWidth
          variant="contained"
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Loading...
        </Button>
      ) : (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          sx={{
            mt: 3,
            mb: 2,
            py: 1,
          }}
        >
          Update Event
        </Button>
      )}
    </Box>
  );
};

export default EventInfoUpdate;
