import { TOKEN_KEY } from 'src/Helpers/utils/Constant';

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const createMemberId = (id: number = 0) => {
  let newId = '';
  for (let i = 1; i < 6 - id.toString().length; i++) {
    newId += `0`;
  }
  return 'MN-' + newId + id;
};
