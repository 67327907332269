import { Divider, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { IJournalReportType } from "./journalReportTypes";

type IProps = {
  singleJournalData: IJournalReportType;
};
const JournalReportTableRow = ({ singleJournalData }: IProps) => {
  const {
    VoucherDate,
    VoucherNo,
    Description,
    entries: { debits, credits },
  } = singleJournalData || {};

  return (
    <TableRow hover>
      <TableCell>{moment(VoucherDate).format("DD-MMM-YYYY")}</TableCell>
      <TableCell>{VoucherNo}</TableCell>
      <TableCell>{Description}</TableCell>
      <TableCell>
        <div>
          {debits?.map((item) => <p>{item?.AccountHeadName}</p>)}
          <Divider />
          {credits?.map((item) => (
            <p style={{ marginLeft: "20px" }}>{item?.AccountHeadName}</p>
          ))}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {debits?.map((item) => <p>{item?.Debit}</p>)}
          <Divider />
          {credits?.map((item) => <p>--</p>)}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {debits?.map((item) => <p>--</p>)}
          <Divider />
          {credits?.map((item) => <p>{item?.Credit}</p>)}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default JournalReportTableRow;
