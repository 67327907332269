import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import IncomeStatementReportTableRow from "./IncomeStatementReportTableRow";
import { IIncomeStatementReportType } from "./incomeStatementReportTypes";

const TraineeIncomeStatementReport = () => {
  const [IncomeStatementData, setIncomeStatementData] =
    useState<IIncomeStatementReportType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | string>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<Dayjs | string>(
    dayjs().endOf("month")
  );
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/admin/accounts/report/income-statement?CompanyProfileId=2&from_date=${dayjs(startDate).format("YYYY-MM-DD")}&to_date=${dayjs(endDate).format("YYYY-MM-DD")}`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setIncomeStatementData(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh, startDate, endDate]);

  /* Total Income */
  const totalIncome = IncomeStatementData?.income?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );
  /* Total Expenditure */
  const totalExpenditure = IncomeStatementData?.expense?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );
  const totalProfitOrLoss = totalIncome - totalExpenditure;
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e: any) => setStartDate(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      variant="h6"
                      component="h6"
                      gutterBottom
                    >
                      To
                    </Typography>

                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e: any) => setEndDate(e)}
                    />
                  </Grid>
                </Box>
              }
              title="Income Statement"
            />
            <Divider />
            <CardContent>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                }}
              >
                Overall Income Details
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Accounts</span>
                          <span>Amount</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : IncomeStatementData?.income ? (
                      <>
                        <IncomeStatementReportTableRow
                          singleIncomeStatementData={
                            IncomeStatementData?.income
                          }
                        />
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardContent>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "8px",
                }}
              >
                Overall Expense Details
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Accounts</span>
                          <span>Amount</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : IncomeStatementData?.expense ? (
                      <>
                        <IncomeStatementReportTableRow
                          singleIncomeStatementData={
                            IncomeStatementData?.expense
                          }
                        />
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "24px" }}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardHeader title="Total Profit/Loss" />
            <Divider />
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Accounts</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : (
                    <>
                      <TableRow>
                        <TableCell>Total Income</TableCell>
                        <TableCell>{totalIncome}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Expenditure</TableCell>
                        <TableCell>{totalExpenditure}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong
                            style={{
                              color: totalProfitOrLoss > 0 ? "green" : "red",
                            }}
                          >
                            Profit/Loss
                          </strong>
                        </TableCell>
                        <TableCell>
                          <strong
                            style={{
                              color: totalProfitOrLoss > 0 ? "green" : "red",
                            }}
                          >
                            {Math.abs(totalProfitOrLoss)}
                          </strong>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TraineeIncomeStatementReport;
