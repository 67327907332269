import { FC, ChangeEvent, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
} from "@mui/material";

import FairMemberTableRow from "./FairMemberTableRow";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { IFairMember } from "src/Utils/type";
import { debounce } from "lodash";

const applyPagination = (
  fairAllMember: IFairMember[],
  page: number,
  limit: number
): IFairMember[] => {
  return fairAllMember.slice(page * limit, page * limit + limit);
};

const FairMemberTable = ({ fairAllMember, loading, setFilterITem, status }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const statusOptions = [
    {
      id: "approved",
      name: "Approved",
    },
    {
      id: "pending",
      name: "Pending",
    },

    {
      id: "rejected",
      name: "Rejected",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(fairAllMember, page, limit);
  const handelChangeInput = debounce((value) => {
    setFilterITem((prv) => {
      return { ...prv, searchParams: value };
    });
  }, 1000);
  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <TextField
              style={{ width: "250px" }}
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              size="small"
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  handelChangeInput(e.target.value)

                // setSearchCompanyVal(e.target.value)
              }
            />{" "}
            <FormControl fullWidth variant="outlined" sx={{ width: "150px" }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) =>
                  setFilterITem((prv) => {
                    return { ...prv, status: e.target.value };
                  })
                }
                label="Status"
                autoWidth
                size="small"
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        title="Exhibitor List"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Member name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Created date</TableCell>
              {/* <TableCell>Fair details</TableCell> */}
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : paginatedInvoice.length ? (
              <>
                {paginatedInvoice.map((allAdmin: IFairMember) => {
                  return (
                    <FairMemberTableRow
                      key={allAdmin.user_fair_member_id}
                      allAdmin={allAdmin}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={fairAllMember.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
    </Card>
  );
};

export default FairMemberTable;
