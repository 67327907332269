import {
  Container,
  Card,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import ShowTraineeIdCard from "./ShowTraineeIdCard";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { imgUrl } from "src/Helpers/utils/Constant";
export type TIdCard = {
  batch_id: number;
  batch_no: string;
  id_photo: string;
  created_at: string;
  created_by: number;
  id: number;
  trainee_id: number;
  training_batch_name: string;
  training_course_application_name: string;
  valid_date: string;
};

const IdCardList = () => {
  const { id } = useParams();
  const [cardList, setCardList] = useState<TIdCard[]>([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data: any = await HttpClient.get(
          `/api/trainee/id-card?batch_id=${id}`
        );

        if (data.success) {
          setCardList(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [id, refresh]);

  const downloadFiles = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder("images");

    const fetchPromises = cardList.map(async (trainee) => {
      const response = await fetch(`${imgUrl}/${trainee.id_photo}`);
      console.log(response);

      const blob = await response.blob();

      imgFolder.file(`${trainee.training_course_application_name}.jpeg`, blob);
    });

    await Promise.all(fetchPromises);
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "IDCards.zip");
  };
  return (
    <div>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Card>
          <CardHeader
            title="ID card list"
            action={
              cardList?.length ? (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={downloadFiles}
                >
                  Download All
                </Button>
              ) : (
                ""
              )
            }
          />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SL No</TableCell>
                  <TableCell>Trainee Name</TableCell>
                  <TableCell>Validity date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableLoader />
                ) : cardList?.length ? (
                  <>
                    {cardList?.map((trainee, index: number) => (
                      <ShowTraineeIdCard
                        key={index}
                        trainee={trainee}
                        index={index}
                        setRefresh={setRefresh}
                      />
                    ))}
                  </>
                ) : (
                  <TableNotFound />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </div>
  );
};

export default IdCardList;

// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Card,
//   CardHeader,
//   TableContainer,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Button,
//   Box,
// } from "@mui/material";
// import { useParams } from "react-router-dom";
// import { HttpClient } from "src/Helpers/http/http";
// import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
// import ShowTraineeIdCard from "./ShowTraineeIdCard";
// import { Document, Page, pdf, Image, StyleSheet } from "@react-pdf/renderer";
// import { saveAs } from "file-saver";
// import { imgUrl } from "src/Helpers/utils/Constant";
// import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";

// // Define styles for the PDF
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "column",
//     backgroundColor: "#E4E4E4",
//   },
//   image: {
//     margin: 10,
//     padding: 10,
//     objectFit: "contain",
//   },
// });

// export type TIdCard = {
//   batch_id: number;
//   batch_no: string;
//   id_photo: string;
//   created_at: string;
//   created_by: number;
//   id: number;
//   trainee_id: number;
//   training_batch_name: string;
//   training_course_application_name: string;
//   valid_date: string;
// };

// const IdCardList = () => {
//   const { id } = useParams();
//   const [cardList, setCardList] = useState<TIdCard[]>([]);
//   const [loading, setLoading] = useState(false);
//   const [refresh, setRefresh] = useState(false);
//   const [openPdfView, setOpenPdfView] = useState(false);
//   const [pdfUrl, setPdfUrl] = useState("");

//   useEffect(() => {
//     (async () => {
//       try {
//         setLoading(true);
//         const data: any = await HttpClient.get(
//           `/api/trainee/id-card?batch_id=${id}`
//         );

//         if (data.success) {
//           setCardList(data.data);
//           setLoading(false);
//         } else {
//           setLoading(false);
//         }
//       } catch (err) {
//         setLoading(false);
//       }
//     })();
//   }, [id, refresh]);

//   function extractIdPhotos(arr: TIdCard[]) {
//     return arr.map((obj) => ({
//       id_photo: obj.id_photo || "",
//     }));
//   }

//   const onlyPhoto = extractIdPhotos(cardList);

//   const MyDocument = () => (
//     <Document>
//       {onlyPhoto.map((photo, index) => (
//         <Page key={index} size="A4" style={styles.page}>
//           <Image src={`${imgUrl}/${photo.id_photo}`} style={styles.image} />
//         </Page>
//       ))}
//     </Document>
//   );

//   const handleDownloadAllPhotos = async () => {
//     try {
//       const pdfBlob = await pdf(<MyDocument />).toBlob();
//       saveAs(pdfBlob, "All_ID_Cards.pdf");
//     } catch (error) {
//       console.error("Error generating PDF:", error);
//     }
//   };

//   return (
//     <div>
//       <Container sx={{ mt: 4 }} maxWidth="xl">
//         <Card>
//           <CardHeader
//             title="ID card list"
//             action={
//               <Box>
//                 <Button
//                   onClick={handleDownloadAllPhotos}
//                   variant="contained"
//                   color="primary"
//                   sx={{ mr: 2 }}
//                 >
//                   Download All Photos
//                 </Button>
//               </Box>
//             }
//           />

//           <TableContainer>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>SL No</TableCell>
//                   <TableCell>Trainee Name</TableCell>
//                   <TableCell>validity date</TableCell>
//                   <TableCell>Download</TableCell>
//                   <TableCell>View</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {loading ? (
//                   <TableLoader />
//                 ) : cardList?.length ? (
//                   <>
//                     {cardList?.map((trainee, index: number) => (
//                       <ShowTraineeIdCard
//                         key={index}
//                         trainee={trainee}
//                         index={index}
//                         setRefresh={setRefresh}
//                       />
//                     ))}
//                   </>
//                 ) : (
//                   <TableNotFound />
//                 )}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Card>
//       </Container>
//       {pdfUrl && (
//         <PdfAndImageView
//           open={openPdfView}
//           setOpen={setOpenPdfView}
//           file={pdfUrl}
//         />
//       )}
//     </div>
//   );
// };

// export default IdCardList;
