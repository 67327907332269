import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ISingleInvoiceList } from "src/Utils/type";

type IProps = {
  itemDetails: ISingleInvoiceList[];
};

function ItemList({ itemDetails }: IProps) {
  const theme = useTheme();
  return (
    <>
      <Typography
        sx={{ textDecoration: "underline" }}
        mt={8}
        mb={3}
        color="text."
        align="center"
        variant="h4"
      >
        Invoice Items
      </Typography>

      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Item Name
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Discount
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align="center"
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemDetails?.map((details: ISingleInvoiceList) => (
              <TableRow key={details?.training_payment_invoice_item_id}>
                <TableCell>
                  {details?.training_payment_invoice_item_id}
                </TableCell>
                <TableCell align="center">
                  {details?.training_payment_invoice_item_name}
                </TableCell>
                <TableCell align="center">
                  {details?.training_payment_invoice_discount}
                </TableCell>
                <TableCell align="center">
                  {details?.training_payment_invoice_item_amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ItemList;
