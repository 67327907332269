import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import { getStatusLabel } from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { visitorList } from "src/Utils/type";

interface IProps {
  visitor: visitorList;
  setReload: Function;
}

const VisitorTableRow = ({ visitor, setReload }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover key={visitor?.visitor_id}>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitor?.visitor_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex" }}>
          <img
            width={30}
            height={30}
            style={{
              borderRadius: "50%",
              marginRight: "10px",
            }}
            src={
              visitor?.visitor_photo
                ? `${imgUrl}/${visitor?.visitor_photo}`
                : "/static/images/avatars/avatar2.png"
            }
            alt=""
          />

          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {visitor?.visitor_first_name} {visitor?.visitor_last_name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitor?.visitor_email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitor?.visitor_phone}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitor?.visitor_designation}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitor?.visitor_type}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitor?.visitor_organization}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {visitor?.visitor_gender}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {getStatusLabel(visitor?.visitor_status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link to={`/visitor/list/${visitor?.visitor_id.toString()}`}>
          <Tooltip title="view" arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default VisitorTableRow;
