import { ChangeEvent, useState } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import FairStallTableRow from "./FairStallTableRow";
import { IFairStall } from "src/Types/module/fairStall/fairStallType";
import { applyPagination } from "src/Utils/CommonAllShowUtils";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import CreateFairStall from "../FairStall/CreateFairStall/CreateFairStall";

const FairStallTable = ({
  allAdmin,
  setAllAdmins,
  loading,
  setStatus,
  setSecStatus,
  secStatus,
  status,
  setRefetch,
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const statusOptions = [
    {
      id: "available",
      name: "Available",
    },
    {
      id: "reserved",
      name: "Reserved",
    },
    {
      id: "booked",
      name: "Booked",
    },
  ];
  const secStatusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "Milky Way",
      name: "Milky Way",
    },
    {
      id: "Carnival",
      name: "Carnival",
    },
    {
      id: "Hormone",
      name: "Hormone",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allAdmin, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <FormControl variant="outlined" sx={{ width: "150px" }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                autoWidth
                size="small"
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "150px" }} variant="outlined">
              <InputLabel>Hall Name</InputLabel>
              <Select
                value={secStatus}
                size="small"
                onChange={(e) => setSecStatus(e.target.value)}
                label="Hall Name"
                autoWidth
              >
                {secStatusOptions.map((statusSecOption) => (
                  <MenuItem key={statusSecOption.id} value={statusSecOption.id}>
                    {statusSecOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={() => handleOpen()}
              variant="contained"
              size="small"
            >
              Create Stall
            </Button>
          </Box>
        }
        title="Stall List"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {/* <TableCell>Name</TableCell> */}
              <TableCell>Stall Type</TableCell>
              <TableCell>Hall name</TableCell>
              <TableCell>Create Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : allAdmin.length ? (
              <>
                {paginatedInvoice.map((allAdmin: IFairStall) => {
                  return (
                    <FairStallTableRow
                      key={allAdmin.fair_stall_id}
                      allAdmin={allAdmin}
                      setRefetch={setRefetch}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={allAdmin.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2 }}> Create Stall</Typography>
            <Divider />
          </Box>
          <CreateFairStall
            handleClose={handleClose}
            setAllAdmins={setAllAdmins}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default FairStallTable;
