import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { IChartOfAccount } from "../types/AccountTypes";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";

const ChartOfAccount = () => {
  const [limit, setLimit] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [allChartData, setAllChartData] = useState<IChartOfAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
  });
  const page = parseInt(searchParams.get("page"));
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/admin/accounts/chart-of-accounts?CompanyProfileId=1`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAllChartData(res.data);
          setLoading(false);
          setTotal(res.data.length);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page, limit]);
  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const renderTreeItems = (item: IChartOfAccount, parentId: string = "") => {
    const uniqueId = `${parentId}-${item.Id}`;

    return (
      <TreeItem
        key={uniqueId}
        itemId={uniqueId}
        label={
          <div>
            <span>{item.Name}</span>
          </div>
        }
      >
        {item.AccSubGroup &&
          item.AccSubGroup.map((subItem) => renderTreeItems(subItem, uniqueId))}
        {item.AccHeadGroup &&
          item.AccHeadGroup.map((subItem) =>
            renderTreeItems(subItem, uniqueId)
          )}
        {item.AccHeadAndSubGroup &&
          item.AccHeadAndSubGroup.map((subItem) =>
            renderTreeItems(subItem, uniqueId)
          )}
        {item.AccHead &&
          item.AccHead.map((subItem) => renderTreeItems(subItem, uniqueId))}
      </TreeItem>
    );
  };
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Chart Of Account" />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : allChartData.length ? (
                      <>
                        {allChartData.map((item) => (
                          <TableRow key={item.Id}>
                            <TableCell>
                              <SimpleTreeView>
                                {renderTreeItems(item, String(item.Id))}
                              </SimpleTreeView>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChartOfAccount;
