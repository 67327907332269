import { Imembers } from "src/Types/module/member/memberTypes";
import { Button } from "@mui/material";
import jsPDF from "jspdf";
import { useState } from "react";
import GlobalLoader from "../SuspenseLoader/GlobalLoader";
import { imgUrl } from "src/Helpers/utils/Constant";

// import { imgUrl } from "src/Helpers/utils/Constant";

interface Props {
  getDownloadData: () => Promise<{
    total: number;
    data: Imembers[];
  }>;
}

const PdfGenerator = ({ getDownloadData }: Props) => {
  const [loading, setLoading] = useState(false);

  const generatePdf = async () => {
    var doc = new jsPDF("p", "pt");
    setLoading(true);
    const data = await getDownloadData();

    const ROWS_PER_PAGE = 9;
    const totalPages = Math.ceil(data.total / ROWS_PER_PAGE);

    for (let page = 1; page <= totalPages; page++) {
      doc.setFontSize(22).text("ATAB Member", 300, 25, { align: "center" });
      doc.setFontSize(12).text(`Total: ${data.total}`, 30, 40);
      doc
        .setFontSize(12)
        .text(`Date:${new Date().toDateString()}`, 570, 40, { align: "right" });

      doc.setFontSize(8);
      const startIndex = (page - 1) * ROWS_PER_PAGE;
      const endIndex = Math.min(startIndex + ROWS_PER_PAGE, data.total);
      let j = 0;

      for (let i = startIndex; i < endIndex; i++) {
        const position = 10 + (j + 1) * 80;

        j++;
        const image = data.data[i].user_member_representative_photo
          ? ` ${imgUrl}/${data.data[i].user_member_representative_photo}`
          : "/static/images/avatars/avatar2.png";

        // `${imgUrl}/${data.data[i].user_member_representative_photo}`;

        doc.addImage(image, "JPEG", 55, position - 10, 50, 50);

        doc
          .setFontSize(10)
          .setFont("helvetica", "bold")
          .text(`${data.data[i].user_member_company_name}`, 150, position)

          .setFontSize(9)
          .text(`${data.data[i].company_owner_name}`, 150, position + 12)
          .setFontSize(8)
          .setFont("helvetica", "normal")
          .text(`${data.data[i].company_owner_designation}`, 150, position + 22)

          .text(`${data.data[i].addressline1}`, 150, position + 35, {
            maxWidth: 200,
          })
          .text(`Phone`, 380, position)
          .text(`:`, 410, position)
          .text(`${data.data[i].user_member_phone}`, 420, position, {
            maxWidth: 200,
          })
          .text(`Mobile`, 380, position + 15)
          .text(`:`, 410, position + 15)
          .text(`${data.data[i].cellphone}`, 420, position + 15, {
            maxWidth: 200,
          })
          .text(`E-mail`, 380, position + 30)
          .text(`:`, 410, position + 30)
          .text(`${data.data[i].user_member_email}`, 420, position + 30, {
            maxWidth: 120,
          });
        // .text(`Email: `, 150, position + 20)
        // .text(
        //   `${data.data[i].user_member_email?.slice(0, 100)}`,
        //   150,
        //   position + 30
        // );
        // .text(`Phone: `, 150, position + 40)
        // .text(`${data.data[i].user_member_phone}`, 150, position + 50);

        doc.setFontSize(10).text(`Page ${page} of ${totalPages}`, 230, 815, {
          align: "left",
        });
      }
      if (page < totalPages) {
        doc.addPage();
      }
    }
    setLoading(false);
    doc.save("member_list.pdf");
  };

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button
          onClick={generatePdf}
          variant="outlined"
          size="small"
          sx={{ py: 1 }}
        >
          Download pdf
        </Button>
      )}
    </>
  );
};

export default PdfGenerator;
