import {
  Autocomplete,
  Button,
  CardContent,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { IBatchList, ISelectOptions } from "src/Types/module/courses/trainee";
import { ISingleTraineeApplication } from "src/Utils/type";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

const AssignBatch = ({
  details,
  handelClose,
  setReload,
}: {
  details: ISingleTraineeApplication;
  handelClose: any;
  setReload: any;
}) => {
  const { user } = useAuthContext();

  const [batchID, setBatchID] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [batchList, setBatchList] = useState<IBatchList[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/batch/get/all/or/status/running?course_id=${details?.training_course_application_course_id}`
        );

        if (data.success) {
          setBatchList(data.data);
        }
      } catch (error) {}
    })();
  }, [details?.training_course_application_course_id]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const data: any = {
      batch_id: batchID,
      batch_trainee: [details?.training_course_application_id],
      course_id: details?.training_course_application_course_id,
      status: "assigned",
    };

    try {
      setLoading(true);

      const res = await axios.post(
        `${url}/api/training/batch/add/trainee/to/batch?user_admin_id=${user.user_admin_id}`,
        data
      );

      if (res.data.success) {
        Toaster().fire({
          icon: "success",
          title: "Successfully assign in batch",
        });
        setReload();
        handelClose();
        setLoading(false);
      } else {
        Toaster().fire({
          icon: "error",
          title: res.data.message,
        });
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  const batchOptions = batchList?.length
    ? batchList?.map((batch) => {
        return {
          id: batch.training_batch_id,
          label: `${batch.training_batch_name}(${batch?.batch_no})`,
        };
      })
    : [];
  return (
    <Box p={2}>
      {" "}
      <Box onSubmit={onSubmit} component="form" sx={{ mt: 1 }} maxWidth={"sm"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={batchOptions}
              getOptionLabel={(option) => `${option.label}`}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {`${option.label}`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a batch"
                  variant="outlined"
                  style={{ width: "330px" }}
                  required
                />
              )}
              onChange={(_event, value) => {
                setBatchID(value?.id);
              }}
            />
          </Grid>
        </Grid>

        {loading ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            disabled
            sx={{
              mt: 3,
              py: 1,
            }}
          >
            Creating...
          </Button>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              py: 1,
            }}
          >
            Assign
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AssignBatch;
