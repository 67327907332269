import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";

const CurrentOwnerInfo = ({ prvOwnerInfo }: any) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={21}>
            Current information
          </Typography>
        }
      />
      {prvOwnerInfo?.map((sOwner) => {
        return (
          <TableContainer key={sOwner.company_owner_id}>
            <Table aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner name
                  </TableCell>
                  <TableCell align="right">
                    {sOwner.company_owner_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner designation
                  </TableCell>
                  <TableCell align="right">
                    {sOwner.company_owner_designation}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner phone
                  </TableCell>
                  <TableCell align="right">
                    {sOwner.company_owner_phone}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner email
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {sOwner.company_owner_email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner address
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {sOwner.company_owner_address}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner nid
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {sOwner.company_owner_nid}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner photo
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      href={`${imgUrl}/${sOwner.company_owner_photo}`}
                    >
                      {sOwner.company_owner_photo}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner bio data
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      href={`${imgUrl}/${sOwner.company_owner_biodata}`}
                    >
                      {sOwner.company_owner_biodata}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner passport
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      href={`${imgUrl}/${sOwner.company_owner_passport}`}
                    >
                      {sOwner.company_owner_passport}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company owner signature
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      href={`${imgUrl}/${sOwner.company_owner_signature}`}
                    >
                      {sOwner.company_owner_signature}
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      })}
    </Card>
  );
};

export default CurrentOwnerInfo;
