import CommonMemberTable from "../commonModule/CommonMemberTable";

const ApplicationChecker = () => {
  return (
    <>
      <CommonMemberTable
        status="Application Inspection"
        title={"Application Forward List"}
      />
    </>
  );
};

export default ApplicationChecker;
