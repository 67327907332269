import { useEffect, useState } from "react";
import { CssTextField } from "./CustomTextFiled";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
} from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";

interface ISourceInfoList {
  name: string;
  id: number;
  checked: boolean;
}

const Sourceinfo = ({
  sourceInfo,
  setSourceInfo,
  setOtherSource,
  setCheckValue,
  checkValue,
  setSearchAbleValue,
  searchAblevalue,
  register,
  singleTraineeApplication,
}: any) => {
  const sourceInfoList: ISourceInfoList[] = [
    { id: 1, name: "News paper", checked: false },
    { id: 2, name: "Website", checked: false },
    { id: 3, name: "Email", checked: false },
    { id: 4, name: "Friends", checked: false },
    { id: 5, name: "other", checked: false },
  ];

  const [checkboxes, setCheckboxes] = useState(sourceInfoList);
  const [allMember, setAllMember] = useState([]);
  const [companyId, setCompanyId] = useState<number>(
    singleTraineeApplication?.training_course_application_member_id
  );
  const [companyName, seTCompanyName] = useState<string>("");
  useEffect(() => {
    (async function () {
      const { data } = await axios.get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/active?company_name=${companyName}`
      );
      if (data.success) {
        setAllMember(data.data);
      }
    })();
  }, [companyName]);

  useEffect(() => {
    const defaultChecked = checkboxes?.map((check) =>
      check.name === sourceInfo
        ? { ...check, checked: true }
        : { ...check, checked: false }
    );
    setCheckboxes(defaultChecked);
  }, [sourceInfo]);
  const memberOption = allMember?.map((option: any) => ({
    id: option.user_member_id,
    label: `${option.user_member_company_name}`,
  }));

  const handleCheckboxChange = (
    checkboxId: number,
    name: string,
    checked: any
  ) => {
    if (checked) {
      const updatedCheckboxes = checkboxes.map((checkbox: any) =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: false }
          : { ...checkbox, checked: true }
      );
      setSourceInfo(name);
      setCheckboxes(updatedCheckboxes);
    } else {
      const updatedCheckboxes = checkboxes.map((checkbox: any) =>
        checkbox.id === checkboxId
          ? { ...checkbox, checked: false }
          : { ...checkbox, checked: false }
      );
      setSourceInfo("");
      setCheckboxes(updatedCheckboxes);
    }
  };

  // const handleCheckboxChange = (
  //   checkboxId: number,
  //   name: string,
  //   checked: boolean
  // ) => {
  //   const updatedCheckboxes = checkboxes.map((checkbox) =>
  //     checkbox.id === checkboxId
  //       ? { ...checkbox, checked }
  //       : { ...checkbox, checked: false }
  //   );

  //   setCheckboxes(updatedCheckboxes);
  //   setSourceInfo(checked ? name : "");
  // };
  return (
    <Box>
      <Box
        sx={{
          "& .MuiTextField-root": { m: 1, width: { xl: "30ch", xs: "40ch" } },
        }}
      >
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            {checkboxes.map((sInfo: any) => {
              return (
                <FormControlLabel
                  key={sInfo.id}
                  value={sInfo.name}
                  disabled={sInfo.checked}
                  onChange={(e: any) =>
                    handleCheckboxChange(sInfo.id, sInfo.name, e.target.checked)
                  }
                  control={<Checkbox checked={sInfo.checked} />}
                  label={sInfo.name}
                />
              );
            })}
          </Box>

          {sourceInfo === "other" && (
            <Box>
              <CssTextField
                sx={{ mt: 0.5 }}
                id="outlined-basic12"
                variant="outlined"
                label="Source of information"
                type="text"
                required
                fullWidth
                onChange={(e: any) => setOtherSource(e.target.value)}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkValue ? true : false}
                onChange={() => {
                  checkValue === "" ? setCheckValue("Yes") : setCheckValue("");
                }}
                id="ConfirmATAB"
              />
            }
            label="Are you a member of ATAB?"
          />
          {checkValue && (
            <Box display="flex" alignItems="center">
              <Autocomplete
                disablePortal
                onChange={(e: any, newValue: any) => {
                  setCompanyId(newValue?.id);
                  setSearchAbleValue({
                    ...searchAblevalue,
                    user_member_company_id: newValue?.id,
                  });
                }}
                defaultValue={{
                  id: singleTraineeApplication?.training_course_application_member_id,
                  label: `${singleTraineeApplication?.user_member_company_name}`,
                }}
                isOptionEqualToValue={(proposerOption, value) =>
                  proposerOption.id === value.id
                }
                loading={true}
                id="controllable-states-demo"
                options={memberOption}
                onInputChange={(_event, newInputValue) => {
                  seTCompanyName(newInputValue);
                }}
                getOptionLabel={(option) => option.label}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Select company Name"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />

              {companyId !==
              singleTraineeApplication?.training_course_application_member_id ? (
                <Box>
                  <CssTextField
                    id="outlined-basic2"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("training_course_application_member_document")}
                    type="file"
                    label="Upload member document"
                    fullWidth
                    required
                  />
                </Box>
              ) : (
                <CssTextField
                  id="outlined-basic2"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("training_course_application_member_document")}
                  type="file"
                  label="Upload member document"
                  fullWidth
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sourceinfo;
