import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import PaymentableItemTable from './PaymentableItemTable';
import { IPaymentableItemList } from 'src/Types/module/paymentableItem/itemList';

const PaymentableItemShow = () => {
  const { user } = useAuthContext();
  const [allPayments, setAllPayments] = useState<IPaymentableItemList[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    try {
      if (user?.user_admin_id) {
        axios
          .get(`${url}/api/atab/payment/get/all/paymentable/item`)
          .then(function (res) {
            if (res.data.success) {
              setAllPayments(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllPayments([]);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [user.user_admin_id, reload]);

  const allInvoice: IPaymentableItemList[] = allPayments;

  return (
    <>
      <Card>
        <PaymentableItemTable
          allInvoice={allInvoice}
          setAllPayments={setAllPayments}
          loading={loading}
          setReload={setReload}
        />
      </Card>
    </>
  );
};

export default PaymentableItemShow;
