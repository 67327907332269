import { SimCardDownload } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { singleMoneyReceipt } from "src/Types/module/common/commonTypes";
import PrintSingleMoneyReceipt from "../../memberList/singleMember/PrintInformationForm/PrintSingleMoneyReceipt/PrintSingleMoneyReceipt";
import MoneyReceiptBillingInfo from "./MoneyReceiptBillingInfo";

interface IProps {
  singleInvoiceInfo: singleMoneyReceipt;
  setSingleInvoiceInfo: Function;
}
const CardMoneyReceipt = ({
  singleInvoiceInfo,
  setSingleInvoiceInfo,
}: IProps) => {
  const theme = useTheme();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Box ml={3}>
      {singleInvoiceInfo && (
        <Card className="invoice_print_form">
          <CardHeader
            title={`Money Receipt Details `}
            action={
              <Tooltip title="Print money receipt">
                <IconButton onClick={handlePrint}>
                  <SimCardDownload
                    sx={{
                      cursor: "pointer",
                      color: theme.colors.primary.main,
                    }}
                  />
                </IconButton>
              </Tooltip>
            }
          />
          <CardContent>
            <MoneyReceiptBillingInfo
              showModal={showModal}
              setShowModal={setShowModal}
              singleInvoiceDetails={singleInvoiceInfo}
              setSingleInvoiceInfo={setSingleInvoiceInfo}
            />
          </CardContent>
          <div className="invoice_footer_form">
            {singleInvoiceInfo && (
              <PrintSingleMoneyReceipt
                singleMoneyReceipt={singleInvoiceInfo}
                componentRef={componentRef}
              />
            )}
          </div>
        </Card>
      )}
    </Box>
  );
};

export default CardMoneyReceipt;
