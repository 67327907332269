import { Box, Button, Grid, TextField, Select } from "@mui/material";
import { useForm } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

const CreateTraineeAccount = ({ setRefresh, handelClose }: any) => {
  const { register, handleSubmit, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const accountType = watch("account_type");
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const submitData = { CompanyProfileId: 2, ...inputs };
    console.log(submitData);
    try {
      const res: any = await HttpClient.post(
        `${url}/api/atab/common-account`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        setLoading(false);
        handelClose();
        setRefresh((prv) => !prv);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Box sx={{ p: 2 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                id="account_name"
                label="Account Name"
                type="text"
                variant="outlined"
                {...register("account_name")}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="account_type_label">Account Type</InputLabel>
                <Select
                  labelId="account_type_label"
                  id="account_type"
                  label="Account Type"
                  {...register("account_type")}
                >
                  <MenuItem value={"Bank"}>Bank</MenuItem>
                  <MenuItem value={"Cash"}>Cash</MenuItem>
                  <MenuItem value={"Mobile Banking"}>Mobile Banking</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {accountType !== "Cash" && (
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="account_number"
                  label="Account Number"
                  type="text"
                  variant="outlined"
                  {...register("account_number")}
                />
              </Grid>
            )}
            {accountType === "Bank" && (
              <>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="bank_name"
                    label="Bank Name"
                    type="text"
                    variant="outlined"
                    {...register("bank_name")}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="branch_name"
                    label="Branch Name"
                    type="text"
                    variant="outlined"
                    {...register("branch_name")}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    id="routing_no"
                    label="Routing Number"
                    type="number"
                    variant="outlined"
                    {...register("routing_no")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                id="account_date"
                label="Account Date"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("account_date")}
                defaultValue={moment().format("YYYY-MM-DD")}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                id="opening_balance"
                label="Opening Balance"
                type="number"
                variant="outlined"
                {...register("opening_balance")}
              />
            </Grid>
          </Grid>
          <Box textAlign={"center"} mt={3}>
            {loading ? (
              <LoadingButton
                loading={loading}
                fullWidth
                color="secondary"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                variant="contained"
              >
                Loading...
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Create
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateTraineeAccount;
