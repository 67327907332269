import { Container, Box, Grid, Typography, Divider } from "@mui/material";
import moment from "moment";
import { imgUrl } from "src/Helpers/utils/Constant";
import traineeLogo from "../../../../../../assets/image/atab-logo3.jpg";
const ShortCourseForm = ({ componentRef, singleTraineeApplication }: any) => {
  console.log(singleTraineeApplication);
  return (
    <Box ref={componentRef} mt={2}>
      <Container maxWidth="md">
        <Box>
          <Box mb={-2} textAlign={"center"}>
            <img src={traineeLogo} alt="" width={300} height={80} />
          </Box>
          <p style={{ borderBottom: "2px solid gray" }}></p>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Box textAlign={"center"} ml={13}>
                  <Typography variant="h3">
                    ATAB Tourism Training Institute (ATTI)
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    Sattar Center (16th Floor), 30/4, Naya Paltan,
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    VIP Road, Dhaka-1000. Tel: 02 58314284
                  </Typography>
                  <Typography fontWeight={"bold"}>
                    Mob: 01882088739 Email: atti.atab@gmail.com
                  </Typography>
                  <Typography variant="h4" mt={2} fontWeight={"bold"}>
                    ADMISSION FORM
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <img
                  style={{
                    height: "180px",
                    width: "170px",
                    borderRadius: "10px",
                  }}
                  src={`${imgUrl}/${singleTraineeApplication.training_course_application_photo}`}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box className="single-trainee-application">
            <ul>
              <li>
                <span>Name</span>:
                <p>
                  {singleTraineeApplication.training_course_application_name}
                </p>
              </li>
              <li>
                <span>Father's Name</span>:
                <p>
                  {
                    singleTraineeApplication.training_course_application_fathers_name
                  }
                </p>
              </li>
              <li>
                <span>Mother's Name</span>:
                <p>
                  {
                    singleTraineeApplication.training_course_application_mothers_name
                  }
                </p>
              </li>
              <li>
                <span className="form-input">Date of Birth</span>:
                <p>
                  {moment(
                    singleTraineeApplication.training_course_application_birthdate
                  ).format("D/MM/YYYY")}
                </p>
                <span className="second-input">Nationality</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_nationality
                  }
                </p>
              </li>
              <li>
                <span>Gender</span>:{" "}
                <p>
                  {singleTraineeApplication.training_course_application_gender}
                </p>
              </li>
              <li>
                <span className="form-input">Religion</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_religion
                  }
                </p>
                <span className="second-input">Occupation</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_father_occupation
                  }
                </p>
              </li>
              <li>
                <span className="from-long-text-title">
                  Last Academic Qualification
                </span>
                :
                <p>
                  {
                    singleTraineeApplication.training_course_application_last_academic_qualification
                  }
                </p>
              </li>
              <li>
                <span className="from-long-text-title">
                  Present/ Mailing Address
                </span>
                :
                <p>
                  {
                    singleTraineeApplication.training_course_application_present_address
                  }
                </p>
              </li>
              <li>
                <span className="form-input">Whatsapp</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_whatsapp_number
                  }
                </p>
                <span className="second-input">Email</span>:{" "}
                <p>
                  {singleTraineeApplication.training_course_application_email}
                </p>
              </li>
              <li>
                <span className="form-input">Local Guardian Name</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_guardian_name
                  }
                </p>
                <span className="second-input">Relation</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_guardian_relation
                  }
                </p>
              </li>
              <li>
                <span>Address</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_guardian_address
                  }
                </p>
              </li>
              <li>
                <span className="form-input">Mobile</span>:{" "}
                <p>
                  {
                    singleTraineeApplication?.training_course_application_guardian_phone
                  }
                </p>
                <span className="second-input">NID No</span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_nid_number
                  }
                </p>
              </li>
              <li>
                <span>Course</span>:{" "}
                <p>{singleTraineeApplication.atab_training_course_name}</p>
              </li>
              <li>
                <span>Source of Information </span>:{" "}
                <p>
                  {
                    singleTraineeApplication.training_course_application_info_source
                  }
                </p>
              </li>
            </ul>
          </Box>
        </Box>
        <Typography fontWeight={"bold"} my={1.5}>
          I hereby agree to abide by the rules and regulations of ATAB Tourism &
          Training Institute. I also agree that the admission fee cannot be
          cancelled and fees are non-refundable and will pay dues in given time
          to avoid discontinuation of classes
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            marginTop: 5,
          }}
        >
          <img
            style={{
              height: "50px",
              width: "190px",
              paddingBottom: "4px",
              // objectFit: "contain",
            }}
            src={`${imgUrl}/${singleTraineeApplication.training_course_application_signature_file}`}
            alt=""
          />
          <Divider sx={{ width: "25%", height: 1.5, background: "#000" }} />
          <Typography pr={2}>Signature of the student</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default ShortCourseForm;
