import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useForm } from "react-hook-form";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import dayjs, { Dayjs } from "dayjs";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

function CreateEvent({ handleClose, setAllEvents }) {
  const { register, handleSubmit, reset } = useForm();

  const Toast = Toaster();

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthContext();

  const currentDate = new Date();
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(currentDate));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(currentDate));

  const onSubmit = async (inputs: any) => {
    setIsLoading(true);
    const bodyFormData = new FormData();

    for (const key in inputs) {
      if (typeof inputs[key] === "object") {
        bodyFormData.append(key, inputs[key][0]);
      } else {
        bodyFormData.append(key, inputs[key]);
      }
    }
    bodyFormData.append(
      "member_event_start_date",
      startDate.toISOString().split("T")[0]
    );
    bodyFormData.append(
      "member_event_end_date",
      endDate.toISOString().split("T")[0]
    );

    bodyFormData.append(
      "member_event_created_by",
      user.user_admin_id.toString()
    );
    try {
      const { data } = await axios.post(
        `${url}/api/member/event`,
        bodyFormData
      );

      if (data.success) {
        setAllEvents((prev) => [
          {
            member_event_id: data.data.member_event_id,
            member_event_name: inputs.member_event_name,
            member_event_details: inputs.member_event_details,
            member_event_cover_photo: data.data.member_event_cover_photo,
            member_event_start_date: startDate.toISOString(),
            member_event_end_date: endDate.toISOString(),
            member_event_amount: inputs.member_event_amount,
            member_event_location: inputs.member_event_location,
            member_event_status: "upcoming",
            member_event_created_at: currentDate.toISOString(),
            event_created_by_id: user.user_admin_id,
            event_created_by_name: user.user_admin_name,
          },
          ...prev,
        ]);
        handleClose();
        Toast.fire({
          icon: "success",
          title: data.message,
        });
        setIsLoading(false);
        reset();
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });

      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Atab - Create Event</title>
      </Helmet>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={{ xs: 2 }} mb={2}>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Event title"
              required
              id="outlined-required"
              type="text"
              {...register("member_event_name")}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Event price"
              id="outlined-required"
              type="number"
              {...register("member_event_amount")}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              required
              id="outlined-required"
              type="file"
              label="Upload a event image"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("member_event_cover_photo")}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Event place"
              required
              id="outlined-required"
              type="text"
              {...register("member_event_location")}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Event start date"
                format="MM/DD/YYYY"
                value={startDate}
                onChange={(value) => setStartDate(value)}
                // renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Event end date"
                format="MM/DD/YYYY"
                value={endDate}
                onChange={(value) => setEndDate(value)}
                // renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Event details"
              required
              id="outlined-required"
              type="text"
              {...register("member_event_details")}
            />
          </Grid>
        </Grid>
        {!isLoading ? (
          <Button type="submit" fullWidth variant="contained" disableElevation>
            Create Event
          </Button>
        ) : (
          <LoadingButton
            loading
            loadingIndicator="Creating..."
            variant="contained"
            fullWidth
            disabled
          >
            <span>Creating...</span>
          </LoadingButton>
        )}
      </Box>
    </>
  );
}

export default CreateEvent;
