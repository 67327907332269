import React from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { ISingleCivilAviation } from "src/Types/module/UpdateRequest/RequestCivilAviation";
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";
import { imgUrl } from "src/Helpers/utils/Constant";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  requestCivilAviationInfo: ISingleCivilAviation;
}
const useStyles = makeStyles({
  TableCell: {
    fontWeight: "bold !important",
  },
});
const CivilAviationAppReject = ({ requestCivilAviationInfo }: IProps) => {
  const classes = useStyles();
  const [query] = useSearchParams();
  const status = query.get("status");
  return (
    <Container>
      <Card>
        <CardHeader
          title={
            status === "approved"
              ? "Approved information"
              : "Rejected information"
          }
        />
        <TableContainer sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.TableCell}>Status</TableCell>
                <TableCell align="right">
                  {getStatusLabel(requestCivilAviationInfo.status)}
                </TableCell>
              </TableRow>

              {requestCivilAviationInfo.reject_reason && (
                <TableRow>
                  <TableCell className={classes.TableCell}>Status</TableCell>
                  <TableCell align="right">
                    {requestCivilAviationInfo.reject_reason}
                  </TableCell>
                </TableRow>
              )}

              {requestCivilAviationInfo.certificate_number && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate number
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {requestCivilAviationInfo.certificate_number}
                  </TableCell>
                </TableRow>
              )}

              {requestCivilAviationInfo.certificate_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      href={`${imgUrl}/${requestCivilAviationInfo?.certificate_file}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {requestCivilAviationInfo.certificate_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestCivilAviationInfo.certificate_issue_date && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate issue date
                  </TableCell>
                  <TableCell align="right">
                    {requestCivilAviationInfo.certificate_issue_date
                      ? moment(
                          requestCivilAviationInfo.certificate_issue_date
                        ).format("LL")
                      : ""}
                  </TableCell>
                </TableRow>
              )}

              {requestCivilAviationInfo.certificate_expiry_date && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Certificate expiry date
                  </TableCell>
                  <TableCell align="right">
                    {requestCivilAviationInfo.certificate_expiry_date
                      ? moment(
                          requestCivilAviationInfo.certificate_expiry_date
                        ).format("LL")
                      : ""}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default CivilAviationAppReject;
