import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { ICompanyOwners } from "src/Types/module/member/CompanyInformation";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";
import { DeleteOutline } from "@mui/icons-material";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";

interface IProps {
  sOwner: ICompanyOwners;
  setUpdateModal: (value: boolean) => void;
  setSingleOwner: Function;
  setRefresh: (value: boolean) => void;
  refresh: boolean;
  length: number;
}
const ShowOwner = ({
  sOwner,
  setUpdateModal,
  setSingleOwner,
  refresh,
  setRefresh,
  length,
}: IProps) => {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/atab/admin/member-company/member/owner/${id}`;
        const data = await HttpClient.delete(uri);

        if (data) {
          // const filterData = allCheque.filter((item) => item.id !== id);
          // setAllCheque(filterData);
          setRefresh(!refresh);
          void Toaster().fire({
            icon: "success",
            title: "Deleted successfully!",
          });

          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 5 }}>
        <Table aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner name
              </TableCell>
              <TableCell align="right">
                {sOwner.company_owner_name}{" "}
                {sOwner?.company_representative ? (
                  <b>(Will represent organization)</b>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner designation
              </TableCell>
              <TableCell align="right">
                {sOwner.company_owner_designation}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner phone
              </TableCell>
              <TableCell align="right">
                {/* {getStatusLabel(sOwner.proposer_request_status)} */}
                {sOwner.company_owner_phone}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner email
              </TableCell>
              <TableCell align="right"> {sOwner.company_owner_email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner address
              </TableCell>
              <TableCell align="right">
                {" "}
                {sOwner.company_owner_address}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner NID number
              </TableCell>
              <TableCell align="right">
                {" "}
                {sOwner.company_owner_nid
                  ? sOwner.company_owner_nid
                  : "Not provided"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner NID file
              </TableCell>
              <TableCell align="right">
                {sOwner.company_owner_nid_file ? (
                  <CommonTypoGraphy
                    name="View"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(sOwner.company_owner_nid_file);
                    }}
                  />
                ) : (
                  "Not provided"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner photo
              </TableCell>
              <TableCell align="right">
                {sOwner.company_owner_photo ? (
                  <CommonTypoGraphy
                    name="View"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(sOwner.company_owner_photo);
                    }}
                  />
                ) : (
                  "Not provided"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner bio data
              </TableCell>
              <TableCell align="right">
                {" "}
                {sOwner.company_owner_biodata ? (
                  <CommonTypoGraphy
                    name="View"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(sOwner.company_owner_biodata);
                    }}
                  />
                ) : (
                  "Not provided"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner passport
              </TableCell>
              <TableCell align="right">
                {" "}
                {sOwner.company_owner_passport ? (
                  <CommonTypoGraphy
                    name="View"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(sOwner.company_owner_passport);
                    }}
                  />
                ) : (
                  "Not provided"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Owner seal & signature
              </TableCell>
              <TableCell align="right">
                {sOwner.company_owner_signature ? (
                  <CommonTypoGraphy
                    name="View"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(sOwner.company_owner_signature);
                    }}
                  />
                ) : (
                  "Not provided"
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right">
                <Box
                  sx={{
                    position: "absolute",
                    top: -10,
                    right: 0,
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ marginRight: "12px" }}
                    onClick={() => {
                      setUpdateModal(true);
                      setSingleOwner(sOwner);
                    }}
                  >
                    Update
                  </Button>
                  {!sOwner.company_representative && length !== 1 && (
                    <Button
                      onClick={() => setOpen(true)}
                      size="small"
                      variant="contained"
                      color="error"
                    >
                      <DeleteOutline style={{ fontSize: "19px" }} />
                    </Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteConfirmation
        open={open}
        handleClose={() => setOpen(false)}
        text="Want to delete this owner?"
        handleDeleteFun={() => handleDelete(sOwner.company_owner_id)}
        loading={isLoading}
      />
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default ShowOwner;
