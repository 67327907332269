import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

export default function DeleteConfirmation({
  open,
  handleClose,
  text = "Are you want delete this Invoice item?",
  handleDeleteFun,
  loading = false,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          {!loading ? (
            <Button color="error" onClick={handleDeleteFun} autoFocus>
              Delete
            </Button>
          ) : (
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              <span>Deleting</span>
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
