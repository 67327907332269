import { url } from "src/Helpers/utils/Constant";
import { getToken } from "./Lib";
import axios from "axios";
const token = getToken();

export const fetchInstance = axios.create({
  headers: {
    Authorization: `Bearer ${token}` || "",
  },
  baseURL: url + "/api",
});

// fetcher post
export const fetcherPost = async (endpoint: string, body: any) => {
  try {
    const { data } = await fetchInstance.post(endpoint, body);

    if (data.success) {
      return data;
    } else {
      return data;
    }
  } catch (err) {
    return {
      success: false,
      message: err.response.data.message,
    };
  }
};

// fetcher get
export const fetcherGet = async (endpoint: string) => {
  try {
    const { data } = await fetchInstance.get(endpoint);
    if (data.success) {
      return data;
    } else {
      return data;
    }
  } catch (err) {
    return {
      success: false,
      message: err.response.data.message,
    };
  }
};

// fetcher patch
export const fetcherPatch = async (endpoint: string, body: any) => {
  try {
    const { data } = await fetchInstance.patch(endpoint, body);

    if (data.success) {
      return data;
    } else {
      return data;
    }
  } catch (err) {
    return {
      success: false,
      message: err.response.data.message,
    };
  }
};

// fetcher put
export const fetcherPut = async (endpoint: string, body: any) => {
  try {
    const { data } = await fetchInstance.put(endpoint, body);
    if (data.success) {
      return data;
    } else {
      return data;
    }
  } catch (err) {
    return {
      success: false,
      message: err.response.data.message,
    };
  }
};

// fetcher delete
export const fetcherDelete = async (endpoint: string) => {
  try {
    const { data } = await fetchInstance.delete(endpoint);
    if (data.success) {
      return data;
    } else {
      return data;
    }
  } catch (err) {
    return {
      success: false,
      message: err.response.data.message,
    };
  }
};
