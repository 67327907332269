import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Grid,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Chip,
  Autocomplete,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { useForm } from "react-hook-form";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface IProps {
  user_fair_member_id: number;
  user_fair_member_contact_name: string;
}

const FairInvoiceUpdateForm = ({ details, handleClose, setReload }: any) => {
  const { register, handleSubmit, reset } = useForm();
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [isMSgsent, setisMsgSent] = useState<boolean>(false);
  const [membersId, setMembersId] = useState<IProps>(null);
  const [uploadFile, setUploadFile] = useState<string>("");
  const [invoiceIssueDate, setInvoiceIssueDate] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [paymentChange, setPaymentChange] = useState<string>("");
  const Toast = Toaster();

  useEffect(() => {
    if (details.fair_invoice_status && details.fair_invoice_transaction_date) {
      setInvoiceIssueDate(
        moment(details.fair_invoice_transaction_date).format("YYYY-MM-DD")
      );
      setStatus(details.fair_invoice_status);
    }
  }, [details.fair_invoice_transaction_date, details.fair_invoice_status]);
  useEffect(() => {
    if (details.fair_invoice_payment_method) {
      setPaymentChange(details.fair_invoice_payment_method);
    }
  }, [details.fair_invoice_payment_method]);
  const handlePaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentChange(e.target.value);
  };
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setStatus(e.target.value);
    }
  };
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    if (details.fair_invoice_transaction_id && status) {
      try {
        const bodyFormData = new FormData();
        for (const key in inputs) {
          bodyFormData.append(key, inputs[key]);
        }
        bodyFormData.append(
          "fair_invoice_file",
          uploadFile || details.fair_invoice_file
        );
        bodyFormData.append("fair_invoice_payment_method", paymentChange);
        bodyFormData.append("fair_invoice_id", details.fair_invoice_id);
        bodyFormData.append(
          "fair_invoice_transaction_id",
          details.fair_invoice_transaction_id
        );
        bodyFormData.append(
          "fair_invoice_updated_by",
          user.user_admin_id.toString()
        );
        bodyFormData.append(
          "fair_invoice_transaction_date",
          moment(invoiceIssueDate).format("YYYY-MM-DD")
        );
        bodyFormData.append("fair_invoice_status", status);

        const { data } = await axios.put(
          `${url}/api/atab/fair-invoice/update/for-admin`,
          bodyFormData
        );

        if (data.success) {
          setLoading(false);
          Toast.fire({
            icon: "success",
            title: `Invoiced Successfully`,
          });
          setReload((prev: Boolean) => !prev);
          reset();
          handleClose();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: "error",
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Total amount"
                  defaultValue={details.total_amount}
                  required
                  id="outlined-required"
                  {...register("total_amount")}
                  type="text"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="outlined-required"
                  label="Fair Invoice remark"
                  defaultValue={details.fair_invoice_remark}
                  {...register("fair_invoice_remark")}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="filled-size-small"
                  // variant="filled"
                  type="file"
                  fullWidth
                  label="Payment Invoice File"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e: any) => setUploadFile(e.target.files[0])}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl required fullWidth>
                  <InputLabel required id="demo-simple-select-label">
                    invoice status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={status}
                    value={status}
                    // value="paid"
                    label="Invoice status"
                    required
                    // variant="filled"
                    // {...register("fair_invoice_status")}
                    onChange={handleStatusChange}
                  >
                    <MenuItem value="paid">Paid</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="reported">Reported</MenuItem>
                    <MenuItem value="cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl required fullWidth>
                  <InputLabel required id="demo-simple-select-label">
                    Fair invoice payment method
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // defaultValue="cash"
                    // value="Cash"
                    value={paymentChange}
                    label="Fair invoice payment method"
                    // defaultValue={details.fair_invoice_payment_method}
                    // value={details.fair_invoice_payment_method}
                    required
                    onChange={handlePaymentChange}
                    // {...register("fair_invoice_payment_method")}
                  >
                    <MenuItem value="Cash">cash</MenuItem>
                    <MenuItem value="SSL Commerz">SSL Commerz</MenuItem>
                    <MenuItem value="bank">Bank</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Pick update date"
                    format="YYYY-MM-DD"
                    // value={moment(invoiceIssueDate).format("YYYY-MM-DD")}
                    value={invoiceIssueDate}
                    onChange={(newValue: any) => {
                      setInvoiceIssueDate(newValue);
                    }}
                    // renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            {!isLoading ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update fair invoice
              </Button>
            ) : (
              <LoadingButton
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Updating...
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default FairInvoiceUpdateForm;
