import { CircularProgress, TableCell, TableRow } from "@mui/material";

export function TableLoader() {
  return (
    <TableRow>
      <TableCell align="center" colSpan={100}>
        <CircularProgress size={40} disableShrink thickness={3} />
      </TableCell>
    </TableRow>
  );
}
export function TableNotFound() {
  return (
    <TableRow>
      <TableCell
        sx={{ fontSize: "20px", fontWeight: "bold" }}
        colSpan={100}
        align="center"
      >
        No data found
      </TableCell>
    </TableRow>
  );
}
