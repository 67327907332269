import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
  InputLabel,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

const CreateMember = ({ setAllMembers, handleClose }: any) => {
  const Toast = Toaster();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [zone, setZone] = useState<number>(1);
  const [memberId, setMemberId] = useState<any>();
  const { user } = useAuthContext();

  const onSubmit = async (inputs: any) => {
    let zoneName = "";
    if (zone === 1) {
      zoneName = "Dhaka";
    } else if (zone === 2) {
      zoneName = "Chittagong";
    } else zoneName = "Sylhet";
    inputs.user_member_zone_id = zone;
    if (
      !inputs.user_member_phone.startsWith("01") ||
      inputs.user_member_phone.length !== 11
    ) {
      Toast.fire({
        icon: "error",
        title: `Invalid mobile number`,
      });
      return;
    }

    const formData = new FormData();
    if (memberId) {
      formData.append("user_member_uid", memberId);
    }
    Object.keys(inputs).forEach((item) => {
      if (item === "user_member_representative_photo") {
        formData.append(item, inputs[item][0]);
      } else {
        formData.append(item, inputs[item]);
      }
    });

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${url}/api/atab/user-member/member/register?user_admin_id=${user.user_admin_id}`,
        formData
      );

      if (data.success) {
        setAllMembers((prev) => [
          {
            user_member_account_status: "pending",
            user_member_company_name: inputs.user_member_company_name,
            user_member_id: data.user_member_id,
            user_member_info_verified_status: null,
            user_member_payment_verified_status: null,
            user_member_phone: inputs.user_member_phone,
            user_member_representative_name:
              inputs.user_member_representative_name,
            user_member_representative_photo:
              data.user_member_representative_photo,
            user_member_uid: inputs.user_member_uid,
            user_member_zone_id: inputs.user_member_zone_id,
            user_member_zone_name: zoneName,
          },
          ...prev,
        ]);
        handleClose(false);
        Toast.fire({
          icon: "success",
          title: `Successfully add member`,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Atab - Add Member</title>
      </Helmet>
      <Box
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mx: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='name'
              label='Enter company name'
              placeholder='Enter company name'
              {...register("user_member_company_name")}
              type='text'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='phone'
              label='Enter your mobile'
              placeholder='Enter your mobile number'
              {...register("user_member_phone")}
              type='text'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={3} mt={1.6}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Select zone</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={zone}
                label='Select zone'
                onChange={(e: any) => setZone(e.target.value)}
              >
                <MenuItem value={1}>Dhaka</MenuItem>
                <MenuItem value={2}>Chittagong</MenuItem>
                <MenuItem value={3}>Sylhet</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='representative name'
              label='Representative name'
              placeholder='Representative name'
              {...register("user_member_representative_name")}
              type='text'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Enter your email'
              placeholder='Enter your email'
              {...register("user_member_email")}
              type='email'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='password'
              label='Enter your password'
              value='@12345678@'
              placeholder='Enter your password'
              {...register("user_member_password")}
              autoComplete='password'
              type='text'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='representative designation'
              label='Representative designation'
              placeholder='Representative designation'
              {...register("user_member_representative_designation")}
              type='text'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label='Member id (optional)'
              margin='normal'
              placeholder='Enter member id'
              fullWidth
              onChange={(e) => setMemberId(e.target.value)}
              type='text'
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label='Upload photo'
              margin='normal'
              InputLabelProps={{ shrink: true }}
              fullWidth
              {...register("user_member_representative_photo")}
              type='file'
            />
          </Grid>
        </Grid>
        {loading ? (
          <Button
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Loading ...
          </Button>
        ) : (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Add Member
          </Button>
        )}
      </Box>
    </>
  );
};

export default CreateMember;
