import { useState, Dispatch, SetStateAction } from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { url } from "../../../../../Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { ISingleCourseDetails } from "src/Types/module/courses/courseList";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

type IProps = {
  course: ISingleCourseDetails;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
};

const UpdateCourse = ({
  showModal,
  setShowModal,
  setReload,
  course,
}: IProps) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState<Boolean>(false);
  const [statusValue, setStatusValue] = useState("Approved");

  const { user } = useAuthContext();

  const onSubmit = async (inputs) => {
    setLoading(true);
    inputs.atab_training_course_id = course.atab_training_course_id;
    inputs.atab_training_course_status = statusValue;

    try {
      const { data } = await axios.put(
        `${url}/api/training/course/update?user_admin_id=${user.user_admin_id}`,
        inputs
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setLoading(false);
        setShowModal(false);
        setReload((prev) => !prev);
      } else {
        Toaster().fire({
          icon: "failed",
          title: `${data.message}`,
        });
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  const statusOptions = [
    {
      id: "approved",
      value: "Approved",
    },

    {
      id: "rejected",
      value: "Rejected",
    },
  ];
  return (
    <ModalsWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      modalData={{ title: "Update Course" }}
    >
      <Container maxWidth='sm'>
        <Box onSubmit={handleSubmit(onSubmit)} component='form'>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <TextField
                label='Course name'
                fullWidth
                margin='normal'
                defaultValue={course?.atab_training_course_name}
                {...register("atab_training_course_name")}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <TextField
                label='Course duration'
                fullWidth
                margin='normal'
                defaultValue={course?.atab_training_course_duration}
                {...register("atab_training_course_duration")}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label='Class length'
                fullWidth
                margin='normal'
                defaultValue={course?.atab_training_course_class_duration}
                {...register("atab_training_course_class_duration")}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                margin='normal'
                fullWidth
                label='Class days'
                defaultValue={course?.atab_training_course_class_days}
                {...register("atab_training_course_class_days")}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                margin='normal'
                fullWidth
                label='Class time'
                defaultValue={course?.atab_training_course_class_time}
                {...register("atab_training_course_class_time")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='normal'
                fullWidth
                label='Course Amount'
                defaultValue={course?.atab_training_course_amount}
                {...register("atab_training_course_amount")}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant='outlined' sx={{ mt: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  defaultValue={course.atab_training_course_status}
                  onChange={(e) => setStatusValue(e.target.value)}
                  label='Status'
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin='normal'
                fullWidth
                multiline
                rows={2}
                label='Course remark'
                defaultValue={course?.atab_training_course_remark}
                {...register("atab_training_course_remark")}
              />
            </Grid>
          </Grid>

          {loading ? (
            <Button
              disabled
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
              }}
            >
              updating...
            </Button>
          ) : (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
              }}
            >
              Update course
            </Button>
          )}
        </Box>
        <Box
          sx={{
            mb: 3,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <Button onClick={() => setShowModal(!showModal)}>Close</Button> */}
        </Box>
      </Container>
    </ModalsWrapper>
  );
};

export default UpdateCourse;
