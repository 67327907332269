import { MdDeleteForever, MdDownload } from "react-icons/md";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import React, { useState } from "react";
import { TIdCard } from "./IdCardList";
import {
  Button,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import Toaster from "src/Toaster/Toaster";
import { HttpClient } from "src/Helpers/http/http";
import { Train, VerticalAlignBottom } from "@mui/icons-material";
import { imgUrl } from "src/Helpers/utils/Constant";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowTraineeIdCard = ({
  trainee,
  index,
  setRefresh,
}: {
  trainee: TIdCard;
  index: number;
  setRefresh: any;
}) => {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  const [open, setOpen] = useState(false);

  const handelDeleted = async (id: number) => {
    const data: any = await HttpClient.delete(
      `/api/trainee/id-card/${trainee.id}`
    );
    if (data.success) {
      Toaster().fire({
        icon: "success",
        title: data.message,
      });
      setRefresh((prv) => !prv);
    } else {
      Toaster().fire({
        icon: "error",
        title: data.message,
      });
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell size="small">{index + 1}</TableCell>
        <TableCell size="small">
          {trainee.training_course_application_name}
        </TableCell>

        <TableCell size="small">
          {dayjs(trainee.valid_date).format("D MMMM YYYY")}
        </TableCell>
        <TableCell size="small">
          <Tooltip title="View" arrow>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                SetImageViewOpen(true);
                setImageFile(trainee.id_photo);
              }}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton
              href={`${imgUrl}/${trainee.id_photo}`}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <MdDownload style={{ color: "blue" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete"} sx={{ mr: 1 }}>
            <IconButton onClick={() => setOpen(true)}>
              <MdDeleteForever style={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Sure to delete this id card ?"}</DialogTitle>

        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handelDeleted(trainee.id)}
          >
            Yes
          </Button>{" "}
          <Button
            size="small"
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShowTraineeIdCard;
