import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ICompanyOwners } from "src/Types/module/member/CompanyInformation";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
import { IResponse } from "src/Types/module/common/commonTypes";

interface IProps {
  singleOwner: ICompanyOwners;
  setRefresh: (value: boolean) => void;
  setUpdateModal: (value: boolean) => void;
  refresh: boolean;
}
const UpdateCompanyOwner = ({
  singleOwner,
  setRefresh,
  setUpdateModal,
  refresh,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [representative, setRepresentative] = useState<number>(
    singleOwner?.company_representative
  );

  const onSubmit = async (inputsData: any) => {
    setIsLoading(true);
    inputsData.company_owner_id = singleOwner?.company_owner_id;
    const formData = new FormData();

    formData.append("company_representative", String(representative));

    Object.keys(inputsData).forEach((key) => {
      if (inputsData[key]) {
        if (typeof inputsData[key] === "object") {
          if (inputsData[key][0]) {
            formData.append(key, inputsData[key][0]);
          }
        } else {
          formData.append(key, inputsData[key]);
        }
      }
    });

    try {
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/owner/${singleOwner?.company_owner_id}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setUpdateModal(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });

      // setUpdateModal(false)
      setIsLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Owner name"
              type="text"
              defaultValue={singleOwner?.company_owner_name}
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_name")}
            />
          </Grid>{" "}
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Owner email"
              defaultValue={singleOwner?.company_owner_email}
              type="email"
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_email")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="date"
              type="text"
              defaultValue={singleOwner?.company_owner_phone}
              label="Owner phone"
              sx={{ width: "100%", pb: 2 }}
              {...register("company_owner_phone")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={singleOwner?.company_owner_designation}
              label="Owner designation"
              type="text"
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_designation")}
            />
          </Grid>{" "}
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Owner photo "
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_photo")}
            />
          </Grid>{" "}
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="text"
              fullWidth
              defaultValue={singleOwner?.company_owner_address}
              label="Owner address"
              sx={{ pb: 2 }}
              {...register("company_owner_address")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="date"
              type="number"
              defaultValue={singleOwner?.company_owner_nid}
              label="Owner NID number"
              sx={{ width: "100%", pb: 2 }}
              {...register("company_owner_nid")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Owner NID (Both Side)"
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_nid_file")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Owner seal & signature "
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_signature")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Owner bio data "
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_biodata")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Represent organization
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={representative}
                label="Represent organization"
                onChange={(e) => setRepresentative(Number(e.target.value))}
                // {...register("company_representative")}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>{" "}
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Company owner passport "
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              fullWidth
              sx={{ pb: 2 }}
              {...register("company_owner_passport")}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 2, textAlign: "end", mb: 3 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyOwner;
