import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ITraineeListOfCourse } from "src/Utils/type";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import axios from "axios";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableNotFound } from "src/Utils/TableLoader";

const applyPagination = (
  students: ITraineeListOfCourse[],
  page: number,
  limit: number
): ITraineeListOfCourse[] => {
  return students.slice(page * limit, page * limit + limit);
};

function StudentOfCourse() {
  const [students, setStudents] = useState<ITraineeListOfCourse[] | null>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("all");
  const { courseId } = useParams();
  const [query] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/training/trainee/get/all/by/course-id/joinedStatus/${courseId}/all`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setStudents(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [status, courseId]);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedStudentList = applyPagination(students, page, limit);

  const theme = useTheme();
  return !loading ? (
    <>
      <Helmet>
        <title>enrollment students of this course</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          pt={5}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={`Enrollment students of ${query.get("course")}`}
              />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Trainee name</TableCell>
                      <TableCell>Trainee email</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {students.length ? (
                    <TableBody>
                      {paginatedStudentList.map(
                        (student: ITraineeListOfCourse) => {
                          return (
                            <TableRow
                              hover
                              key={student.user_training_trainee_id}
                            >
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {student.user_training_trainee_id}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <img
                                    width={30}
                                    height={30}
                                    style={{
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                    }}
                                    src={
                                      student.user_training_trainee_photo
                                        ? `${imgUrl}/${student.user_training_trainee_photo}`
                                        : "/static/images/avatars/avatar2.png"
                                    }
                                    alt=""
                                  />
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    color="text.primary"
                                    gutterBottom
                                    noWrap
                                  >
                                    {student.user_training_trainee_name}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="body1"
                                  fontWeight="bold"
                                  color="text.primary"
                                  gutterBottom
                                  noWrap
                                >
                                  {student.user_training_trainee_email}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`/training-trainee/details/${student.user_training_trainee_id}`}
                                >
                                  <Tooltip title="view" arrow>
                                    <IconButton
                                      sx={{
                                        "&:hover": {
                                          background:
                                            theme.colors.primary.lighter,
                                        },
                                        color: theme.palette.primary.main,
                                      }}
                                      color="inherit"
                                      size="small"
                                    >
                                      <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  ) : (
                    <TableNotFound />
                  )}
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={students.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 25, 30]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  ) : (
    <GlobalLoader />
  );
}

export default StudentOfCourse;
