import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IJoinedExam } from "src/Utils/type";
import moment from "moment";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

type IProps = {
  joinedExam: IJoinedExam[];
};

const applyPagination = (
  joinedExam: IJoinedExam[],
  page: number,
  limit: number
): IJoinedExam[] => {
  return joinedExam.slice(page * limit, page * limit + limit);
};

function StudentOfExam({ joinedExam }: IProps) {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedCryptoOrders = applyPagination(joinedExam, page, limit);

  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Joined student of this exam</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Joined student of this exam" />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Trainee name</TableCell>
                    <TableCell>Exam date</TableCell>
                    <TableCell>Exam joined date</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCryptoOrders.map((student: IJoinedExam) => {
                    return (
                      <TableRow hover key={student.user_training_trainee_id}>
                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {student.user_training_trainee_id}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {student.user_training_trainee_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            noWrap
                          >
                            {moment(student.training_exam_date).format("LL")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            noWrap
                          >
                            {moment(student.training_exam_joined_date).format(
                              "LL"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Link
                            to={`/training-trainee/details/${student.user_training_trainee_id}`}
                          >
                            <Tooltip title="view" arrow>
                              <IconButton
                                sx={{
                                  "&:hover": {
                                    background: theme.colors.primary.lighter,
                                  },
                                  color: theme.palette.primary.main,
                                }}
                                color="inherit"
                                size="small"
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={joinedExam.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 30]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default StudentOfExam;
