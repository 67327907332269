import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet-async";

import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import SuspenseLoader from "src/components/SuspenseLoader";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IInputValue {
  materials_title: string;
  batch_id: any;
  batch_materials_file: any;
}

interface ISelectOption {
  training_batch_id: number;
  training_batch_name: string;
  training_batch_status: string;
  training_batch_course_name: string;
  training_teacher_name: string;
  training_batch_total_student: number;
}

const CreateMaterial = ({ handleClose, setAllMaterials }) => {
  const [values, setValues] = useState<IInputValue>({
    materials_title: "",
    batch_id: 0,
    batch_materials_file: "",
  });
  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingbtn, setLoadingBtn] = useState<boolean>(false);
  const [batchName, setBatchName] = useState<string>("");
  const { user } = useAuthContext();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${url}/api/training/batch/get/all/or/status/all`
        );
        if (data.success) {
          setSelectOptions(data.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (!values.batch_id) {
      Toaster().fire({
        icon: "error",
        title: "You must select a batch",
      });
      return;
    }

    Object.keys(values).forEach((item) => {
      formData.append(item, values[item]);
    });

    try {
      setLoadingBtn(true);
      const res = await axios.post(
        `${url}/api/training/batch/create/materials?user_admin_id=${user.user_admin_id}`,
        formData
      );

      if (res.status === 200) {
        setAllMaterials((prev) => [
          {
            training_batch_materials_id:
              res.data.data.training_batch_materials_batch_id,
            training_batch_materials_title: values.materials_title,
            training_batch_materials_date: new Date().toString(),
            training_batch_id: values.batch_id,
            training_batch_name: batchName,
          },
          ...prev,
        ]);
        setLoadingBtn(false);
        Toaster().fire({
          icon: "success",
          title: "Material created successfully!",
        });
        handleClose();
      }
    } catch (error) {}
  };

  return (
    <>
      <Helmet>
        <title>Atab - Create Material</title>
      </Helmet>

      {loading ? (
        <SuspenseLoader />
      ) : (
        <CardContent>
          <Box onSubmit={onSubmit} component='form' sx={{ p: 2, mt: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} md={6} item>
                <TextField
                  required
                  id='name'
                  value={values.materials_title}
                  label='Material Title'
                  onChange={(newValue) => {
                    setValues({
                      ...values,
                      materials_title: newValue.target.value,
                    });
                  }}
                  type='text'
                  autoFocus
                  fullWidth
                />
              </Grid>

              <Grid xs={12} md={6} item>
                <Autocomplete
                  options={selectOptions}
                  getOptionLabel={(option: ISelectOption) =>
                    option.training_batch_id + " " + option.training_batch_name
                  }
                  renderOption={(props, option) => (
                    <Box component='li' {...props}>
                      {option.training_batch_name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select a Batch'
                      variant='outlined'
                    />
                  )}
                  onChange={(_event, value: ISelectOption) => {
                    setValues({
                      ...values,
                      batch_id: value.training_batch_id,
                    });
                    setBatchName(value.training_batch_name);
                  }}
                />
              </Grid>
              <Grid xs={12} md={12} item>
                <TextField
                  margin='normal'
                  required
                  id='outlined-required'
                  label='Choose Material File'
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValues({
                      ...values,
                      batch_materials_file: e.target.files[0],
                    })
                  }
                  autoComplete='file'
                  type='file'
                />
              </Grid>
            </Grid>
            {loadingbtn ? (
              <Button
                disabled
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Loading...
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Create Material
              </Button>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default CreateMaterial;
