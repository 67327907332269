import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Box, Button, Container, Grid, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import Toaster from "src/Toaster/Toaster";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import {
  IPermission,
  ISingleRole,
} from "src/Types/module/roleAndPermission/roleAndPermissionType";
import CheckBoxDetails from "./CheckBoxDetails";
import { useParams } from "react-router-dom";

interface IProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  singleRole: ISingleRole;
  setReload: Dispatch<SetStateAction<boolean>>;
  reload: boolean;
}
const UpdateSingleRole = ({
  setShowModal,
  showModal,
  singleRole,
  setReload,
  reload,
}: IProps) => {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [allPermission, setAllPermission] = useState<IPermission[]>([]);
  const [deletedPermissionList, setDeletedPermissionList] = useState([]);
  const [addedPermissionList, setAddedPermissionList] = useState([]);
  const { id } = useParams();

  const onSubmit = async (inputs: any) => {
    setLoading(true);

    try {
      const inputData = {
        role_name: inputs.role_name,
        added: addedPermissionList,
        deleted: deletedPermissionList,
      };

      const { data } = await axios.put(
        `${url}/api/atab/admin/update/role/${id}`,
        inputData
      );

      if (data.success) {
        setShowModal(false);
        Toaster().fire({ icon: "success", title: "Updated Successfully!" });
        setLoading(false);
        setReload(!reload);
      } else {
        Toaster().fire({ icon: "info", title: "Something went wrong" });
        setLoading(false);
      }
    } catch (err) {
      Toaster().fire({
        icon: "info",
        title: "Something went wrong try again later",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/permissions`)
          .then(function (res) {
            if (res.data.success) {
              setAllPermission(res.data.result);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllPermission([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <ModalsWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      modalData={{ title: "Update Role" }}
    >
      <Container
        maxWidth="xl"
        sx={{
          overflow: "scroll",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#efefef",
            borderRadius: 2,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#01579b",
            borderRadius: 2,
          },
        }}
      >
        <Box onSubmit={handleSubmit(onSubmit)} component="form">
          <Grid container spacing={2}>
            <Grid lg={12} item>
              <TextField
                fullWidth
                margin="normal"
                id="name"
                label="Role name"
                defaultValue={singleRole ? singleRole?.role?.roles_name : ""}
                {...register("role_name")}
                type="text"
                autoFocus
                variant="outlined"
              />
            </Grid>

            <Grid xs={12} item>
              {allPermission?.map((item, index: number) => (
                <CheckBoxDetails
                  key={index}
                  listOfPermission={singleRole.permissions}
                  permissionItem={item}
                  deletedPermissionList={deletedPermissionList}
                  setDeletedPermissionList={setDeletedPermissionList}
                  addedPermissionList={addedPermissionList}
                  setAddedPermissionList={setAddedPermissionList}
                />
              ))}
            </Grid>
          </Grid>

          {loading ? (
            <Button
              disabled
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              lodaing...
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Update Role
            </Button>
          )}
        </Box>
      </Container>
    </ModalsWrapper>
  );
};

export default UpdateSingleRole;
