import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { ILedgerReportType } from "./ledgerReportTypes";

type IProps = {
  singleLedgerData: ILedgerReportType;
};
const LedgerReportTableRow = ({ singleLedgerData }: IProps) => {
  const {
    VoucherDate,
    VoucherNo,
    Description,
    AccountHeadName,
    Debit,
    Credit,
  } = singleLedgerData || {};

  return (
    <TableRow hover>
      <TableCell>{moment(VoucherDate).format("DD-MMM-YYYY")}</TableCell>
      <TableCell>{VoucherNo}</TableCell>
      <TableCell>{Description}</TableCell>
      <TableCell>{AccountHeadName}</TableCell>
      <TableCell>{Debit}</TableCell>
      <TableCell>{Credit}</TableCell>
    </TableRow>
  );
};

export default LedgerReportTableRow;
