import { Grid } from "@mui/material";
import { CssTextField } from "./CustomTextFiled";
import { ISingleTraineeApplication } from "src/Utils/type";

const TraineeEducationFields = ({
  register,
  singleTraineeApplication,
}: {
  singleTraineeApplication: ISingleTraineeApplication;
  register: any;
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic15"
            variant="outlined"
            label="Enter your education degree"
            defaultValue={
              singleTraineeApplication?.training_course_application_degree
            }
            type="text"
            fullWidth
            {...register("training_course_application_degree")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic24"
            variant="outlined"
            type="text"
            label="Enter your institute name"
            defaultValue={
              singleTraineeApplication?.training_course_application_institute
            }
            fullWidth
            {...register("training_course_application_institute")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic32"
            label="Enter your education board"
            variant="outlined"
            type="text"
            fullWidth
            defaultValue={
              singleTraineeApplication?.training_course_application_board
            }
            {...register("training_course_application_board")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic52"
            variant="outlined"
            type="text"
            label="Enter your cgpa"
            defaultValue={
              singleTraineeApplication?.training_course_application_cgpa
            }
            fullWidth
            {...register("training_course_application_cgpa")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TraineeEducationFields;
