import { useState, useCallback } from "react";
import {
  createArea,
  createDistrict,
  createThana,
  fetchArea,
  fetchDistrict,
  fetchDivision,
  fetchThana,
} from "./address";
import {
  IAreaList,
  IDistrictList,
  IDivisionList,
  IResponse,
  IThanaList,
} from "./types";
import Toaster from "src/Toaster/Toaster";

interface IResponse2 {
  success: boolean;
  message?: string;
  // Add other properties as needed
}
export const useFetchDivisionData = (dependency?: unknown) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDivisionList[]>([]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchDivision();
      if (response.success) {
        setData(response.data);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, [dependency]);

  return {
    loading,
    data,
    fetchData,
  };
};

export const useFetchDistrictData = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDistrictList[]>([]);

  const fetchDistrictData = useCallback(
    async (params?: { division_id: number }) => {
      try {
        setLoading(true);
        const response = await fetchDistrict({ params });
        if (response.success) {
          setData(response.data);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    data,
    fetchDistrictData,
    setData,
  };
};

export const useCreateDistrictData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const Toast = Toaster();

  const createDistrictData = useCallback(
    async (input: {
      division_id: number;
      district_name: string;
    }): Promise<any> => {
      try {
        setLoading(true);
        const response = (await createDistrict({ input })) as IResponse;
        if (response.success) {
          Toast.fire({
            icon: "success",
            title: `District Successfully created!`,
          });
          return response;
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: `${error.response?.data?.message}`,
        });
        return {
          success: false,
          message: `${error.response?.data?.message}`,
        };
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    error,
    createDistrictData,
  };
};

export const useFetchThanaData = () => {
  const [data, setData] = useState<IThanaList[]>([]);
  const [loading, setLoading] = useState(false);
  const fetchThanaData = useCallback(
    async (params?: { division_id: number; district_id: number }) => {
      try {
        setLoading(true);
        const response = await fetchThana({ params });
        if (response.success) {
          setData(response.data);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    data,
    setData,
    fetchThanaData,
  };
};

export const useCreateThana = () => {
  const [loading, setLoading] = useState(false);
  const Toast = Toaster();

  const createThanaData = useCallback(
    async (input: {
      district_id: number;
      thana_name: string;
    }): Promise<any> => {
      try {
        setLoading(true);
        const response = await createThana({ input });
        if (response.success) {
          Toast.fire({
            icon: "success",
            title: `Thana Successfully created!`,
          });
          return response;
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: `${error.response?.data?.message}`,
        });
        return {
          success: false,
          message: `${error.response?.data?.message}`,
        };
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    createThanaData,
  };
};

export const useFetchAreaData = () => {
  const [data, setData] = useState<IAreaList[]>([]);
  const [loading, setLoading] = useState(false);
  const fetchAreaData = useCallback(
    async (params?: {
      division_id: number;
      district_id: number;
      thana_id: number;
    }) => {
      try {
        setLoading(true);
        const response = await fetchArea({ params });
        if (response.success) {
          setData(response.data);
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    data,
    setData,
    fetchAreaData,
  };
};

export const useCreateArea = () => {
  const [loading, setLoading] = useState(false);
  const Toast = Toaster();

  const createAreaData = useCallback(
    async (input: { thana_id: number; area_name: string }): Promise<any> => {
      try {
        setLoading(true);
        const response = await createArea({ input });
        if (response.success) {
          Toast.fire({
            icon: "success",
            title: `Area Successfully created!`,
          });
          return response;
        }
      } catch (error) {
        Toast.fire({
          icon: "error",
          title: `${error.response?.data?.message}`,
        });
        return {
          success: false,
          message: `${error.response?.data?.message}`,
        };
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    createAreaData,
  };
};
