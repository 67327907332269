import { useState, useEffect } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  CardHeader,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import TeacherTableRow from "./TeacherTableRow";
import { ITeacher } from "src/Types/module/teacher/teacherTypes";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import CreateTeacher from "../CreateTeacher";
import { debounce } from "lodash";

const TeacherTable = () => {
  const [allTeachers, setAllTeachers] = useState<ITeacher[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterItem, setFilterITem] = useState({
    name: "",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/teacher/get/all/or/status/all?searchParams=${filterItem?.name}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllTeachers(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [filterItem?.name]);

  const handelChangeInput = debounce((value) => {
    setFilterITem({ ...filterItem, name: value });
  }, 1000);
  return (
    <Card>
      <CardHeader
        action={
          <Box>
            {" "}
            <TextField
              // style={{ height: "10px" }}0
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              // sx={{ mb: "10px" }}
              size="small"
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  handelChangeInput(e.target.value)

                // setSearchCompanyVal(e.target.value)
              }
            />
            <Button
              onClick={() => handleOpen()}
              variant="contained"
              sx={{ ml: 2 }}
            >
              Create Teacher
            </Button>
          </Box>
        }
        title="All Teachers"
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Teacher Name</TableCell>
              <TableCell>Photo</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableLoader />
          ) : allTeachers?.length ? (
            <TableBody>
              {allTeachers.map((teacher) => {
                return (
                  <TeacherTableRow
                    key={teacher.training_teacher_id}
                    teacher={teacher}
                  />
                );
              })}
            </TableBody>
          ) : (
            <TableNotFound />
          )}
        </Table>
      </TableContainer>
      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2 }}> Create Teacher</Typography>
            <Divider />
          </Box>
          <CreateTeacher
            handleClose={handleClose}
            setAllTeachers={setAllTeachers}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default TeacherTable;
