import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { imgUrl } from "src/Helpers/utils/Constant";
import { getStatusLabel } from "src/Utils/Combined";
import { IGuest } from "src/Types/module/guestAndSpecker/guestAndSpeaker";
import moment from "moment-timezone";

interface IProps {
  guest: IGuest;
}
const SingleGuestAndSpeakerDetails = ({ guest }: IProps) => {
  return (
    <div>
      <Box sx={{ mt: 3 }}>
        <Grid container>
          <Grid item lg={4} sm={12} md={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                width={200}
                height={250}
                style={{
                  borderRadius: "5px",
                }}
                src={
                  guest?.guest_speaker_photo
                    ? `${imgUrl}/guest-speaker-files/${guest?.guest_speaker_photo}`
                    : "/static/images/avatars/avatar2.png"
                }
                alt=""
              />
            </Box>
          </Grid>
          <Grid item lg={8} sm={4} md={4}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  ID:
                </Typography>
                {guest?.guest_speaker_id}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Name:
                </Typography>
                {guest?.guest_speaker_name}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Company Name:
                </Typography>
                {guest?.guest_speaker_company_name}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Designation:
                </Typography>
                {guest?.guest_speaker_designation}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Type:
                </Typography>
                {guest?.guest_speaker_type}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Status:
                </Typography>
                {getStatusLabel(guest?.guest_speaker_status)}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Created At:
                </Typography>
                {moment
                  .tz(guest?.guest_speaker_created_at, "Asia/Dhaka")
                  .format("LT")}
                <span> | </span>
                {moment
                  .tz(guest?.guest_speaker_created_at, "Asia/Dhaka")
                  .format("MMM Do YY")}
              </Grid>

              <Grid item lg={12} sm={12} md={12}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Details:
                </Typography>
                {guest?.guest_speaker_details}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SingleGuestAndSpeakerDetails;
