import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ISingleBatch } from "src/Types/module/courses/trainee";
import SingleBatchStudentRow from "./SingleBatchStudentRow";
import UpdateBatch from "./UpdateBatch/UpdateBatch";
import AddTraineeToBatch from "./AddTraineeToBatch";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import { TableNotFound } from "src/Utils/TableLoader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateBatchWiseIdCard from "./CreateBatchWiseIdCard";

const SingleBatch = () => {
  const { id } = useParams();
  const [batchDetails, setBatchDetails] = useState<ISingleBatch>(
    {} as ISingleBatch
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // modal status
  const [open, setOpen] = useState({ addTrainee: false, createIDCard: false });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/training/batch/get/single/with/students/${id}`
        );

        if (data.success) {
          setBatchDetails(data.data);
          setLoading(false);
        } else {
          Toaster().fire({ icon: "info", title: "No batch found" });
          setLoading(false);
        }
      } catch (err) {
        Toaster().fire({ icon: "info", title: "No batch found" });
        setLoading(false);
      }
    })();
  }, [reload]);
  const openPopOver = Boolean(anchorEl);
  const popoverID = open ? "simple-popover" : undefined;
  return (
    <>
      <Helmet>
        <title>Atab - Batch Details</title>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <SuspenseLoader />
        ) : (
          batchDetails && (
            <Container>
              <Box m={1}>
                <Card sx={{ margin: "10px" }}>
                  <CardHeader
                    title="Training Batch Details"
                    action={
                      <Box>
                        <Link to={`/training-batch/${id}/id-card`}>
                          <Button
                            sx={{ margin: "3px" }}
                            variant="outlined"
                            size="small"
                          >
                            ID card
                          </Button>
                        </Link>
                        <Link
                          to={`/training-batch/class-list/${batchDetails.training_batch_id}?batch=${batchDetails.training_batch_name}`}
                        >
                          <Button
                            sx={{ margin: "3px" }}
                            variant="outlined"
                            size="small"
                          >
                            Class list
                          </Button>
                        </Link>
                        <Link
                          to={`/training-batch/exam-list/${id}?batch=${batchDetails.training_batch_name}`}
                        >
                          <Button
                            sx={{ margin: "3px" }}
                            variant="outlined"
                            size="small"
                          >
                            Exam list
                          </Button>
                        </Link>
                        <Link
                          to={`/training-batch/material/batch/${id}?batch=${batchDetails.training_batch_name}`}
                        >
                          <Button
                            sx={{ margin: "3px" }}
                            variant="outlined"
                            size="small"
                          >
                            Material list
                          </Button>
                        </Link>
                        <Button
                          sx={{ margin: "3px" }}
                          onClick={() => setShowModal(!showModal)}
                          variant="outlined"
                          size="small"
                        >
                          Edit
                        </Button>
                      </Box>
                    }
                  />
                  <Divider />
                  <Grid spacing={2} container>
                    <Grid xs={12} md={6} item>
                      <Box sx={{ minWidth: 275 }}>
                        <CardHeader
                          title="Batch Information"
                          action={
                            <Link
                              to={`/training-batch/notice/batch/${id}?batch=${batchDetails.training_batch_name}`}
                            >
                              <Button variant="outlined" size="small">
                                All Notice
                              </Button>
                            </Link>
                          }
                        />
                        <Divider />
                        <CardContent>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Batch NO
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>{batchDetails.batch_no}</Typography>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Batch Name
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {batchDetails.training_batch_name}
                              </Typography>
                            </Box>
                          </Box>
                          {/* <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Total Students
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {batchDetails.training_batch_total_student}
                              </Typography>
                            </Box>
                          </Box> */}
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Start Date
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {moment(
                                  batchDetails.training_batch_start_date
                                ).format("MMMM Do YYYY")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Status
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {getStatusLabel(
                                  batchDetails.training_batch_status
                                )}
                              </Typography>
                            </Box>
                          </Box>

                          {batchDetails.training_batch_end_date && (
                            <Box display="flex" height="100%">
                              <Box width={"30%"}>
                                <Typography
                                  sx={{ mb: 1.5 }}
                                  color="text.secondary"
                                >
                                  End Date
                                </Typography>
                              </Box>
                              <Box width={"70%"}>
                                <Typography>
                                  {batchDetails.training_batch_end_date}
                                </Typography>
                              </Box>
                            </Box>
                          )}

                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Course
                              </Typography>
                            </Box>
                            <Box width={"60%"}>
                              <Typography>
                                {batchDetails.training_batch_course_name}
                              </Typography>
                            </Box>
                            <Box width="10%">
                              <Tooltip title="view" arrow>
                                <IconButton color="inherit" size="small">
                                  <Link
                                    to={`/training-course/details/${batchDetails.training_batch_course_id}`}
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </Link>
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display="flex" height="100%">
                            <Box width={"30%"}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                Batch Details
                              </Typography>
                            </Box>
                            <Box width={"70%"}>
                              <Typography>
                                {batchDetails.training_batch_details}
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Box>
                    </Grid>
                    <Grid
                      xs={12}
                      md={6}
                      item
                      height={780}
                      sx={{
                        overflow: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      <Box>
                        <CardHeader
                          title={`Batch Students (${batchDetails.training_batch_total_student})`}
                          action={
                            <IconButton
                              aria-label="delete"
                              onClick={(event) =>
                                setAnchorEl(event.currentTarget)
                              }
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                        <Divider />
                        <CardContent>
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Id</TableCell>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Joined</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {batchDetails?.batchStudents?.length ? (
                                  batchDetails.batchStudents.map((student) => (
                                    <SingleBatchStudentRow
                                      key={student.user_training_trainee_id}
                                      student={student}
                                      courseId={
                                        batchDetails.training_batch_course_id
                                      }
                                      setReload={setReload}
                                    />
                                  ))
                                ) : (
                                  <TableNotFound />
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>

                {/* modals wrapper */}
                <UpdateBatch
                  showModal={showModal}
                  setShowModal={setShowModal}
                  loading={loading}
                  setLoading={setLoading}
                  batchDetails={batchDetails}
                  setReload={setReload}
                />
              </Box>
            </Container>
          )
        )}
      </Container>

      <Dialog
        open={open.addTrainee}
        onClose={() => setOpen({ ...open, addTrainee: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{ p: 4 }} id="responsive-dialog-title">
          <Typography variant="h4">Add trainee to a batch</Typography>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <AddTraineeToBatch
            handleClose={() => setOpen({ ...open, addTrainee: false })}
            setReload={setReload}
            courseID={batchDetails.training_batch_course_id}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setOpen({ ...open, addTrainee: false })}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        id={popoverID}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, m: 1 }}>
          <Button
            onClick={() => {
              setOpen({ ...open, addTrainee: true });
              setAnchorEl(null);
            }}
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
          >
            Add trainee to a batch
          </Button>

          <Button
            onClick={() => {
              setOpen({ ...open, createIDCard: true });
              setAnchorEl(null);
            }}
            size="small"
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Create ID card
          </Button>
        </Box>
      </Popover>

      {/* Modal */}
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open.createIDCard}
        modalData={{ title: "Create Trainee ID card" }}
      >
        <CreateBatchWiseIdCard
          handleClose={() => setOpen({ ...open, createIDCard: false })}
        />
      </ModalsWrapper>
    </>
  );
};

export default SingleBatch;
