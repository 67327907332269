import { Visibility } from "@mui/icons-material";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Toaster from "src/Toaster/Toaster";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import {
  IPaymentableItemList,
  IPaymentableTableRow,
} from "src/Types/module/paymentableItem/itemList";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import UpdatePaymentableItem from "../UpdatePaymentable/Index";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";

const PaymentableItemTableRow = ({
  invoiceData,
  setReload,
}: IPaymentableTableRow) => {
  const Toast = Toaster();
  const theme = useTheme();
  const { user } = useAuthContext();
  const [open, setOpen] = React.useState(false);

  const handelUpdate = async (checked: number) => {
    try {
      const { data } = await axios.put(
        `${url}/api/atab/payment/update/paymentable/item/${invoiceData.atab_paymentable_item_id}?user_admin_id=${user.user_admin_id}`,
        {
          atab_paymentable_item_name: invoiceData.atab_paymentable_item_name,
          atab_paymentable_item_bdt_amount:
            invoiceData.atab_paymentable_item_bdt_amount,
          atab_paymentable_item_usd_amount:
            invoiceData.atab_paymentable_item_usd_amount,
          atab_paymentable_item_status: checked,
        }
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setReload((prv) => !prv);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
    }
  };

  return (
    <>
      {invoiceData && (
        <TableRow hover key={invoiceData.atab_paymentable_item_id}>
          <TableCell>{invoiceData.atab_paymentable_item_id}</TableCell>
          <TableCell>
            {invoiceData.atab_paymentable_item_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </TableCell>
          <TableCell>{invoiceData.atab_paymentable_item_bdt_amount}</TableCell>
          <TableCell>{invoiceData.atab_paymentable_item_usd_amount}</TableCell>
          <TableCell>
            <Box>
              <Tooltip title="Edit" arrow>
                <IconButton
                  onClick={() => setOpen(true)}
                  sx={{
                    "&:hover": {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color="inherit"
                  size="small"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={
                  invoiceData.atab_paymentable_item_status
                    ? "Deactivate"
                    : "Active"
                }
                arrow
              >
                <Checkbox
                  defaultChecked={
                    invoiceData.atab_paymentable_item_status ? true : false
                  }
                  onClick={(e: any) => handelUpdate(e.target.checked ? 1 : 0)}
                />
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      )}

      {/* update modal  */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="h4">Update Invoice Item</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <UpdatePaymentableItem
              details={invoiceData}
              handleClose={() => setOpen(false)}
              setReload={setReload}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentableItemTableRow;
