import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Box,
} from "@mui/material";
import axios from "axios";
import { debounceTime, url } from "src/Helpers/utils/Constant";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { getStatusLabel } from "src/Utils/Combined";
import { IAgencyUpdateInfoApplication } from "src/Types/module/applications/applications";
import { debounce } from "lodash";

const AgencyInfoUpdateApplication = () => {
  const [allAgencyUpdateInfo, setAllAgencyUpdateInfo] = useState<
    IAgencyUpdateInfoApplication[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("pending");
  const [filterItem, serFilterItem] = useState<any>({
    company_name: "",
    status: "pending",
  });
  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        let uri = `${url}/api/atab/agency-member-info/all?status=${filterItem.status}`;

        if (filterItem.company_name) {
          uri += `&company_name=${filterItem.company_name}`;
        }
        const { data } = await axios.get(uri);

        if (data.success) {
          setAllAgencyUpdateInfo(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [filterItem.status, filterItem.company_name]);
  const handelChangeInput = debounce((value) => {
    serFilterItem({
      ...filterItem,
      company_name: value,
    });
  }, debounceTime);
  return (
    <Container sx={{ mt: 4, pb: 3 }} maxWidth="xl">
      <Card>
        <CardHeader
          title={
            <Typography sx={{ fontWeight: 700, lineHeight: 1.34 }}>
              Agency information update application
            </Typography>
          }
          action={
            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                // style={{ height: "10px" }}0
                id="outlined-basic"
                label="Search by agency name "
                variant="outlined"
                size="small"
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) =>
                    handelChangeInput(e.target.value)

                  // setSearchCompanyVal(e.target.value)
                }
              />

              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Select status
                </InputLabel>
                <Select
                  label="Select status"
                  value={filterItem.status}
                  size="small"
                  onChange={(e) =>
                    serFilterItem({
                      ...filterItem,
                      status: e.target.value,
                    })
                  }
                  displayEmpty
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
              </FormControl>
            </Box>
          }
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Agency Name </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {allAgencyUpdateInfo?.length ? (
                    <>
                      {allAgencyUpdateInfo?.map((item) => {
                        return (
                          <TableRow
                            key={item.agency_info_update_application_id}
                          >
                            <TableCell>
                              {item.agency_info_update_application_id}
                            </TableCell>

                            <TableCell>
                              {moment(item.agency_info_created_at).format(
                                "MMM Do YYYY"
                              )}
                            </TableCell>
                            <TableCell>
                              {item.user_member_company_name}
                            </TableCell>
                            <TableCell>{getStatusLabel(item.status)}</TableCell>
                            <TableCell>
                              <Link
                                to={`/update-applications/agency-info-update/${item.agency_info_update_application_id}`}
                              >
                                <Tooltip title="view" arrow>
                                  <IconButton
                                    sx={{
                                      "&:hover": {
                                        background:
                                          theme.colors.primary.lighter,
                                      },
                                      color: theme.palette.primary.main,
                                    }}
                                    color="inherit"
                                    size="small"
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default AgencyInfoUpdateApplication;
