// export const localURL: string = "http://192.168.0.56:5005"; //moon
export const localURL: string = "http://192.168.0.102:5000"; //srabon
// export const localURL: string = "http://192.168.0.191:5000"; //jasim

const liveURL: string = "https://server.atab.services";
export const url: string =
  process.env.NODE_ENV === "development" ? localURL : liveURL;

// export const imgUrl: string =
//   'https://atab.blob.core.ows.net/atab-storage/uploads';http://223.27.82.110:8080/Auth

export const imgUrl: string =
  "https://m360ict.s3.ap-south-1.amazonaws.com/uploads";

// Token key
export const TOKEN_KEY = "atab_admin";

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const IMAGE_CHANGE_SUCCESS = "IMAGE_CHANGE_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const SUPPORT_CONVERSATION_MGS_ID = "SUPPORT_CONVERSATION_MGS_ID";
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";

// Auth loading state
export const CHANGE_LOADING_STATE = "CHANGE_LOADING_STATE";

// export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
// export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_HIDE = "MODAL_HIDE";

export const debounceTime = 2000;
