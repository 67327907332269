import { useState, useEffect, ChangeEvent } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Typography,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { ISingleMaterial } from "src/Types/module/batch/allTypeOfBatch";
import { useParams } from "react-router-dom";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import AllMaterialsTableRow from "./AllMaterialsTableRow";
import { useSearchParams } from "react-router-dom";
import CreateMaterialOfSingleBatch from "./CreateMaterialOfSingleBatch";

const AllMaterialOfSingleBatch = () => {
  const [materials, setAllMaterials] = useState<ISingleMaterial[] | null>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/batch/get/materials/${id}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllMaterials(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [refresh]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Box>
        <Card>
          <CardHeader
            action={
              <Box display="flex" alignItems="center" gap={2}>
                <Button onClick={() => handleOpen()} variant="contained">
                  Create Material
                </Button>
              </Box>
            }
            title={`All materials of ${searchParams.get("batch")}`}
          />
          <Divider />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell> Title</TableCell>
                  <TableCell>Batch Name</TableCell>
                  <TableCell> Create Date</TableCell>
                  <TableCell> Create Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              {isLoading ? (
                <TableLoader />
              ) : materials ? (
                <TableBody>
                  {!materials.length ? (
                    <TableNotFound />
                  ) : (
                    materials.map((material) => {
                      return (
                        <AllMaterialsTableRow
                          key={material.training_batch_materials_id}
                          material={material}
                          allMaterials={materials}
                          setAllMaterials={setAllMaterials}
                        />
                      );
                    })
                  )}
                </TableBody>
              ) : (
                <TableNotFound />
              )}
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={materials?.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 75, 100]}
            />
          </Box>
        </Card>
      </Box>

      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box>
            <Typography sx={{ mb: 2 }}> Create Material</Typography>
            <Divider />
          </Box>
          <CreateMaterialOfSingleBatch
            handleClose={handleClose}
            id={id}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AllMaterialOfSingleBatch;
