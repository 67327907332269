import { useCreateDistrictData } from "src/basic-rest/useFetchAddressData";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { IDistrictList } from "src/basic-rest/types";
import Toaster from "src/Toaster/Toaster";

export default function CreateDistrict({ division_id, setDistrict }) {
  const { createDistrictData, loading: submitLoading } =
    useCreateDistrictData();
  const [value, setValue] = useState<string>("");
  const Toast = Toaster();
  const submit = async (e) => {
    e.preventDefault();

    if (!value) {
      Toast.fire({
        icon: "error",
        title: "Provide district name",
      });
      return;
    }
    const data = await createDistrictData({
      division_id,
      district_name: value,
    });

    if (data.success) {
      setValue("");
      setDistrict((district: IDistrictList[]) => [
        ...district,
        {
          district_id: data?.data?.district_id,
          district_name: value,
          division_id: district.length ? district[0].division_id : division_id,
          division_name: district.length ? district[0].division_name : "",
        },
      ]);
    }
  };

  return (
    <Stack
      component="form"
      sx={{
        width: "35ch",
        p: 2,
      }}
      spacing={2}
      noValidate
      autoComplete="off"
      onSubmit={submit}
    >
      <TextField
        id="test"
        label="District Name"
        defaultValue="Small"
        size="small"
        required
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button disabled={submitLoading} type="submit" variant="contained">
        {submitLoading ? "Please wait..." : "Create district"}
      </Button>
    </Stack>
  );
}
