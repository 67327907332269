import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Button,
  Grid,
  CardContent,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import TraineeTableRow from "./TraineeTableRow";
import { ItraineeList } from "src/Types/module/courses/trainee";
import { applyPagination } from "src/Utils/CommonAllShowUtils";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { debounce } from "lodash";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateTrainee from "./CreateTrainee";

const TraineeTable = () => {
  const { user } = useAuthContext();
  const [allTrainee, setAllTrainee] = useState<ItraineeList[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterItem, setFilterITem] = useState({
    name: "",
    status: "all",
  });

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    (async function () {
      try {
        let uri: string;
        uri = `${url}/api/training/trainee/get/all/or/status/${filterItem.status}?user_training_trainee_name=${filterItem.name}`;
        const { data } = await axios.get(uri);

        if (data.success) {
          setAllTrainee(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [user.user_admin_id, filterItem.status, filterItem.name]);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "1",
      name: "Active",
    },

    {
      id: "0",
      name: "Rejected",
    },
  ];

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedMembers = applyPagination(allTrainee, page, limit);

  const handelChangeInput = debounce((value) => {
    setFilterITem({ ...filterItem, name: value });
  }, 1000);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
            spacing={1}
          >
            <Grid item xs={12} md={2.7}>
              <TextField
                fullWidth
                // style={{ height: "10px" }}0
                id="outlined-basic"
                label="Search by trainee name"
                variant="outlined"
                size="small"
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) =>
                    handelChangeInput(e.target.value)

                  // setSearchCompanyVal(e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              {" "}
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  size="small"
                  value={filterItem.status}
                  onChange={(e) =>
                    setFilterITem({ ...filterItem, status: e.target.value })
                  }
                  label="Status"
                  autoWidth
                >
                  {statusOptions &&
                    statusOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>{" "}
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                onClick={() => setOpen(true)}
                variant="contained"
                size="small"
                sx={{ py: 1 }}
              >
                Create Trainee
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={`Trainee List (${allTrainee?.length || 0})`} />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableLoader />
              ) : allTrainee.length ? (
                <>
                  {paginatedMembers.map((allMembers: ItraineeList) => {
                    return (
                      <TraineeTableRow
                        key={allMembers.user_training_trainee_id}
                        allMembers={allMembers}
                      />
                    );
                  })}
                </>
              ) : (
                <TableNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={allTrainee.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>

        {/* Modal */}
        <ModalsWrapper
          setShowModal={setOpen}
          showModal={open}
          modalData={{ title: "Create Trainee" }}
        >
          <CreateTrainee
            handelClose={() => setOpen(!open)}
            setAllTrainee={setAllTrainee}
          />
        </ModalsWrapper>
      </Card>
    </>
  );
};

export default TraineeTable;
