import React from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ISingleProposer } from 'src/Types/module/UpdateRequest/RequestProposerInfo';

const useStyles = makeStyles({
  TableCell: {
    fontWeight: 'bold !important',
  },
});
interface IProps {
  requestProposerInfo: ISingleProposer[];
}
const ProposerAppReject = ({ requestProposerInfo }: IProps) => {
  const classes = useStyles();

  return (
    <Container>
      <Card>
        <CardHeader
          title={requestProposerInfo.map((info) => {
            return info.status === 'approved'
              ? 'Approved information'
              : 'Rejected information';
          })}
        />
        {requestProposerInfo.map((sProposer: ISingleProposer) => {
          return (
            <TableContainer sx={{ mb: 5 }} key={sProposer.proposer_request_id}>
              <Table aria-label='custom pagination table'>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Company Name
                    </TableCell>
                    <TableCell align='right'>
                      {sProposer?.proposer_request_to_company_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.TableCell}>
                      Representative Name
                    </TableCell>
                    <TableCell align='right'>
                      {sProposer?.proposer_request_to_representative_name}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
      </Card>
    </Container>
  );
};

export default ProposerAppReject;
