import React, { ReactNode, Dispatch, SetStateAction, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  Autocomplete,
  Button,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { IThanaList } from "src/basic-rest/types";
import ListSubheader from "@mui/material/ListSubheader";
import { AddressLoading, AddressNotFound } from "./utils";
import { IGetID } from "./Address";
import { useFetchThanaData } from "src/basic-rest/useFetchAddressData";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateThana from "./create-address/CreateThana";
import { Box } from "@mui/system";

type IProps = {
  checked: IGetID;
  setChecked: Dispatch<SetStateAction<IGetID>>;
};

function ThanaList({ checked, setChecked }: IProps) {
  const [open, setOpen] = useState(false);
  const {
    data: thanaData,
    fetchThanaData,
    loading,
    setData,
  } = useFetchThanaData();

  React.useEffect(() => {
    if (checked.division) {
      fetchThanaData({
        district_id: checked.district.district_id,
        division_id: checked.division.division_id,
      });
    }
  }, [checked.district, checked.division]);

  let content: ReactNode;
  if (loading) {
    content = <AddressLoading />;
  }
  if (!loading && thanaData.length === 0) {
    content = <AddressNotFound />;
  }

  if (thanaData.length > 0 && !loading) {
    content = (
      <Autocomplete
        getOptionLabel={(option: IThanaList) => option.thana_name}
        id="combo-box-demo"
        options={thanaData}
        renderOption={(params: any, option: IThanaList) => (
          <Box component="li" {...params}>
            {option.thana_name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Select thana" variant="outlined" />
        )}
        onChange={(_event, value: IThanaList) => {
          setChecked({ ...checked, thana: value, area: null });
        }}
      />
    );
  }

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          // position: "relative",
          overflow: "auto",
          maxHeight: 300,
          "& ul": { padding: 0 },
        }}
      >
        <ListSubheader
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            top: "-12px !important",
            padding: "10px",
          }}
        >
          <Typography variant="h4">Thana list</Typography>
          <Button onClick={() => setOpen(true)} size="small">
            Create Thana
          </Button>
        </ListSubheader>
        {content}
      </List>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: "Create Thana",
        }}
      >
        <CreateThana
          district_id={checked.district.district_id}
          setThana={setData}
        />
      </ModalsWrapper>
    </>
  );
}

export default ThanaList;
