import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import CardNotice from "./CardNotice";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";

const SingleNotice = () => {
  const { noticeId } = useParams();
  const [singleNotice, setSingleNotice] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/member/notice/${noticeId}`
        );
        if (data.success) {
          setSingleNotice(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [noticeId]);

  return (
    <>
      <Helmet>
        <title>Atab - Single Notice</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {loading ? (
            <GlobalLoader />
          ) : (
            <CardNotice singleNotice={singleNotice} />
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SingleNotice;
