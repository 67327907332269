import React, { useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { ISingleBatchNoticeDetails } from "src/Types/module/batch/allTypeOfBatch";
import moment from "moment";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

interface IProps {
  notice: ISingleBatchNoticeDetails;
}
function SingleBatchNoticeDetails({ notice }: IProps) {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  const {
    training_batch_notice_id,
    training_batch_notice_title,
    training_batch_notice_date,
    training_batch_id,
    training_batch_name,
    training_batch_notice_file,
  } = notice || {};

  const HandleDownload = () => {};
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              ID:
            </Typography>
            {training_batch_notice_id}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Notice Date:
            </Typography>
            {moment(training_batch_notice_date).format("LLL")}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Batch Name:
            </Typography>
            {training_batch_name}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Batch ID:
            </Typography>
            {training_batch_id}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <CommonTypoGraphy
              name="View Notice"
              onClick={() => {
                SetImageViewOpen(true);
                setImageFile(`${training_batch_notice_file}`);
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} md={4}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              Title:
            </Typography>
            {training_batch_notice_title}
          </Grid>
        </Grid>
      </Box>
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
}

export default SingleBatchNoticeDetails;
