import { ChangeEvent, useState } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import ExhibitorInvoiceTableRow from "./ExhibitorInvoiceTableRow";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { fairInvoice } from "src/Types/module/fairInvoice/fairInvoiceType";
import CreateExhibitorInvoice from "../CreateExhibitorInvoice";

const applyPagination = (
  allInvoice: fairInvoice[],
  page: number,
  limit: number
): fairInvoice[] => {
  return allInvoice.slice(page * limit, page * limit + limit);
};

interface IProps {
  allAdmin: any;
  setExhibitorInvoice: Function;
  loading: boolean;
  setStatus: Function;
  status: string;
}
const ExhibitorInvoiceTable = ({
  allAdmin,
  setExhibitorInvoice,
  loading,
  setStatus,
  status,
}: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const statusOptions = [
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "paid",
      name: "Paid",
    },

    {
      id: "cancelled",
      name: "Cancelled",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedInvoice = applyPagination(allAdmin, page, limit);

  return (
    <Card>
      <CardHeader
        action={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <FormControl variant="outlined" sx={{ width: "150px" }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                autoWidth
                size="small"
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              onClick={() => handleOpen()}
              variant="contained"
              size="small"
            >
              Create Exhibitor invoice
            </Button>
          </Box>
        }
        title="Exhibitor invoice List"
      />

      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Invoice issue date</TableCell>
              <TableCell>Total amount</TableCell>
              <TableCell>Transaction date</TableCell>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Payment method</TableCell>
              <TableCell>Invoice status</TableCell>
              <TableCell>Member contact name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableLoader />
            ) : allAdmin.length ? (
              <>
                {paginatedInvoice.map((allAdmin: fairInvoice) => {
                  return (
                    <ExhibitorInvoiceTableRow
                      key={allAdmin.fair_invoice_id}
                      allAdmin={allAdmin}
                    />
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={allAdmin.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[25, 50, 75, 100]}
        />
      </Box>
      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2 }}>Create exhibitor invoice</Typography>
            <Divider />
          </Box>
          <CreateExhibitorInvoice
            handleClose={handleClose}
            setExhibitorInvoice={setExhibitorInvoice}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ExhibitorInvoiceTable;
