import React, { Dispatch, SetStateAction, useState } from "react";

import { Button, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { muiStyles } from "../../../../Utils/Styles";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
const useStyles = muiStyles;
import { url } from "../../../../Helpers/utils/Constant";
import Toaster from "../../../../Toaster/Toaster";
import axios from "axios";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IProps {
  handleClose: Function;
  setAllCourse: Function;
}
const CreateCourse = ({ handleClose, setAllCourse }: IProps) => {
  const classes = useStyles();
  const { register, handleSubmit, reset } = useForm();
  const Toast = Toaster();
  const [loading, setLoading] = useState<Boolean>(false);

  const { user } = useAuthContext();

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${url}/api/training/course/create?user_admin_id=${user.user_admin_id}`,
        inputs
      );

      if (data.success) {
        setAllCourse((prev) => [
          {
            atab_training_course_id: data.data.atab_training_course_id,
            atab_training_course_name: inputs.atab_training_course_name,
            atab_training_course_duration: inputs.atab_training_course_duration,
            atab_training_course_amount: inputs.atab_training_course_amount,
            atab_training_course_status: "approved",
          },
          ...prev,
        ]);
        Toast.fire({
          icon: "success",
          title: `${data.message}`,
        });
        setLoading(false);
        handleClose();
      } else {
        Toast.fire({
          icon: "fail",
          title: `${data.message}`,
        });
        setLoading(false);
      }

      reset();
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Atab - Create Course</title>
      </Helmet>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        component='form'
        sx={{ p: 2, mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              margin='normal'
              required
              id='name'
              label='Course name'
              {...register("atab_training_course_name")}
              type='text'
              autoFocus
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              margin='normal'
              required
              id='name'
              label='Course duration'
              {...register("atab_training_course_duration")}
              type='text'
              autoFocus
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              margin='normal'
              required
              id='name'
              label='Class duration'
              {...register("atab_training_course_class_duration")}
              type='text'
              autoFocus
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              margin='normal'
              required
              id='name'
              label='Course price'
              {...register("atab_training_course_amount")}
              type='number'
              autoFocus
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid lg={12} item>
            {loading ? (
              <Button
                disabled
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                lodaing...
              </Button>
            ) : (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Create Course
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CreateCourse;
