import travel from "../assets/lotties/atab_login.json";
import traveler from "../assets/lotties/travel-r.json";
import Label from "src/components/Label";
import { adminStatus } from "src/Types/module/admin/adminTypes";

export const LottiedefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: travel,
  speed: 1,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const LottiedefaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: traveler,
  speed: 1,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const getStatusLabel = (invoiceStatus: string): JSX.Element => {
  const map = {
    failed: {
      text: "Failed",
      color: "error",
    },
    completed: {
      text: "Completed",
      color: "success",
    },
    running: {
      text: "Running",
      color: "success",
    },
    finished: {
      text: "Finished",
      color: "success",
    },
    pending: {
      text: "Pending",
      color: "warning",
    },
    attend: {
      text: "Attend",
      color: "success",
    },
    absent: {
      text: "Absent",
      color: "warning",
    },
    rejected: {
      text: "Rejected",
      color: "error",
    },
    active: {
      text: "Active",
      color: "success",
    },
    deactive: {
      text: "Deactive",
      color: "warning",
    },
    block: {
      text: "Block",
      color: "warning",
    },
    approved: {
      text: "Approved",
      color: "success",
    },
    ended: {
      text: "Ended",
      color: "warning",
    },
    upcoming: {
      text: "Upcoming",
      color: "warning",
    },
    cancelled: {
      text: "Cancelled",
      color: "warning",
    },
    available: {
      text: "Available",
      color: "success",
    },
    reserved: {
      text: "Reserved",
      color: "warning",
    },
    booked: {
      text: "Booked",
      color: "warning",
    },
    paid: {
      text: "Paid",
      color: "success",
    },
    reported: {
      text: "Reported",
      color: "warning",
    },
  };
  if ((map[invoiceStatus] && invoiceStatus !== null) || "") {
    const { text, color }: any = map[invoiceStatus];
    return <Label color={color}>{text}</Label>;
  } else if (invoiceStatus !== null || "") {
    return <Label>{invoiceStatus}</Label>;
  }
  return <Label>Unknown Status</Label>;
};
export const getStatusLabelMember = (accountStatus: string): JSX.Element => {
  const map = {
    rejected: {
      text: "Rejected",
      color: "error",
    },
    active: {
      text: "Active",
      color: "success",
    },
    pending: {
      text: "Pending",
      color: "warning",
    },
  };

  const { text, color }: any = map[accountStatus];

  return <Label color={color}>{text}</Label>;
};

export const getStatusLabelAdminFun = (adminStatus: adminStatus) => {
  const map = {
    rejected: {
      text: "Rejected",
      color: "error",
    },
    active: {
      text: "Active",
      color: "success",
    },
    pending: {
      text: "Pending",
      color: "warning",
    },
    approved: {
      text: "Approved",
      color: "success",
    },
  };
  const { text, color }: any = map[adminStatus];

  return <Label color={color}>{text}</Label>;
};

export type IfairListStatus = "ended" | "upcoming" | "cancelled" | string;

export const getStatusLabelFairList = (fairListStatus: IfairListStatus) => {
  const map = {
    ended: {
      text: "Ended",
      color: "warning",
    },
    upcoming: {
      text: "Upcoming",
      color: "warning",
    },
    cancelled: {
      text: "Cancelled",
      color: "warning",
    },
  };

  const { text, color }: any = map[fairListStatus];

  return <Label color={color}>{text}</Label>;
};
export type IfairStallStatus = "upcoming" | "cancelled" | "available" | string;

export const getStatusLabelFairStall = (fairListStatus: IfairStallStatus) => {
  const map = {
    available: {
      text: "Available",
      color: "success",
    },
    reserved: {
      text: "Reserved",
      color: "warning",
    },
    booked: {
      text: "Booked",
      color: "warning",
    },
  };

  const { text, color }: any = map[fairListStatus];

  return <Label color={color}>{text}</Label>;
};
export type IinvoiceStatus =
  | "pending"
  | "cancelled"
  | "reported"
  | "paid"
  | "rejected"
  | string;

export const getStatusLabelInvoiceStatus = (invoiceStatus: IinvoiceStatus) => {
  const map = {
    paid: {
      text: "Paid",
      color: "success",
    },
    reported: {
      text: "Reported",
      color: "warning",
    },
    rejected: {
      text: "Rejected",
      color: "warning",
    },
    pending: {
      text: "Pending",
      color: "warning",
    },
    cancelled: {
      text: "Cancelled",
      color: "warning",
    },
  };

  const { text, color }: any = map[invoiceStatus];
  return <Label color={color}>{text}</Label>;
};
export type ITraineeStatus = "pending" | "active" | "rejected" | string;

export const getStatusLabelTraineeStatus = (invoiceStatus: IinvoiceStatus) => {
  const map = {
    rejected: {
      text: "Rejected",
      color: "error",
    },
    active: {
      text: "Active",
      color: "success",
    },
    pending: {
      text: "Pending",
      color: "warning",
    },
  };

  const { text, color }: any = map[invoiceStatus];

  return <Label color={color}>{text}</Label>;
};
export type IMeetingStatus = "upcoming" | "active" | "rejected" | string;

export const getStatusLabelMeetingStatus = (invoiceStatus: IMeetingStatus) => {
  const map = {
    rejected: {
      text: "Rejected",
      color: "error",
    },
    active: {
      text: "Active",
      color: "success",
    },
    upcoming: {
      text: "upcoming",
      color: "warning",
    },
  };

  const { text, color }: any = map[invoiceStatus];

  return <Label color={color}>{text}</Label>;
};
