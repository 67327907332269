import { Button } from "@mui/material";
import React, { useState } from "react";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { saveAs } from "file-saver";
import axios from "axios";
import Excel from "exceljs";
const AgencyWiseReportCen = ({
  getDownloadData,
  fileName,
}: {
  getDownloadData: () => Promise<{ data: any }>;
  fileName: string;
}) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    const { data } = await getDownloadData();

    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(fileName);

      const titleRow = worksheet.addRow(["Agency name", "Students Name"]);

      // titleRow.eachCell((cell) => {
      //   cell.font = { bold: true };
      // });

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
        // cell.alignment = { horizontal: "center" };
      });

      worksheet.columns.forEach((column) => {
        column.width = 35;
      });
      await Promise.all(
        data.map(async (item) => {
          const row = worksheet.addRow([
            `${item.agency_name} (${item.student_count})`,
            item?.student_names
              ?.map((name, index) => `${index + 1}. ${name}`)
              .join("\n"), // Join student names with line breaks
          ]);

          // Set wrapText for each cell in the row
          row.eachCell((cell) => {
            cell.alignment = { wrapText: true };
          });
        })
      );

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      setLoading(false);
      console.error("Something Went Wrong", error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button
          onClick={saveExcel}
          variant="outlined"
          size="small"
          sx={{ marginLeft: "5px" }}
        >
          Download excel
        </Button>
      )}
    </>
  );
};

export default AgencyWiseReportCen;
// get image buffer
async function getImageBuffer(url: string) {
  try {
    const imgBuffer = await axios.get(url, {
      responseType: "arraybuffer",
    });
    return imgBuffer.data;
  } catch (err) {
    return null;
  }
}
