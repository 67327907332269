import React, { useContext, useReducer, useState, useEffect } from "react";
import { IauthContextState } from "./AuthTypes";
import {
  AUTH_USER_FAILED,
  AUTH_USER_SUCCESS,
  url,
} from "src/Helpers/utils/Constant";
import reducer from "src/Reducers/AuthReducer/AuthReducer";
import { socket } from "src/Helpers/utils/socket";
import axios from "axios";
import { getToken } from "src/Utils/Lib";

const initialState: IauthContextState = {
  user: {},
  notification: { notification: [], count: 0 },
  token: "",
};

const AuthContext = React.createContext(initialState);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const token = getToken();
      if (token) {
        try {
          const { data } = await axios.post(
            `${url}/api/atab/admin/verify/profile`,
            { token }
          );

          if (data.success) {
            dispatch({
              type: AUTH_USER_SUCCESS,
              payload: { data: data.data, token },
            });
            setIsLoading(false);
          } else {
            dispatch({ type: AUTH_USER_FAILED });
            setIsLoading(false);
          }
          setIsLoading(false);
        } catch (err) {
          dispatch({ type: AUTH_USER_FAILED });
          setIsLoading(false);
        }
      } else {
        dispatch({ type: AUTH_USER_FAILED });
        setIsLoading(false);
      }
    })();

    socket.on("user_online", (data) => {
      console.log({ data });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (state.user?.user_admin_id) {
      socket.auth = { id: state.user?.user_admin_id, type: "user_admin" };
      socket.connect();
    }
  }, [state.user]);

  return (
    <>
      <AuthContext.Provider
        value={{ ...state, dispatch, isLoading, setIsLoading }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthContextProvider, useAuthContext };
