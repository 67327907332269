import React, { useState } from "react";
import { IMoneyReceipt } from "src/Types/module/common/commonTypes";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { Visibility } from "@mui/icons-material";
import Toaster from "src/Toaster/Toaster";
import { HttpClient } from "src/Helpers/http/http";
import { url } from "src/Helpers/utils/Constant";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";

type IProps = {
  sInvoice: IMoneyReceipt;
  allInvoices: IMoneyReceipt[];
  setAllInvoices: any;
};
const MoneyReceiptTableRow = ({
  sInvoice: invoiceData,
  allInvoices,
  setAllInvoices,
}: IProps) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/atab/admin/money-receipt/${id}`;
        const data = await HttpClient.delete(uri);

        if (data) {
          const filterData = allInvoices.filter((item) => item.id !== id);

          setAllInvoices(filterData);

          Toaster().fire({
            icon: "success",
            title: "Deleted successfully!",
          });

          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };
  return (
    <TableRow hover>
      <TableCell>
        {moment(invoiceData.created_at).format("DD-MMM-YYYY")}
      </TableCell>
      <TableCell>{invoiceData.money_receipt_no}</TableCell>
      <TableCell>{invoiceData.user_member_company_name}</TableCell>
      <TableCell>
        <p>
          {invoiceData.account_name}{" "}
          <span style={{ color: "#999999" }}>
            ({invoiceData.payment_method})
          </span>
        </p>
      </TableCell>
      {/* <TableCell>
        <Link to={`/member-list/${invoiceData.user_member_id}`}>
          {invoiceData.user_member_company_name
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Link>
      </TableCell> */}

      <TableCell>{invoiceData.total_collected_amount}</TableCell>

      {/* <TableCell>
            {getStatusLabel(invoiceData.atab_payment_invoice_status)}
          </TableCell> */}
      <TableCell>
        <Tooltip title="View" arrow>
          <Link to={`${invoiceData.id}`}>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <Visibility fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Delete money receipt" arrow>
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* Delele Confirmation modal  */}
        <DeleteConfirmation
          open={open}
          handleClose={() => setOpen(false)}
          text="Want to delete this money receipt?"
          handleDeleteFun={() => handleDelete(invoiceData?.id)}
          loading={isLoading}
        />
      </TableCell>
    </TableRow>
  );
};

export default MoneyReceiptTableRow;
