import { useState } from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ISingleMaterial } from "src/Types/module/batch/allTypeOfBatch";
import moment from "moment-timezone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IProps {
  material: ISingleMaterial;
  allMaterials: any;
  setAllMaterials: any;
}

const AllMaterialTableRow = ({
  material,
  allMaterials,
  setAllMaterials,
}: IProps) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user } = useAuthContext();

  const handleDelete = (id) => {
    (async function () {
      try {
        const uri = `${url}/api/training/batch/delete/materials/${id}?user_admin_id=${user.user_admin_id}`;
        const { data } = await axios.delete(uri);

        if (data.success) {
          Toaster().fire({
            icon: "success",
            title: "Deleted successfully!",
          });

          const filterData = allMaterials.filter(
            (sMaterial) => sMaterial.training_batch_materials_id !== id
          );

          setAllMaterials(filterData);
          setIsLoading(false);
          handleClose();
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {material.training_batch_materials_id}
        </Typography>
      </TableCell>
      <TableCell sx={{ wordBreak: "break-word", maxWidth: "350px" }}>
        <Typography
          title={material.training_batch_materials_title
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {material.training_batch_materials_title
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Typography>
      </TableCell>
      <TableCell sx={{ wordBreak: "break-word", maxWidth: "350px" }}>
        <Typography
          title={material.training_batch_name}
          variant="body1"
          color="text.primary"
          gutterBottom
          noWrap
        >
          {material.training_batch_name}
        </Typography>
      </TableCell>
      <TableCell>
        {moment
          .tz(material.training_batch_materials_date, "Asia/Dhaka")
          .format("MMM Do YY")}
      </TableCell>
      <TableCell>
        {moment
          .tz(material.training_batch_materials_date, "Asia/Dhaka")
          .format("LT")}
      </TableCell>
      <TableCell>
        <Box>
          <Link
            to={`/training-batch/material/${material.training_batch_materials_id}`}
          >
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
          <Tooltip title="Delete Materials" arrow>
            <IconButton
              onClick={() => setOpen(true)}
              sx={{
                "&:hover": { background: theme.colors.error.lighter },
                color: theme.palette.error.main,
              }}
              color="inherit"
              size="small"
            >
              <DeleteTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          {/* Delele Confirmation modal  */}
          <DeleteConfirmation
            open={open}
            handleClose={() => setOpen(false)}
            text="Do you want to delete material?"
            handleDeleteFun={() =>
              handleDelete(material.training_batch_materials_id)
            }
            loading={isLoading}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default AllMaterialTableRow;
