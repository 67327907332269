import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import DivisionList from "./DivisionList";
import DistrictList from "./DistrictList";
import ThanaList from "./ThanaList";
import AreaList from "./AreaList";
import {
  IAreaList,
  IDistrictList,
  IDivisionList,
  IThanaList,
} from "src/basic-rest/types";

export type IGetID = {
  division: IDivisionList | null;
  district: IDistrictList | null;
  thana: IThanaList | null;
  area: IAreaList | null;
};

export default function Address() {
  const [checked, setChecked] = React.useState<IGetID>({
    division: null,
    district: null,
    thana: null,
    area: null,
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Typography textAlign="center" variant="h2">
        Manage Address
      </Typography>
      <Grid
        sx={{ p: 4 }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item sm={12} md={4} xl={3}>
          <DivisionList checked={checked} setChecked={setChecked} />
        </Grid>
        {checked.division && (
          <Grid item sm={12} md={4} xl={3}>
            <DistrictList checked={checked} setChecked={setChecked} />
          </Grid>
        )}
        {checked.district && checked.division && (
          <Grid item sm={12} md={4} xl={3}>
            <ThanaList checked={checked} setChecked={setChecked} />
          </Grid>
        )}
        {checked.division && checked.district && checked.thana && (
          <Grid item sm={12} md={4} xl={3}>
            <AreaList checked={checked} setChecked={setChecked} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
