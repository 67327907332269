import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';

import { LoadingButton } from '@mui/lab';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';

function EventUpdateForm({ singleEvent, handleEditModeChange }) {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const Toast = Toaster();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>(null);

  const onSubmit = async (inputs: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    const bodyFormData = new FormData();

    for (const key in inputs) {
      bodyFormData.append(key, inputs[key]);
    }
    if (uploadFile) bodyFormData.append('member_event_cover_photo', uploadFile);

    try {
      const { data } = await axios.patch(
        `${url}/api/member/event/${singleEvent.member_event_id}?user_admin_id=${user.user_admin_id}`,
        bodyFormData
      );

      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: data.message,
        });
        setIsLoading(false);
        handleEditModeChange();
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      Toast.fire({
        icon: 'error',
        title: `${err.response?.data?.message}`,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} mb={2} p={2}>
        <Grid item xs={12} md={6}>
          {' '}
          <TextField
            fullWidth
            label='Member meeting title:'
            id='filled-size-small'
            defaultValue={singleEvent.member_event_name}
            variant='outlined'
            size='medium'
            type='text'
            {...register('member_event_name')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id='filled-size-small'
            variant='outlined'
            type='file'
            label='Upload a meeting image'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: any) => setUploadFile(e.target.files[0])}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label='Member meeting detail:'
            id='filled-size-small'
            multiline
            rows={4}
            defaultValue={singleEvent.member_event_details}
            variant='outlined'
            size='medium'
            type='text'
            {...register('member_event_details')}
          />
        </Grid>
      </Grid>
      {!isLoading ? (
        <Button
          type='submit'
          variant='contained'
          disableElevation
          sx={{
            margin: 2,
          }}
        >
          Update event
        </Button>
      ) : (
        <LoadingButton
          loading
          loadingIndicator='Updating...'
          variant='outlined'
        >
          Updating...
        </LoadingButton>
      )}
    </Box>
  );
}

export default EventUpdateForm;
