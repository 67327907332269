import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import axios from "axios";

import { useEffect, useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import { ISingleTraineeApplication } from "src/Utils/type";
type IProps = {
  division_id: number;
  district_id: number;
  thana_id: number;
};

type IStateProps = {
  setAreaID?: (number) => void;
  setInfo?: (any) => void;
  info?: any;
  singleTraineeApplication?: ISingleTraineeApplication;
};

function ApplicationAddressSelect({
  setAreaID,
  setInfo,
  info,
  singleTraineeApplication,
}: IStateProps) {
  const [addressID, setAddressID] = useState<IProps>({
    division_id: singleTraineeApplication?.division_id ?? 0,
    district_id: singleTraineeApplication?.district_id ?? 0,
    thana_id: singleTraineeApplication?.thana_id ?? 0,
  });
  const [data, setData] = useState({
    division: [],
    district: [],
    thana: [],
    area: [],
  });

  useEffect(() => {
    const fetchData = async (endpoint: string, state) => {
      try {
        const { data } = await axios.get(`${url}/api/atab/address/${endpoint}`);

        if (data.success) {
          setData((prevData) => ({ ...prevData, [state]: data.data }));
        }
      } catch (error) {
        console.error(`Error fetching data for ${endpoint}:`, error);
      }
    };

    // Fetch data for division
    fetchData(`division`, "division");

    // Fetch data for district when division_id changes

    if (addressID.division_id) {
      fetchData(`district?division_id=${addressID.division_id}`, "district");
    }

    // Fetch data for thana when division_id or district_id changes
    if (addressID.division_id && addressID.district_id) {
      fetchData(
        `thana?division_id=${addressID.division_id}&district_id=${addressID.district_id}`,
        "thana"
      );
    }

    // Fetch data for area when division_id, district_id, or thana_id changes
    if (addressID.division_id && addressID.district_id && addressID.thana_id) {
      fetchData(
        `area?division_id=${addressID.division_id}&district_id=${addressID.district_id}&thana_id=${addressID.thana_id}`,
        "area"
      );
    }
  }, [addressID.division_id, addressID.district_id, addressID.thana_id]);

  return (
    <>
      <Grid xs={12} md={4} lg={3} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select division</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select division"
            defaultValue={singleTraineeApplication?.division_id}
            onChange={(e) =>
              setAddressID({
                ...addressID,
                division_id: Number(e.target.value),
              })
            }
          >
            {data?.division.length > 0 &&
              data?.division.map((item, i) => (
                <MenuItem key={i + 1} value={item.division_id}>
                  {item.division_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={4} lg={3} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select district</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select district"
            defaultValue={singleTraineeApplication?.district_id}
            onChange={(e) =>
              setAddressID({
                ...addressID,
                district_id: Number(e.target.value),
              })
            }
          >
            {data?.district.length > 0 &&
              data?.district.map((item, i) => (
                <MenuItem key={i + 1} value={item.district_id}>
                  {item.district_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={4} lg={3} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select thana</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select thana"
            defaultValue={singleTraineeApplication?.thana_id}
            onChange={(e) =>
              setAddressID({
                ...addressID,
                thana_id: Number(e.target.value),
              })
            }
          >
            {data?.thana.length > 0 &&
              data?.thana.map((item, i) => (
                <MenuItem key={i + 1} value={item.thana_id}>
                  {item.thana_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={4} lg={3} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select area</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select area"
            defaultValue={String(singleTraineeApplication?.area_id)}
            onChange={(e: SelectChangeEvent<string>) => {
              if (setInfo) {
                setInfo({ ...info, area_id: e.target.value });
              }

              // Optional setAreaID
              if (setAreaID) {
                setAreaID(e.target.value);
              }
            }}
          >
            {data?.area.length > 0 &&
              data?.area.map((item, i) => (
                <MenuItem key={i + 1} value={item.area_id}>
                  {item.area_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default ApplicationAddressSelect;
