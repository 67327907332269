import { TableCell, TableRow, Typography } from "@mui/material";
import { getStatusLabelFairStall } from "src/Utils/Combined";
import moment from "moment";
import { IFairStall } from "src/Types/module/fairStall/fairStallType";
import { FaRegEdit } from "react-icons/fa";
import { useState } from "react";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateFairStall from "./UpdateFairStall";

type IProps = {
  allAdmin: IFairStall;
  setRefetch: any;
};

const FairStallTableRow = ({ allAdmin, setRefetch }: IProps) => {
  const [singleData, setSingleData] = useState({} as IFairStall);
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow hover key={allAdmin.fair_stall_id}>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_stall_id}
          </Typography>
        </TableCell>
        {/* <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_stall_name}
          </Typography>
        </TableCell> */}
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_stall_type}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_stall_hall_name === "Hormone"
              ? "Harmony"
              : allAdmin.fair_stall_hall_name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {moment(allAdmin.fair_stall_created_at).format("LL")}
          </Typography>
        </TableCell>
        <TableCell>
          {getStatusLabelFairStall(allAdmin.fair_stall_available_status)}
        </TableCell>
        <TableCell>
          <FaRegEdit
            size={23}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSingleData(allAdmin);
              setOpen(true);
            }}
          />
        </TableCell>
      </TableRow>

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: "Update stall" }}
      >
        <UpdateFairStall
          handleClose={() => setOpen(false)}
          singleData={singleData}
          setRefetch={setRefetch}
        />
      </ModalsWrapper>
    </>
  );
};

export default FairStallTableRow;
