import { useState, useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";

import { ISingleBusinessLicense } from "src/Types/module/UpdateRequest/RequestBusinessLicense";
import CurrentBusinessLicenseInfo from "./CurrentBusinessLicenseInfo";
import UpdateBusinessLicenseInfo from "./UpdateBusinessLicenseInfo";
import { useSearchParams, useParams } from "react-router-dom";
import BusinessLicenseAppReject from "./BusinessLicenseAppReject";

const RequestSingleBusinessLicenseInfo = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const memberId = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preBusinessLicenseInfo, setPrvBusinessLicenseInfo] =
    useState<ISingleBusinessLicense>({} as ISingleBusinessLicense);
  const [requestBusinessLicenseInfo, setRequestBusinessLicenseInfo] =
    useState<ISingleBusinessLicense>({} as ISingleBusinessLicense);

  const urls = [
    `${url}/api/atab/member-company/member/business-license/${memberId}`,
    `${url}/api/atab/member-company/update-request/member/business-license/${id}`,
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const data = await Promise.all(urls.map((url) => axios.get(url)));
        if (data?.length) {
          setPrvBusinessLicenseInfo(data[0]?.data?.data);
          setRequestBusinessLicenseInfo(data[1]?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestBusinessLicenseInfo.status === "pending" ? (
            <Container maxWidth="xl" sx={{ mt: 5 }}>
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CurrentBusinessLicenseInfo
                    preBusinessLicenseInfo={preBusinessLicenseInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UpdateBusinessLicenseInfo
                    requestBusinessLicenseInfo={requestBusinessLicenseInfo}
                    id={id}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <BusinessLicenseAppReject
              requestBusinessLicenseInfo={requestBusinessLicenseInfo}
            />
          )}
        </>
      )}
    </>
  );
};

export default RequestSingleBusinessLicenseInfo;
