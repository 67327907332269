import { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateCompanyOwnerInformation from "../../../CompanyAddInfo/UpdateCompanyInformation/UpdateCompanyOwnerInformation";
import {
  ICompanyOwnerInfo,
  ICompanyOwnerInfoResponse,
} from "src/Types/module/member/CompanyInformation";
import moment from "moment";
import OrganizationInfo from "../../../CompanyAddInfo/CompanyAddInfoComponents/OrganizationInfo";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import { HttpClient } from "src/Helpers/http/http";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

function CompanyInformation({ setRefresh: singleDataLoad }: any) {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [ownerInformation, setOwnerInformation] = useState<ICompanyOwnerInfo>(
    {} as ICompanyOwnerInfo
  );
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const data: ICompanyOwnerInfoResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/company/${memberId}`
        );

        if (data.success) {
          setOwnerInformation(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    member_company_name,
    member_company_type,
    member_company_stablished_date,
    member_company_status,
    member_company_verified_by,
    member_company_verified_date,
    member_company_created_at,
    member_company_website,
    member_company_logo,
    phone,
    cellphone,
    email,
    addressline1,
    addressline2,
    addressline3,
    postalcode,
    police_station,
    zone_name,
    area_name,
    thana_name,
    district_name,
    division_name,
  } = ownerInformation || {};
  return (
    <>
      {/* {applied ? ( */}
      <>
        <PageTitleWrapper>
          <PageTitle
            heading="Company information"
            actionButtonTitle={member_company_name ? "Update" : "Add"}
            setAction={setOpen}
          />
        </PageTitleWrapper>

        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {member_company_name ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company name:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_name
                              ? member_company_name?.charAt(0).toUpperCase() +
                                member_company_name?.slice(1)
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company status:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_status
                              ? member_company_status
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company type:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_type
                              ? member_company_type
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company phone number:
                          </TableCell>
                          <TableCell align="right">
                            {phone ? phone : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company cell phone number:
                          </TableCell>
                          <TableCell align="right">
                            {cellphone ? cellphone : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company email:
                          </TableCell>
                          <TableCell align="right">
                            {email ? email : "Not provided"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company address 1:
                          </TableCell>
                          <TableCell align="right">
                            {addressline1 ? addressline1 : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company address 2:
                          </TableCell>
                          <TableCell align="right">
                            {addressline2 ? addressline2 : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company address 3:
                          </TableCell>
                          <TableCell align="right">
                            {addressline3 ? addressline3 : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company website:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_website ? (
                              <a href={member_company_website} target="_blank">
                                {member_company_website}
                              </a>
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company logo:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_logo ? (
                              <CommonTypoGraphy
                                name="View"
                                onClick={() => {
                                  SetImageViewOpen(true);
                                  setImageFile(member_company_logo);
                                }}
                              />
                            ) : (
                              "Not provided"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company created date:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_created_at
                              ? moment(member_company_created_at).format(
                                  "Do MMM YYYY"
                                )
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company stablish date :
                          </TableCell>
                          <TableCell align="right">
                            {member_company_stablished_date
                              ? moment(member_company_stablished_date).format(
                                  "Do MMM YYYY"
                                )
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company verify by:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_verified_by
                              ? member_company_verified_by
                              : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company verify date:
                          </TableCell>
                          <TableCell align="right">
                            {member_company_verified_date
                              ? moment(member_company_verified_date).format(
                                  "Do MMM YYYY"
                                )
                              : "Not provided"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company police station:
                          </TableCell>
                          <TableCell align="right">
                            {police_station ? police_station : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company post code:
                          </TableCell>
                          <TableCell align="right">
                            {postalcode ? postalcode : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company zone name:
                          </TableCell>
                          <TableCell align="right">
                            {zone_name ? zone_name : "Not provided"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company division:
                          </TableCell>
                          <TableCell align="right">
                            {division_name ? division_name : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company district:
                          </TableCell>
                          <TableCell align="right">
                            {district_name ? district_name : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company thana:
                          </TableCell>
                          <TableCell align="right">
                            {thana_name ? thana_name : "Not provided"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Company area:
                          </TableCell>
                          <TableCell align="right">
                            {area_name ? area_name : "Not provided"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="h3"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>
      {/* ) : (
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
          No data found
        </Typography>
      )} */}

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: member_company_name
            ? "Update company information"
            : "Add company information",
        }}
      >
        {member_company_name ? (
          <UpdateCompanyOwnerInformation
            ownerInformation={ownerInformation}
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
            singleDataLoad={singleDataLoad}
          />
        ) : (
          <OrganizationInfo
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
}

export default CompanyInformation;
