import { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";

import { ISingleTradeLicense } from "src/Types/module/UpdateRequest/RequestTradeLicense";
import CurrentTradeLicenseInfo from "./CurrentTradeLicenseInfo";
import UpdateTradeLicenseInfo from "./UpdateTradeLicenseInfo";
import { useSearchParams } from "react-router-dom";
import TraderLicenseAppReject from "./TraderLicenseAppReject";

const initualValue = {
  company_bin_file: null,
  company_bin_number: null,
  company_tin_file: null,
  company_tin_number: null,
  company_trade_license: null,
  company_trade_license_expiry_date: null,
  company_trade_license_file: null,
};
const RequestSingleTradeLicenseInfo = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const memberID = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preTradeLicenseInfo, setPrvTradeLicenseInfo] =
    useState<ISingleTradeLicense>({} as ISingleTradeLicense);
  const [requestTradeLicenseInfo, setRequestTradeLicenseInfo] =
    useState<ISingleTradeLicense>({} as ISingleTradeLicense);

  const urls = [
    `${url}/api/atab/member-company/update-request/member/license/${id}`,
    `${url}/api/atab/member-company/member/license/${memberID}`,
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const data = await Promise.all(urls.map((url) => axios.get(url)));
        if (data?.length) {
          setRequestTradeLicenseInfo(data[0]?.data?.data);
          setPrvTradeLicenseInfo(data[1]?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestTradeLicenseInfo.status === "pending" ? (
            <Container maxWidth="xl" sx={{ mt: 5 }}>
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CurrentTradeLicenseInfo
                    preTradeLicenseInfo={preTradeLicenseInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UpdateTradeLicenseInfo
                    requestTradeLicenseInfo={requestTradeLicenseInfo}
                    id={id}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <TraderLicenseAppReject
              requestTradeLicenseInfo={requestTradeLicenseInfo}
            />
          )}
        </>
      )}
    </>
  );
};

export default RequestSingleTradeLicenseInfo;
