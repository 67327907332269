import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Grid,
  Typography,
  Button,
  TextField,
  Chip,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { IMemberOrganizationProposer } from "src/Types/module/member/UpdateCompanyInfoTypes";
import { IProposerInput } from "src/Types/module/member/UpdateCompanyInfoStateTypes";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
interface Props {
  memberId: string;
  setOpen: (value: boolean) => void;
  setRefresh: (value: boolean) => void;
  refresh: boolean;
}
const ProposerInput = ({ memberId, setOpen, setRefresh, refresh }: Props) => {
  const { handleSubmit } = useForm();
  const [memberOrganizationProposer, setMemberOrganizationProposer] = useState<
    IMemberOrganizationProposer[]
  >([]);
  const [proposerInput, setProposerInput] = useState<IProposerInput>({
    member_1st_proposer_id: 0,
    member_1st_proposer_seal_signature: "",
    member_2nd_proposer_id: 0,
    member_2nd_proposer_seal_signature: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, seTCompanyName] = useState<string>("");
  useEffect(() => {
    axios
      .get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/active?company_name=${companyName}`
      )
      .then(function (res) {
        if (res.data.success) {
          setMemberOrganizationProposer(res.data.data);
        }
      });
  }, [companyName]);

  const proposerOption = memberOrganizationProposer.map((option: any) => ({
    id: option.user_member_id,
    label: `${option.user_member_company_name}`,
  }));

  const onSubmit = async (inputData: any) => {
    setIsLoading(true);
    const formData: any = new FormData();

    for (const key in proposerInput) {
      if (proposerInput.hasOwnProperty(key)) {
        const typedKey = key as keyof IProposerInput;
        const value: any = proposerInput[typedKey];

        if (value !== undefined && value !== null) {
          if (
            typedKey === "member_1st_proposer_seal_signature" ||
            key === "member_2nd_proposer_seal_signature"
          ) {
            formData.append(typedKey as string, value);
          } else {
            formData.append(typedKey as string, String(value));
          }
        }
      }
    }
    try {
      const data: any = await HttpClient.post(
        `/api/atab/member-company/add/proposer/for-admin/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <label htmlFor="">
                  Select 1st proposer name{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Box>
                  <Autocomplete
                    sx={{
                      width: proposerInput?.member_1st_proposer_id
                        ? "100%"
                        : 300,
                    }}
                    disablePortal
                    onChange={(e: any, newValue: any) =>
                      setProposerInput({
                        ...proposerInput,
                        member_1st_proposer_id: newValue?.id,
                      })
                    }
                    isOptionEqualToValue={(proposerOption, value) =>
                      proposerOption.id === value.id
                    }
                    loading={true}
                    id="controllable-states-demo"
                    options={proposerOption}
                    onInputChange={(_event, newInputValue) => {
                      seTCompanyName(newInputValue);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Select 1st proposer name"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>{" "}
            {proposerInput?.member_1st_proposer_id ? (
              <Grid item xs={12} md={6}>
                <Box>
                  <label htmlFor="">
                    1st proposer seal and signature ( max size 5mb )
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="outlined-basic"
                    required
                    type="file"
                    fullWidth
                    onChange={(e: any) => {
                      setProposerInput({
                        ...proposerInput,
                        member_1st_proposer_seal_signature: e.target.files[0],
                      });
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          <Divider style={{ margin: "20px 0px" }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <label htmlFor="">
                  Select 2nd proposer name{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Box>
                  <Autocomplete
                    sx={{
                      width: proposerInput?.member_1st_proposer_id
                        ? "100%"
                        : 300,
                    }}
                    disablePortal
                    onChange={(e: any, newValue: any) =>
                      setProposerInput({
                        ...proposerInput,
                        member_2nd_proposer_id: newValue?.id,
                      })
                    }
                    isOptionEqualToValue={(proposerOption, value) =>
                      proposerOption.id === value.id
                    }
                    loading={true}
                    id="controllable-states-demo"
                    options={proposerOption}
                    onInputChange={(_event, newInputValue) => {
                      seTCompanyName(newInputValue);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Select 2nd proposer name"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>{" "}
            {proposerInput?.member_2nd_proposer_id ? (
              <Grid item xs={12} md={6}>
                <Box>
                  <label htmlFor="">
                    2nd proposer seal and signature ( max size 5mb )
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="outlined-basic"
                    required
                    variant="outlined"
                    type="file"
                    fullWidth
                    onChange={(e: any) => {
                      setProposerInput({
                        ...proposerInput,
                        member_2nd_proposer_seal_signature: e.target.files[0],
                      });
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>
        <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
          {isLoading ? (
            <Button
              disabled
              variant="contained"
              disableElevation
              className="add-remove-button"
            >
              loading..
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disableElevation
              className="add-remove-button"
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProposerInput;
