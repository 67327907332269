import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import { TableLoader } from "src/Utils/TableLoader";
import { ITrainingInvoiceList } from "src/Utils/type";
import { applyPagination } from "src/Utils/CommonAllShowUtils";
import { TableNotFound } from "src/Utils/TableLoader";
import TrainingInvoiceTableRow from "./TrainingInvoiceTableRow";
import CreateTrainingInvoice from "./CreateTrainingInvoice";
import { HttpClient } from "src/Helpers/http/http";

function TrainingInvoice() {
  const [trainingInvoiceList, setTrainingInvoiceList] = useState<
    ITrainingInvoiceList[]
  >([]);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [status, setStatus] = useState<string>("unpaid");
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/admin/training/invoice?limit=${limit}&skip=${limit * page}`;
        if (searchValue) {
          uri += `&trainee_name=${searchValue}`;
        }
        if (status) {
          uri += `&status=${status}`;
        }
        const res: any = await HttpClient.get(uri);

        if (res.success) {
          setTrainingInvoiceList(res.data);
          setLoading(false);
          setTotal(res.total);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page, limit, status, searchValue, refresh]);

  const statusOptions = [
    {
      id: "unpaid",
      name: "Unpaid",
    },
    {
      id: "paid",
      name: "Paid",
    },
  ];

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedInvoice = applyPagination(trainingInvoiceList, page, limit);

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display="flex" justifyContent="space-between" gap={2}>
                  <TextField
                    id="outlined-basic"
                    label="Trainee name"
                    variant="outlined"
                    size="small"
                    onChange={(
                      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => setSearchValue(e.target.value)}
                  />

                  <FormControl sx={{ width: "150px" }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      label="Status"
                      size="small"
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    onClick={() => handleOpen()}
                    variant="contained"
                    size="small"
                  >
                    Create invoice
                  </Button>
                </Box>
              }
              title="Training invoice List"
            />

            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SL</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Invoice no</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Due Amount</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {!loading ? (
                  paginatedInvoice.length ? (
                    <TableBody>
                      {paginatedInvoice.map(
                        (
                          trainingInvoiceSingle: ITrainingInvoiceList,
                          index: number
                        ) => {
                          return (
                            <TrainingInvoiceTableRow
                              key={
                                trainingInvoiceSingle.training_payment_invoice_id
                              }
                              index={index}
                              trainingInvoiceList={trainingInvoiceSingle}
                              allInvoices={trainingInvoiceList}
                              setAllInvoices={setTrainingInvoiceList}
                            />
                          );
                        }
                      )}
                    </TableBody>
                  ) : (
                    <TableNotFound />
                  )
                ) : (
                  <TableLoader />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={trainingInvoiceList.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      {/* Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ mb: 2 }}> Create a training invoice</Typography>
            <Divider />
          </Box>
          <CreateTrainingInvoice
            handleClose={handleClose}
            setRefresh={setRefresh}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TrainingInvoice;
