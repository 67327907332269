import { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  useTheme,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { debounceTime, url } from "src/Helpers/utils/Constant";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { getStatusLabel } from "src/Utils/Combined";
import { IOwnershipChangeApplication } from "src/Types/module/applications/applications";
import { debounce } from "lodash";
const OwnerShipChangeApplication = () => {
  const [filterItem, serFilterItem] = useState<any>({
    applicant_name: "",
    status: "pending",
  });
  const [idCard, setIdCard] = useState<IOwnershipChangeApplication[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/member/owner-change-application?ownership_change_application_status=${filterItem.status}&user_member_company_name=${filterItem.applicant_name}`
        );

        if (data.success) {
          setIdCard(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [filterItem.status, filterItem.applicant_name]);

  const handelChangeInput = debounce((value) => {
    serFilterItem({
      ...filterItem,
      applicant_name: value,
    });
  }, debounceTime);

  const statusOptions = [
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "approved",
      name: "Approved",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Card>
        <CardHeader
          action={
            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                // style={{ height: "10px" }}0
                id="outlined-basic"
                label="Search by agency name "
                variant="outlined"
                size="small"
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) =>
                    handelChangeInput(e.target.value)

                  // setSearchCompanyVal(e.target.value)
                }
              />
              <FormControl sx={{ width: "150px" }} variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  size="small"
                  value={filterItem.status}
                  onChange={(e) =>
                    serFilterItem({
                      ...filterItem,
                      status: e.target.value,
                    })
                  }
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title={
            <Typography sx={{ fontWeight: 700, lineHeight: 1.34 }}>
              Ownership change application
            </Typography>
          }
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Agency Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {idCard?.length ? (
                    <>
                      {idCard.map((item) => {
                        return (
                          <TableRow key={item.ownership_change_application_id}>
                            <TableCell>
                              {item.ownership_change_application_id}
                            </TableCell>

                            <TableCell
                              sx={{ color: "blue", fontWeight: "bold" }}
                            >
                              <Link to={`/member-list/${item.user_member_id}`}>
                                {" "}
                                {item.user_member_company_name}
                              </Link>
                            </TableCell>

                            <TableCell>
                              {getStatusLabel(
                                item.ownership_change_application_status
                              )}
                            </TableCell>
                            <TableCell>
                              {moment(
                                item.ownership_change_application_created_at
                              ).format("MMM Do YY")}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/update-applications/ownership-change-application/${item.ownership_change_application_id}`}
                              >
                                <Tooltip title="view" arrow>
                                  <IconButton
                                    sx={{
                                      "&:hover": {
                                        background:
                                          theme.colors.primary.lighter,
                                      },
                                      color: theme.palette.primary.main,
                                    }}
                                    color="inherit"
                                    size="small"
                                  >
                                    <VisibilityIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default OwnerShipChangeApplication;
