import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Box,
  Divider,
  Container,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { IpaymentableItem } from "src/Types/module/invoice/invoiceTypes";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

const DueReport = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const [selectFilter, setSelectFilter] = useState({
    zone: "Dhaka",
    subscription: "",
  });
  const [filterItem, setFilterItem] = useState({
    zone: [],
    subscription: "",
  });
  const [statusItem, setStatusItem] = useState<{
    status: string;
    item: number;
  }>({
    status: "paid",
    item: 0,
  });
  const [invoiceItem, setInvoiceItem] = useState([]);
  // get all zone
  useEffect(() => {
    (async function () {
      const { data } = await axios.get(`${url}/api/atab/common/get/all/zone`);
      if (data.success) {
        setFilterItem({ ...filterItem, zone: data.data });
      }
    })();
  }, []);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid>
        <Grid item xs={12} mb={2}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Search by agency name"
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <DatePicker
                  label="Start Date"
                  value={date.startDate}
                  onChange={(e: any) => setDate({ ...date, startDate: e })}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  variant="h6"
                  component="h6"
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  label="End Date"
                  value={date.endDate}
                  onChange={(e: any) => setDate({ ...date, endDate: e })}
                />
              </Grid>
              <Grid item>
                <FormControl sx={{ width: "120px" }}>
                  <InputLabel id="demo-simple-select-label">Zone</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectFilter.zone}
                    label="Zone"
                    onChange={(e: any) =>
                      setSelectFilter({
                        ...selectFilter,
                        zone: e.target.value,
                      })
                    }
                  >
                    {filterItem.zone.map((sZone) => {
                      return (
                        <MenuItem value={sZone} key={sZone.zone_id}>
                          {sZone.zone_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={"Due Report"}
              // action={
              //   <Box
              //     sx={{
              //       display: 'flex',
              //       justifyContent: 'center',
              //       alignItems: 'center',
              //       gap: 1,
              //     }}
              //   >
              //     <ExcelGenerator getDownloadData={getDownloadData} />
              //     <PdfGenerator getDownloadData={getDownloadData} />
              //   </Box>
              // }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SL No</TableCell>
                    <TableCell>Membership No</TableCell>
                    <TableCell>Representative Name</TableCell>
                    <TableCell>Due Period</TableCell>
                    <TableCell>Contact No</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableNotFound />
                  {/* {isLoading ? (
                    <TableLoader />
                  ) : members.total ? (
                    <>
                      {members.members.map((allMembers: Imembers) => {
                        return (
                          <InvoiceMemberTableRow
                            key={allMembers.user_member_id}
                            allMembers={allMembers}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Box p={2}>
              <TablePagination
                component='div'
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DueReport;
