import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { url } from 'src/Helpers/utils/Constant';
import axios from 'axios';
import { IAllJoinVisitor } from 'src/Utils/type';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';


const AllJoinVisitor = ({ eventId }: any) => {
  const theme = useTheme();
  const [allVisitorJoin, setAllVisitorJoin] = useState<IAllJoinVisitor[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    try {
      (async () => {
        const { data } = await axios.get(
          `${url}/api/fair/event/get/all/registered-visitor/2/all`
        );

        if (data.success) {
          setAllVisitorJoin(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [eventId]);

  return (
    <>
      <TableContainer sx={{ marginBottom: 10 }}>
        <Table>
          <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
            <TableRow>
              <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
                Visitor ID
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Name
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Email
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Organization
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Type
              </TableCell>
              <TableCell
                sx={{ color: theme.colors.alpha.white[100] }}
                align='center'
              >
                Visitor Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? <TableLoader /> : (
              <>
                {allVisitorJoin.length ? (
                  <>
                    {allVisitorJoin.map((details) => (
                      <TableRow
                        hover
                        key={details.fair_event_joined_visitor_id}
                      >
                        <TableCell align='center'>
                          {details.fair_event_joined_visitor_id}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_first_name}{' '}
                          {details.visitor_last_name}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_email}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_organization}
                        </TableCell>
                        <TableCell align='center'>
                          {details.visitor_type}
                        </TableCell>
                        <TableCell align='center'>
                          {details.fair_event_joined_visitor_status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : <TableNotFound />}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllJoinVisitor;
