import React from "react";
import { ISingleBusinessLicense } from "src/Types/module/UpdateRequest/RequestBusinessLicense";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";
import { imgUrl } from "src/Helpers/utils/Constant";
import { getStatusLabel } from "src/Utils/Combined";
interface IProps {
  requestBusinessLicenseInfo: ISingleBusinessLicense;
}
const useStyles = makeStyles({
  TableCell: {
    fontWeight: "bold !important",
  },
});
const BusinessLicenseAppReject = ({ requestBusinessLicenseInfo }: IProps) => {
  const classes = useStyles();
  const [query] = useSearchParams();
  const status = query.get("status");
  return (
    <Container>
      <Card sx={{ mb: 5 }}>
        <CardHeader
          title={
            status === "approved"
              ? "Approved information"
              : "Rejected information"
          }
        />
        <TableContainer sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.TableCell}>Status</TableCell>
                <TableCell align="right">
                  {getStatusLabel(requestBusinessLicenseInfo.status)}
                </TableCell>
              </TableRow>

              {requestBusinessLicenseInfo.reject_reason && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Reject reason
                  </TableCell>
                  <TableCell align="right">
                    {requestBusinessLicenseInfo.status}
                  </TableCell>
                </TableRow>
              )}
              {requestBusinessLicenseInfo.hajj_license ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Hajj license
                  </TableCell>
                  <TableCell align="right">
                    {requestBusinessLicenseInfo.hajj_license ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              {requestBusinessLicenseInfo.hajj_license_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Hajj license file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestBusinessLicenseInfo.hajj_license_file}`}
                    >
                      {requestBusinessLicenseInfo.hajj_license_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}
              {requestBusinessLicenseInfo.umrah_license ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Umrah license
                  </TableCell>
                  <TableCell align="right">
                    {requestBusinessLicenseInfo.umrah_license ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}

              {requestBusinessLicenseInfo.umrah_license_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Umrah license file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestBusinessLicenseInfo.umrah_license_file}`}
                    >
                      {requestBusinessLicenseInfo.umrah_license_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}
              {requestBusinessLicenseInfo?.recruting_license && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Recruting license
                  </TableCell>
                  <TableCell align="right">
                    {requestBusinessLicenseInfo?.recruting_license
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
              )}
              {requestBusinessLicenseInfo.recruting_license_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Recruting license file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestBusinessLicenseInfo.recruting_license_file}`}
                    >
                      {requestBusinessLicenseInfo.recruting_license_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestBusinessLicenseInfo.inbound && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Tourism activities inbound
                  </TableCell>
                  <TableCell align="right">
                    {requestBusinessLicenseInfo.inbound ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              )}

              {requestBusinessLicenseInfo.outbound ? (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Tourism activities outbound
                  </TableCell>
                  <TableCell align="right">
                    {requestBusinessLicenseInfo.outbound ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default BusinessLicenseAppReject;
