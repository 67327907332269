import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { IAgencyUpdateInfoApplication } from "src/Types/module/applications/applications";
import { getStatusLabel } from "src/Utils/Combined";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateAgencyInfoApplication from "./UpdateAgencyInfoApplication";
import { Link } from "react-router-dom";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

const SingleAgencyInfoUpdateApplication = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [application, setApplication] = useState<IAgencyUpdateInfoApplication>(
    {} as IAgencyUpdateInfoApplication
  );
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/atab/agency-member-info/${id}`
        );

        if (data.success) {
          setApplication(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [id]);
  return (
    <>
      {" "}
      <Helmet>
        <title>Atab - Agency information update application</title>
      </Helmet>
      <Container maxWidth="xl">
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {" "}
            <Card>
              <CardHeader
                title="Agency information update application"
                action={
                  application.status !== "approved" ? (
                    <Button
                      onClick={() => setShowModal(true)}
                      variant="outlined"
                    >
                      Update
                    </Button>
                  ) : (
                    ""
                  )
                }
              />
              <Divider />
              <CardContent>
                {application ? (
                  <Grid
                    container
                    pt={2}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Agency Name:
                      </Typography>

                      <Link to={`/member-list/${application.agency_member_id}`}>
                        {application.user_member_company_name}
                      </Link>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Agency email:
                      </Typography>

                      {/* <Link to={`/member-list/${application.agency_member_id}`}> */}
                      {application.agency_email}
                      {/* </Link> */}
                    </Grid>

                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Status:
                      </Typography>
                      {getStatusLabel(application.status)}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Application Date:
                      </Typography>
                      {moment(application.agency_info_created_at).format(
                        "MMM Do YY"
                      )}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Representative name:
                      </Typography>
                      {application.user_member_representative_name}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Representative designation:
                      </Typography>
                      {application.user_member_representative_designation}
                    </Grid>

                    {/* <Grid item xs={2} sm={4} md={4}>
                      <CommonTypoGraphy
                        name="View company logo"
                        onClick={() => {
                          SetImageViewOpen(true);
                          setImageFile(application.member_company_logo);
                        }}
                      />
                    </Grid> */}
                    <Grid item xs={2} sm={4} md={4}>
                      <CommonTypoGraphy
                        name="View representative photo"
                        onClick={() => {
                          SetImageViewOpen(true);
                          setImageFile(
                            application.user_member_representative_photo
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Mobile number:
                      </Typography>
                      {application.phone}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Telephone number:
                      </Typography>
                      {application.cellphone}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Website:
                      </Typography>
                      <a
                        href={application.member_company_website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        {application.member_company_website}
                      </a>
                    </Grid>

                    {application.note && (
                      <Grid item xs={2} sm={4} md={4}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          display="inline"
                          sx={{ pr: 1 }}
                          gutterBottom
                        >
                          Note:
                        </Typography>
                        {application.note}
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    No Data found
                  </Typography>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </Container>
      <ModalsWrapper
        setShowModal={setShowModal}
        showModal={showModal}
        modalData={{
          title: "Update agency information update application",
        }}
      >
        <UpdateAgencyInfoApplication
          setApplication={setApplication}
          application={application}
          handleClose={() => setShowModal(false)}
        />
      </ModalsWrapper>
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default SingleAgencyInfoUpdateApplication;
