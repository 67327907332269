import { Visibility } from "@mui/icons-material";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { getStatusLabel } from "src/Utils/Combined";
import { IMoneyReceipt } from "src/Types/module/common/commonTypes";
import { IInvoice } from "./types/invoiceType";
import { useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { url } from "src/Helpers/utils/Constant";

type IProps = {
  sInvoice: IInvoice;
  index: number;
  allInvoices: IInvoice[];
  setAllInvoices: any;
};

const InvoiceTableRow = ({
  sInvoice: invoiceData,
  index,
  allInvoices,
  setAllInvoices,
}: IProps) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/atab/admin/invoice/${id}`;
        const data = await HttpClient.delete(uri);

        if (data) {
          const filterData = allInvoices.filter(
            (item) => item.atab_payment_invoice_id !== id
          );

          setAllInvoices(filterData);

          Toaster().fire({
            icon: "success",
            title: "Deleted successfully!",
          });

          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };
  return (
    <>
      <TableRow hover>
        <TableCell>{index + 1}</TableCell>

        <TableCell>
          {moment(invoiceData.atab_payment_invoice_issue_date).format(
            "DD-MMM-YYYY"
          )}
        </TableCell>
        <TableCell>{invoiceData.atab_payment_invoice_no}</TableCell>
        <TableCell>{invoiceData.user_member_company_name}</TableCell>
        <TableCell style={{ color: "green" }}>
          {invoiceData.atab_payment_invoice_total_amount}
        </TableCell>
        <TableCell style={{ color: "red" }}>
          {invoiceData.atab_payment_invoice_due}
        </TableCell>
        <TableCell>
          <Tooltip title="View" arrow>
            <Link to={`${invoiceData.atab_payment_invoice_id}`}>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <Visibility fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete invoice" arrow>
            <IconButton
              onClick={() => setOpen(true)}
              sx={{
                "&:hover": { background: theme.colors.error.lighter },
                color: theme.palette.error.main,
              }}
              color="inherit"
              size="small"
            >
              <DeleteTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {/* Delele Confirmation modal  */}
          <DeleteConfirmation
            open={open}
            handleClose={() => setOpen(false)}
            text="Want to delete this invoice?"
            handleDeleteFun={() =>
              handleDelete(invoiceData?.atab_payment_invoice_id)
            }
            loading={isLoading}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default InvoiceTableRow;
