import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import { Divider } from "@mui/material";
import { ISingleBusinessLicense } from "src/Types/module/UpdateRequest/RequestBusinessLicense";

interface IProps {
  preBusinessLicenseInfo: ISingleBusinessLicense;
}
const CurrentBusinessLicenseInfo = ({ preBusinessLicenseInfo }: IProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={21}>
            Current Business License information
          </Typography>
        }
      />

      <TableContainer sx={{ mb: 5 }}>
        <Table aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Hajj license
              </TableCell>
              <TableCell align="right">
                {" "}
                {preBusinessLicenseInfo?.hajj_license ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Hajj license file
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preBusinessLicenseInfo.hajj_license_file}`}
                >
                  {preBusinessLicenseInfo.hajj_license_file}
                </a>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Umrah license
              </TableCell>
              <TableCell align="right">
                {preBusinessLicenseInfo?.umrah_license ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Umrah license file
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preBusinessLicenseInfo.umrah_license_file}`}
                >
                  {preBusinessLicenseInfo.umrah_license_file}
                </a>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Recruiting license
              </TableCell>
              <TableCell align="right">
                {preBusinessLicenseInfo?.recruting_license ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Recruiting license file
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preBusinessLicenseInfo.recruting_license_file}`}
                >
                  {preBusinessLicenseInfo.recruting_license_file}
                </a>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Tourism activities inbound
              </TableCell>
              <TableCell align="right">
                {preBusinessLicenseInfo?.inbound ? "Yes" : "No"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Tourism activities outbound
              </TableCell>
              <TableCell align="right">
                {preBusinessLicenseInfo?.outbound ? "Yes" : "No"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CurrentBusinessLicenseInfo;
