import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  CardContent,
  Divider,
  MenuItem,
  TextareaAutosize,
} from '@mui/material';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import Toaster from 'src/Toaster/Toaster';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { ISingleExhibitorInvoice } from 'src/Types/module/invoice/singleExhibitorInvoice';

interface IProps {
  details: ISingleExhibitorInvoice;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
}

const ExhibitorInvoiceUpdateForm = ({
  details,
  handleClose,
  setReload,
}: IProps) => {
  const { register, handleSubmit, reset } = useForm();
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const Toast = Toaster();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(new Date(event.target.value));
  };
  useEffect(() => {
    if (details.fair_invoice_status) {
      setStatus(details.fair_invoice_status);
    }
  }, [details.fair_invoice_status]);

  const onSubmit = async (inputs: ChangeEvent<HTMLFormElement>) => {
    if (user.user_admin_id) {
      try {
        setLoading(true);
        const bodyFormData = new FormData();

        for (const key in inputs) {
          bodyFormData.append(key, inputs[key]);
        }
        bodyFormData.append(
          'fair_invoice_transaction_id',
          details?.fair_invoice_transaction_id?.toString()
        );
        bodyFormData.append(
          'fair_invoice_transaction_date',
          selectedDate.toISOString().slice(0, 10)
        );
        bodyFormData.append(
          'fair_invoice_updated_by',
          user.user_admin_id?.toString()
        );
        bodyFormData.append(
          'fair_invoice_id',
          details.fair_invoice_id?.toString()
        );
        bodyFormData.append('fair_invoice_file', uploadFile);

        const { data } = await axios.put(
          `${url}/api/atab/fair-invoice/update/for-admin`,
          bodyFormData
        );
        if (data.success) {
          Toast.fire({
            icon: 'success',
            title: `Update Successfully`,
          });
          setLoading(false);
          setReload((prev: Boolean) => !prev);
          reset();
          handleClose();
        }
      } catch (err: any) {
        setLoading(false);
        Toast.fire({
          icon: 'error',
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };

  const currencies = [
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'paid',
      label: 'Paid',
    },
    {
      value: 'cancelled',
      label: 'Cancelled',
    },
    {
      value: 'reported',
      label: 'Reported',
    },
  ];

  const paymentMethod = [
    {
      value: 'Cash',
      label: 'Cash',
    },
    {
      value: 'SSL Commerz',
      label: 'SSL Commerz',
    },
    {
      value: 'Bank',
      label: 'Bank',
    },
    {
      value: 'Pay Order',
      label: 'Pay Order',
    },
  ];

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              mt: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  select
                  id='outlined-required'
                  label='Select Payment Method'
                  defaultValue={
                    details.fair_invoice_payment_method &&
                    details?.fair_invoice_payment_method.toLowerCase()
                  }
                  {...register('fair_invoice_payment_method')}
                >
                  {paymentMethod.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value.toLowerCase()}
                    >
                      {option.label.toLowerCase()}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  select
                  fullWidth
                  id='outlined-required'
                  label='Select status'
                  defaultValue={
                    details.fair_invoice_status &&
                    details?.fair_invoice_status.toLowerCase()
                  }
                  {...register('fair_invoice_status')}
                >
                  {currencies.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value.toLowerCase()}
                    >
                      {option.label.toLowerCase()}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  label='Invoice Date'
                  fullWidth
                  type='date'
                  value={selectedDate.toISOString().slice(0, 10)}
                  onChange={handleDateChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  id='outlined-required'
                  type='file'
                  label='Payment Invoice File'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e: any) => setUploadFile(e.target.files[0])}
                />
              </Grid>
              <Box sx={{ margin: '20px 0px 5px 18px', width: '100%' }}>
                <TextareaAutosize
                  placeholder='Type in invoice remark'
                  minRows={5}
                  maxRows={10}
                  defaultValue={
                    details.fair_invoice_remark && details?.fair_invoice_remark
                  }
                  id='outlined-required'
                  {...register('fair_invoice_remark')}
                  style={{ width: '100%', padding: '15px' }}
                />
              </Box>
            </Grid>
            {!isLoading ? (
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update exhibitor invoice
              </Button>
            ) : (
              <LoadingButton
                loading
                loadingIndicator='Updating...'
                fullWidth
                variant='outlined'
                disabled
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                <span>Updating...</span>
              </LoadingButton>
            )}
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default ExhibitorInvoiceUpdateForm;
