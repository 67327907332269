import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Container,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { HttpClient } from "src/Helpers/http/http";

const CreateMember = ({
  setAllMembers,
  handleClose,
}: {
  setAllMembers: any;
  handleClose: any;
}) => {
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [zone, setZone] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (inputs: any) => {
    const { user_member_confirm_password, ...body } = inputs;
    inputs.user_member_zone_id = zone;
    if (
      !inputs.user_member_phone.startsWith("01") ||
      inputs.user_member_phone.length !== 11
    ) {
      Toast.fire({
        icon: "error",
        title: `Invalid mobile number`,
      });
      return;
    }

    const formData = new FormData();
    Object.keys(inputs).forEach((item) => {
      if (inputs[item]) {
        if (item === "user_member_representative_photo") {
          formData.append(item, inputs[item][0]);
        } else {
          formData.append(item, inputs[item]);
        }
      }
    });

    console.table(Object.fromEntries(formData));

    try {
      setLoading(true);
      const data: any = await HttpClient.post(
        `${url}/api/atab/user-member/member/register`,
        formData
      );

      if (data.success) {
        Toast.fire({
          icon: "success",
          title: `Successfully add member`,
        });

        setAllMembers((prv) => [
          {
            company_tin_number: null,
            is_applied: 1,
            member_company_address: 13542,
            member_company_type: "Travel Agency",
            user_member_account_status: "active",
            user_member_company_name: inputs?.user_member_company_name,
            user_member_email: inputs.user_member_email,
            user_member_id: data?.user_member_id,
            user_member_id_card_number: "",
            user_member_membership_payment_expiry_date: "",
            user_member_phone: inputs.user_member_phone,
            user_member_representative_designation:
              inputs?.user_member_representative_designation,
            user_member_representative_name: "",
            user_member_representative_photo:
              data?.user_member_representative_photo,
            user_member_zone_id: 1,
            user_member_zone_name:
              zone === 1 ? "Dhaka" : zone === 2 ? "Chittagong" : "Sylhet",
          },
          ...prv,
        ]),
          reset();
        setZone(1);
        setLoading(false);
        handleClose();
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>ATAB - Add Member</title>
      </Helmet>

      <Container>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Enter company name"
                placeholder="Enter company name"
                {...register("user_member_company_name")}
                type="text"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Enter your mobile"
                placeholder="Enter your mobile number"
                {...register("user_member_phone")}
                type="text"
                autoFocus
              />
            </Grid>{" "}
            <Grid item xs={12} md={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Enter your email"
                placeholder="Enter your email"
                {...register("user_member_email")}
                type="email"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={4} mt={1.6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select zone
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={zone}
                  label="Select zone"
                  onChange={(e: any) => setZone(e.target.value)}
                >
                  <MenuItem value={1}>Dhaka</MenuItem>
                  <MenuItem value={2}>Chittagong</MenuItem>
                  <MenuItem value={3}>Sylhet</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="representative name"
                label="Owner name"
                placeholder="Owner name"
                {...register("user_member_company_owner_name")}
                type="text"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="representative designation"
                label="Representative designation"
                placeholder="Representative designation"
                {...register("user_member_representative_designation")}
                type="text"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Member id"
                margin="normal"
                placeholder="Enter member id"
                fullWidth
                {...register("user_member_uid")}
                type="text"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Upload photo"
                margin="normal"
                fullWidth
                {...register("user_member_representative_photo")}
                type="file"
                focused
              />
            </Grid>{" "}
            <Grid item xs={12} md={4}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Enter your password"
                placeholder="Enter your password"
                {...register("user_member_password")}
                autoComplete="password"
                type="text"
                autoFocus
              />
            </Grid>
          </Grid>
          {loading ? (
            <Button
              fullWidth
              disabled
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading ...
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Add Member
            </Button>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default CreateMember;
