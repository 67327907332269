import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useLocation } from "react-router-dom";
import { imgUrl } from "src/Helpers/utils/Constant";
import { ImemberTableRow } from "src/Types/module/member/memberTypes";
import { getStatusLabel } from "src/Utils/Combined";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
// import { createMemberId } from "src/Utils/Lib"

const CommonMemberTableRow = ({ allMembers }: ImemberTableRow) => {
  const { user } = useAuthContext();
  const location = useLocation();
  const theme = useTheme();

  return (
    <>
      <TableRow hover key={allMembers.user_member_id}>
        <TableCell>
          <Typography
            variant="body1"
            color="text.primary"
            gutterBottom
            noWrap
            fontSize={13}
          >
            {allMembers.user_member_id_card_number}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
              }}
              src={
                allMembers.company_owner_photo
                  ? `${imgUrl}/${allMembers.company_owner_photo}`
                  : "/static/images/avatars/avatar2.png"
              }
              alt=""
            />
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "12rem",
              }}
            >
              <Typography
                variant="body1"
                color="text.primary"
                gutterBottom
                noWrap
                fontSize={13}
                title={allMembers.user_member_company_name}
              >
                {allMembers?.user_member_company_name
                  ?.toLowerCase()
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </Typography>
            </div>
          </Box>
        </TableCell>
        <TableCell>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "12rem",
            }}
          >
            <Typography
              title={allMembers.user_member_representative_name}
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
              fontSize={13}
            >
              {allMembers?.user_member_representative_name
                ?.toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Typography>
          </div>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            color="text.primary"
            gutterBottom
            noWrap
            fontSize={13}
          >
            {allMembers.user_member_phone}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="body1"
            color="text.primary"
            gutterBottom
            noWrap
            fontSize={13}
          >
            {allMembers.user_member_zone_name}
          </Typography>
        </TableCell>
        {user.user_admin_role !== "Application Inspection" && (
          <TableCell>
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
              fontSize={13}
            >
              {allMembers.user_admin_name}
            </Typography>
          </TableCell>
        )}

        <TableCell>
          {getStatusLabel(
            allMembers?.user_member_account_status === "Application Inspection"
              ? "Office Inspection"
              : allMembers?.user_member_account_status
          )}
        </TableCell>
        <TableCell>
          <Link to={`/member-list/${allMembers.user_member_id.toString()}`}>
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CommonMemberTableRow;
