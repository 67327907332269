import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  Autocomplete,
  Button,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { IDistrictList, IDivisionList } from "src/basic-rest/types";
import ListSubheader from "@mui/material/ListSubheader";
import { IGetID } from "./Address";
import { AddressLoading, AddressNotFound } from "./utils";
import { useFetchDistrictData } from "src/basic-rest/useFetchAddressData";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateDistrict from "./create-address/CreateDistrict";
import { Box } from "@mui/system";
type IProps = {
  checked: IGetID;
  setChecked: Dispatch<SetStateAction<IGetID>>;
};

function DistrictList({ checked, setChecked }: IProps) {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const {
    data: districtData,
    fetchDistrictData,
    loading,
    setData,
  } = useFetchDistrictData();

  React.useEffect(() => {
    if (checked.division) {
      fetchDistrictData({
        division_id: checked.division.division_id,
      });
    }
  }, [checked.division, reload]);

  let content: ReactNode;
  if (loading) {
    content = <AddressLoading />;
  }
  if (!loading && districtData.length === 0) {
    content = <AddressNotFound />;
  }
  if (!loading && districtData.length > 0) {
    content = (
      <Autocomplete
        getOptionLabel={(option: IDistrictList) => option.district_name}
        id="combo-box-demo"
        options={districtData}
        renderOption={(params: any, option: IDistrictList) => (
          <Box component="li" {...params}>
            {option.district_name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Select district" variant="outlined" />
        )}
        onChange={(_event, value: IDistrictList) => {
          setChecked({ ...checked, district: value, thana: null, area: null });
        }}
      />
    );
  }

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          // position: "relative",
          overflow: "auto",
          maxHeight: 300,
          "& ul": { padding: 0 },
        }}
      >
        <ListSubheader
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            top: "-12px !important",
            padding: "10px",
          }}
        >
          <Typography variant="h4">District list</Typography>
          <Button size="small" onClick={() => setOpen(true)}>
            Create District
          </Button>
        </ListSubheader>
        {content}
      </List>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: "Create district",
        }}
      >
        <CreateDistrict
          division_id={checked.division.division_id}
          setDistrict={setData}
        />
      </ModalsWrapper>
    </>
  );
}

export default DistrictList;
