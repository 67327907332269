import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { ISingleAddressChangeApplication } from "src/Types/module/applications/applications";
import { getStatusLabel } from "src/Utils/Combined";
import UpdateModal from "./UpdateModal";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { fetcherGet } from "src/Utils/fetcher";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

const SingleAddressApplication = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [application, setApplication] =
    useState<ISingleAddressChangeApplication>(
      {} as ISingleAddressChangeApplication
    );
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  // const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        // const {data} = await axios.get(
        //   `${url}/api/member/address-change-application/${id}`
        // );
        const data = await fetcherGet(
          `/member/address-change-application/${id}`
        );
        if (data.success) {
          setApplication(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err: any) {
        setLoading(false);
        // alert("No application found");
        // navigate("/update-applications/address-change-application");
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Atab - Address change application</title>
      </Helmet>
      <Container>
        {loading ? (
          <GlobalLoader />
        ) : (
          <Card>
            <CardHeader
              title="Address change application"
              action={
                <Button
                  onClick={() => setShowModal(true)}
                  variant="outlined"
                  size="small"
                >
                  Update
                </Button>
              }
            />
            <Divider />
            <CardContent>
              {application ? (
                <Grid
                  container
                  pt={2}
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Company name:
                    </Typography>
                    {application.user_member_company_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Application Date:
                    </Typography>
                    {moment(
                      application.address_change_application_created_at
                    ).format("MMM Do YY")}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                    {getStatusLabel(
                      application.address_change_application_status
                    )}
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Email:
                    </Typography>
                    {application.address_change_application_email}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Phone no:
                    </Typography>
                    {application.address_change_application_phone}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Cellphone no:
                    </Typography>
                    {application.address_change_application_cellphone}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Address:
                    </Typography>
                    {application.address_change_application_addressline}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Division:
                    </Typography>
                    {application.division_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      District:
                    </Typography>
                    {application.district_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Thana:
                    </Typography>
                    {application.thana_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Area:
                    </Typography>
                    {application.area_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Police station:
                    </Typography>
                    {application.address_change_application_police_station}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Postal code:
                    </Typography>
                    {application.address_change_application_postal_code}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View Civil Aviation"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(
                          application.address_change_application_civil_aviation
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View Trade License"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(
                          application.address_change_application_trade_license
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View Forwarding latter"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(
                          application.address_change_application_form
                        );
                      }}
                    />
                  </Grid>
                  {application.address_change_application_reject_reason ? (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Note:
                      </Typography>
                      {application.address_change_application_reject_reason}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  No Data found
                </Typography>
              )}
            </CardContent>
          </Card>
        )}
      </Container>
      {application && (
        <UpdateModal
          setShowModal={setShowModal}
          showModal={showModal}
          application={application}
          id={id}
          setApplication={setApplication}
        />
      )}{" "}
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default SingleAddressApplication;
