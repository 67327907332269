import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Autocomplete,
  Chip,
} from "@mui/material";
import { ISingleTrainee } from "./types/singleTraineeTypes";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import Toaster from "src/Toaster/Toaster";
import axios from "axios";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";

const CreateTraineeCertificate = ({
  traineeInfo,
  handelClose,
  Reload,
}: {
  traineeInfo: ISingleTrainee;
  handelClose: any;
  Reload: any;
}) => {
  const { user } = useAuthContext();
  const { handleSubmit } = useForm();
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs());
  const [loading, setLoading] = useState(false);
  // : SubmitHandler<Inputs>
  const [fromValue, setFormValue] = useState({
    course_id: 0,
    batch_no: 0,
    training_batch_id: 0,
  });
  const onSubmit = async (inputValue) => {
    setLoading(true);
    inputValue.trainee_id = traineeInfo?.user_training_trainee_id;
    inputValue.c_issue_date = dayjs(dateValue).format("YYYY-MM-DD");

    const submitData = { ...inputValue, ...fromValue };

    try {
      const { data } = await axios.post(
        `${url}/api/training/trainee/trainee-certificate?user_admin_id=${user?.user_admin_id}`,
        submitData
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        Reload();
        handelClose();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };

  const courseOption = traineeInfo?.applications?.map((course) => {
    return {
      id: course?.training_course_application_course_id,
      label: course?.atab_training_course_name,
    };
  });

  const batchOption = traineeInfo?.batchList
    ?.filter(
      (batch) =>
        batch?.training_batch_status === "ended" &&
        fromValue?.course_id === batch?.atab_training_course_id
    )
    .map((batch) => ({
      id: batch?.batch_no,
      label: batch?.training_batch_name,
      batchID: batch?.training_batch_id,
    }));

  return (
    <div style={{ width: "380px" }}>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                fullWidth
                id="tags-filled"
                options={courseOption}
                freeSolo
                onChange={(_event, newValue: { id: number; label: string }) => {
                  setFormValue({ ...fromValue, course_id: newValue?.id });
                }}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Select course"
                    placeholder="More.."
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                fullWidth
                id="tags-filled"
                options={batchOption}
                freeSolo
                onChange={(
                  _event,
                  newValue: { id: string; label: string; batchID: number }
                ) => {
                  {
                    setFormValue({
                      ...fromValue,
                      batch_no: Number(newValue?.id),
                      training_batch_id: newValue?.batchID,
                    });
                  }
                }}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Select batch"
                    placeholder="More.."
                  />
                )}
              />
            </Grid>{" "}
            <Grid xs={12} md={12} item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Issue date"
                  format="MM/DD/YYYY"
                  value={dateValue}
                  onChange={(e: any) => setDateValue(e)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          {/* user_training_trainee_photo */}
          <Box mt={5} textAlign="center">
            {loading ? (
              <Button
                disabled
                variant="contained"
                disableElevation
                className="add-remove-button"
              >
                loading..
              </Button>
            ) : (
              <Button type="submit" variant="contained">
                Create
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default CreateTraineeCertificate;
