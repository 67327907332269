import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import { Divider } from "@mui/material";
import { ISingleOtherBusinessLicense } from "src/Types/module/UpdateRequest/RequestOtherBusinessLicense";
// import moment from "moment";

interface IProps {
  preOtherBusinessLicenseInfo: ISingleOtherBusinessLicense;
}
const CurrentOtherBusinessLicenseInfo = ({
  preOtherBusinessLicenseInfo,
}: IProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={21}>
            Current information of other business license
          </Typography>
        }
      />

      <TableContainer sx={{ mb: 5 }}>
        <Table aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Membership of BAIRA
              </TableCell>
              <TableCell align="right">
                {" "}
                {preOtherBusinessLicenseInfo?.baira_membership ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Certificate of BAIRA
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preOtherBusinessLicenseInfo.baira_membership_file}`}
                >
                  {preOtherBusinessLicenseInfo.baira_membership_file}
                </a>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Membership of HAAB
              </TableCell>
              <TableCell align="right">
                {preOtherBusinessLicenseInfo?.haab_membership ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Certificate of HAAB
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preOtherBusinessLicenseInfo.haab_membership_file}`}
                >
                  {preOtherBusinessLicenseInfo.haab_membership_file}
                </a>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Membership of IATA
              </TableCell>
              <TableCell align="right">
                {preOtherBusinessLicenseInfo?.iata_membership ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Certificate of IATA
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preOtherBusinessLicenseInfo.iata_membership_file}`}
                >
                  {preOtherBusinessLicenseInfo.iata_membership_file}
                </a>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Membership of TDAB
              </TableCell>
              <TableCell align="right">
                {preOtherBusinessLicenseInfo?.tdab_membership ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Certificate of TDAB
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preOtherBusinessLicenseInfo.tdab_membership_file}`}
                >
                  {preOtherBusinessLicenseInfo.tdab_membership_file}
                </a>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Membership of TOAB
              </TableCell>
              <TableCell align="right">
                {preOtherBusinessLicenseInfo?.toab_membership ? "Yes" : "No"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" scope="row">
                Certificate of TOAB
              </TableCell>
              <TableCell align="right">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${imgUrl}/${preOtherBusinessLicenseInfo.toab_membership_file}`}
                >
                  {preOtherBusinessLicenseInfo.toab_membership_file}
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CurrentOtherBusinessLicenseInfo;
