import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { debounceTime, url } from "src/Helpers/utils/Constant";
import { IBatchList } from "src/Types/module/courses/trainee";
import { applyPagination, RefreshButton } from "src/Utils/CommonAllShowUtils";
import SingleBatchRow from "./SingleBatchRow";
// import { Helmet } from "react-helmet-async"
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import CreateBatch from "./CreateBatch";
import { debounce } from "lodash";

const statusOptions = [
  {
    id: "running",
    name: "Running",
  },
  {
    id: "ended",
    name: "Ended",
  },
  {
    id: "cancelled",
    name: "Cancelled",
  },
];

const BatchList = () => {
  const [allBatch, setAllBatch] = useState<IBatchList[]>([]);
  const [searchFilter, setSearchFilter] = useState<any>({
    status: "running",
    searchParams: "",
  });
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let isCancelled = false;
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/training/batch/get/all/or/status/${searchFilter.status}?searchParams=${searchFilter.searchParams}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllBatch(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [searchFilter.status, searchFilter.searchParams, refresh]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedBatch = applyPagination(allBatch, page, limit);
  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };
  const handelChangeInput = debounce((value) => {
    setSearchFilter({
      ...searchFilter,
      searchParams: value,
    });
  }, debounceTime);

  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <Box display="flex" alignItems="center" gap={2}>
                    <TextField
                      // style={{ height: "10px" }}0
                      id="outlined-basic"
                      label="Search "
                      variant="outlined"
                      size="small"
                      onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                          handelChangeInput(e.target.value)

                        // setSearchCompanyVal(e.target.value)
                      }
                    />
                    <FormControl sx={{ width: "150px" }} variant="outlined">
                      <InputLabel>Status</InputLabel>
                      <Select
                        size="small"
                        value={searchFilter.status}
                        onChange={(e) =>
                          setSearchFilter({
                            ...searchFilter,
                            status: e.target.value,
                          })
                        }
                        label="Status"
                        autoWidth
                      >
                        {statusOptions &&
                          statusOptions.map((statusOption) => (
                            <MenuItem
                              key={statusOption.id}
                              value={statusOption.id}
                            >
                              {statusOption.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <Button onClick={() => handleOpen()} variant="contained">
                      Create batch
                    </Button>
                    <RefreshButton action={setRefresh} />{" "}
                  </Box>
                }
                title="All Batch List"
              />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Batch No</TableCell>
                      <TableCell>Batch Name</TableCell>
                      <TableCell sx={{ width: "250px" }}>Course Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableLoader />
                    ) : allBatch.length ? (
                      <>
                        {paginatedBatch.map((batch: IBatchList) => (
                          <SingleBatchRow
                            key={batch.training_batch_id}
                            batch={batch}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={allBatch.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          maxWidth="md"
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <CreateBatch handleClose={handleClose} setAllBatch={setAllBatch} />

          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default BatchList;
