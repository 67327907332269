import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Grid, Box, TextField } from "@mui/material";
import {
  IRepresentativeFormInput,
  IResponse,
} from "src/Types/module/common/commonTypes";
import { IRepresentativeInfo } from "src/Types/module/member/CompanyInformation";
import Toaster from "src/Toaster/Toaster";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";

interface IProps {
  representativeInfo: IRepresentativeInfo;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const UpdateCompanyRepresentativeInfo = ({
  representativeInfo,
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm<IRepresentativeFormInput>();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    try {
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/representative/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setOpen(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              placeholder=" Representative name"
              label="Representative name"
              defaultValue={representativeInfo.user_member_representative_name}
              variant="outlined"
              type="text"
              fullWidth
              {...register("user_member_representative_name")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              label="Representative full address"
              placeholder="Representative full address"
              variant="outlined"
              type="text"
              defaultValue={
                representativeInfo.user_member_representative_full_address
              }
              fullWidth
              {...register("user_member_representative_full_address")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              label="Representative Phone number"
              placeholder="Representative Phone number"
              variant="outlined"
              defaultValue={representativeInfo.user_member_representative_phone}
              type="number"
              fullWidth
              {...register("user_member_representative_phone")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              label="Representative designation"
              placeholder="Representative designation"
              variant="outlined"
              type="text"
              defaultValue={
                representativeInfo.user_member_representative_designation
              }
              fullWidth
              {...register("user_member_representative_designation")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              label="Representative nid number"
              placeholder="Representative nid number"
              variant="outlined"
              type="number"
              defaultValue={
                representativeInfo.user_member_representative_nid_no
              }
              fullWidth
              {...register("user_member_representative_nid_no")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              label="Representative NID photo"
              variant="outlined"
              type="file"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              {...register("user_member_representative_nid_file")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Representative photo"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              {...register("user_member_representative_photo")}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button disabled variant="contained" disableElevation>
            loading..
          </Button>
        ) : (
          <Button type="submit" variant="contained" disableElevation>
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyRepresentativeInfo;
