import React, { useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Box,
  FormControl,
  InputLabel,
  Container,
  TextField,
  Divider,
} from "@mui/material";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";

import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { muiStyles } from "../../../../../Utils/Styles";
import { ITeacher } from "src/Types/module/teacher/teacherTypes";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
const useStyles = muiStyles;

interface IProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  teacher: ITeacher;
}

const UpdateTeacher = ({ setShowModal, showModal, teacher }: IProps) => {
  const { id } = useParams();
  const { register, handleSubmit, reset } = useForm();
  const Toast = Toaster();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [statusValue, setStatusValue] = useState<string>("active");
  const [updateTeacherPhoto, setUpdateTeacherPhoto] = useState<string>("");
  const { user } = useAuthContext();

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    inputs.training_teacher_status = statusValue;
    inputs.training_teacher_photo = updateTeacherPhoto
      ? updateTeacherPhoto
      : teacher.training_teacher_photo;

    try {
      const formData = new FormData();
      for (const key in inputs) {
        formData.append(key, inputs[key]);
      }

      try {
        const { data } = await axios.put(
          `${url}/api/training/teacher/update/${id}?user_admin_id=${user.user_admin_id}`,
          formData
        );

        if (data.success) {
          setShowModal(false);
          Toaster().fire({ icon: "success", title: "Updated Successfully!" });
          setLoading(false);
          reset();
        } else {
          Toaster().fire({ icon: "info", title: "Something went wrong" });
          setLoading(false);
        }
      } catch (err) {
        Toaster().fire({ icon: "info", title: "Something went wrong" });
        setLoading(false);
      }
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  const statusOptions = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "deactive",
      name: "Deactive",
    },
    {
      id: "block",
      name: "Block",
    },
  ];

  return (
    <ModalsWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      modalData={{ title: "Update Teacher" }}
    >
      <Divider />
      <Container sx={{ mt: 4 }} maxWidth="md">
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          sx={{ p: 2, mt: 1 }}
        >
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextField
                margin="normal"
                fullWidth
                id="name"
                label="Teacher name"
                defaultValue={teacher ? teacher.training_teacher_name : ""}
                {...register("training_teacher_name")}
                type="text"
                autoFocus
                variant="outlined"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Teacher Email"
                defaultValue={teacher ? teacher.training_teacher_email : ""}
                {...register("training_teacher_email")}
                type="email"
                autoFocus
                variant="outlined"
              />
            </Grid>
            <Grid xs={6} item>
              <TextField
                margin="normal"
                fullWidth
                id="phone"
                label="Teacher Phone"
                defaultValue={teacher ? teacher.training_teacher_phone : ""}
                {...register("training_teacher_phone")}
                type="text"
                autoFocus
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                id="file"
                label="Choose Teacher Photo"
                InputLabelProps={{ shrink: true }}
                onChange={(e: any) => setUpdateTeacherPhoto(e.target.files[0])}
                autoComplete="file"
                type="file"
                autoFocus
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                label="Teacher details"
                placeholder="Enter some details about this Teacher here.."
                defaultValue={teacher ? teacher.training_teacher_details : ""}
                multiline
                {...register("training_teacher_details")}
                rows={5}
                maxRows={10}
                fullWidth
              />
            </Grid>
            <Grid xs={12} item>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  defaultValue={
                    teacher ? teacher.training_teacher_status : "active"
                  }
                  onChange={(e) => setStatusValue(e.target.value)}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {loading ? (
            <Button
              disabled
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              lodaing...
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Update Teacher
            </Button>
          )}
        </Box>
      </Container>
    </ModalsWrapper>
  );
};

export default UpdateTeacher;
