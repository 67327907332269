import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ISingleCourseDetails } from "src/Types/module/courses/courseList";
import Button from "@mui/material/Button";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  course: ISingleCourseDetails;
}
function SingleCourseDetails({ course }: IProps) {
  const {
    atab_training_course_id,
    atab_training_course_name,
    atab_training_course_duration,
    atab_training_course_class_duration,
    atab_training_course_class_time,
    atab_training_course_class_days,
    atab_training_course_amount,
    atab_training_course_remark,
    atab_training_course_status,
  } = course;

  return (
    <div>
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              ID:
            </Typography>
            {atab_training_course_id}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Name:
            </Typography>
            {atab_training_course_name}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Course Status:
            </Typography>
            {getStatusLabel(atab_training_course_status)}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Course Duration:
            </Typography>
            {atab_training_course_duration}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Class Duration:
            </Typography>
            {atab_training_course_class_duration}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Class Time:
            </Typography>
            {atab_training_course_class_time}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Class Days:
            </Typography>
            {atab_training_course_class_days}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Course Amount:
            </Typography>
            {atab_training_course_amount}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography
              variant='body1'
              fontWeight='bold'
              color='text.primary'
              display='inline'
              sx={{ pr: 1 }}
              gutterBottom
            >
              Course Remark:
            </Typography>
            {atab_training_course_remark}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default SingleCourseDetails;
