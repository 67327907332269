import { Navigate } from "react-router-dom";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";

interface IComponent {
  component: JSX.Element;
}

export default function PrivateRoute({ component }: IComponent) {
  const { isLoading, user } = useAuthContext();

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (!user.user_admin_id) {
    return <Navigate to="/login" replace />;
  }
  return component;
}
