import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  TextareaAutosize,
  Autocomplete,
  Chip,
} from "@mui/material";
import { useForm } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { IuserMemberList } from "src/Types/module/invoice/invoiceTypes";
import { debounce } from "lodash";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import {
  IInvoiceForMember,
  ISingleMemberInvoice,
} from "../types/moneyReceiptTypes";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";
import { IAccountType } from "../../../MemberAccount/Account/types/AccountTypes";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface ISingleMember {
  user_member_id: number;
  user_member_company_name: string;
  user_member_representative_name: string;
}
const CreateMoneyReceipt = ({ setRefresh, handelClose }: any) => {
  const { register, handleSubmit, watch } = useForm();
  const [allMember, setAllMember] = useState<IuserMemberList[]>([]);
  const [member, setMember] = useState<ISingleMember | null>(null);
  const [companyName, seTCompanyName] = useState<string>("");
  const [memberInvoices, setMemberInvoices] = useState<ISingleMemberInvoice[]>(
    []
  );
  const [invoices, setInvoices] = useState<IInvoiceForMember>(
    {} as IInvoiceForMember
  );
  const [loading, setLoading] = useState(false);
  const paymentMethod = watch("payment_method");
  const [accountData, setAccountData] = useState<IAccountType[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/atab/user-member/company-name/get-all/status-or-all/all?company_name=${companyName}`
        );
        if (data.success) {
          setAllMember(data.data);
        }
      } catch (error) {}
    })();
  }, [companyName]);

  useEffect(() => {
    if (member?.user_member_id) {
      (async function () {
        try {
          const data: any = await HttpClient.get(
            `${url}/api/atab/admin/invoice?memberId=${member?.user_member_id}&status=unpaid`
          );
          if (data.success) {
            setMemberInvoices(data.data);
          }
        } catch (error) {}
      })();
    }
  }, [member]);
  useEffect(() => {
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/common-account?CompanyProfileId=1`)
          .then(function (res: any) {
            if (res.success) {
              setAccountData(res.data);
            } else {
            }
          })
          .catch(function (err) {
            setAccountData([]);
          });
      } catch (error) {}
    })();
  }, []);
  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const submitData = { ...inputs };
    submitData.member_id = member.user_member_id;

    if (invoices?.atab_payment_invoice_id) {
      submitData.invoice_id = invoices.atab_payment_invoice_id;
    }

    try {
      const res: any = await HttpClient.post(
        `${url}/api/atab/admin/money-receipt`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        setLoading(false);
        handelClose();
        setRefresh((prv) => !prv);
        navigate(`/money-receipt/list/${res.data.id}`);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  const specificInvoice = watch("specific_type") || "all";

  // const totalAmount = invoices?.reduce(
  //   (acc, curr) => acc + parseFloat(curr.atab_payment_invoice_grand_total),
  //   0
  // );
  // const allAmount = memberInvoices?.reduce(
  //   (acc, curr) => acc + parseFloat(curr.atab_payment_invoice_grand_total),
  //   0
  // );

  const handelInputChange = debounce((value: string) => {
    seTCompanyName(value);
  }, 500);

  return (
    <div>
      <Box sx={{ p: 2 }}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} md={6} lg={6} item>
              <Autocomplete
                value={member || null}
                onChange={(_event, newValue) => {
                  setMember(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.user_member_id === value.user_member_id
                }
                loading={true}
                id="controllable-states-demo"
                options={allMember}
                onInputChange={(_event, newInputValue) => {
                  handelInputChange(newInputValue);
                }}
                getOptionLabel={(option) => option.user_member_company_name}
                renderTags={(value: readonly any[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select member"
                    required
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Payment to specific invoice
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={specificInvoice}
                  label="Payment to specific invoice"
                  {...register("specific_type")}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"specific"}>Specific</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* {specificInvoice === "specific" && ( */}
            <>
              <Grid xs={12} md={6} lg={6} item>
                <Autocomplete
                  value={invoices}
                  onChange={(_event: any, newValue: any) => {
                    setInvoices(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.atab_payment_invoice_id ===
                    value.atab_payment_invoice_id
                  }
                  loading={true}
                  id="controllable-states-demo"
                  options={memberInvoices}
                  onInputChange={(_event, newInputValue) => {
                    handelInputChange(newInputValue);
                  }}
                  getOptionLabel={
                    (option: ISingleMemberInvoice) =>
                      option.atab_payment_invoice_no || "" // Provide a fallback label
                  }
                  renderTags={(
                    value: readonly ISingleMemberInvoice[],
                    getTagProps
                  ) =>
                    value.map((option: ISingleMemberInvoice, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={key} // Pass key directly
                          variant="filled"
                          label={
                            option.atab_payment_invoice_no ||
                            "No Invoice Number"
                          } // Provide a fallback label
                          {...tagProps} // Spread the rest of the props
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select invoices"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            </>
            {/* )} */}
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={12}
              md={6}
              lg={6}
            >
              <FormControl sx={{ mt: 1 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select payment method
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentMethod}
                  label="Select payment method"
                  {...register("payment_method")}
                >
                  <MenuItem value={"Cash"}>Cash</MenuItem>
                  <MenuItem value={"Bank"}>Bank</MenuItem>
                  <MenuItem value={"Cheque"}>Cheque</MenuItem>
                  <MenuItem value={"Mobile Banking"}>Mobile Banking</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormControl fullWidth required margin="normal">
                <InputLabel id="demo-simple-select-label">Account</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  {...register("account_id")}
                  label="Account"
                >
                  {accountData?.map((account) => {
                    return (
                      <MenuItem key={account.id} value={account.id}>
                        {account.account_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {paymentMethod === "Cheque" && (
              <>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="BankName"
                    label="Bank Name"
                    type="text"
                    variant="outlined"
                    {...register("BankName")}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    required
                    id="chequeNo"
                    label="Cheque Number"
                    type="text"
                    variant="outlined"
                    {...register("chequeNo")}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    fullWidth
                    id="ChequeDate"
                    label="Cheque Date"
                    type="date"
                    required
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    {...register("ChequeDate")}
                    defaultValue={moment().format("YYYY-MM-DD")}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                id="Amount"
                value={
                  invoices?.atab_payment_invoice_total_amount
                    ? invoices?.atab_payment_invoice_total_amount
                    : 0
                }
                // label="Amount"
                disabled
                // {...register("amount")}
                type="number"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                required
                id="payment_date"
                label="Payment Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                {...register("payment_date")}
                defaultValue={moment().format("YYYY-MM-DD")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                placeholder="Remark"
                required
                minRows={3}
                label="Remark"
                id="outlined-required"
                {...register("remark")}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Box textAlign={"center"} mt={3}>
            {loading ? (
              <LoadingButton
                loading={loading}
                fullWidth
                color="secondary"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                variant="contained"
              >
                Loading...
              </LoadingButton>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Create
              </Button>
            )}{" "}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CreateMoneyReceipt;
