import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import CurrentOwnerInfo from "./CurrentOwnerInfo";
import UpdateOwnerInfo from "./UpdateOwnerInfo";
import { useSearchParams } from "react-router-dom";
import OwnerInfoAppReject from "./OwnerInfoAppReject";
import { ISingleOwnerInfo } from "src/Types/module/UpdateRequest/RequestCompanyInfo";

const RequestSingleOwnerInfo = () => {
  const { ownerId } = useParams();
  const [query] = useSearchParams();
  const status = query.get("status");
  const memberID = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prvOwnerInfo, setPrvOwnerInfo] = useState<ISingleOwnerInfo[]>([]);
  const [requestOwnerInfo, setRequestOwnerInfo] = useState<ISingleOwnerInfo[]>(
    []
  );

  const urls = [
    `${url}/api/atab/member-company/update-request/member/owner/${ownerId}`,
    `${url}/api/atab/member-company/member/owner/${memberID}`,
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const data = await Promise.all(urls.map((url) => axios.get(url)));

        if (data?.length) {
          setRequestOwnerInfo(data[0]?.data?.data);
          setPrvOwnerInfo(data[1]?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [ownerId]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestOwnerInfo?.map((info, index) => {
            return info.status === "pending" ? (
              <Container maxWidth="xl" sx={{ mt: 5 }} key={index}>
                <Grid sx={{ pb: 5 }} container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CurrentOwnerInfo prvOwnerInfo={prvOwnerInfo} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <UpdateOwnerInfo
                      requestOwnerInfo={requestOwnerInfo}
                      ownerId={ownerId}
                      status={status}
                    />
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <OwnerInfoAppReject requestOwnerInfo={requestOwnerInfo} />
            );
          })}
        </>
      )}
    </>
  );
};

export default RequestSingleOwnerInfo;
