import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { IBalanceSheetReportType } from "./balanceSheetReportTypes";

const TraineeBalanceSheetReport = () => {
  const [BalanceSheetData, setBalanceSheetData] = useState<
    IBalanceSheetReportType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/admin/accounts/report/balance-sheet?CompanyProfileId=2`;
        const res: any = await HttpClient.getWithSignal(uri, {
          params: {},
          signal,
        });
        if (res.success) {
          setBalanceSheetData(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Fetch error:", error);
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, [refresh]);

  /* Assets Data */
  const assets = BalanceSheetData?.find((item) => item.GroupName === "Asset");
  /* Liability Data */
  const liability = BalanceSheetData?.find(
    (item) => item.GroupName === "Liability"
  );
  /* Equity Data */
  const equity = BalanceSheetData?.find((item) => item.GroupName === "Equity");
  /* Income Data */
  const income = BalanceSheetData?.find((item) => item.GroupName === "Income");
  /* Expenditure Data */
  const expenditure = BalanceSheetData?.find(
    (item) => item.GroupName === "Expenditure"
  );
  /* Drawing Data */
  const drawing = BalanceSheetData?.find(
    (item) => item.GroupName === "Drawing"
  );

  const totalAssets = assets?.Heads?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );
  const totalLiability = liability?.Heads?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );
  const totalEquity = equity?.Heads?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );
  const totalIncome = income?.Heads?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );
  const totalExpenditure = expenditure?.Heads?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );
  const totalDrawing = drawing?.Heads?.reduce(
    (sum, item) => sum + item.Balance,
    0
  );

  /* Total Owner's Equity */
  const totalOwnersEquity =
    totalEquity + totalIncome - totalExpenditure - totalDrawing || 0;

  const renderAccGroup = (
    asset,
    padding = 0,
    fontSize = 18,
    fontWeight = "bold"
  ) => {
    return (
      <React.Fragment key={asset.Id}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: `${padding}px`,
          }}
        >
          <span style={{ fontSize, fontWeight }}>{asset.Name}</span>
          <span style={{ fontSize, fontWeight }}>{asset.Balance}</span>
        </div>
        {asset.AccHeadGroup?.map((subGroup) =>
          renderAccGroup(subGroup, padding + 40, 16, "600")
        )}
        {asset.AccHeadAndSubGroup?.map((subGroup) =>
          renderAccGroup(subGroup, padding + 40, 15, "500")
        )}
        {asset.AccHead?.map((subGroup) =>
          renderAccGroup(subGroup, padding + 40, 14, "400")
        )}
      </React.Fragment>
    );
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Trainee Balance Sheet" />
            <Divider />
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "60vh",
                }}
              >
                <TableLoader />
              </div>
            ) : (
              <CardContent>
                <div style={{ display: "flex", gap: "60px" }}>
                  <div style={{ width: "50%" }}>
                    {/* Assets */}
                    <Typography
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Assets :
                    </Typography>
                    <div>
                      {" "}
                      {assets?.Heads?.flatMap((item) => renderAccGroup(item))}
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // paddingLeft: `40px`,
                      }}
                    >
                      <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                        Total Assets :
                      </span>
                      <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                        {(Math.abs(totalAssets) || 0).toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    {/* Liability */}
                    <Typography
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Liability :
                    </Typography>
                    <div>
                      {" "}
                      {liability?.Heads?.flatMap((item) =>
                        renderAccGroup(item)
                      )}
                    </div>
                    {/* Equity */}
                    <Typography
                      style={{
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Owner's Equity :
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: `40px`,
                      }}
                    >
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        Total Equity
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        {totalEquity}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: `40px`,
                      }}
                    >
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        Total Income (+)
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        {totalIncome}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: `40px`,
                      }}
                    >
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        Total Expenditure (-)
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        {totalExpenditure}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: `40px`,
                      }}
                    >
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        Total Drawing (-)
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        {totalDrawing}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: `40px`,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          fontStyle: "italic",
                          marginLeft: "40px",
                        }}
                      >
                        Total Owner's Equity :
                      </span>
                      <span style={{ fontSize: "16px", fontWeight: "600" }}>
                        {totalOwnersEquity}
                      </span>
                    </div>
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // paddingLeft: `40px`,
                      }}
                    >
                      <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                        Total Liabilities and Owner's Equity :
                      </span>
                      <span style={{ fontSize: "22px", fontWeight: "bold" }}>
                        {(
                          (Math.abs(totalOwnersEquity) || 0) +
                          (totalLiability || 0)
                        ).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TraineeBalanceSheetReport;
