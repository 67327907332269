import React from "react";
import Box from "@mui/material/Box";
import { Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import Container from "@mui/material/Container";
import TraineeInfo from "./TraineeInfo";
import TraineeGuardian from "./TraineeGuardian";
import Sourceinfo from "./Sourceinfo";

const ShortCourseRegistrationForm = ({
  register,
  sourceInfo,
  setSourceInfo,
  setCheckValue,
  checkValue,
  searchAbleValue,
  setSearchAbleValue,
  setOtherSource,
  setAreaID,
  singleTraineeApplication,
}: any) => {
  return (
    <>
      <Box mt={5}>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Trainee information Fields" />
                <Divider />
                <TraineeInfo
                  register={register}
                  setAreaID={setAreaID}
                  singleTraineeApplication={singleTraineeApplication}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Trainee guardian information Fields" />
                <Divider />
                <TraineeGuardian
                  register={register}
                  singleTraineeApplication={singleTraineeApplication}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Where did your know about our training? (Please check)" />
                <Divider />
                <CardContent>
                  <Sourceinfo
                    register={register}
                    sourceInfo={sourceInfo}
                    setSourceInfo={setSourceInfo}
                    setOtherSource={setOtherSource}
                    setCheckValue={setCheckValue}
                    checkValue={checkValue}
                    searchAblevalue={searchAbleValue}
                    setSearchAbleValue={setSearchAbleValue}
                    singleTraineeApplication={singleTraineeApplication}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ShortCourseRegistrationForm;
