import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
} from "@mui/material";

import { imgUrl, url } from "src/Helpers/utils/Constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";

const UpdateOwnerInfo = ({ ownerId, requestOwnerInfo, status }: any) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const handelAccept = async () => {
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/member/owner/${ownerId}?user_admin_id=${user.user_admin_id}`
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/owner-info");
      }
    } catch (err: any) {}
  };

  const handelReject = async () => {
    setLoading(true);
    if (!rejectReason) {
      setLoading(false);
      return Toaster().fire({
        icon: "error",
        title: "Please enter reject reason",
      });
    }
    if (rejectReason) {
      try {
        const { data } = await axios.post(
          `${url}/api/atab/member-company/update-request/member/owner/${ownerId}?confirmation_type=reject&user_admin_id=${user.user_admin_id}`,
          { reject_reason: rejectReason }
        );
        if (data.success) {
          Toaster().fire({
            icon: "success",
            title: data.message,
          });
          navigate("/update-request/owner-info");
          setLoading(false);
        }
      } catch (err: any) {
        Toaster().fire({
          icon: "error",
          title: err?.response?.data.message,
        });
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4" fontSize={21}>
              Requested information
            </Typography>
          }
          action={
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button size="small" variant="outlined" onClick={handelAccept}>
                Accept request
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setOpen(true)}
              >
                Reject request
              </Button>
            </Box>
          }
        />
        {requestOwnerInfo?.map((sOwner) => {
          return (
            <TableContainer sx={{ mb: 5 }} key={sOwner.company_owner_id}>
              <Table>
                <TableBody>
                  {sOwner.company_owner_name && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner name
                      </TableCell>
                      <TableCell align="right">
                        {sOwner.company_owner_name}
                      </TableCell>
                    </TableRow>
                  )}
                  {sOwner.company_owner_designation && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner designation
                      </TableCell>
                      <TableCell align="right">
                        {sOwner.company_owner_designation}
                      </TableCell>
                    </TableRow>
                  )}

                  {sOwner.company_owner_phone && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner phone
                      </TableCell>
                      <TableCell align="right">
                        {sOwner.company_owner_phone}
                      </TableCell>
                    </TableRow>
                  )}

                  {sOwner.company_owner_email && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner email
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {sOwner.company_owner_email}
                      </TableCell>
                    </TableRow>
                  )}
                  {sOwner.company_owner_address && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner address
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {sOwner.company_owner_address}
                      </TableCell>
                    </TableRow>
                  )}

                  {sOwner.company_owner_nid && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner nid
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        {sOwner.company_owner_nid}
                      </TableCell>
                    </TableRow>
                  )}

                  {sOwner.company_owner_photo && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner photo
                      </TableCell>
                      <TableCell align="right">
                        <a
                          target="_blank"
                          href={`${imgUrl}/${sOwner.company_owner_photo}`}
                        >
                          {sOwner.company_owner_photo}
                        </a>
                      </TableCell>
                    </TableRow>
                  )}

                  {sOwner.company_owner_biodata && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner bio data
                      </TableCell>
                      <TableCell align="right">
                        <a
                          target="_blank"
                          href={`${imgUrl}/${sOwner.company_owner_biodata}`}
                        >
                          {sOwner.company_owner_biodata}
                        </a>
                      </TableCell>
                    </TableRow>
                  )}

                  {sOwner.company_owner_passport && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner passport
                      </TableCell>
                      <TableCell align="right">
                        <a
                          target="_blank"
                          href={`${imgUrl}/${sOwner.company_owner_passport}`}
                        >
                          {sOwner.company_owner_passport}
                        </a>
                      </TableCell>
                    </TableRow>
                  )}

                  {sOwner.company_owner_signature && (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company owner signature
                      </TableCell>
                      <TableCell align="right">
                        <a
                          target="_blank"
                          href={`${imgUrl}/${sOwner.company_owner_signature}`}
                        >
                          {sOwner.company_owner_signature}
                        </a>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
      </Card>
      {/* Modal */}
      <ModalsWrapper
        modalData={{ title: "Rejected reason" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <Box
          sx={{
            mx: 4,
          }}
        >
          <TextField
            required
            rows={4}
            fullWidth
            id="reason"
            label="Rejected reason"
            placeholder="Rejected reason"
            onChange={(e) => setRejectReason(e.target.value)}
            type="text"
            multiline
            autoFocus
          />

          {loading ? (
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading ...
            </Button>
          ) : (
            <Button
              onClick={handelReject}
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Submit
            </Button>
          )}
        </Box>

        {/* <CreateMember setAllMembers={setAllMembers} handleClose={setOpen} /> */}
      </ModalsWrapper>
    </>
  );
};

export default UpdateOwnerInfo;
