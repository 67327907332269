import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { imgUrl } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { Grid } from "@mui/material";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

const OldDocuments = () => {
  const { memberId } = useParams();
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  useEffect(() => {
    setLoading(true);
    try {
      (async function () {
        const res: any = await HttpClient.get(
          `/api/atab/admin/update/previous-file/${memberId}`
        );
        if (res.success) {
          setDocuments(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <GlobalLoader />
      ) : (
        <>
          {Object.entries(documents).length ? (
            <ul>
              {Object.entries(documents).map(([key, value]: any) => {
                return (
                  <li key={key}>
                    <span>
                      {key.split("_").map((keyName, index) => {
                        if (
                          keyName.includes("user") &&
                          keyName.includes("member")
                        ) {
                          keyName = keyName
                            .replace("user", "")
                            .replace("member", "");
                        } else if (keyName.includes("user")) {
                          keyName = keyName.replace("user", "");
                        } else if (keyName.includes("member")) {
                          keyName = keyName.replace("member", "");
                        }

                        keyName = keyName.trim();
                        return (
                          <span
                            style={{
                              paddingRight: "5px",
                              fontWeight: "bold",
                            }}
                            key={index}
                          >
                            {keyName.charAt(0).toUpperCase() + keyName.slice(1)}
                          </span>
                        );
                      })}
                    </span>
                    <span>
                      {" "}
                      :{" "}
                      <CommonTypoGraphy
                        name="View"
                        onClick={() => {
                          SetImageViewOpen(true);
                          setImageFile(value);
                        }}
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
          ) : (
            <Box
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                py: 5,
              }}
            >
              No data found
            </Box>
          )}
        </>
      )}
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </div>
  );
};

export default OldDocuments;
