import { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
  Box,
} from "@mui/material";

import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IBatchNoticeList } from "src/Types/module/batch/allTypeOfBatch";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import moment from "moment-timezone";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { url } from "src/Helpers/utils/Constant";
import axios, { all } from "axios";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IProps {
  notice: IBatchNoticeList;
  allBatchNotice: IBatchNoticeList[];
  setAllBatchNotice: any;
}

const AllBatchNoticeTableRow = ({
  notice,
  allBatchNotice,
  setAllBatchNotice,
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useAuthContext();

  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/training/batch/delete/notice/${id}?user_admin_id=${user.user_admin_id}`;
        const { data } = await axios.delete(uri);

        if (data.success) {
          const filterData = allBatchNotice.filter(
            (sNotice) => sNotice.training_batch_notice_id !== id
          );

          setAllBatchNotice(filterData);

          Toaster().fire({
            icon: "success",
            title: "Deleted successfully!",
          });

          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {notice.training_batch_notice_id}
          </Typography>
        </TableCell>
        <TableCell sx={{ wordBreak: "break-word", maxWidth: "350px" }}>
          <Typography
            title={notice.training_batch_notice_title}
            variant="body1"
            color="text.primary"
            gutterBottom
            noWrap
          >
            {notice.training_batch_notice_title
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </TableCell>
        <TableCell sx={{ wordBreak: "break-word", maxWidth: "350px" }}>
          <Typography
            title={notice.training_batch_name}
            variant="body1"
            color="text.primary"
            gutterBottom
            noWrap
          >
            {notice.training_batch_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </TableCell>
        <TableCell>
          {moment
            .tz(notice.training_batch_notice_date, "Asia/Dhaka")
            .format("MMM Do YY")}
        </TableCell>
        <TableCell>
          {moment
            .tz(notice.training_batch_notice_date, "Asia/Dhaka")
            .format("LT")}
        </TableCell>
        <TableCell>
          <Box>
            <Link
              to={`/training-batch/notice/${notice.training_batch_notice_id}`}
            >
              <Tooltip title="view" arrow>
                <IconButton
                  sx={{
                    "&:hover": {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color="inherit"
                  size="small"
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>

            <Tooltip title="Delete Notice" arrow>
              <IconButton
                onClick={() => setOpen(true)}
                sx={{
                  "&:hover": { background: theme.colors.error.lighter },
                  color: theme.palette.error.main,
                }}
                color="inherit"
                size="small"
              >
                <DeleteTwoToneIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {/* Delele Confirmation modal  */}
            <DeleteConfirmation
              open={open}
              handleClose={() => setOpen(false)}
              text="Want to delete this notice?"
              handleDeleteFun={() =>
                handleDelete(notice.training_batch_notice_id)
              }
              loading={isLoading}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AllBatchNoticeTableRow;
