import { ChangeEvent, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { applyPagination, RefreshButton } from "src/Utils/CommonAllShowUtils";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { ITraineeApplication } from "src/Utils/type";
import TraineeApplicationTableRow from "./TraineeApplicationTableRow";
import { debounce } from "lodash";

interface IApplicationState {
  applications: ITraineeApplication[];
  total: number;
}

const TraineeApplications = () => {
  const [traineeApplication, setTraineeApplication] =
    useState<IApplicationState>({ applications: [], total: 0 });
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [status, setStatus] = useState("pending");
  const [applicantName, setApplicantName] = useState("");
  // const [course, setCourse] = useState<number | null>(null);

  useEffect(() => {
    let baseUrl = `${url}/api/training/course/application?training_course_application_status=${status}`;
    if (page > 1) {
      baseUrl += `&skip=${limit * (page - 1)}&limit=${limit}`;
    } else {
      baseUrl += `&skip=0&limit=${limit}`;
    }

    if (applicantName) {
      baseUrl += `&applicant_name=${applicantName}`;
    }
    setLoading(true);
    axios
      .get(baseUrl)
      .then((data) => {
        setTraineeApplication({
          applications: data.data.data,
          total: data.data.total,
        });
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
  }, [refresh, status, page, limit, applicantName]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };
  const handelChangeInput = debounce((value) => {
    setApplicantName(value);
  }, 2000);

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <Box display="flex" alignItems="center">
                    <TextField
                      // style={{ height: "10px" }}0
                      id="outlined-basic"
                      label="Search by applicant name"
                      variant="outlined"
                      size="small"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handelChangeInput(e.target.value)
                      }
                    />
                    <Box>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="status-lebel">Select status</InputLabel>
                        <Select
                          labelId="status-lebel"
                          id="status-lebel"
                          label="Select status"
                          value={status}
                          size="small"
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <MenuItem value="pending">Pending</MenuItem>
                          <MenuItem value="assigned">Assigned</MenuItem>
                          <MenuItem value="rejected">Rejected</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <RefreshButton action={setRefresh} />
                    </Box>
                  </Box>
                }
                title="Trainee Applications"
              />
              <Divider />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Application id</TableCell>
                      <TableCell>Applicant name</TableCell>
                      <TableCell>Course name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>View</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableLoader />
                    ) : traineeApplication.total ? (
                      <>
                        {traineeApplication.applications.map(
                          (traineeApplication: ITraineeApplication) => {
                            return (
                              <TraineeApplicationTableRow
                                key={
                                  traineeApplication.training_course_application_id
                                }
                                traineeApplication={traineeApplication}
                              />
                            );
                          }
                        )}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={traineeApplication.total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default TraineeApplications;
