import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import Toaster from "src/Toaster/Toaster";
import { HttpClient } from "src/Helpers/http/http";
import { useNavigate, useParams } from "react-router-dom";

const CreateBatchWiseIdCard = ({ handleClose }: any) => {
  const { id } = useParams();
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const Toast = Toaster();

  const navigate = useNavigate();

  const handelSubmit = async () => {
    setLoading(true);
    if (!date) {
      Toaster().fire({
        icon: "error",
        title: "Please select validity date",
      });
      setLoading(false);
      return;
    }
    try {
      const data: any = await HttpClient.post(`/api/trainee/id-card`, {
        batch_id: Number(id),
        valid_date: date,
      });

      if (data.success) {
        Toast.fire({
          icon: "success",
          title: data.message,
        });

        handleClose();
        navigate(`/training-batch/${id}/id-card`);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };
  return (
    <Box p={2}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="Enter validity date"
            onChange={(e: any) => setDate(dayjs(e).format("YYYY-MM-DD"))}
          />
        </DemoContainer>
      </LocalizationProvider>
      <Box sx={{ textAlign: "center" }}>
        <Button
          onClick={handelSubmit}
          type="submit"
          sx={{
            mt: 3,
            mb: 2,
            width: { xs: "15rem", md: "15rem" },
          }}
          // className={classes.signInButton}
          variant="contained"
          disabled={loading}
        >
          {!loading ? "Create" : "Loading..."}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateBatchWiseIdCard;
