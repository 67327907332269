import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";

import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import { ISingleTrainee } from "./types/singleTraineeTypes";
type Inputs = {
  user_training_trainee_account_status: string;
  user_training_trainee_phone: string;
  user_training_trainee_photo: string;
  user_training_trainee_name: string;
};
const UpdateTrainee = ({
  traineeInfo,
  handelClose,
  setReload,
}: {
  traineeInfo: ISingleTrainee;
  handelClose: any;
  setReload: any;
}) => {
  const { register, handleSubmit } = useForm<Inputs>();
  const [loading, setLoading] = useState(false);
  const onSubmit: SubmitHandler<Inputs> = async (inputValue) => {
    const { user_training_trainee_account_status, ...rest } = inputValue;
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "user_training_trainee_account_status",
      inputValue.user_training_trainee_account_status
    );

    for (const key in rest) {
      if (rest[key]) {
        if (key === "user_training_trainee_photo") {
          if (rest[key][0]) formData.append(key, rest[key][0]);
        } else {
          formData.append(key, rest[key]);
        }
      }
    }

    try {
      const data: any = await HttpClient.patch(
        `/api/training/trainee/update/for-admin/${traineeInfo?.user_training_trainee_id}`,
        formData
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setReload((prv) => !prv);
        handelClose();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={2}>
          <Grid container spacing={2}>
            {" "}
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-textarea"
                label="Trainee name"
                {...register("user_training_trainee_name")}
                defaultValue={traineeInfo?.user_training_trainee_name}
                {...register("user_training_trainee_name")}
                fullWidth
                type="text"
              />
            </Grid>{" "}
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-textarea"
                label="Email"
                defaultValue={traineeInfo?.user_training_trainee_email}
                fullWidth
                type="email"
                disabled
              />
            </Grid>{" "}
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-textarea"
                label="Phone"
                placeholder="Enter phone number"
                defaultValue={traineeInfo?.user_training_trainee_phone}
                {...register("user_training_trainee_phone")}
                fullWidth
                type="text"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Status"
                  defaultValue={Number(
                    traineeInfo?.user_training_trainee_account_status
                  )}
                  {...register("user_training_trainee_account_status")}
                >
                  <MenuItem key={1} value={1}>
                    Active
                  </MenuItem>
                  ,
                  <MenuItem key={0} value={0}>
                    Blocked
                  </MenuItem>
                  ,
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Trainee photo"
                InputLabelProps={{
                  shrink: true,
                }}
                type="file"
                fullWidth
                sx={{ pb: 2 }}
                {...register("user_training_trainee_photo")}
              />
            </Grid>
          </Grid>
          {/* user_training_trainee_photo */}
          <Box mt={5} textAlign="center">
            {loading ? (
              <Button
                disabled
                variant="contained"
                disableElevation
                className="add-remove-button"
              >
                loading..
              </Button>
            ) : (
              <Button type="submit" variant="contained">
                Update
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default UpdateTrainee;
