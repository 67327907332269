import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useForm } from "react-hook-form";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { LoadingButton } from "@mui/lab";
import { IsingleMemberMeeting } from "src/Types/module/meeting/singleMemberMeeting";
import { Imembers } from "src/Types/module/member/memberTypes";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function MeetingUpdateForm({
  singleInvoiceDetails,
  handleEditModeChange,
}: {
  singleInvoiceDetails: IsingleMemberMeeting;
  handleEditModeChange: any;
}) {
  const { register, handleSubmit } = useForm();
  const Toast = Toaster();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const { user } = useAuthContext();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(singleInvoiceDetails?.member_meeting_date)
  );
  const [timeValue, setTimeValue] = useState<Dayjs | null>(() => {
    const date = singleInvoiceDetails?.member_meeting_date;
    const time = singleInvoiceDetails?.member_meeting_time;

    if (date && time) {
      // Combine date and time in a standard format
      const dateTimeString = `${date.split("T")[0]}T${time}`;
      return dayjs(dateTimeString);
    } else {
      return null;
    }
  });

  const [allMember, setAllMember] = useState<Imembers[]>([]);
  const defaultValue = singleInvoiceDetails?.joinedMember?.map((item) =>
    String(item?.joined_member_id + " " + item?.user_member_company_name)
  );

  const [membersId, setMembersId] = useState<string[]>([]);
  const [meetingType, setMeetingType] = useState(
    singleInvoiceDetails.member_meeting_type
  );
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        let uri = `${url}/api/atab/user-member/get/all/by/zone/status/for-admin/all/all?skip=0&limit=10000`;
        const { data } = await axios.get(uri);

        if (isMounted && data.success) {
          setAllMember(data.data);
        }
      } catch (error) {
        // Handle error
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const getDefaultValueID = defaultValue?.map((member) => {
    return parseInt(member.split(" ")[0]);
  });

  const selectedMember = membersId?.map((member) => {
    return parseInt(member.split(" ")[0]);
  });

  const getUniqueMember = selectedMember?.filter(
    (item) => !getDefaultValueID?.includes(Number(item))
  );

  const onSubmit = async (inputs: React.ChangeEvent<HTMLInputElement>) => {
    // setIsLoading(true);

    if (
      (user.user_admin_id &&
        singleInvoiceDetails.member_meeting_time &&
        singleInvoiceDetails.member_meeting_id,
      singleInvoiceDetails.member_meeting_date)
    ) {
      const bodyFormData = new FormData();

      for (const key in inputs) {
        if (typeof inputs[key] === "object") {
          if (inputs[key][0]) {
            bodyFormData.append(key, inputs[key][0]);
          }
        } else {
          bodyFormData.append(key, inputs[key]);
        }
      }
      bodyFormData.append(
        "member_meeting_time",
        dayjs(timeValue).format("hh:mm:ss")
      );
      // timeValue?.toISOString().slice(11, 19);
      bodyFormData.append(
        "member_meeting_date",
        dayjs(selectedDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "member_meeting_id",
        singleInvoiceDetails.member_meeting_id.toString()
      );
      if (meetingType) bodyFormData.append("member_meeting_type", meetingType);

      if (getUniqueMember?.length)
        bodyFormData.append("members_id", JSON.stringify(getUniqueMember));

      try {
        const { data } = await axios.put(
          `${url}/api/member/meeting/update/for-admin?user_admin_id=${user.user_admin_id}`,
          bodyFormData
        );

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: `Updated Successfully`,
          });
          handleEditModeChange();
          setSuccess(false);
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: `${err.response?.data?.message}`,
        });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleEditModeChange();
    }
  }, [isSuccess]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={{ xs: 2, md: 2 }}>
        <Grid xs={12} md={4} lg={4} item>
          <TextField
            fullWidth
            label="Member meeting title"
            id="filled-size-small"
            defaultValue={singleInvoiceDetails.member_meeting_title}
            variant="outlined"
            size="medium"
            type="text"
            {...register("member_meeting_title")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={4} item>
          <FormControl required fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Status
            </InputLabel>
            <Select
              fullWidth
              // sx={{ mt: -2 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              key={singleInvoiceDetails.member_meeting_status}
              defaultValue={singleInvoiceDetails.member_meeting_status}
              {...register("member_meeting_status")}
            >
              <MenuItem value="upcoming" disabled>
                Upcoming
              </MenuItem>
              <MenuItem value="running">Running</MenuItem>
              <MenuItem value="ended">Ended</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={4} lg={4} item>
          <FormControl required fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Meeting type
            </InputLabel>
            <Select
              fullWidth
              // sx={{ mt: -2 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Meeting type"
              value={meetingType}
              onChange={(e) => setMeetingType(e.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"specific"}>Specific</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {meetingType === "specific" && (
          <Grid xs={12} md={12} lg={12} item>
            <Autocomplete
              multiple
              id="tags-filled"
              options={allMember.map(
                (option) =>
                  option.user_member_id + " " + option.user_member_company_name
              )}
              freeSolo
              defaultValue={defaultValue}
              // value={defaultValue.map((id) => String(id))}
              onChange={(_event, newValue) => {
                setMembersId(newValue);
              }}
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant="filled"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select member"
                  placeholder="More.."
                />
              )}
            />
          </Grid>
        )}
        <Grid xs={12} md={4} lg={4} item>
          <TextField
            fullWidth
            label="Member meeting place:"
            id="filled-size-small"
            defaultValue={singleInvoiceDetails.member_meeting_place}
            variant="outlined"
            size="medium"
            type="text"
            {...register("member_meeting_place")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={4} item>
          <TextField
            fullWidth
            id="filled-size-small"
            variant="outlined"
            type="file"
            label="Upload a meeting image"
            InputLabelProps={{
              shrink: true,
            }}
            {...register("member_meeting_img")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={4} item>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Update meeting date"
              format="MM/DD/YYYY"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e)}
            />
          </LocalizationProvider>
        </Grid>{" "}
        <Grid xs={12} md={4} lg={4} item>
          {" "}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Meeting time"
              value={timeValue}
              sx={{ width: "100%" }}
              onChange={(e: any) => setTimeValue(e)}
            />
          </LocalizationProvider>
        </Grid>{" "}
        <Grid xs={12} md={6} lg={6} item>
          <TextField
            multiline
            rows={4}
            fullWidth
            label="Member meeting details"
            id="filled-size-small"
            defaultValue={singleInvoiceDetails.member_meeting_details}
            variant="outlined"
            size="medium"
            type="text"
            {...register("member_meeting_details")}
          />
        </Grid>
        <Grid xs={12} md={6} lg={6} item>
          <TextField
            label="Member meeting remark:"
            id="filled-size-small"
            multiline
            fullWidth
            rows={4}
            defaultValue={singleInvoiceDetails?.member_meeting_remark}
            variant="outlined"
            size="medium"
            type="text"
            {...register("member_meeting_remark")}
          />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "end", mt: 5 }}>
        {" "}
        {!isLoading ? (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            sx={{
              margin: 2,
            }}
          >
            Update meeting
          </Button>
        ) : (
          <LoadingButton
            loading
            loadingIndicator="Updating..."
            variant="outlined"
          >
            Updating...
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}

export default MeetingUpdateForm;
