import { useState, useEffect } from "react";
import {
  Box,
  Card,
  Container,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Tooltip,
  CardContent,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SuspenseLoader from "src/components/SuspenseLoader";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AttendentStudentList from "./AttendentStudentList";
import { ISingleClassWithAttendance } from "src/Types/module/batch/allTypeOfBatch";
import UpdateClass from "./UpdateClass";
import AddAttendance from "./AddAttendance";
import { getStatusLabel } from "src/Utils/Combined";
import moment from "moment-timezone";

const SingleClassWithAttendance = () => {
  const [singleClass, setSingleClass] =
    useState<ISingleClassWithAttendance | null>(
      {} as ISingleClassWithAttendance
    );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const theme = useTheme();
  const { id } = useParams();

  // Modal state
  const [open, setOpen] = useState({ editClass: false, addAttendance: false });

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/class/get/single/with/attendance/${id}`;
        const { data } = await axios.get(uri);

        if (data.success) {
          setSingleClass(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [id, reload]);

  return (
    <Box>
      <Helmet>
        <title>Atab - Single Class</title>
      </Helmet>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 8,
        }}
        maxWidth="lg"
      >
        {isLoading ? (
          <SuspenseLoader />
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            {singleClass ? (
              <Card sx={{ p: 3 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    textAlign="center"
                    gutterBottom
                    variant="h2"
                    component="div"
                  >
                    Class information
                  </Typography>
                  <Box sx={{ display: "flex" }} gap={2}>
                    <Tooltip title="Edit Class">
                      <IconButton
                        onClick={() =>
                          setOpen({ addAttendance: false, editClass: true })
                        }
                      >
                        <EditIcon
                          sx={{
                            cursor: "pointer",
                            color: theme.colors.primary.main,
                          }}
                        />{" "}
                      </IconButton>
                    </Tooltip>
                    {singleClass.attendance.length ? (
                      <Tooltip title="Add Attendance">
                        <IconButton
                          onClick={() =>
                            setOpen({ editClass: false, addAttendance: true })
                          }
                        >
                          <AddIcon
                            sx={{
                              cursor: "pointer",
                              color: theme.colors.primary.main,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Box>
                </Box>
                <CardContent sx={{ mt: 2 }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        ID:
                      </Typography>
                      {singleClass?.training_class_id}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Batch Name:
                      </Typography>
                      {singleClass?.training_batch_name}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Batch ID:
                      </Typography>
                      {singleClass?.training_batch_id}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Class Start Date:
                      </Typography>
                      {moment
                        .tz(singleClass?.training_class_date, "Asia/Dhaka")
                        .format("DD-MM-YYYY")}
                    </Grid>

                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Class End Time:
                      </Typography>
                      {moment(
                        singleClass.training_class_end_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Class status:
                        {getStatusLabel(singleClass.training_class_status)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Class remark:
                      </Typography>
                      {singleClass?.training_class_remark}
                    </Grid>
                  </Grid>
                </CardContent>

                {isLoading ? (
                  <SuspenseLoader />
                ) : singleClass.attendance.length ? (
                  <Box>
                    <Typography
                      my={3}
                      color="text."
                      align="center"
                      variant="h3"
                    >
                      Attendance list
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead
                          sx={{ backgroundColor: theme.colors.primary.main }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{ color: theme.colors.alpha.white[100] }}
                            >
                              ID
                            </TableCell>
                            <TableCell
                              sx={{ color: theme.colors.alpha.white[100] }}
                              align="center"
                            >
                              Photo
                            </TableCell>
                            <TableCell
                              sx={{ color: theme.colors.alpha.white[100] }}
                              align="center"
                            >
                              Student name
                            </TableCell>
                            <TableCell
                              sx={{ color: theme.colors.alpha.white[100] }}
                              align="center"
                            >
                              Attendant time
                            </TableCell>
                            <TableCell
                              sx={{ color: theme.colors.alpha.white[100] }}
                              align="center"
                            >
                              Status
                            </TableCell>

                            <TableCell
                              sx={{ color: theme.colors.alpha.white[100] }}
                              align="center"
                            >
                              Update attendance
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {singleClass.attendance.map((student) => {
                            return (
                              <AttendentStudentList
                                key={student.user_training_trainee_id}
                                student={student}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      marginTop: "2rem",
                    }}
                  >
                    No student attened
                  </Typography>
                )}
              </Card>
            ) : (
              ""
            )}
          </Grid>
        )}
      </Container>

      {/* Update class modal  */}
      <Dialog
        open={open.editClass}
        maxWidth="lg"
        onClose={() => setOpen({ ...open, editClass: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box sx={{ mb: 4 }}>
            <Typography sx={{ mb: 2 }}> Update Class</Typography>
            <Divider />
          </Box>
          <UpdateClass
            singleClass={singleClass}
            setReload={setReload}
            setSingleClass={setSingleClass}
            handleClose={() => setOpen({ ...open, editClass: false })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setOpen({ ...open, editClass: false })}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Attendance modal  */}
      <Dialog
        open={open.addAttendance}
        maxWidth="lg"
        onClose={() => setOpen({ ...open, addAttendance: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <AddAttendance
            singleClass={singleClass}
            handleClose={() => setOpen({ ...open, addAttendance: false })}
            setReload={setReload}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setOpen({ ...open, addAttendance: false })}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SingleClassWithAttendance;
