import { Card, Container, Grid } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import EntryFairTable from "./EntryFairTable";
import { exhibitorInvoice } from "src/Utils/type";

const EntryFairList = () => {
  const [entryFairList, setEntryFairList] = useState<exhibitorInvoice[] | null>(
    []
  );
  const [loading, setLoading] = useState<Boolean>(false);

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/fair/entry/get/all`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setEntryFairList(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, []);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <EntryFairTable loading={loading} entryFairList={entryFairList} />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EntryFairList;
