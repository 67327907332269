import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import UpdateCompanyInfo from "./UpdateCompanyInfo";
import CurrentCompanyInfo from "./CurrentCompanyInfo";
import { useSearchParams } from "react-router-dom";
import CompanyInfoAppReject from "./CompanyInfoAppReject";
import { ISingleCompanyInfo } from "src/Types/module/UpdateRequest/RequestCompanyInfo";

const RequestSingleCompanyInfo = () => {
  const { companyID } = useParams();
  const [query] = useSearchParams();
  const status = query.get("status");
  const memberID = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestCompanyInfo, setRequestCompanyInfo] =
    useState<ISingleCompanyInfo>({} as ISingleCompanyInfo);
  const [prvCompanyInfo, setPrvCompanyInfo] = useState<ISingleCompanyInfo>(
    {} as ISingleCompanyInfo
  );

  const urls = [
    `${url}/api/atab/member-company/update-request/member/company/${companyID}`,
    `${url}/api/atab/member-company/member/company/${memberID}`,
  ];

  useEffect(() => {
    (async function () {
      const data = await Promise.all(urls.map((url) => axios.get(url)));

      if (data.length) {
        setRequestCompanyInfo(data[0].data.data);
        setPrvCompanyInfo(data[1].data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    })();
  }, [companyID]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestCompanyInfo.status === "pending" ? (
            <Container maxWidth="xl" sx={{ mt: 5 }}>
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CurrentCompanyInfo prvCompanyInfo={prvCompanyInfo} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UpdateCompanyInfo
                    requestCompanyInfo={requestCompanyInfo}
                    companyID={companyID}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <CompanyInfoAppReject requestCompanyInfo={requestCompanyInfo} />
          )}
        </>
      )}
    </>
  );
};

export default RequestSingleCompanyInfo;
