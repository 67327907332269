import { Card } from "@mui/material";
import TraineeTable from "./TraineeTable";

const TraineeShow = () => {
  return (
    <>
      <Card>
        <TraineeTable />
      </Card>
    </>
  );
};

export default TraineeShow;
