import { useState, useEffect, ChangeEvent } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { IBatchNoticeList } from "src/Types/module/batch/allTypeOfBatch";
import AllBatchNoticeTableRow from "./AllNoticeTableRow";
import { useParams } from "react-router-dom";
import {
  TableLoader,
  TableNotFound,
} from "../../../../../../Utils/TableLoader";
import { useSearchParams } from "react-router-dom";

const AllNoticeOfBatch = () => {
  const [AllBatchNotice, setAllBatchNotice] = useState<IBatchNoticeList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const { id } = useParams();
  const [query] = useSearchParams();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/batch/get/notice/${id}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllBatchNotice(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [id, refresh]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title={`All notice of a ${query.get("batch")}`}>
              <Divider />
            </CardHeader>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Batch Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {isLoading ? (
                  <TableLoader />
                ) : AllBatchNotice.length ? (
                  <TableBody>
                    {AllBatchNotice.map((notice: IBatchNoticeList) => {
                      return (
                        <AllBatchNoticeTableRow
                          key={notice.training_batch_notice_id}
                          notice={notice}
                          allBatchNotice={AllBatchNotice}
                          setAllBatchNotice={setAllBatchNotice}
                        />
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableNotFound />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={AllBatchNotice.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AllNoticeOfBatch;
