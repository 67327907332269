import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { IBusinessLicense } from "src/Types/module/member/UpdateCompanyInfoStateTypes";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
import { IResponse } from "src/Types/module/common/commonTypes";
import { ElectricScooterSharp } from "@mui/icons-material";

interface Props {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const OtherBusinessLicense = ({
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: Props) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [businessLicense, setBusinessLicense] = useState<IBusinessLicense>({
    hajj_license: 0,
    umrah_license: 0,
    recruting_license: 0,
    outbound: 0,
    inbound: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (inputsData: any) => {
    setIsLoading(true);
    inputsData = { ...inputsData, ...businessLicense };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    try {
      const data: IResponse = await HttpClient.post(
        `/api/atab/admin/member-company/member/business-license/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Other business license ( please tick mark in box )
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Hajj"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      hajj_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      hajj_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Umrah"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      umrah_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      umrah_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Recruiting"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      recruting_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      recruting_license: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Box>
            {businessLicense.hajj_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Hajj license{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("hajj_license_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {businessLicense.umrah_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Umrah license{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("umrah_license_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {businessLicense.recruting_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Recruiting license
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("recruting_license_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Involved in tourism activities ( please tick mark in box )
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 15,
              pl: { xs: 7, md: 0 },
              // justifyContent: 'space-evenly',
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label=" Inbound"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      inbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      inbound: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Outbound"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      outbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      outbound: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OtherBusinessLicense;
