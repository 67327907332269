import { useState, Dispatch } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { IMemberInfo } from "src/Types/module/member/memberTypes";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateBasicInfo from "./UpdateBasicInfo";
import { getStatusLabel } from "src/Utils/Combined";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";

type IProps = {
  memberInfo: IMemberInfo;
  setRefresh: Dispatch<React.SetStateAction<boolean>>;
};

function BasicInformation({ memberInfo, setRefresh }: IProps) {
  const [open, setOpen] = useState<boolean>(false);
  const {
    user_member_email,
    user_member_phone,
    user_member_account_status,
    user_member_membership_payment_date,
    user_member_membership_payment_expiry_date,
    user_member_company_name,
    user_member_id_card_number,
    user_member_reject_reason,
    user_member_active_date,
    user_member_last_reject_date,
    user_member_id_card_issue_date,
    user_member_id_card_expiry_date,
    checker_reject_reason,
  } = memberInfo || {};

  return (
    <Box>
      <PageTitleWrapper>
        <PageTitle
          heading={`Basic Information of ${user_member_company_name}`}
          subHeading={`ID: ${user_member_id_card_number}`}
          actionButtonTitle="Update"
          setAction={setOpen}
        />
      </PageTitleWrapper>
      <Grid sx={{ pb: 5 }} container spacing={2}>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Account Status:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_account_status
                      ? getStatusLabel(
                          user_member_account_status ===
                            "Application Inspection"
                            ? "Office Inspection"
                            : user_member_account_status
                        )
                      : "Inactive"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Phone:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_phone ? user_member_phone : ""}
                  </TableCell>
                </TableRow>
                {user_member_account_status === "active" ? (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Active date:
                    </TableCell>
                    <TableCell align="right">
                      {moment(user_member_active_date).format("Do MMM YYYY")}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {user_member_account_status === "blocked" ||
                user_member_account_status === "rejected" ? (
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ color: "red" }}>
                      Deactivate date:
                    </TableCell>
                    <TableCell align="right" sx={{ color: "red" }}>
                      {moment(user_member_last_reject_date).format(
                        "Do MMM YYYY"
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {user_member_account_status !== "active" &&
                user_member_reject_reason ? (
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ color: "red" }}>
                      Reject reason:
                    </TableCell>
                    <TableCell align="right" sx={{ color: "red" }}>
                      {user_member_reject_reason}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                {checker_reject_reason ? (
                  <TableRow>
                    <TableCell component="th" scope="row" sx={{ color: "red" }}>
                      Reject reason from checker:
                    </TableCell>
                    <TableCell align="right" sx={{ color: "red" }}>
                      {checker_reject_reason}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID card number:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_id_card_number
                      ? user_member_id_card_number
                      : "Not provided"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID card issue date:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_id_card_issue_date
                      ? moment(user_member_id_card_issue_date).format(
                          "Do MMM YYYY"
                        )
                      : "Not provided"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID card expiry date:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_id_card_expiry_date
                      ? moment(user_member_id_card_expiry_date).format(
                          "Do MMM YYYY"
                        )
                      : "Not provided"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Company Name:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_company_name
                      ? user_member_company_name?.charAt(0).toUpperCase() +
                        user_member_company_name?.slice(1)
                      : "Not provided yet"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_email ? user_member_email : "Not provided yet"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Membership payment date:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_membership_payment_date
                      ? moment(user_member_membership_payment_date).format(
                          "Do MMM YYYY"
                        )
                      : "Payment Date not found"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Membership payment expiry date:
                  </TableCell>
                  <TableCell align="right">
                    {user_member_membership_payment_expiry_date
                      ? moment(
                          user_member_membership_payment_expiry_date
                        ).format("Do MMM YYYY")
                      : "Expiry date not found"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{ title: "Update member information" }}
      >
        <UpdateBasicInfo
          memberInfo={memberInfo}
          setRefresh={setRefresh}
          setShowModal={setOpen}
        />
      </ModalsWrapper>
    </Box>
  );
}

export default BasicInformation;
