import { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";

import { ISingleCompanyDocsInfo } from "src/Types/module/UpdateRequest/RequestCompanyDocsInfo";
import CurrentCompanyDocsInfo from "./CurrentCompanyDocsInfo";
import UpdateCompanyDocsInfo from "./UpdateCompanyDocsInfo";
import { useSearchParams } from "react-router-dom";
import CompanyDocsAppReject from "./CompanyDocsAppReject";

const RequestSingleCompanyDocsInfo = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const status = query.get("status");
  const memberID = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preCompanyDocsInfo, setPrvCompanyDocsInfo] =
    useState<ISingleCompanyDocsInfo>({} as ISingleCompanyDocsInfo);
  const [requestCompanyDocsInfo, setRequestCompanyDocsInfo] =
    useState<ISingleCompanyDocsInfo>({} as ISingleCompanyDocsInfo);

  const urls = [
    `${url}/api/atab/member-company/update-request/member/documents/${id}`,
    `${url}/api/atab/member-company/member/documents/${memberID}`,
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const data = await Promise.all(urls.map((url) => axios.get(url)));
        if (data?.length) {
          setRequestCompanyDocsInfo(data[0]?.data?.data);
          setPrvCompanyDocsInfo(data[1]?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestCompanyDocsInfo.status === "pending" ? (
            <Container maxWidth="xl" sx={{ mt: 5 }}>
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CurrentCompanyDocsInfo
                    preCompanyDocsInfo={preCompanyDocsInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UpdateCompanyDocsInfo
                    requestCompanyDocsInfo={requestCompanyDocsInfo}
                    id={id}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <CompanyDocsAppReject
              requestCompanyDocsInfo={requestCompanyDocsInfo}
            />
          )}
        </>
      )}
    </>
  );
};

export default RequestSingleCompanyDocsInfo;
