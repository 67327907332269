import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { ICertificate, ISingleTrainee } from "./types/singleTraineeTypes";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { getStatusLabel } from "src/Utils/Combined";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

const TraineeCertificateList = ({
  traineeInfo,
}: {
  traineeInfo: ISingleTrainee;
}) => {
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/training/trainee/trainee-certificate/${traineeInfo?.user_training_trainee_id}`
        );
        if (data.success) {
          setCertificates(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [traineeInfo?.user_training_trainee_id]);

  return (
    <>
      <Card>
        <CardHeader title="Certificate list" />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">SL NO</TableCell>
                <TableCell align="center">Course Name</TableCell>
                <TableCell align="center">Certificate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : (
                <>
                  {certificates?.length ? (
                    <>
                      {certificates?.map((certificate, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            width={600}
                          >
                            {certificate?.atab_training_course_name}
                          </TableCell>
                          <TableCell align="center">
                            <CommonTypoGraphy
                              name="View"
                              onClick={() => {
                                SetImageViewOpen(true);
                                setImageFile(certificate?.c_photo);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>{" "}
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default TraineeCertificateList;
