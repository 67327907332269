import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const CommonInspectionView = ({
  serial,
  title,
  value,
}: {
  serial?: string;
  title: string;
  value: string | number;
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: value === 1 || value === 0 ? "space-between" : "",
        gap: 5,
        paddingBottom: "15px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        {serial ? (
          <Typography variant="h4" gutterBottom>
            {`${serial}.`}
          </Typography>
        ) : (
          ""
        )}

        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </div>
      {value === 1 || value === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid black",
            padding: "0px 10px",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{
              borderRight: "1px solid black",
              paddingRight: "3px",
              width: "45px",
              textAlign: "center",
            }}
          >
            হাঁ
            {value === 1 && <CheckIcon style={{ height: "16px" }} />}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            style={{ paddingLeft: "3px", width: "45px", textAlign: "center" }}
          >
            না
            {value === 0 && <CheckIcon style={{ height: "16px" }} />}
          </Typography>
          {/* <div style={{ paddingLeft: "3px" }}>না</div> */}
        </div>
      ) : (
        <Typography variant="h4" gutterBottom>
          {value}
        </Typography>
      )}
    </div>
  );
};

export default CommonInspectionView;
