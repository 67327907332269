import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useCreateArea } from "src/basic-rest/useFetchAddressData";
import { useState } from "react";
import { IAreaList } from "src/basic-rest/types";
import Toaster from "src/Toaster/Toaster";

export default function CreateArea({ thana_id, setArea }) {
  const { createAreaData, loading: submitLoading } = useCreateArea();
  const Toast = Toaster();
  const [value, setValue] = useState<string>("");

  const submit = async (e) => {
    e.preventDefault();
    if (!value) {
      Toast.fire({
        icon: "error",
        title: "Provide area name",
      });
      return;
    }
    const data = await createAreaData({ thana_id, area_name: value });

    if (data.success) {
      setValue("");
      setArea((area: IAreaList[]) => [
        ...area,
        {
          area_id: data.data.area_id,
          area_name: value,
          thana_id: area.length ? area[0].thana_id : thana_id,
          thana_name: area.length ? area[0].thana_name : "",
          district_id: area.length ? area[0].district_id : 2,
          district_name: area.length ? area[0].district_name : "",
          division_id: area.length ? area[0].division_id : 1,
          division_name: area.length ? area[0].division_name : "",
        },
      ]);
    }
  };

  return (
    <Stack
      component="form"
      sx={{
        width: "35ch",
        p: 2,
      }}
      spacing={2}
      noValidate
      autoComplete="off"
      onSubmit={submit}
    >
      <TextField
        id="test"
        label="Area Name"
        defaultValue="Small"
        size="small"
        required
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button disabled={submitLoading} type="submit" variant="contained">
        {submitLoading ? "Please wait" : "Create area"}
      </Button>
    </Stack>
  );
}
