export const API_ENDPOINTS = {
	LOGIN: '/login',
	REGISTER: '/register',
	LOGOUT: '/logout',
	FORGET_PASSWORD: '/forget-password',
	DIVISION: '/api/atab/address/division',
	DISTRICT: '/api/atab/address/district',
	THANA: '/api/atab/address/thana',
	AREA: '/api/atab/address/area',
};
