import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import axios from "axios";
import { ICourseList } from "src/Types/module/courses/courseList";
import { url } from "src/Helpers/utils/Constant";
import { ISingleTraineeApplication } from "src/Utils/type";

const RegisterForm = ({
  register,
  course,
  SetCourse,
}: {
  register: any;
  course: string;
  SetCourse: any;
}) => {
  const [allCourse, setAllCourse] = useState<ICourseList[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${url}/api/training/course/get/approved`
      );
      if (data.success) {
        setAllCourse(data.data);
      } else {
        setAllCourse([]);
      }
    })();
  }, []);

  return (
    <>
      <Box sx={{ pt: 2 }}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Select Course</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Course"
            placeholder="Select Course"
            value={course}
            required
            sx={{
              borderRadius: "10px",
              width: { xl: "115ch", md: "85ch", xs: "25ch" },
              height: 56,
            }}
            {...register("training_course_application_course_id")}
            onChange={(e) => SetCourse(String(e.target.value))}
          >
            {allCourse.map((sCourse) => {
              return (
                <MenuItem
                  value={sCourse.atab_training_course_id}
                  key={sCourse.atab_training_course_id}
                >
                  {sCourse.atab_training_course_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default RegisterForm;
