import { Button } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import moment from "moment";

interface Props {
  PDFFileName: string;
  fileHeader: string;
  PDFHeader: string[];
  PDFData: any;
  name?: string;
}

const CommonPdfGenerator: React.FC<Props> = ({
  PDFFileName,
  fileHeader,
  PDFHeader,
  PDFData,
  name,
}) => {
  const date_time = moment(new Date()).format("DD-MM-YYYY");
  const savePDF = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      format: "a4",
    });

    doc.setFontSize(10);
    doc.text(`Meeting Name: ${name}`, 14, 18, {
      align: "left",
    });
    doc.text(`Print Date: ${date_time}`, 14, 24, {
      align: "left",
    });

    doc.text(fileHeader, doc.internal.pageSize.getWidth() / 2, 10, {
      align: "center",
      renderingMode: "fill",
    });

    const tableRows = PDFData.map((obj: any) => Object.values(obj));
    autoTable(doc, {
      styles: { halign: "center" },
      headStyles: { fillColor: "#0079FF" },
      startY: 30,
      head: [PDFHeader],
      body: tableRows,
      theme: "grid",
      didDrawPage: ({ pageNumber }) => {
        doc.setFontSize(9);
        doc.text(
          `Page - ${pageNumber}`,
          doc.internal.pageSize.getWidth() / 2,
          doc.internal.pageSize.getHeight() - 10,
          { align: "center" }
        );
      },
    });

    doc.save(`${PDFFileName}.pdf`);
  };
  return (
    <>
      <Button onClick={savePDF} variant="outlined" size="small">
        Download pdf
      </Button>
    </>
  );
};

export default CommonPdfGenerator;
