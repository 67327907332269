import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { IBusinessLicense } from "src/Types/module/member/UpdateCompanyInfoStateTypes";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ICompanyBusinessLicense } from "src/Types/module/member/CompanyInformation";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
import { IResponse } from "src/Types/module/common/commonTypes";

interface IProps {
  businessLicense: ICompanyBusinessLicense;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}

const CompanyBusinessLicense = ({
  businessLicense,
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [businessLicenses, setBusinessLicense] = useState<IBusinessLicense>({
    hajj_license: businessLicense.hajj_license,
    umrah_license: businessLicense.umrah_license,
    recruting_license: businessLicense.recruting_license,
    outbound: businessLicense.outbound,
    inbound: businessLicense.inbound,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    // setIsLoading(true);
    inputsData = { ...inputsData, ...businessLicenses };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    try {
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/business-license/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(true);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid
        container
        spacing={3}
        p={2}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Other business license
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.hajj_license === 1 ? true : false
                    }
                  />
                }
                label="Hajj"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      hajj_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      hajj_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.umrah_license === 1 ? true : false
                    }
                  />
                }
                label="Umrah"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      umrah_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      umrah_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.recruting_license === 1 ? true : false
                    }
                  />
                }
                label="Recruiting"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicenses,
                      recruting_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicenses,
                      recruting_license: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Box>
            {businessLicenses.hajj_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Hajj license{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("hajj_license_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {businessLicenses.umrah_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Umrah license{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("umrah_license_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {businessLicenses.recruting_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Recruiting license
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("recruting_license_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Involved in tourism activities
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              pl: { xs: 5, md: 0 },
              // justifyContent: 'space-evenly',
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.inbound === 1 ? true : false
                    }
                  />
                }
                label=" Inbound"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      inbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      inbound: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      businessLicenses.outbound === 1 ? true : false
                    }
                  />
                }
                label="Outbound"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      outbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      outbound: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CompanyBusinessLicense;
