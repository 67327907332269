import React, { ReactNode, Dispatch, SetStateAction, useState } from "react";
import List from "@mui/material/List";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import { IAreaList } from "src/basic-rest/types";
import ListSubheader from "@mui/material/ListSubheader";
import { AddressLoading, AddressNotFound } from "./utils";
import { IGetID } from "./Address";
import { useFetchAreaData } from "src/basic-rest/useFetchAddressData";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateArea from "./create-address/CreateArea";
import { Box } from "@mui/system";

type IProps = {
  checked: IGetID;
  setChecked: Dispatch<SetStateAction<IGetID>>;
};

function ThanaList({ checked, setChecked }: IProps) {
  const [open, setOpen] = useState(false);
  const {
    data: areaData,
    fetchAreaData,
    loading,
    setData,
  } = useFetchAreaData();

  React.useEffect(() => {
    if (checked.division || checked.district || checked.thana) {
      fetchAreaData({
        district_id: checked.district.district_id,
        division_id: checked.division.division_id,
        thana_id: checked.thana.thana_id,
      });
    }
  }, [checked]);

  let content: ReactNode;
  if (loading) {
    content = <AddressLoading />;
  }
  if (!loading && areaData.length === 0) {
    content = <AddressNotFound />;
  }
  if (areaData.length > 0 && !loading) {
    content = (
      <Autocomplete
        getOptionLabel={(option: IAreaList) => option.area_name}
        id="combo-box-demo"
        options={areaData}
        renderOption={(params: any, option: IAreaList) => (
          <Box component="li" {...params}>
            {option.area_name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Select area" variant="outlined" />
        )}
        onChange={(_event, value: IAreaList) => {
          setChecked({ ...checked, area: value });
        }}
      />
    );
  }

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          // position: 'relative',
          overflow: "auto",
          maxHeight: 300,
          "& ul": { padding: 0 },
        }}
      >
        <ListSubheader
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            top: "-12px !important",
            padding: "10px",
          }}
        >
          <Typography variant="h4">Area list</Typography>
          <Button onClick={() => setOpen(true)} size="small">
            Create Area
          </Button>
        </ListSubheader>
        {content}
      </List>
      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title: "Create district",
        }}
      >
        <CreateArea thana_id={checked.thana.thana_id} setArea={setData} />
      </ModalsWrapper>
    </>
  );
}

export default ThanaList;
