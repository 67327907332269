import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Container,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";

import axios from "axios";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { ItraineeList } from "src/Types/module/courses/trainee";
import { applyPagination } from "src/Utils/CommonAllShowUtils";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Label from "src/components/Label";

const TrainingReport = () => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const [allMembers, setAllMembers] = useState<ItraineeList[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<string>("all");

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    (async function () {
      try {
        let uri: string;
        uri = `${url}/api/training/trainee/get/all/or/status/${status}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setAllMembers(data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [user.user_admin_id, status]);

  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "1",
      name: "Active",
    },

    {
      id: "0",
      name: "Rejected",
    },
  ];

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedMembers = applyPagination(allMembers, page, limit);
  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          action={
            <Box>
              <FormControl sx={{ width: "150px" }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  label="Status"
                  autoWidth
                  size="small"
                >
                  {statusOptions &&
                    statusOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Trainee Report List"
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Report</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableLoader />
              ) : allMembers.length ? (
                <>
                  {paginatedMembers.map((allMembers: ItraineeList) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {allMembers.user_training_trainee_id}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex" }}>
                            <img
                              width={30}
                              height={30}
                              style={{
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                              src={
                                allMembers.user_training_trainee_photo
                                  ? `${imgUrl}/training_trainee_files/${allMembers.user_training_trainee_photo}`
                                  : "/static/images/avatars/avatar2.png"
                              }
                              alt=""
                            />
                            <Link
                              to={`/training-trainee/details/${allMembers.user_training_trainee_id}`}
                            >
                              {allMembers.user_training_trainee_name
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </Link>
                          </Box>
                        </TableCell>

                        <TableCell>
                          {allMembers.user_training_trainee_account_status ? (
                            <Label color="success">Active</Label>
                          ) : (
                            <Label color="error">Blocked</Label>
                          )}
                        </TableCell>

                        <TableCell>
                          <Link
                            to={`/training-report/list/${allMembers.user_training_trainee_id}`}
                          >
                            <Tooltip title="view" arrow>
                              <IconButton
                                sx={{
                                  "&:hover": {
                                    background: theme.colors.primary.lighter,
                                  },
                                  color: theme.palette.primary.main,
                                }}
                                color="inherit"
                                size="small"
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={allMembers.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default TrainingReport;
