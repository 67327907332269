import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import { imgUrl } from "src/Helpers/utils/Constant";
import { ISingleEvent } from "src/Types/module/member/Event";
import EventUpdateForm from "./EventUpdateForm";
import EventJoinedMember from "./EventJoinedMember";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { useState } from "react";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

const BillingInfo = ({
  singleEvent,
  editMode,
  handleEditModeChange,
}: {
  singleEvent: ISingleEvent;
  editMode: boolean;
  handleEditModeChange: any;
}) => {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <div>
      <Box sx={{ mt: 3 }}>
        {!editMode ? (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Event Title:
              </Typography>
              {singleEvent?.member_event_name}
            </Grid>

            {singleEvent.member_event_remark && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Event Remark:
                </Typography>
                {singleEvent?.member_event_remark}
              </Grid>
            )}

            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Event Place:
              </Typography>
              {singleEvent?.member_event_location}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Event Creator:
              </Typography>
              {singleEvent?.member_event_created_by_name}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Event Status:
              </Typography>

              {getStatusLabel(singleEvent?.member_event_status)}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Event Start Date:
              </Typography>
              {moment(singleEvent?.member_event_start_date).format("LL")}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Event End Date:
              </Typography>
              {moment(singleEvent?.member_event_end_date).format("LL")}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Event created date:
              </Typography>
              {moment(singleEvent?.member_event_created_at).format("LL")}
            </Grid>

            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1, textAlign: "justify" }}
                gutterBottom
              >
                Event Image:
              </Typography>
              <CommonTypoGraphy
                name="View"
                onClick={() => {
                  SetImageViewOpen(true);
                  setImageFile(singleEvent?.member_event_cover_photo);
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1, textAlign: "justify" }}
                gutterBottom
              >
                Event details:
              </Typography>
              {singleEvent?.member_event_details}
            </Grid>
          </Grid>
        ) : (
          <EventUpdateForm
            handleEditModeChange={handleEditModeChange}
            singleEvent={singleEvent}
          />
        )}

        {/* Form input  */}

        <EventJoinedMember singleEvent={singleEvent} />
      </Box>

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </div>
  );
};

export default BillingInfo;
