import React from 'react';
import { Container, Grid } from '@mui/material';
import TeacherShow from './TeacherShow';

const TeacherList = () => {
  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <TeacherShow />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TeacherList;
