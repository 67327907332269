import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import { url } from "src/Helpers/utils/Constant";
import { Helmet } from "react-helmet-async";
import GlobalLoader from "../../../../components/SuspenseLoader/GlobalLoader";
import { ISingleFairEvent } from "src/Utils/type";
import SingleFairEventInfo from "../AllEventList/FairEventComponents/SingleFairEventInfo";
import SingleEventInfoBottom from "../AllEventList/FairEventComponents/SingleEventInfoBottom";

const SingleEvent = () => {
  const { eventId } = useParams();

  const [singleEvent, setSingleEvent] = useState<ISingleFairEvent | null>(
    {} as ISingleFairEvent
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);

  const handleEditModeChange = () => {
    setReload((prev) => !prev);
  };
  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${url}/api/fair/event/get/single/${eventId}`
        );

        if (data.success) {
          setSingleEvent(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [eventId, reload]);

  return (
    <div>
      <Helmet>
        <title>Single Event information</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!loading ? (
            <>
              {singleEvent && (
                <SingleFairEventInfo
                  handleEditModeChange={handleEditModeChange}
                  singleEvent={singleEvent}
                />
              )}
              <SingleEventInfoBottom loading={loading} eventId={eventId} />
            </>
          ) : (
            <GlobalLoader />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default SingleEvent;
