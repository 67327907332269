import { useState, useEffect } from "react";
import {
  Card,
  Container,
  CardContent,
  Divider,
  CardHeader,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { Helmet } from "react-helmet-async";
import SuspenseLoader from "src/components/SuspenseLoader";
import { ISingleMaterialDetails } from "src/Types/module/batch/allTypeOfBatch";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import SingleMaterialDetails from "./SingleMaterialDetails";

const SingleMaterial = () => {
  const [material, setMaterial] = useState<ISingleMaterialDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/training/batch/get/materials/single/${id}`;
        const { data } = await axios.get(uri);

        if (data.success) {
          setMaterial(data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [id]);

  return (
    <Box>
      <Helmet>
        <title>Atab - Single Material</title>
      </Helmet>
      {isLoading ? (
        <SuspenseLoader />
      ) : (
        <Container sx={{ mt: 4 }} maxWidth="lg">
          <Card>
            <CardHeader title={` ${material?.training_batch_name} materials`} />
            <Divider />
            <CardContent>
              {isLoading ? (
                <SuspenseLoader />
              ) : (
                <SingleMaterialDetails material={material} />
              )}
            </CardContent>
          </Card>
        </Container>
      )}
    </Box>
  );
};

export default SingleMaterial;
