import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Spinner from "src/components/Spinner/Spinner";
import {
  AUTH_USER_SUCCESS,
  IMAGE_CHANGE_SUCCESS,
  url,
} from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { ISingleAdmin, Iadmin } from "src/Types/module/admin/adminTypes";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import SingleDetailsinfo from "./SingleDetailsinfo";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { IRoleList } from "src/Types/module/roleAndPermission/roleAndPermissionType";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";

const SingleAdmin = () => {
  const { id } = useParams();
  const Toast = Toaster();
  const { register, handleSubmit } = useForm();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [singleAdmin, setSingleAdmin] = useState<ISingleAdmin>(
    {} as ISingleAdmin
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [zone, setZone] = React.useState<string>("");
  const [allRole, setAllRole] = useState<IRoleList[]>([]);
  const [role, setRole] = React.useState<number>(singleAdmin.user_admin_role);
  const [status, setStatus] = React.useState<string>("");
  const { user, dispatch } = useAuthContext();

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/atab/admin/get/single/admin/${id}`
        );

        if (data.success) {
          setSingleAdmin(data.data);
          setRole(data?.data?.user_admin_role);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id, showModal]);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/roles`)
          .then(function (res) {
            if (res.data.success) {
              setAllRole(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllRole([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);
  if (loading) {
    <Spinner />;
  }

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    // if (photo) {
    //   inputs.user_admin_photo = photo;
    // } else {
    //   inputs.user_admin_photo = singleAdmin.user_admin_photo;
    // }
    inputs.user_admin_status = status ? status : singleAdmin.user_admin_status;
    inputs.user_admin_id = singleAdmin.user_admin_id;
    inputs.user_admin_role = role;
    const bodyFormData = new FormData();
    for (const key in inputs) {
      if (inputs[key]) {
        if (
          key === "user_admin_photo" ||
          key === "user_admin_president_signature" ||
          key === "user_admin_secretary_signature" ||
          key === "user_admin_principle_signature"
        ) {
          if (inputs[key][0]) {
            bodyFormData.append(key, inputs[key][0]);
          }
        } else {
          bodyFormData.append(key, inputs[key]);
        }
      }
    }

    console.table(Object.fromEntries(bodyFormData));

    // inputs.user_admin_zone = zone ? zone : singleAdmin.user_admin_zone;
    // inputs.user_admin_status = status ? status : singleAdmin.user_admin_status;

    // const bodyFormData = new FormData();
    // Object.keys(inputs).forEach((data) => {
    //   if (inputs[data]) {
    //     bodyFormData.append(data, inputs[data]);
    //   }
    // });

    try {
      const res: any = await HttpClient.put(
        `/api/atab/admin/update/an/admin`,
        bodyFormData
      );

      if (res.success) {
        Toast.fire({
          icon: "success",
          title: `Admin Updated Successfully`,
        });
        if (singleAdmin.user_admin_id === user.user_admin_id) {
          dispatch?.({
            type: IMAGE_CHANGE_SUCCESS,
            payload: res.user_admin_photo,
          });
        }
        setLoading(false);
        setShowModal(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      Toast.fire({
        icon: "error",
        title: error?.response?.data?.message,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Atab - Single Admin</title>
      </Helmet>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" color="text.primary">
                Admin information
              </Typography>
              <Button
                onClick={() =>
                  singleAdmin.user_admin_id && setShowModal(!showModal)
                }
                variant="outlined"
              >
                Edit
              </Button>
            </Box>
            <SingleDetailsinfo singleAdmin={singleAdmin} />

            {/* modals wrapper */}

            <ModalsWrapper
              showModal={showModal}
              setShowModal={setShowModal}
              modalData={{ title: "Edit admin info " }}
            >
              <Box sx={{ p: 2 }}>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{
                    mt: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Admin Name"
                        defaultValue={singleAdmin?.user_admin_name}
                        {...register("user_admin_name")}
                        type="text"
                        autoFocus
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="phone"
                        label="Phone"
                        defaultValue={singleAdmin?.user_admin_phone}
                        {...register("user_admin_phone")}
                        type="number"
                        autoFocus
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="Email"
                        defaultValue={singleAdmin?.user_admin_email}
                        {...register("user_admin_email")}
                        type="email"
                        autoFocus
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={role}
                          label="role"
                          defaultValue={singleAdmin.user_admin_role}
                          onChange={(e) => {
                            setRole(Number(e.target.value));
                          }}
                        >
                          {allRole.map((sRole) => {
                            return (
                              <MenuItem
                                value={sRole.roles_id}
                                key={sRole.roles_id}
                              >
                                {sRole.roles_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Zone
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={zone ? zone : singleAdmin.user_admin_zone}
                          label="zone"
                          onChange={(e: any) => setZone(e.target.value)}
                        >
                          <MenuItem value={1}>Dhaka</MenuItem>
                          <MenuItem value={2}>Chittagong</MenuItem>
                          <MenuItem value={3}>Sylhet</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <FormControl margin="normal" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            status ? status : singleAdmin.user_admin_status
                          }
                          defaultValue={singleAdmin.user_admin_status}
                          label="status"
                          required
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="rejected">Rejected</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="file"
                        label="Admin Photo"
                        InputLabelProps={{ shrink: true }}
                        {...register("user_admin_photo")}
                        autoComplete="file"
                        type="file"
                      />
                    </Grid>

                    {role === 2 ? (
                      <>
                        {" "}
                        <Grid item xs={12} md={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="file"
                            label="Upload president signature"
                            InputLabelProps={{ shrink: true }}
                            {...register("user_admin_president_signature")}
                            InputProps={{
                              inputProps: {
                                accept: "image/jpeg,image/png,image/jpg",
                              },
                            }}
                            autoComplete="file"
                            type="file"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="file"
                            label="Upload secretary signature"
                            InputLabelProps={{ shrink: true }}
                            {...register("user_admin_secretary_signature")}
                            InputProps={{
                              inputProps: {
                                accept: "image/jpeg,image/png,image/jpg",
                              },
                            }}
                            autoComplete="file"
                            type="file"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="file"
                            label="Upload principle signature"
                            InputLabelProps={{ shrink: true }}
                            {...register("user_admin_principle_signature")}
                            InputProps={{
                              inputProps: {
                                accept: "image/jpeg,image/png,image/jpg",
                              },
                            }}
                            autoComplete="file"
                            type="file"
                          />
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>

                  {!loading ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                    >
                      Update Admin
                    </Button>
                  ) : (
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                      }}
                    >
                      Updating...
                    </LoadingButton>
                  )}
                </Box>
              </Box>
            </ModalsWrapper>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default SingleAdmin;
