import {
  Card,
  CardContent,
  Container,
  Typography,
  Box,
  Divider,
  Grid,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";

const CardNotice = ({ singleNotice }: any) => {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <Container>
      <Card className="invoice_print_form">
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "cneter",
              mb: 2,
            }}
          >
            <Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                gutterBottom
              >
                Notice
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pl: 1 }}
                gutterBottom
              >
                # {singleNotice?.member_notice_id}
              </Typography>
            </Box>
          </Box>
          <Divider />

          <>
            <Box sx={{ mt: 3 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Notice Title:
                  </Typography>
                  {singleNotice?.member_notice_title}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Notice Created by:
                  </Typography>
                  {singleNotice?.member_notice_created_by_name}
                </Grid>

                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Notice Created at:
                  </Typography>
                  {moment(singleNotice.member_notice_created_at).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <h4>
                    Notice file:{" "}
                    <CommonTypoGraphy
                      name="View"
                      onClick={() => {
                        SetImageViewOpen(true);
                        setImageFile(singleNotice?.member_notice_file);
                      }}
                    />
                  </h4>
                </Grid>
              </Grid>
            </Box>
          </>
        </CardContent>
      </Card>
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </Container>
  );
};

export default CardNotice;
