import { Divider, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { IMemberLedger } from "./memberLedgerReportTypes";

type IProps = {
  singleMemberLedgerData: IMemberLedger;
};
const MemberLedgerReportTableRow = ({ singleMemberLedgerData }: IProps) => {
  const {
    voucher_no,
    ledger_date,
    amount,
    type,
    tr_type,
    details,
    payment_method,
    particular,
  } = singleMemberLedgerData || {};

  return (
    <TableRow hover>
      <TableCell>{voucher_no}</TableCell>
      <TableCell>{moment(ledger_date).format("DD-MMM-YYYY")}</TableCell>
      <TableCell>{particular}</TableCell>
      <TableCell>{details}</TableCell>
      <TableCell>{tr_type}</TableCell>
      <TableCell>{payment_method}</TableCell>
      <TableCell>{type === "OUT" && amount}</TableCell>
      <TableCell>{type === "IN" && amount}</TableCell>
    </TableRow>
  );
};

export default MemberLedgerReportTableRow;
