import { RouterProvider } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import "../src/Styles/Login_reg.css";
import "../src/Styles/responsive.css";
import "../src/Styles/Dashboard.css";
import "../src/Styles/bind.css";
import "../src/Styles/index.css";
import { routes } from "./router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <RouterProvider router={routes} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
