import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Edit, Visibility } from "@mui/icons-material";

import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import Toaster from "src/Toaster/Toaster";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { HttpClient } from "src/Helpers/http/http";
import { IExpenseHeadType } from "../types/expenseTypes";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import UpdateExpenseHead from "./UpdateExpenseHead";

type IProps = {
  expenseHeadData: IExpenseHeadType;
  allExpenseHead: IExpenseHeadType[];
  setAllExpenseHead: any;
  setRefresh: any;
  index: number;
};
const ExpenseHeadTableRow = ({
  expenseHeadData,
  allExpenseHead,
  setAllExpenseHead,
  setRefresh,
  index,
}: IProps) => {
  const { expense_head_id, name, id } = expenseHeadData || {};
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  //   const handleDelete = (id: number) => {
  //     (async function () {
  //       try {
  //         setIsLoading(true);
  //         const uri = `${url}/api/atab/common-account/${id}`;
  //         const data = await HttpClient.delete(uri);

  //         if (data) {
  //           const filterData = allAccount.filter((item) => item.id !== id);

  //           setAllAccount(filterData);

  //           Toaster().fire({
  //             icon: "success",
  //             title: "Deleted successfully!",
  //           });

  //           setIsLoading(false);
  //           setOpen(false);
  //         }
  //       } catch (error) {
  //         setIsLoading(false);
  //       }
  //     })();
  //   };
  return (
    <TableRow hover>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{name}</TableCell>
      {/* <TableCell>
        <Tooltip title="Update" arrow>
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              "&:hover": {
                background: theme.colors.primary.lighter,
              },
              color: theme.palette.primary.main,
            }}
            color="inherit"
            size="small"
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete account" arrow>
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <DeleteConfirmation
          open={open}
          handleClose={() => setOpen(false)}
          text="Want to delete this account?"
          handleDeleteFun={() => handleDelete(id)}
          loading={isLoading}
        />
      </TableCell> */}
      <ModalsWrapper
        modalData={{ title: "Update Expense Head" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <UpdateExpenseHead
          setRefresh={setRefresh}
          handelClose={() => setOpen(false)}
          id={id}
          singleData={expenseHeadData}
        />
      </ModalsWrapper>
    </TableRow>
  );
};

export default ExpenseHeadTableRow;
