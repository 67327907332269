import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { singleVisitorInfo } from "src/Utils/type";
import { useNavigate } from "react-router-dom";

interface IProps {
  visitorInfo: singleVisitorInfo;
}

const SingleVisitorInfo = ({ visitorInfo }: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Card>
        <CardContent>
          <Typography
            textAlign="center"
            gutterBottom
            variant="h2"
            component="div"
          >
            Single visitor information
          </Typography>
          <Typography textAlign="center" variant="body2" color="text.secondary">
            {visitorInfo?.visitor_first_name} {visitorInfo?.visitor_last_name}
          </Typography>
          <Box sx={{ mx: 3, my: 4 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor ID:
                </Typography>
                {visitorInfo?.visitor_id}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor name:
                </Typography>
                {visitorInfo?.visitor_first_name}{" "}
                {visitorInfo?.visitor_last_name}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Gender:
                </Typography>
                {visitorInfo?.visitor_gender}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Email:
                </Typography>
                {visitorInfo?.visitor_email}
              </Grid>
              {visitorInfo.visitor_designation && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Visitor designation:
                  </Typography>
                  {visitorInfo.visitor_designation}
                </Grid>
              )}
              {visitorInfo.visitor_date_of_birth && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Created date:
                  </Typography>
                  {moment(visitorInfo.visitor_date_of_birth).format("LL")}
                </Grid>
              )}

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Contact number:
                </Typography>
                {visitorInfo.visitor_phone}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Education:
                </Typography>
                {visitorInfo.visitor_education}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  City:
                </Typography>
                {visitorInfo.visitor_city}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Organization:
                </Typography>
                {visitorInfo.visitor_organization}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor address:
                </Typography>
                {visitorInfo?.visitor_address}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor zip code:
                </Typography>
                {visitorInfo?.visitor_zip_code}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor profession:
                </Typography>
                {visitorInfo?.visitor_profession}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor type:
                </Typography>
                {visitorInfo?.visitor_type}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor website:
                </Typography>
                {visitorInfo?.visitor_website}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Visitor facebook:
                </Typography>
                {visitorInfo?.visitor_facebook}
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SingleVisitorInfo;
