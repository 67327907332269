import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Button,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import moment from "moment";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { useNavigate } from "react-router-dom";

const UpdateCompanyInfo = ({ requestCompanyInfo, companyID }: any) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const {
    member_company_name,
    member_company_type,
    member_company_stablished_date,
    member_company_status,
    member_company_verified_by,
    member_company_verified_date,
    member_company_created_at,
    member_company_website,
    member_company_logo,
    phone,
    cellphone,
    email,
    addressline1,
    addressline2,
    addressline3,
    postalcode,
    police_station,
    zone_name,
    area,
    thana,
    district,
    division,
  } = requestCompanyInfo || {};

  const handelAccept = async () => {
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/member/company/${companyID}?user_admin_id=${user.user_admin_id}`
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/company-information");
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "success",
        title: err?.response?.data.message,
      });
    }
  };
  const handelReject = async () => {
    setLoading(true);
    if (!rejectReason) {
      setLoading(false);
      return Toaster().fire({
        icon: "error",
        title: "Please enter reject reason",
      });
    }
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/member/company/${companyID}?confirmation_type=reject&user_admin_id=${user.user_admin_id}`,
        { reject_reason: rejectReason }
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/company-information");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4" fontSize={21}>
              Requested information
            </Typography>
          }
          action={
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button size="small" variant="outlined" onClick={handelAccept}>
                Accept request
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setOpen(true)}
              >
                Reject request
              </Button>
            </Box>
          }
        />

        <TableContainer>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Company name:
                </TableCell>
                <TableCell align="right">{member_company_name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company status:
                </TableCell>
                <TableCell align="right">{member_company_status}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company type:
                </TableCell>
                <TableCell align="right">{member_company_type}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company phone number:
                </TableCell>
                <TableCell align="right">{phone}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company cell phone number:
                </TableCell>
                <TableCell align="right">{cellphone}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company email:
                </TableCell>
                <TableCell align="right">{email}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company address 1:
                </TableCell>
                <TableCell align="right">{addressline1}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company address 2:
                </TableCell>
                <TableCell align="right">{addressline2}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company address 3:
                </TableCell>
                <TableCell align="right">{addressline3}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company website:
                </TableCell>
                <TableCell align="right">
                  <a href={member_company_website} target="_blank">
                    {member_company_website}
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company logo:
                </TableCell>
                <TableCell align="right">
                  <a
                    href={`${imgUrl}/member_${member_company_logo}`}
                    target="_blank"
                  >
                    {member_company_logo}
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company created date:
                </TableCell>
                <TableCell align="right">
                  {moment(member_company_created_at).format("Do MMM YYYY")}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company stablish date :
                </TableCell>
                <TableCell align="right">
                  {moment(member_company_stablished_date).format("Do MMM YYYY")}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company verify by:
                </TableCell>
                <TableCell align="right">
                  {member_company_verified_by}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company verify date:
                </TableCell>
                <TableCell align="right">
                  {moment(member_company_verified_date).format("Do MMM YYYY")}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company police station:
                </TableCell>
                <TableCell align="right">{police_station}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company post code:
                </TableCell>
                <TableCell align="right">{postalcode}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company zone name:
                </TableCell>
                <TableCell align="right">{zone_name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company division:
                </TableCell>
                <TableCell align="right">{division}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company district:
                </TableCell>
                <TableCell align="right">{district}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company thana:
                </TableCell>
                <TableCell align="right">{thana}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Company area:
                </TableCell>
                <TableCell align="right">{area}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <ModalsWrapper
        modalData={{ title: "Rejected reason" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <Box
          sx={{
            mx: 4,
          }}
        >
          <TextField
            margin="normal"
            required
            rows={4}
            fullWidth
            id="reason"
            label="Rejected reason"
            placeholder="Rejected reason"
            onChange={(e) => setRejectReason(e.target.value)}
            // {...register('user_member_company_name')}
            type="text"
            multiline
            autoFocus
          />

          {loading ? (
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading ...
            </Button>
          ) : (
            <Button
              onClick={handelReject}
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Submit
            </Button>
          )}
        </Box>
      </ModalsWrapper>
    </>
  );
};

export default UpdateCompanyInfo;
