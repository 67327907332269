import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Tooltip,
  useTheme,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { getStatusLabel } from "src/Utils/Combined";
import { ITradeLicense } from "src/Types/module/UpdateRequest/RequestTradeLicense";

interface IProps {
  tradeLicenseInfo: ITradeLicense[];

  isLoading: boolean;
}
const TradeLicenseIndex = ({ isLoading, tradeLicenseInfo }: IProps) => {
  const theme = useTheme();
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Company Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : tradeLicenseInfo.length ? (
              <>
                {tradeLicenseInfo.map((sTradeLicense) => {
                  return (
                    <TableRow hover key={sTradeLicense.request_id}>
                      <TableCell>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {sTradeLicense.member_company_id}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {sTradeLicense.member_company_name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {sTradeLicense.member_company_type}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {getStatusLabel(sTradeLicense.status)}
                      </TableCell>

                      <TableCell>
                        <Link
                          to={`/update-request/trade-license/${sTradeLicense.request_id}?memberid=${sTradeLicense.user_member_id}`}
                        >
                          <Tooltip title="view" arrow>
                            <IconButton
                              sx={{
                                "&:hover": {
                                  background: theme.colors.primary.lighter,
                                },
                                color: theme.palette.primary.main,
                              }}
                              color="inherit"
                              size="small"
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableNotFound />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TradeLicenseIndex;
