import React from "react";
import { useState } from "react";
import { Button, CssBaseline, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Lottie from "react-lottie";
import East from "@mui/icons-material/East";
import axios from "axios";
import { LottiedefaultOptions } from "src/Utils/Combined";
import Toaster from "src/Toaster/Toaster";
import { Helmet } from "react-helmet-async";
import { muiStyles } from "src/Utils/Styles";
import { url } from "src/Helpers/utils/Constant";

const useStyles = muiStyles;
const ChangePassword = () => {
  const Toast = Toaster();
  const classes = useStyles();
  const [password, setPassword] = useState<string>("");
  const [query] = useSearchParams();
  const email = query.get("email");
  const token = localStorage.getItem("otpToken");
  const defaultOptions = LottiedefaultOptions;
  const navigate = useNavigate();

  const handleOtpSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    try {
      const body = { email, token, password };
      const { data } = await axios.post(
        `${url}/api/auth/admin/forget/password`,
        body
      );

      if (data.success) {
        localStorage.setItem("otpToken", data.token);
        navigate(`/login`);
        Toast.fire({
          icon: "success",
          title: data.message,
        });
        localStorage.removeItem("otpToken");
      } else {
        Toast.fire({
          icon: "error",
          title: `Otp does not valid`,
        });
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err.response.data.message,
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Atab - Forget Password</title>
      </Helmet>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-right-col">
            <Container maxWidth="sm" component="main">
              <CssBaseline />

              <div className="lottie-img">
                <Lottie options={defaultOptions} height={300} width={300} />
              </div>
              <Box>
                {
                  <Box component="form" onSubmit={handleOtpSubmit}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      id="password"
                      label="Enter new password"
                      value={password}
                      onChange={(e: any) => setPassword(e.target.value)}
                      autoComplete="password"
                      autoFocus
                      variant="filled"
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                        background: "#1113a2",
                      }}
                      className={classes.signInButton}
                    >
                      Submit
                    </Button>
                  </Box>
                }
              </Box>

              <Grid container>
                <Grid item xs={12} sm={6} md={12}>
                  <Link to="/forget-password">
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                      className="text-main"
                    >
                      {"Go Back"}
                      <East sx={{ marginLeft: "5px" }} fontSize="small" />
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
