import TextField from "@mui/material/TextField";
import { Box, Button, Grid, FormHelperText } from "@mui/material";
import { useForm } from "react-hook-form";
import { ITrainingRegistration } from "src/Types/module/courses/trainee";
import { useState } from "react";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { HttpClient } from "src/Helpers/http/http";
const CreateTrainee = ({ handelClose, setAllTrainee }: any) => {
  //   const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<ITrainingRegistration>();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = async (value: any) => {
    setLoading(true);
    const formData = new FormData();

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        if (typeof value[key] === "object") {
          if (value[key][0]) formData.append(key, value[key][0]);
        } else {
          formData.append(key, value[key]);
        }
      }
    });

    try {
      const data: any = await HttpClient.post(
        `${url}/api/trainee/create`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `Trainee Created Successfully`,
        });
        setAllTrainee((prv) => [
          {
            user_training_trainee_id: data?.data?.user_training_trainee_id,
            user_training_trainee_name: value.user_training_trainee_name,
            user_training_trainee_email: value.user_training_trainee_email,
            user_training_trainee_photo:
              data?.data?.user_training_trainee_photo,
            user_training_trainee_account_status: 1,
          },
          ...prv,
        ]);
        handelClose();
        setLoading(false);
        reset();
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });

      setLoading(false);
    }
  };
  return (
    <div style={{ width: "600px" }}>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        noValidate
        autoComplete="off"
        p={2}
      >
        <Grid container spacing={2} columns={8}>
          <Grid item xs={12} md={4}>
            {" "}
            <TextField
              fullWidth
              id="outlined-basic"
              label="Name"
              required
              variant="outlined"
              {...register("user_training_trainee_name", {
                required: "Name is required",
              })}
              error={
                errors.user_training_trainee_name &&
                Boolean(errors.user_training_trainee_name)
              }
              helperText={
                Boolean(errors.user_training_trainee_name?.message)
                  ? "Name is required"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email"
              type="email"
              required
              variant="outlined"
              {...register("user_training_trainee_email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              error={
                errors.user_training_trainee_email &&
                Boolean(errors.user_training_trainee_email)
              }
              helperText={
                Boolean(errors.user_training_trainee_email?.message)
                  ? `${errors.user_training_trainee_email?.message}`
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-basic"
              label="Phone"
              type="number"
              variant="outlined"
              fullWidth
              required
              {...register("user_training_trainee_phone", {
                required: "Phone is required",
                pattern: {
                  value: /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/,
                  message: "Invalid phone number",
                },
              })}
              error={
                errors.user_training_trainee_phone &&
                Boolean(errors.user_training_trainee_phone)
              }
              helperText={
                Boolean(errors.user_training_trainee_phone?.message)
                  ? `${errors.user_training_trainee_phone?.message}`
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Upload photo"
              type="file"
              {...register("user_training_trainee_photo")}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              id="standard-password-input"
              label="Password"
              type={`${showPassword ? "text" : "password"}`}
              required
              {...register("user_training_trainee_password", {
                required: "Password is required",
              })}
              autoComplete="current-password"
              error={
                errors.user_training_trainee_password &&
                Boolean(errors.user_training_trainee_password)
              }
              helperText={
                Boolean(errors.user_training_trainee_password?.message)
                  ? "Password is required"
                  : ""
              }
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <FormControl
              fullWidth
              variant="outlined"
              error={Boolean(errors.user_training_trainee_password)}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                required
                {...register("user_training_trainee_password", {
                  required: "Password is required",
                })}
                autoComplete="current-password"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(
                        event: React.MouseEvent<HTMLButtonElement>
                      ) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ width: "20px" }} />
                      ) : (
                        <Visibility sx={{ width: "20px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              <FormHelperText>
                {errors.user_training_trainee_password?.message || ""}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: "center" }}>
          {" "}
          <Button
            type="submit"
            sx={{
              mt: 3,
              mb: 2,

              width: { xs: "15rem", md: "15rem" },
            }}
            // className={classes.signInButton}
            variant="contained"
            disabled={loading}
          >
            {!loading ? "Create" : "Loading..."}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CreateTrainee;
