import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
  Divider,
  Button,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { ISingleProposer } from "src/Types/module/UpdateRequest/RequestProposerInfo";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { useState } from "react";

interface IProps {
  id: string;
  requestProposerInfo: ISingleProposer[];
}

const UpdateProposerInfo = ({ id, requestProposerInfo }: IProps) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const handelAccept = async () => {
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/change/proposer/requester/${id}?user_admin_id=${user.user_admin_id}`
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/proposer-info");
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
    }
  };

  const handelReject = async () => {
    setLoading(true);
    if (!rejectReason) {
      setLoading(false);
      return Toaster().fire({
        icon: "error",
        title: "Please enter reject reason",
      });
    }
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/change/proposer/requester/${id}?confirmation_type=reject&user_admin_id=${user.user_admin_id}`,
        {
          reject_reason: rejectReason,
        }
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/proposer-info");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {requestProposerInfo.length &&
        requestProposerInfo.map((sProposer: ISingleProposer) => {
          return (
            <Card key={sProposer.request_id}>
              <CardHeader
                title={
                  <Typography variant="h4" fontSize={21}>
                    Requested information
                  </Typography>
                }
                action={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handelAccept}
                    >
                      Accept request
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => setOpen(true)}
                    >
                      Reject request
                    </Button>
                  </Box>
                }
              />
              <TableContainer sx={{ mb: 5 }}>
                <Table aria-label="custom pagination table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Company Name
                      </TableCell>
                      <TableCell align="right">
                        {sProposer?.proposer_request_to_company_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Representative Name
                      </TableCell>
                      <TableCell align="right">
                        {sProposer?.proposer_request_to_representative_name}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          );
        })}

      <ModalsWrapper
        modalData={{ title: "Rejected reason" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <Box
          sx={{
            mx: 4,
          }}
        >
          <TextField
            required
            rows={4}
            fullWidth
            id="reason"
            label="Rejected reason"
            placeholder="Rejected reason"
            onChange={(e) => setRejectReason(e.target.value)}
            type="text"
            multiline
            autoFocus
          />

          {loading ? (
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading ...
            </Button>
          ) : (
            <Button
              onClick={handelReject}
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Submit
            </Button>
          )}
        </Box>

        {/* <CreateMember setAllMembers={setAllMembers} handleClose={setOpen} /> */}
      </ModalsWrapper>
    </>
  );
};

export default UpdateProposerInfo;
