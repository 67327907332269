import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
  Button,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { ISingleOtherBusinessLicense } from "src/Types/module/UpdateRequest/RequestOtherBusinessLicense";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { useState } from "react";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";

interface IProps {
  id: number | string;
  requestOtherBusinessLicenseInfo: ISingleOtherBusinessLicense;
}

const UpdateOtherBusinessLicenseInfo = ({
  id,
  requestOtherBusinessLicenseInfo,
}: IProps) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const handelAccept = async () => {
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/member/association/${id}?user_admin_id=${user.user_admin_id}`
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/other-business-license");
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
    }
  };

  const handelReject = async () => {
    setLoading(true);
    if (!rejectReason) {
      setLoading(false);
      return Toaster().fire({
        icon: "error",
        title: "Please enter reject reason",
      });
    }
    try {
      const { data } = await axios.post(
        `${url}/api/atab/member-company/update-request/member/association/${id}?confirmation_type=reject&user_admin_id=${user.user_admin_id}`,
        { reject_reason: rejectReason }
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        navigate("/update-request/other-business-license");
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4" fontSize={21}>
              Requested information
            </Typography>
          }
          action={
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button size="small" variant="outlined" onClick={handelAccept}>
                Accept request
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setOpen(true)}
              >
                Reject request
              </Button>
            </Box>
          }
        />
        <TableContainer sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Membership of BAIRA
                </TableCell>
                <TableCell align="right">
                  {" "}
                  {requestOtherBusinessLicenseInfo?.baira_membership
                    ? "Yes"
                    : "No"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Certificate of BAIRA
                </TableCell>
                <TableCell align="right">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${requestOtherBusinessLicenseInfo.baira_membership_file}`}
                  >
                    {requestOtherBusinessLicenseInfo.baira_membership_file}
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Membership of HAAB
                </TableCell>
                <TableCell align="right">
                  {requestOtherBusinessLicenseInfo?.haab_membership
                    ? "Yes"
                    : "No"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Certificate of HAAB
                </TableCell>
                <TableCell align="right">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${requestOtherBusinessLicenseInfo.haab_membership_file}`}
                  >
                    {requestOtherBusinessLicenseInfo.haab_membership_file}
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Membership of IATA
                </TableCell>
                <TableCell align="right">
                  {requestOtherBusinessLicenseInfo?.iata_membership
                    ? "Yes"
                    : "No"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Certificate of IATA
                </TableCell>
                <TableCell align="right">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${requestOtherBusinessLicenseInfo.iata_membership_file}`}
                  >
                    {requestOtherBusinessLicenseInfo.iata_membership_file}
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Membership of TDAB
                </TableCell>
                <TableCell align="right">
                  {requestOtherBusinessLicenseInfo?.tdab_membership
                    ? "Yes"
                    : "No"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Certificate of TDAB
                </TableCell>
                <TableCell align="right">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${requestOtherBusinessLicenseInfo.tdab_membership_file}`}
                  >
                    {requestOtherBusinessLicenseInfo.tdab_membership_file}
                  </a>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Membership of TOAB
                </TableCell>
                <TableCell align="right">
                  {requestOtherBusinessLicenseInfo?.toab_membership
                    ? "Yes"
                    : "No"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Certificate of TOAB
                </TableCell>
                <TableCell align="right">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${requestOtherBusinessLicenseInfo.toab_membership_file}`}
                  >
                    {requestOtherBusinessLicenseInfo.toab_membership_file}
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <ModalsWrapper
        modalData={{ title: "Rejected reason" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <Box
          sx={{
            mx: 4,
          }}
        >
          <TextField
            required
            rows={4}
            fullWidth
            id="reason"
            label="Rejected reason"
            placeholder="Rejected reason"
            onChange={(e) => setRejectReason(e.target.value)}
            type="text"
            multiline
            autoFocus
          />

          {loading ? (
            <Button
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading ...
            </Button>
          ) : (
            <Button
              onClick={handelReject}
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Submit
            </Button>
          )}
        </Box>

        {/* <CreateMember setAllMembers={setAllMembers} handleClose={setOpen} /> */}
      </ModalsWrapper>
    </>
  );
};

export default UpdateOtherBusinessLicenseInfo;
