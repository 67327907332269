import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useWatch } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
const DynamicInvoiceField = ({
  register,
  allPaymentableItem,
  index,
  item,
  control,
  remove,
  fields,
  setValue,
}: any) => {
  const itemID = useWatch({
    control,
    name: `items.${index}.id`,
  });

  useEffect(() => {
    const findPaymentItem = allPaymentableItem?.find(
      (item) => item?.atab_paymentable_item_id === Number(itemID)
    );

    if (findPaymentItem?.atab_paymentable_item_id) {
      setValue(
        `items.${index}.amount`,
        findPaymentItem?.atab_paymentable_item_bdt_amount
      );
    } else {
      setValue(`items.${index}.amount`, 0);
    }
  }, [itemID]);
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginBottom: "14px" }}
      >
        <Grid item xs={12} md={12} lg={4}>
          <FormControl sx={{ mt: 1 }} fullWidth required margin="normal">
            <InputLabel id="demo-simple-select-label">Select Item</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              {...register(`items.${index}.id`)}
              label="Expense Head"
            >
              {allPaymentableItem?.map((item) => {
                return (
                  <MenuItem
                    key={item.atab_paymentable_item_id}
                    value={item.atab_paymentable_item_id}
                  >
                    {item.atab_paymentable_item_name} (
                    {item.atab_paymentable_item_bdt_amount} bdt)
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={2}>
          <TextField
            fullWidth
            required
            // defaultValue={0}
            label="Amount"
            type="number"
            variant="outlined"
            {...register(`items.${index}.amount`)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* {item.atab_paymentable_item_id == 2 && ( */}
        {itemID === 2 && (
          <Grid item xs={12} md={12} lg={5}>
            <Controller
              control={control}
              name={`items.${index}.year`}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  options={Array.from({ length: 100 }, (_, i) => {
                    const year = 2000 + i;
                    return { label: `${year}`, value: `${year}` };
                  })}
                  getOptionLabel={(option) => option.label}
                  value={field.value || []}
                  onChange={(e, newValue) => {
                    const uniqueValues = Array.from(
                      new Set(newValue.map((item) => item.value))
                    ).map((value) =>
                      newValue.find((item) => item.value === value)
                    );

                    field.onChange(uniqueValues);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select years"
                      placeholder="More..."
                    />
                  )}
                />
              )}
            />
          </Grid>
        )}

        {/* )} */}
        {fields.length > 1 && (
          <Grid item xs={1}>
            <IconButton onClick={() => remove(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DynamicInvoiceField;
