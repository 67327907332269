import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ITeacher } from "src/Types/module/teacher/teacherTypes";
import { imgUrl } from "../../../../../Helpers/utils/Constant";
import { Link } from "react-router-dom";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  teacher: ITeacher;
}
const TeacherTableRow = ({ teacher }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {teacher?.training_teacher_id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {teacher?.training_teacher_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              width={30}
              height={30}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
              }}
              src={
                teacher?.training_teacher_photo
                  ? `${imgUrl}/${teacher?.training_teacher_photo}`
                  : "/static/images/avatars/avatar2.png"
              }
              alt=""
            />
          </Box>
        </TableCell>

        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {getStatusLabel(teacher.training_teacher_status)}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            to={`/training-teacher/details/${teacher?.training_teacher_id.toString()}`}
          >
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TeacherTableRow;
