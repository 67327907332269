import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Toaster from "src/Toaster/Toaster";
import { url } from "src/Helpers/utils/Constant";
import { HttpClient } from "src/Helpers/http/http";
import { IResponse } from "src/Types/module/common/commonTypes";
import AddressSelect from "../../subModules/memberList/singleMember/AddressSelect/AddressSelect";

interface Props {
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}
const OrganizationInfo = ({
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: Props) => {
  const { register, handleSubmit } = useForm();
  const [date, setDate] = useState("");
  const [zone, setZone] = useState<number>(1);
  const [companyLogo, setCOmpanyLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [areaID, setAreaID] = useState<number>(0);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    data.stablished_date = date;
    data.zone = zone;
    data.area_id = areaID;

    const formData = new FormData();
    formData.append("company_logo", companyLogo);
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    try {
      const data: IResponse = await HttpClient.post(
        `/api/atab/admin/member-company/member/company/${memberId}`,
        formData
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setOpen(false);
        setRefresh(!refresh);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });

      setIsLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <Box mt={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="demo-simple-select-label">
                  Select zone
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={zone}
                  label="Select zone"
                  onChange={(e: any) => setZone(e.target.value)}
                >
                  <MenuItem value={1}>Dhaka</MenuItem>
                  <MenuItem value={2}>Chittagong</MenuItem>
                  <MenuItem value={3}>Sylhet</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={2}>
              <label htmlFor="">
                Organization type <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                // label="Enter organization type"
                defaultValue={"Travel Agency"}
                inputProps={{
                  readOnly: true,
                }}
                type="text"
                fullWidth
                {...register("company_type")}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor="">
                {" "}
                Organization established date{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="date"
                type="date"
                required
                onChange={(e) => setDate(e.target.value)}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor="">
                {" "}
                Which police station does the organization belong to?{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Enter organization police station"
                type="text"
                required
                fullWidth
                {...register("police_station")}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box>
              <label htmlFor="">
                {" "}
                Organization telephone number{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                label="Enter organization telephone number"
                fullWidth
                {...register("cellphone")}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor="">
                Organization mobile number
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                {...register("phone")}
                placeholder="Enter organization mobile number"
                type="number"
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor="">
                Organization full address
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Enter organization full address"
                type="text"
                required
                fullWidth
                {...register("addressline1")}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor="">
                Organization postal code
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Enter organization postal code"
                type="text"
                required
                fullWidth
                {...register("postalcode")}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Box>
              <label htmlFor="">
                Upload organization logo
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="file"
                fullWidth
                onChange={(e: any) => setCOmpanyLogo(e.target.files[0])}
                // {...register('company_logo')}
              />
            </Box>

            <Box mt={2}>
              <label htmlFor="">
                Organization email address
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter organization email address"
                type="email"
                fullWidth
                {...register("email")}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor=""> Organization website</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Enter organization website"
                type="url"
                fullWidth
                {...register("website")}
              />
            </Box>
          </Box>
        </Grid>
        <AddressSelect setAreaID={setAreaID} />
      </Grid>
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OrganizationInfo;
