import { Visibility } from "@mui/icons-material";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";

import { Link } from "react-router-dom";
import { ITrainingInvoiceItem } from "src/Utils/type";

type IProps = {
  trainingInvoiceItem: ITrainingInvoiceItem;
};

const TrainingInvoiceItemTableRow = ({ trainingInvoiceItem }: IProps) => {
  const theme = useTheme();

  return (
    <>
      {trainingInvoiceItem && (
        <TableRow hover>
          <TableCell>
            {trainingInvoiceItem.training_paymentable_item_id}
          </TableCell>
          <TableCell
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "35rem",
            }}
          >
            {trainingInvoiceItem.training_paymentable_item_name}
          </TableCell>
          <TableCell>
            {trainingInvoiceItem.training_paymentable_item_bdt_amount}
          </TableCell>
          <TableCell>
            {trainingInvoiceItem.training_paymentable_item_usd_amount}
          </TableCell>
          <TableCell>
            <Tooltip title="View" arrow>
              <Link
                to={`/training-invoice-item/list/details/${trainingInvoiceItem.training_paymentable_item_id}`}
              >
                <IconButton
                  sx={{
                    "&:hover": {
                      background: theme.colors.primary.lighter,
                    },
                    color: theme.palette.primary.main,
                  }}
                  color="inherit"
                  size="small"
                >
                  <Visibility fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TrainingInvoiceItemTableRow;
