import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
} from "@mui/material";
import { ICompanyProposer } from "src/Types/module/member/CompanyInformation";
import moment from "moment";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";
import { useState } from "react";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";

interface IProps {
  sProposer: ICompanyProposer;
  setUpdateModal: (value: boolean) => void;
  setSingleProposer: Function;
}
const ShowProposer = ({
  sProposer,
  setUpdateModal,
  setSingleProposer,
}: IProps) => {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <TableContainer component={Paper} sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody key={sProposer.proposer_request_id}>
              <TableRow>
                <TableCell component="th" scope="row">
                  Proposer company name
                </TableCell>
                <TableCell align="right">
                  {sProposer.proposer_request_to_company_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Proposer seal and signature
                </TableCell>
                <TableCell align="right">
                  <CommonTypoGraphy
                    name="View"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(sProposer?.proposer_seal_signature);
                    }}
                  />
                </TableCell>
              </TableRow>

              {/* <TableRow>
              <TableCell component="th" scope="row">
                Proposer status
              </TableCell>
              <TableCell align="right">
                {getStatusLabel(sProposer.proposer_request_status)}
              </TableCell>
            </TableRow> */}
              <TableRow>
                <TableCell component="th" scope="row">
                  Proposer request date
                </TableCell>
                <TableCell align="right">
                  {moment(sProposer.proposer_request_date).format(
                    "Do MMM YYYY"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <Box
                    sx={{
                      position: "absolute",
                      top: -30,
                      right: 0,
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={
                        <ModeEditOutlineOutlinedIcon fontSize="small" />
                      }
                      onClick={() => {
                        setUpdateModal(true);
                        setSingleProposer(sProposer);
                      }}
                    >
                      Update
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <>
        <PdfAndImageView
          setOpen={SetImageViewOpen}
          open={imageViewOpen}
          file={imageFile}
        />
      </>
    </>
  );
};

export default ShowProposer;
