import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { imgUrl } from "src/Helpers/utils/Constant";
import { ISingleAdmin } from "src/Types/module/admin/adminTypes";
import { getStatusLabel } from "src/Utils/Combined";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";

const SingleDetailsinfo = ({ singleAdmin }: { singleAdmin: ISingleAdmin }) => {
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  const {
    user_admin_email,
    user_admin_photo,
    user_admin_role_name,
    user_admin_status,
    user_admin_phone,
    user_admin_name,
    user_admin_zone_name,
    user_admin_president_signature,
    user_admin_secretary_signature,
    user_admin_principle_signature,
  } = singleAdmin || {};
  return (
    <>
      {" "}
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={{ lg: 5, md: 3, sm: 2 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
        >
          <Grid lg={4} item>
            <img
              width={150}
              height={200}
              style={{
                borderRadius: "10px",
              }}
              src={
                user_admin_photo
                  ? `${imgUrl}/${user_admin_photo}`
                  : "/static/images/avatars/avatar2.png"
              }
              alt=""
            />
          </Grid>
          <Grid lg={8} sm={4} md={4} item>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Name:
                </Typography>
                {user_admin_name}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Role:
                </Typography>
                {user_admin_role_name}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Status:
                </Typography>
                {getStatusLabel(user_admin_status)}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Phone:
                </Typography>
                {user_admin_phone}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Email:
                </Typography>
                {user_admin_email}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Zone:
                </Typography>
                <Button>{user_admin_zone_name}</Button>
              </Grid>

              {user_admin_president_signature ? (
                <Grid item xs={12} sm={6} md={6}>
                  <CommonTypoGraphy
                    name="View president signature"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(user_admin_president_signature);
                    }}
                  />
                </Grid>
              ) : (
                ""
              )}
              {user_admin_secretary_signature ? (
                <Grid item xs={12} sm={6} md={6}>
                  <CommonTypoGraphy
                    name="View secretary signature"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(user_admin_secretary_signature);
                    }}
                  />
                </Grid>
              ) : (
                ""
              )}
              {user_admin_principle_signature ? (
                <Grid item xs={12} sm={6} md={6}>
                  <CommonTypoGraphy
                    name="View principle signature"
                    onClick={() => {
                      SetImageViewOpen(true);
                      setImageFile(user_admin_principle_signature);
                    }}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>{" "}
      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
};

export default SingleDetailsinfo;
