import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, TextField } from "@mui/material";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { muiStyles } from "src/Utils/Styles";
import Toaster from "src/Toaster/Toaster";
import { url } from "src/Helpers/utils/Constant";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { LoadingButton } from "@mui/lab";
const useStyles = muiStyles;

interface IProps {
  handleClose: Function;
  setTrainingInvoiceItem: Function;
}

const CreateTrainingInvoiceItem = ({
  handleClose,
  setTrainingInvoiceItem,
}: IProps) => {
  const classes = useStyles();
  const Toast = Toaster();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (inputs: any) => {
    setIsLoading(true);
    if (user.user_admin_id) {
      try {
        const {
          training_paymentable_item_name,
          training_paymentable_item_bdt_amount,
          training_paymentable_item_usd_amount,
        } = inputs;

        const { data } = await axios.post(
          `${url}/api/training/payment/create/paymentable/item?user_admin_id=${user.user_admin_id}`,
          {
            training_paymentable_item_name,
            training_paymentable_item_bdt_amount,
            training_paymentable_item_usd_amount,
          }
        );

        if (data.success) {
          setIsLoading(false);
          setTrainingInvoiceItem((prev) => [
            {
              training_paymentable_item_id:
                data.data.training_paymentable_item_id,
              training_paymentable_item_name: training_paymentable_item_name,
              training_paymentable_item_bdt_amount:
                training_paymentable_item_bdt_amount,
              training_paymentable_item_usd_amount:
                training_paymentable_item_usd_amount,
            },
            ...prev,
          ]);
          Toast.fire({
            icon: "success",
            title: `Successfully created`,
          });
          reset();
          handleClose();
        }
      } catch (err: any) {
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };
  return (
    <>
      <Helmet>Create training invoice Item</Helmet>

      <Box sx={{ p: 2 }} className="">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <div className="form-grid">
            <TextField
              margin="normal"
              required
              fullWidth
              {...register("training_paymentable_item_name")}
              label="Training invoice item name"
              variant="filled"
              type="text"
              className={classes.inputText}
              InputProps={{ classes }}
            />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Training invoice item BDT amount"
                {...register("training_paymentable_item_bdt_amount")}
                type="number"
                variant="filled"
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Training invoice item USD amount"
                {...register("training_paymentable_item_usd_amount")}
                type="number"
                variant="filled"
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
          </Grid>
          {isLoading ? (
            <LoadingButton
              loading={isLoading}
              fullWidth
              color="secondary"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant="contained"
            >
              Creating...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create training invoice item
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateTrainingInvoiceItem;
