import React from "react";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import { IFairAllEvents } from "src/Utils/type";
import { getStatusLabel } from "src/Utils/Combined";

interface IProps {
  sEvent: IFairAllEvents;
}

const EventTableRow = ({ sEvent }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover key={sEvent.event_id}>
        <TableCell align="center">
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {sEvent.event_id}
          </Typography>
        </TableCell>
        <TableCell
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "25rem",
          }}
        >
          {sEvent.event_title
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </TableCell>
        <TableCell>{sEvent.event_venu}</TableCell>
        <TableCell>{moment(sEvent.event_date).format("MMM Do YY")}</TableCell>
        <TableCell>
          {moment(sEvent.event_start_time, "HH:mm").format("LT")}
        </TableCell>
        <TableCell>
          {moment(sEvent.event_end_time, "HH:mm").format("LT")}
        </TableCell>
        <TableCell>{getStatusLabel(sEvent.event_status)}</TableCell>

        <TableCell>
          <Link to={`/fair-event/all-event-list/${sEvent.event_id.toString()}`}>
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default EventTableRow;
