import { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { ISingleProposer } from "src/Types/module/UpdateRequest/RequestProposerInfo";
import UpdateProposerInfo from "./UpdateProposerInfo";
import CurrentProposerInfo from "./CurrentProposerInfo";
import { useParams, useSearchParams } from "react-router-dom";
import ProposerAppReject from "./ProposerAppReject";

const RequestSingleProposerInfo = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const memberID = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestProposerInfo, setRequestProposerInfo] = useState<
    ISingleProposer[]
  >([]);
  const [preProposerInfo, setPreProposerInfo] = useState<ISingleProposer[]>([]);

  const urls = [
    `${url}/api/atab/member-company/update-request/get/proposer/for/requester/status/${id}`,
    `${url}/api/atab/member-company/get/proposer/for/requester/status/${memberID}/all`,
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const data = await Promise.all(urls.map((url) => axios.get(url)));
        if (data?.length) {
          setRequestProposerInfo(data[0]?.data?.data);
          setPreProposerInfo(data[1]?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestProposerInfo.map((info, index) => {
            return info.status === "pending" ? (
              <Container maxWidth="xl" sx={{ mt: 5 }} key={index}>
                <Grid sx={{ pb: 5 }} container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CurrentProposerInfo preProposerInfo={preProposerInfo} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <UpdateProposerInfo
                      requestProposerInfo={requestProposerInfo}
                      id={id}
                    />
                  </Grid>
                </Grid>
              </Container>
            ) : (
              <ProposerAppReject requestProposerInfo={requestProposerInfo} />
            );
          })}
        </>
      )}
    </>
  );
};

export default RequestSingleProposerInfo;
