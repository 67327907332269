import React, { useState } from "react";
import { Box, Grid, FormControlLabel, Button, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { IAssociationMember } from "src/Types/module/member/UpdateCompanyInfoStateTypes";
import { url } from "src/Helpers/utils/Constant";
import { IOtherBusinessLicense } from "src/Types/module/member/CompanyInformation";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
import { IResponse } from "src/Types/module/common/commonTypes";

interface IProps {
  otherBusiness: IOtherBusinessLicense;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}

const UpdateCompanyOtherBusinessLicense = ({
  memberId,
  otherBusiness,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [associationMember, setAssociationMember] =
    useState<IAssociationMember>({
      baira_membership: otherBusiness.baira_membership,
      haab_membership: otherBusiness.haab_membership,
      iata_membership: otherBusiness.iata_membership,
      toab_membership: otherBusiness.toab_membership,
      tdab_membership: otherBusiness.tdab_membership,
    });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    setIsLoading(true);

    inputsData = { ...inputsData, ...associationMember };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    try {
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/association/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2} p={4}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.baira_membership === 1 ? true : false
                    }
                  />
                }
                label="BAIRA"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      baira_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      baira_membership: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.haab_membership === 1 ? true : false
                    }
                  />
                }
                label="HAAB"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      haab_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      haab_membership: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            {associationMember.baira_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association BAIRA document{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("baira_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {associationMember.haab_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association HAAB document{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("haab_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.iata_membership === 1 ? true : false
                    }
                  />
                }
                label="IATA"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      iata_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      iata_membership: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.toab_membership === 1 ? true : false
                    }
                  />
                }
                label="TOAB"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      toab_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      toab_membership: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            {associationMember.iata_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association IATA document{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("iata_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {associationMember.toab_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association TOAB document{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("toab_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ pl: { xs: 12, md: 0 } }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={
                    associationMember.tdab_membership === 1 ? true : false
                  }
                />
              }
              label="TDAB"
              onClick={(e: any) => {
                if (e.target.checked) {
                  setAssociationMember({
                    ...associationMember,
                    tdab_membership: 1,
                  });
                } else {
                  setAssociationMember({
                    ...associationMember,
                    tdab_membership: 0,
                  });
                }
              }}
            />
          </Box>
          <Box>
            {associationMember.tdab_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association TDAB document{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("tdab_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyOtherBusinessLicense;
