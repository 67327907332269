import { LoadingButton } from "@mui/lab";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { visitorSingleInvoiceInfo } from "src/Utils/type";

type IProps = {
  singleInvoiceDetails: visitorSingleInvoiceInfo;
  handleEditModeChange: Function;
};

function InvoiceUpdateForm({
  singleInvoiceDetails,
  handleEditModeChange,
}: IProps) {
  const { register, handleSubmit, reset } = useForm();
  const [isSuccess, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [getPaymentMethod, setGetPaymentMethod] = useState(null);
  const { user } = useAuthContext();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    new Date(
      singleInvoiceDetails.visitor_payment_invoice_transaction_date &&
        singleInvoiceDetails.visitor_payment_invoice_transaction_date
    )
  );
  const Toast = Toaster();
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(new Date(event.target.value));
  };
  const currencies = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "paid",
      label: "Paid",
    },
    {
      value: "cancelled",
      label: "Cancelled",
    },
    {
      value: "reported",
      label: "Reported",
    },
  ];

  const paymentMethod = [
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "SSL Commerz",
      label: "SSL Commerz",
    },
    {
      value: "Instrument",
      label: "Instrument",
    },
    {
      value: "Pay Order",
      label: "Pay Order",
    },
  ];

  const onSubmit = async (inputs: any) => {
    if (singleInvoiceDetails.visitor_payment_invoice_id && user.user_admin_id) {
      try {
        setLoading(true);
        const bodyFormData = new FormData();
        for (const key in inputs) {
          bodyFormData.append(key, inputs[key]);
        }
        bodyFormData.append(
          "visitor_payment_invoice_transaction_id",
          singleInvoiceDetails.visitor_payment_invoice_transaction_id
        );
        bodyFormData.append(
          "visitor_payment_invoice_updated_by",
          user.user_admin_id.toString()
        );
        bodyFormData.append(
          "visitor_payment_invoice_payment_method",
          getPaymentMethod
        );
        bodyFormData.append("visitor_payment_invoice_invoice_file", uploadFile);
        bodyFormData.append(
          "visitor_payment_invoice_transaction_date",
          selectedDate.toISOString().slice(0, 10)
        );
        const { data } = await axios.put(
          `${url}/api/fair/visitor/for-admin/visitor-invoice-update/${singleInvoiceDetails.visitor_payment_invoice_id}`,
          bodyFormData
        );
        if (data.success) {
          Toast.fire({
            icon: "success",
            title: `Invoice Update Successfully`,
          });
          setSuccess(true);
          reset();
          setLoading(false);
        }
      } catch (err: any) {
        Toast.fire({
          icon: "error",
          title: `${err.response?.data?.message}`,
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleEditModeChange();
    }
  }, [isSuccess]);

  return (
    <Container maxWidth="md">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          "& .MuiTextField-root": {
            m: 1,
            width: "35ch",
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid lg={4} item>
            <TextField
              id="filled-select-currency"
              select
              size="medium"
              label="Select Payment Method"
              value={getPaymentMethod}
              onChange={(e) =>
                setGetPaymentMethod(e.target.value.toLowerCase())
              }
              variant="outlined"
              fullWidth
              sx={{ width: "100%" }}
            >
              {paymentMethod.map((option) => (
                <MenuItem key={option.value} value={option.value.toLowerCase()}>
                  {option.label.toLowerCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid lg={4} item>
            <TextField
              id="filled-select-currency"
              select
              size="medium"
              label="Select status"
              defaultValue={singleInvoiceDetails?.visitor_payment_invoice_status.toLowerCase()}
              {...register("visitor_payment_invoice_status")}
              variant="outlined"
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value.toLowerCase()}>
                  {option.label.toLowerCase()}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid lg={4} item>
            <TextField
              label="Transaction ID:"
              id="filled-size-small"
              defaultValue={
                singleInvoiceDetails?.visitor_payment_invoice_transaction_id
              }
              variant="outlined"
              size="medium"
              type="number"
              {...register("member_payment_invoice_transaction_id")}
            />
          </Grid>
          <Grid lg={4} item>
            <TextField
              id="filled-size-small"
              variant="outlined"
              type="file"
              label="Payment Invoice File"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: any) => setUploadFile(e.target.files[0])}
            />
          </Grid>
          <Grid lg={4} item>
            <TextField
              label="Select invoice Date"
              type="date"
              value={selectedDate && selectedDate?.toISOString().slice(0, 10)}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid lg={12} item>
            {!loading ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{ mt: 1, py: 1.5, width: "313px", ml: 1 }}
              >
                Update Invoice
              </Button>
            ) : (
              <LoadingButton
                disabled
                fullWidth
                variant="contained"
                disableElevation
                sx={{ mt: 1, py: 1.5, width: "313px", ml: 1 }}
              >
                Updating...
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default InvoiceUpdateForm;
