import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { url } from "src/Helpers/utils/Constant";
import CardInvoice from "./CardInvoice";
import { visitorSingleInvoiceInfo } from "src/Utils/type";

const SingleVisitorInvoicePage = () => {
  const [singleInvoiceInfo, setSingleInvoiceInfo] =
    useState<visitorSingleInvoiceInfo | null>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const { visitorInvoiceId } = useParams();
  visitorInvoiceId;
  const [editMode, setEditMode] = useState(false);
  const handleEditModeChange = () => {
    setEditMode((prev) => !prev);
  };
  singleInvoiceInfo;

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const { data } = await axios.get(
          `${url}/api/fair/visitor//for-admin/visitor-invoice/get/${visitorInvoiceId}/all`
        );

        if (data.success) {
          setSingleInvoiceInfo(data.data[0]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setSingleInvoiceInfo(null);
      }
    })();
  }, [visitorInvoiceId, editMode]);

  return !loading ? (
    singleInvoiceInfo ? (
      <>
        <Helmet>
          <title>Atab - Single visitor invoice</title>
        </Helmet>

        <Container sx={{ mt: 8 }} maxWidth="lg">
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
          >
            <Grid>
              <CardInvoice
                handleEditModeChange={handleEditModeChange}
                singleInvoiceInfo={singleInvoiceInfo}
                editMode={editMode}
              />
            </Grid>
          </Grid>
        </Container>
      </>
    ) : (
      <Typography textAlign="center" variant="h2">
        Not found
      </Typography>
    )
  ) : (
    <GlobalLoader />
  );
};

export default SingleVisitorInvoicePage;
