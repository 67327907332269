import React, { useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Container,
} from "@mui/material";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import TrainingReportExcelGen from "../TrainingReportExcelGen";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { IBatchID, IReport } from "src/Types/module/trainingReport";

const BatchWiseReport = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    batch: "",
    limit: "",
    page: "",
  });
  const [batchReport, setBatchReport] = useState<IReport[]>([]);
  const [batchID, setBatchID] = useState<IBatchID[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const batch = searchParams.get("batch");
  const page = searchParams.get("page");
  const limit = searchParams.get("limit") || 25;

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `${url}/api/training/services/trainee/batch-wise?limit=${limit}&skip=${
          Number(limit) * Number(page)
        }`;

        if (Number(batch) > 0) {
          uri += `&batchId=${batch}`;
        }
        const { data } = await axios.get(uri);

        if (data.success) {
          setBatchReport(data.data);
          setTotal(data.total);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
      }
    })();
  }, [limit, page, batch]);

  const getDownloadData: () => Promise<{
    total: number;
    data: IReport[];
  }> = async () => {
    try {
      let uri = `${url}/api/training/services/trainee/batch-wise?`;
      if (Number(batch) > 0) {
        uri += `&batchId=${batch}`;
      }
      const { data } = await axios.get(uri);

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };

  useEffect(() => {
    try {
      (async function () {
        const { data } = await axios.get(
          `${url}/api/training/services/trainee/batch/list`
        );
        if (data.success) {
          setBatchID(data.data);
        }
      })();
    } catch (error) {}
  }, []);
  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader
          action={
            <>
              <TrainingReportExcelGen
                getDownloadData={getDownloadData}
                fileName={"batch_wise_report"}
              />{" "}
              <FormControl className="batch-report-select">
                <InputLabel id="demo-simple-select-label">
                  Select batch
                </InputLabel>
                <Select
                  sx={{ height: "43px", width: "100px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={zone}
                  defaultValue={0}
                  label="Select batch"
                  onChange={(e: any) => {
                    setSearchParams((prev) => {
                      prev.set("batch", String(e.target.value));
                      return prev;
                    });
                  }}
                >
                  <MenuItem value={0}>All</MenuItem>

                  {batchID.map((id) => (
                    <MenuItem
                      value={id.training_batch_id}
                      key={id.training_batch_id}
                    >
                      {id.batch_no}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          }
          title="Batch wise report"
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Batch no </TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>nid no</TableCell>
                <TableCell>email</TableCell>
                <TableCell>Phone </TableCell>
                <TableCell>date of birth</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader />
              ) : batchReport.length ? (
                <>
                  {batchReport.map((sReport, index: number) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{sReport.batch_no}</TableCell>
                        <TableCell>
                          {sReport.user_training_trainee_name}
                        </TableCell>

                        <TableCell>
                          {sReport.training_course_application_nid_number}
                        </TableCell>
                        <TableCell>
                          {sReport.training_course_application_email}
                        </TableCell>
                        <TableCell>
                          {sReport.training_course_application_phone_number}
                        </TableCell>
                        <TableCell>
                          {moment(
                            sReport.training_course_application_birthdate
                          ).format("Do-MMMM-YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableNotFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={total}
            onPageChange={(_event, newPage) => {
              setSearchParams((prev) => {
                prev.set("page", String(newPage));
                return prev;
              });
            }}
            onRowsPerPageChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setSearchParams((prev) => {
                prev.set("limit", String(e.target.value));
                return prev;
              });
            }}
            page={Number(page) && Number(page)}
            rowsPerPage={Number(limit)}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default BatchWiseReport;
