import React, { useState } from "react";
import {
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { IAgencyUpdateInfoApplication } from "src/Types/module/applications/applications";

interface IProps {
  setApplication: React.Dispatch<
    React.SetStateAction<IAgencyUpdateInfoApplication>
  >;
  application: IAgencyUpdateInfoApplication;
  handleClose: any;
}

const UpdateAgencyInfoApplication = ({
  setApplication,
  application,
  handleClose,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(application.status);
  const [note, setNote] = useState("");

  const handelSubmit = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.put(
        `${url}/api/atab/agency-member-info/update/${application.agency_member_id}`,
        {
          status,
          // note,
        }
      );
      if (data.success) {
        setIsLoading(false);
        Toaster().fire({
          icon: "success",
          title: "successfully update agency information",
        });
        setApplication({ ...application, status: status, note: note });
        handleClose();
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Select Status"
              onChange={(e: any) => setStatus(e.target.value)}
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {status === "rejected" && (
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              label="Reject reason"
              id="outlined-required"
              multiline
              rows={4}
              onChange={(e: any) => setNote(e.target.value)}
              type="text"
            />
          </Grid>
        )}
      </Grid>
      <Box sx={{ pt: 5, mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            fullWidth
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            onClick={handelSubmit}
            fullWidth
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateAgencyInfoApplication;
