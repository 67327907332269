import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { getStatusLabel } from "src/Utils/Combined";
import { IEvent } from "src/Types/module/member/Event";
import { Link } from "react-router-dom";

interface IProps {
  sEvent: IEvent;
}
const EventTableRow = ({ sEvent }: IProps) => {
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {sEvent.member_event_id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {sEvent.member_event_name
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {moment(sEvent.member_event_start_date).format("LL")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {moment(sEvent.member_event_end_date).format("LL")}
        </Typography>
      </TableCell>

      <TableCell>
        <Link to={`/admin-list/single-admin/${sEvent.event_created_by_id}`}>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {sEvent.event_created_by_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography variant="body1" color="text.primary" gutterBottom noWrap>
          {getStatusLabel(sEvent.member_event_status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link to={`/member-list/event/${sEvent.member_event_id}`}>
          <Tooltip title="view" arrow>
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default EventTableRow;
