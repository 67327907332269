import React from "react";
import { ISingleOwnerInfo } from "src/Types/module/UpdateRequest/RequestCompanyInfo";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { imgUrl } from "src/Helpers/utils/Constant";

import { getStatusLabel } from "src/Utils/Combined";
interface IProps {
  requestOwnerInfo: ISingleOwnerInfo[];
}
const useStyles = makeStyles({
  TableCell: {
    fontWeight: "bold !important",
  },
});
const OwnerInfoAppReject = ({ requestOwnerInfo }: IProps) => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Card>
          <CardHeader
            title={requestOwnerInfo.map((s) => {
              return s.status === "approved"
                ? "Approved information"
                : "Rejected information";
            })}
          />

          <Container>
            {requestOwnerInfo?.map((sOwner, index) => {
              return (
                <TableContainer sx={{ mb: 5 }} key={index}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Status
                        </TableCell>
                        <TableCell align="right">
                          {getStatusLabel(sOwner.status)}
                        </TableCell>
                      </TableRow>
                      {sOwner.reject_reason && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Reject reason
                          </TableCell>
                          <TableCell align="right">
                            {sOwner.reject_reason}
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_name && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner name
                          </TableCell>
                          <TableCell align="right">
                            {sOwner.company_owner_name}
                          </TableCell>
                        </TableRow>
                      )}
                      {sOwner.company_owner_designation && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner designation
                          </TableCell>
                          <TableCell align="right">
                            {sOwner.company_owner_designation}
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_phone && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner phone
                          </TableCell>
                          <TableCell align="right">
                            {sOwner.company_owner_phone}
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_email && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner email
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            {sOwner.company_owner_email}
                          </TableCell>
                        </TableRow>
                      )}
                      {sOwner.company_owner_address && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner address
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            {sOwner.company_owner_address}
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_nid && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner nid
                          </TableCell>
                          <TableCell align="right">
                            {" "}
                            {sOwner.company_owner_nid}
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_photo && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner photo
                          </TableCell>
                          <TableCell align="right">
                            <a
                              target="_blank"
                              href={`${imgUrl}/${sOwner.company_owner_photo}`}
                            >
                              {sOwner.company_owner_photo}
                            </a>
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_biodata && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner bio data
                          </TableCell>
                          <TableCell align="right">
                            <a
                              target="_blank"
                              href={`${imgUrl}/${sOwner.company_owner_biodata}`}
                            >
                              {sOwner.company_owner_biodata}
                            </a>
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_passport && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner passport
                          </TableCell>
                          <TableCell align="right">
                            <a
                              target="_blank"
                              href={`${imgUrl}/${sOwner.company_owner_passport}`}
                            >
                              {sOwner.company_owner_passport}
                            </a>
                          </TableCell>
                        </TableRow>
                      )}

                      {sOwner.company_owner_signature && (
                        <TableRow>
                          <TableCell className={classes.TableCell}>
                            Owner signature
                          </TableCell>
                          <TableCell align="right">
                            <a
                              target="_blank"
                              href={`${imgUrl}/${sOwner.company_owner_signature}`}
                            >
                              {sOwner.company_owner_signature}
                            </a>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            })}
          </Container>
        </Card>
      </Container>
    </>
  );
};

export default OwnerInfoAppReject;
