import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  useTheme,
  Box,
  TablePagination,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useSearchParams } from "react-router-dom";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { getStatusLabel } from "src/Utils/Combined";
import { IDate } from "src/Types/module/serviceRequest/serviceRequestTypes";
import { DatePicker } from "@mui/x-date-pickers";

const AllBooks = () => {
  const theme = useTheme();
  const [allBooks, setAllBooks] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<IDate>({
    startDate: null,
    endDate: null,
  });

  // Pagination;
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
    status: "all",
  });
  const [limit, setLimit] = useState<number>(25);
  const status = searchParams.get("status");
  const page = parseInt(searchParams.get("page"));
  const [total, setTotal] = useState<number>(0);
  // fetch data
  useEffect(() => {
    setLoading(true);
    try {
      (async () => {
        let uri = `${url}/api/training/services/trainee/trainee-books?skip=${
          limit * page
        }&limit=${limit}`;
        if (status !== "all") {
          uri += `&status=${status}`;
        }
        if (date.startDate && date.endDate) {
          uri += `&from_date=${
            date.startDate.toISOString().split("T")[0]
          }&to_date=${date.endDate.toISOString().split("T")[0]}`;
        }

        const { data } = await axios.get(uri);

        if (data.success) {
          setAllBooks(data.data);
          setTotal(data.total);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })();
    } catch (error) {
      setLoading(false);
    }
  }, [page, limit, date.endDate, date.startDate, status]);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const handleStatus = (e: SelectChangeEvent<string>) => {
    searchParams.set("status", e.target.value);
    setSearchParams(searchParams);
  };
  const statusOptions = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "approved",
      name: "Approved",
    },
    {
      id: "pending",
      name: "Pending",
    },
    {
      id: "rejected",
      name: "Rejected",
    },
  ];
  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              {" "}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    variant="h6"
                    component="h6"
                    gutterBottom
                  >
                    From
                  </Typography>
                  <DatePicker
                    label="Start Date"
                    value={date.startDate}
                    onChange={(e: any) =>
                      setDate({
                        ...date,
                        startDate: e,
                      })
                    }
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    fontWeight={"bold"}
                    variant="h6"
                    component="h6"
                    gutterBottom
                  >
                    To
                  </Typography>

                  <DatePicker
                    label="End Date"
                    value={date.endDate}
                    onChange={(e: any) =>
                      setDate({
                        ...date,
                        endDate: e,
                      })
                    }
                  />
                </Grid>
                <Grid>
                  <FormControl sx={{ width: "150px" }} variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => handleStatus(e)}
                      label="Status"
                      autoWidth
                    >
                      {statusOptions &&
                        statusOptions.map((statusOption) => (
                          <MenuItem
                            key={statusOption.id}
                            value={statusOption.id}
                          >
                            {statusOption.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Typography sx={{ fontWeight: 700, lineHeight: 1.34 }}>
                    All Books ({total})
                  </Typography>
                }
              />
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                      <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : (
                      <>
                        {allBooks?.length ? (
                          <>
                            {allBooks.map((sBook) => {
                              return (
                                <TableRow key={sBook.trainee_books_id}>
                                  <TableCell>
                                    {sBook.trainee_books_id}
                                  </TableCell>
                                  <TableCell>
                                    {moment(sBook.created_at).format(
                                      "MMM Do YYYY"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {getStatusLabel(sBook.status)}
                                  </TableCell>
                                  <TableCell>
                                    <Link
                                      to={`/service-request/books/${sBook.trainee_books_id}`}
                                    >
                                      <Tooltip title="view" arrow>
                                        <IconButton
                                          sx={{
                                            "&:hover": {
                                              background:
                                                theme.colors.primary.lighter,
                                            },
                                            color: theme.palette.primary.main,
                                          }}
                                          color="inherit"
                                          size="small"
                                        >
                                          <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        ) : (
                          <TableNotFound />
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AllBooks;
