import React from "react";
import { ISingleTradeLicense } from "src/Types/module/UpdateRequest/RequestTradeLicense";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { imgUrl } from "src/Helpers/utils/Constant";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
interface IProps {
  requestTradeLicenseInfo: ISingleTradeLicense;
}
const useStyles = makeStyles({
  TableCell: {
    fontWeight: "bold !important",
  },
});
const TraderLicenseAppReject = ({ requestTradeLicenseInfo }: IProps) => {
  const classes = useStyles();

  return (
    <Container>
      <Card>
        <CardHeader
          title={
            requestTradeLicenseInfo.status === "approved"
              ? "Approved information"
              : "Rejected information"
          }
        />
        <TableContainer sx={{ mb: 5 }}>
          <Table aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.TableCell}>Status</TableCell>
                <TableCell align="right">
                  {getStatusLabel(requestTradeLicenseInfo.status)}
                </TableCell>
              </TableRow>
              {requestTradeLicenseInfo.reject_reason && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Reject reason
                  </TableCell>
                  <TableCell align="right">
                    {requestTradeLicenseInfo.reject_reason}
                  </TableCell>
                </TableRow>
              )}
              {requestTradeLicenseInfo.company_trade_license && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Trade license
                  </TableCell>
                  <TableCell align="right">
                    {requestTradeLicenseInfo.company_trade_license}
                  </TableCell>
                </TableRow>
              )}

              {requestTradeLicenseInfo.company_bin_number && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    BIN number
                  </TableCell>
                  <TableCell align="right">
                    {" "}
                    {requestTradeLicenseInfo.company_bin_number}
                  </TableCell>
                </TableRow>
              )}

              {requestTradeLicenseInfo.company_bin_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>BIN file</TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestTradeLicenseInfo.company_bin_file}`}
                    >
                      {requestTradeLicenseInfo.company_bin_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestTradeLicenseInfo.company_tin_number && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    TIN number
                  </TableCell>
                  <TableCell align="right">
                    {requestTradeLicenseInfo.company_tin_number}
                  </TableCell>
                </TableRow>
              )}

              {requestTradeLicenseInfo.company_tin_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Company TIN file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestTradeLicenseInfo.company_tin_file}`}
                    >
                      {requestTradeLicenseInfo.company_tin_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}

              {requestTradeLicenseInfo?.company_trade_license_expiry_date && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Trade license expiry date
                  </TableCell>
                  <TableCell align="right">
                    {moment(
                      requestTradeLicenseInfo?.company_trade_license_expiry_date
                    ).format("L")}
                  </TableCell>
                </TableRow>
              )}

              {requestTradeLicenseInfo.company_trade_license_file && (
                <TableRow>
                  <TableCell className={classes.TableCell}>
                    Company tarde license file
                  </TableCell>
                  <TableCell align="right">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${imgUrl}/${requestTradeLicenseInfo.company_trade_license_file}`}
                    >
                      {requestTradeLicenseInfo.company_trade_license_file}
                    </a>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Container>
  );
};

export default TraderLicenseAppReject;
