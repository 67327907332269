import { ICertificate } from "src/Types/module/member/memberTypes";
import {
  IconButton,
  TableCell,
  TableRow,
  Checkbox,
  Button,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { MdDeleteForever } from "react-icons/md";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ShowCertificate = ({
  certificate,
  index,
  setRefresh,
}: {
  certificate: ICertificate;
  index: number;
  setRefresh: any;
}) => {
  const { user } = useAuthContext();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  const [open, setOpen] = useState(false);

  const handelUpdate = async (checked: number, id: number) => {
    const { data } = await axios.patch(
      `${url}/api/atab/user-member/certificate/${id}`,
      {
        status: checked,
      }
    );
    if (data.success) {
      Toaster().fire({
        icon: "success",
        title: data.message,
      });
      setRefresh((prv) => !prv);
    } else {
      Toaster().fire({
        icon: "error",
        title: data.message,
      });
    }
  };

  const handelDeleted = async (id: number) => {
    const { data } = await axios.delete(
      `${url}/api/atab/user-member/certificate/delete/${id}`
    );
    if (data.success) {
      Toaster().fire({
        icon: "success",
        title: data.message,
      });
      setRefresh((prv) => !prv);
    } else {
      Toaster().fire({
        icon: "error",
        title: data.message,
      });
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell size="small">{index + 1}</TableCell>
        <TableCell size="small">
          {moment(certificate.c_created_at).format("LL")}
        </TableCell>
        <TableCell size="small">
          {certificate.c_type === "member_certificate" ? (
            <>Member Certificate</>
          ) : (
            <>
              {certificate.c_type === "duplicate_certificate" ? (
                <> Duplicate Certificate</>
              ) : (
                <> Renewal Certificate</>
              )}
            </>
          )}
        </TableCell>
        <TableCell size="small">
          <Tooltip title="View" arrow>
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                SetImageViewOpen(true);
                setImageFile(certificate.c_photo);
              }}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell size="small">
          <Tooltip
            title={certificate.status ? "Deactivate" : "Active"}
            sx={{ mr: 1 }}
          >
            <Checkbox
              {...label}
              checked={certificate?.status ? true : false}
              onClick={(e: any) => {
                handelUpdate(e.target.checked ? 1 : 0, certificate.c_id);
              }}
            />
          </Tooltip>
          {user?.user_admin_role === "Super Admin" && (
            <Tooltip title={"Delete"} sx={{ mr: 1 }}>
              <IconButton onClick={() => setOpen(true)}>
                <MdDeleteForever style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Sure to delete this certificate ?"}</DialogTitle>

        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handelDeleted(certificate.c_id)}
          >
            Yes
          </Button>{" "}
          <Button
            size="small"
            variant="outlined"
            onClick={() => setOpen(false)}
            color="error"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShowCertificate;
