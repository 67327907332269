import { CircularProgress } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export function AddressLoading() {
  return (
    <ListItem secondaryAction={<CircularProgress size={20} />}>
      <ListItemText primary={`Loading...`} />
    </ListItem>
  );
}
export function AddressNotFound() {
  return (
    <ListItem>
      <ListItemText primary={`Address not found`} />
    </ListItem>
  );
}
