import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "../../../../../Toaster/Toaster";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function SingleTrainingInvoiceUpdateForm({
  singleInvoiceDetails,
  handleEditModeChange,
  setReload,
}) {
  const { register, handleSubmit, reset } = useForm();
  const [isSuccess, setSuccess] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>(null);
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankName, setBankName] = useState<string>("");
  const [getPaymentMethod, setGetPaymentMethod] = useState(null);
  const [bankError, setBankError] = useState<string>("");
  const [instrumentDate, setInstrumentDate] = useState<Date | null>(new Date());
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    singleInvoiceDetails.training_payment_invoice_transaction_date
      ? dayjs(singleInvoiceDetails.training_payment_invoice_transaction_date)
      : dayjs()
  );
  const Toast = Toaster();

  const handleInstrumentDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstrumentDate(new Date(event.target.value));
  };

  useEffect(() => {
    if (
      getPaymentMethod ===
      singleInvoiceDetails?.training_payment_invoice_payment_method
    ) {
      return setOpen(false);
    }
    if (getPaymentMethod === "instrument") {
      setOpen(true);
    } else if (getPaymentMethod === "pay order") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [getPaymentMethod]);

  const handleClose = (event: React.SyntheticEvent<unknown>) => {
    if (getPaymentMethod !== "cash" || "ssl commerz") {
      if (!bankName) {
        setBankError("Enter bank name");
      } else {
        setOpen((prev) => !prev);
        setBankError("");
      }
    }
  };

  const handleCloseToCash = (event: React.SyntheticEvent<unknown>) => {
    setOpen(false);
    setGetPaymentMethod(
      singleInvoiceDetails?.training_payment_invoice_payment_method
    );
  };

  const currencies = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "paid",
      label: "Paid",
    },
    {
      value: "cancelled",
      label: "Cancelled",
    },
    {
      value: "reported",
      label: "Reported",
    },
  ];
  const paymentMethod = [
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "SSL Commerz",
      label: "SSL Commerz ",
    },
    {
      value: "Instrument",
      label: "Instrument",
    },
    {
      value: "Pay Order",
      label: "Pay Order",
    },
    {
      value: "Pay Slip",
      label: "Pay Slip",
    },
  ];

  const onSubmit = async (inputs: any) => {
    // if (user.user_admin_id) {
    // setLoading(true);
    try {
      const bodyFormData = new FormData();
      for (const key in inputs) {
        if (inputs[key]) {
          bodyFormData.append(key, inputs[key]);
        }
      }

      bodyFormData.append(
        "training_payment_invoice_transaction_date",
        dayjs(selectedDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "training_payment_invoice_instrument_date",
        instrumentDate.toISOString().slice(0, 10)
      );
      bodyFormData.append("training_payment_invoice_bank_name", bankName);
      bodyFormData.append(
        "training_payment_invoice_updated_by",
        user.user_admin_id.toString()
      );
      bodyFormData.append(
        "training_payment_invoice_payment_method",
        getPaymentMethod ||
          singleInvoiceDetails.training_payment_invoice_payment_method
      );

      if (uploadFile) {
        bodyFormData.append(
          "training_payment_invoice_invoice_file",
          uploadFile ||
            singleInvoiceDetails.training_payment_invoice_invoice_file
        );
      }

      bodyFormData.append(
        "training_payment_invoice_transaction_id",
        singleInvoiceDetails?.training_payment_invoice_transaction_id
      );
      const { data } = await axios.put(
        `${url}/api/training/invoice/update/for-admin/${singleInvoiceDetails.training_payment_invoice_id}`,
        bodyFormData
      );
      if (data.success) {
        Toast.fire({
          icon: "success",
          title: `Invoice Update Successfully`,
        });
        setSuccess(true);
        reset();
        setReload((prv) => !prv);
      } else {
      }
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
    }
    // } else {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    if (isSuccess) {
      handleEditModeChange();
    }
  }, [isSuccess]);

  return (
    singleInvoiceDetails && (
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          "& .MuiTextField-root": { mt: 3, width: "400px" },
        }}
        noValidate
        autoComplete="off"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          container
          sx={{ paddingLeft: "40px" }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <TextField
            id="filled-select-currency"
            select
            size="small"
            label="Select Payment Method"
            value={
              getPaymentMethod ||
              (singleInvoiceDetails.training_payment_invoice_payment_method &&
                singleInvoiceDetails?.training_payment_invoice_payment_method.toLowerCase())
            }
            onChange={(e) => setGetPaymentMethod(e.target.value.toLowerCase())}
            variant="filled"
          >
            {paymentMethod.map((option) => (
              <MenuItem key={option.value} value={option.value.toLowerCase()}>
                {option.label.toLowerCase()}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="filled-select-currency"
            select
            size="small"
            label="Select status"
            defaultValue={singleInvoiceDetails?.training_payment_invoice_status}
            {...register("training_payment_invoice_status")}
            variant="filled"
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value.toLowerCase()}>
                {option.label.toLowerCase()}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="filled-size-small"
            variant="filled"
            type="file"
            label="Payment Invoice File"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: any) => setUploadFile(e.target.files[0])}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Payment Date"
              format="MM/DD/YYYY"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e)}
            />
          </LocalizationProvider>

          {/* <TextField
            label="Payment Date"
            type="date"
            value={
              selectedDate?.toISOString().slice(0, 10) ||
              (singleInvoiceDetails.training_payment_invoice_transaction_date &&
                singleInvoiceDetails.training_payment_invoice_transaction_date
                  ?.toISOString()
                  .slice(0, 10))
            }
            onChange={handleDateFormat}
            InputLabelProps={{ shrink: true }}
          /> */}
          <TextField
            id="outlined-basic"
            {...register("training_payment_invoice_discount_amount")}
            defaultValue={
              singleInvoiceDetails.training_payment_invoice_discount_amount
            }
            label="Discount"
            variant="standard"
            type="number"
          />
          <Box sx={{ margin: "10px 20px 5px 10px" }}>
            <TextareaAutosize
              placeholder="Type in invoice remark"
              minRows={3}
              maxRows={10}
              required
              defaultValue={
                singleInvoiceDetails?.training_payment_invoice_remark
              }
              id="outlined-required"
              {...register("training_payment_invoice_remark")}
              style={{ width: "55ch" }}
            />
          </Box>
        </Grid>
        {!loading ? (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            sx={{
              margin: 2,
            }}
          >
            Update Invoice
          </Button>
        ) : (
          <LoadingButton
            loading
            loadingIndicator="Updating..."
            variant="outlined"
          >
            Updating...
          </LoadingButton>
        )}
        <Dialog disableEscapeKeyDown open={open} onClose={handleCloseToCash}>
          <DialogTitle>Fill the bank details</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl sx={{ m: 2, minWidth: 120 }}>
                <TextField
                  label="Bank name:"
                  type="text"
                  sx={{ mt: 2 }}
                  id="outlined-basic"
                  variant="outlined"
                  required
                  onChange={(e) => setBankName(e.target.value.toLowerCase())}
                />
                {bankError && (
                  <Typography sx={{ color: "#ED4F32" }}>{bankError}</Typography>
                )}
                <TextField
                  label="Branch name of bank:"
                  type="text"
                  sx={{ mt: 2 }}
                  id="outlined-basic"
                  variant="outlined"
                  required
                  {...register("training_payment_invoice_bank_branch")}
                />
                <TextField
                  label={
                    getPaymentMethod === "instrument"
                      ? "Instrument no:"
                      : "Pay order no:"
                  }
                  type="text"
                  sx={{ my: 2 }}
                  id="outlined-basic"
                  variant="outlined"
                  required
                  {...register("training_payment_invoice_instrument")}
                />
                <TextField
                  label={
                    getPaymentMethod === "instrument"
                      ? "Select instrument Date:"
                      : "Select pay order Date:"
                  }
                  type="date"
                  value={instrumentDate?.toISOString().slice(0, 10)}
                  onChange={handleInstrumentDate}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseToCash}>Cancel</Button>
            <Button onClick={handleClose}>Submit</Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  );
}

export default SingleTrainingInvoiceUpdateForm;
