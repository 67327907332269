import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useWatch } from "react-hook-form";
import { useEffect } from "react";
const DynamicTraineeInvoiceField = ({
  register,
  allPaymentableItem,
  index,
  item,
  control,
  remove,
  fields,
  setValue,
}: any) => {
  const itemID = useWatch({
    control,
    name: `items.${index}.id`,
  });
  useEffect(() => {
    setValue(`items.${index}.discount`, 0);
    const findPaymentItem = allPaymentableItem?.find(
      (item) => item?.training_paymentable_item_id === Number(itemID)
    );

    if (findPaymentItem?.training_paymentable_item_id) {
      setValue(
        `items.${index}.amount`,
        parseInt(findPaymentItem?.training_paymentable_item_bdt_amount, 10)
      );
    } else {
      setValue(`items.${index}.amount`, 0);
    }
  }, [itemID]);
  return (
    <>
      <Grid
        container
        spacing={2}
        key={item.id}
        alignItems="center"
        sx={{ marginBottom: "14px" }}
      >
        <Grid item xs={12} md={12} lg={4}>
          <FormControl fullWidth required margin="normal">
            <InputLabel id="demo-simple-select-label">Select Item</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              {...register(`items.${index}.id`)}
              label="Expense Head"
            >
              {allPaymentableItem?.map((item) => {
                return (
                  <MenuItem
                    key={item.training_paymentable_item_id}
                    value={item.training_paymentable_item_id}
                  >
                    {item.training_paymentable_item_name} (
                    {item.training_paymentable_item_bdt_amount} bdt)
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <TextField
            fullWidth
            required
            label="Amount"
            type="number"
            variant="outlined"
            {...register(`items.${index}.amount`)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <TextField
            fullWidth
            required
            label="Discount"
            type="number"
            variant="outlined"
            {...register(`items.${index}.discount`)}
          />
        </Grid>
        {fields.length > 1 && (
          <Grid item xs={1}>
            <IconButton onClick={() => remove(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DynamicTraineeInvoiceField;
