import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { imgUrl } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { ImemberTableRow } from "src/Types/module/member/memberTypes";
import { getStatusLabel, getStatusLabelMember } from "src/Utils/Combined";
import { ISubscriptionReport } from "src/Types/module/common/commonTypes";
import moment from "moment";
const ShowSubscriptionReport = ({
  sReport,
  index,
}: {
  sReport: ISubscriptionReport;
  index: number;
}) => {
  const theme = useTheme();
  return (
    <>
      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{sReport.user_member_id}</TableCell>
        <TableCell sx={{ width: "250px" }}>
          {sReport.user_member_company_name}
        </TableCell>
        <TableCell>
          {sReport.user_member_membership_payment_date &&
            moment(sReport.user_member_membership_payment_date).format("YYYY")}

          {sReport.user_member_membership_payment_expiry_date &&
            -moment(sReport.user_member_membership_payment_expiry_date).format(
              "YYYY"
            )}
        </TableCell>
        <TableCell>{sReport.user_member_zone_name}</TableCell>

        <TableCell>
          <Link to={`/member-list/${sReport.user_member_id.toString()}`}>
            <Tooltip title="view" arrow>
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ShowSubscriptionReport;
