import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
  Typography,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import { Divider } from "@mui/material";
import { ISingleCivilAviation } from "src/Types/module/UpdateRequest/RequestCivilAviation";
import moment from "moment";

interface IProps {
  preCivilAviationInfo: ISingleCivilAviation;
}
const CurrentCivilAviationInfo = ({ preCivilAviationInfo }: IProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" fontSize={21}>
            Civil aviation information
          </Typography>
        }
      />

      <TableContainer sx={{ mb: 5 }}>
        <Table aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Certificate number
              </TableCell>
              <TableCell align="right">
                {" "}
                {preCivilAviationInfo?.certificate_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Certificate file
              </TableCell>
              <TableCell align="right">
                {preCivilAviationInfo?.certificate_file ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${imgUrl}/${preCivilAviationInfo.certificate_file}`}
                  >
                    {preCivilAviationInfo.certificate_file}
                  </a>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Certificate issue date
              </TableCell>
              <TableCell align="right">
                {preCivilAviationInfo?.certificate_issue_date &&
                  moment(preCivilAviationInfo?.certificate_issue_date).format(
                    "LL"
                  )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Certificate expiry date
              </TableCell>
              <TableCell align="right">
                {preCivilAviationInfo?.certificate_issue_date &&
                  moment(preCivilAviationInfo?.certificate_expiry_date).format(
                    "LL"
                  )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default CurrentCivilAviationInfo;
