import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { imgUrl, url } from "../../../../../Helpers/utils/Constant";
import { IAttendantStudent } from "src/Types/module/batch/allTypeOfBatch";
import Label from "src/components/Label";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { getStatusLabel } from "src/Utils/Combined";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IProps {
  student: IAttendantStudent;
}

const AttendenSingleStudentRow = ({ student }: IProps) => {
  const theme = useTheme();
  const { handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<number>(student.class_attendance);

  const { user } = useAuthContext();
  const onSubmit = async () => {
    const updateValue = {
      class_attendance_class: student.class_attendance_class.toString(),
      class_attendance_student: student.user_training_trainee_id,
      class_attendance: status,
    };

    try {
      const { data } = await axios.put(
        `${url}/api/training/class/single/class-attendance/update?user_admin_id=${user.user_admin_id}`,
        updateValue
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setOpen(false);
      } else {
        Toaster().fire({
          icon: "failed",
          title: `${data.message}`,
        });
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
    }
  };

  return (
    <TableRow>
      <TableCell>{student.user_training_trainee_id}</TableCell>
      <TableCell align='center'>
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center ",
          }}
        >
          <img
            width={30}
            height={30}
            style={{
              borderRadius: "50%",
              marginRight: "10px",
            }}
            src={
              student.user_training_trainee_photo
                ? `${imgUrl}/training_batch_files/${student?.user_training_trainee_photo}`
                : "/static/images/avatars/avatar2.png"
            }
            alt=''
          />
        </Typography>
      </TableCell>
      <TableCell align='center'>
        {student.user_training_trainee_name
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
          ? student.user_training_trainee_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
          : "Student Name"}
      </TableCell>
      <TableCell align='center'>
        {moment(student.class_attendance_time, "HH:mm:ss").format("hh:mm A")}
      </TableCell>

      <TableCell align='center'>
        {getStatusLabel(status ? "attend" : "absent")}
      </TableCell>

      <TableCell align='center'>
        <Tooltip title='Update Attandence'>
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon
              sx={{
                cursor: "pointer",
                color: theme.colors.primary.main,
              }}
            />{" "}
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          maxWidth='lg'
          onClose={() => setOpen(true)}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogContent>
            <Box onSubmit={handleSubmit(onSubmit)} component='form'>
              <Box sx={{ mb: 2 }}>
                <Typography sx={{ mb: 2 }}>Update Attendance</Typography>
                <Divider />
              </Box>
              <FormControl sx={{ width: "100%" }} variant='filled'>
                <InputLabel>Select status</InputLabel>
                <Select
                  onChange={(e: any) => setStatus(e.target.value)}
                  label='Select status'
                  fullWidth
                  value={status}
                >
                  <MenuItem value={0}>Absent</MenuItem>
                  <MenuItem value={1}>Attend</MenuItem>
                </Select>
              </FormControl>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
              >
                Update Class
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};

export default AttendenSingleStudentRow;
