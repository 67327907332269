import { useState, ChangeEvent } from "react";
import {
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { url } from "../../../../../Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CreateExam = ({ handleClose, batchId, refresh, setRefresh }) => {
  const { handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState<Boolean>(false);
  const [newDate, setNewDate] = useState<Dayjs | null>(dayjs());
  const [mark, setMark] = useState();
  const [image, setImage] = useState("");
  const [timeValue, setTimeValue] = useState<Dayjs | null>(dayjs());

  const { user } = useAuthContext();
  const newTimeValue = timeValue.add(6, "hour");
  const onSubmit = async (inputs: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("training_exam_batch", batchId.toString());
    formData.append("training_exam_date", newDate.toISOString().split("T")[0]);
    formData.append(
      "training_exam_time",
      newTimeValue.toISOString().slice(11, 19)
    );
    formData.append("training_exam_material_file", image);
    formData.append("training_exam_total_mark", mark);
    setLoading(true);
    try {
      for (const key in inputs) {
        formData.append(key, inputs[key]);
      }
      const { data } = await axios.post(
        `${url}/api/training/exam/create?user_admin_id=${user.user_admin_id}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setLoading(false);
        setRefresh(!refresh);
        handleClose();
      } else {
        Toaster().fire({
          icon: "failed",
          title: `${data.message}`,
        });
        setLoading(false);
      }
      reset();
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
    reset();
  };

  return (
    <>
      <Helmet>
        <title>Atab - Create a exam</title>
      </Helmet>
      <Container maxWidth="sm">
        <CardHeader title="Create a exam" />
        <Divider />
        <CardContent>
          <Box
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            sx={{ pt: 2 }}
          >
            <Grid container spacing={2}>
              <Grid xs={12} md={6} item>
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Select exam date"
                    format="MM/DD/YYYY"
                    value={newDate}
                    onChange={(e: any) => setNewDate(e)}
                    // renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
                {/* <DatePicker
                  label='Select exam date'
                  value={newDate}
                  onChange={(e: any) => setNewDate(e)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                /> */}
              </Grid>
              <Grid xs={12} md={6} item>
                <TimePicker
                  label="Select exam time"
                  value={timeValue}
                  onChange={(e: any) => setTimeValue(e)}
                  // renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="file"
                  label="Choose exam file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: any) => setImage(e.target.files[0])}
                  autoComplete="file"
                  type="file"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="number"
                  label=" Exam mark"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e: any) => setMark(e.target.value)}
                  autoComplete="file"
                  type="number"
                />
              </Grid>
            </Grid>

            {loading ? (
              <Button
                disabled
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                Creating...
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  mt: 3,
                }}
              >
                Create exam
              </Button>
            )}
          </Box>
        </CardContent>
      </Container>
    </>
  );
};

export default CreateExam;
