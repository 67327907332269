import React, { useState } from "react";
import { Box, Typography, Grid, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { url } from "src/Helpers/utils/Constant";
import { ICompanyTradeLicense } from "src/Types/module/member/CompanyInformation";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { HttpClient } from "src/Helpers/http/http";
import { IResponse } from "src/Types/module/common/commonTypes";

interface IProps {
  tradeLicense: ICompanyTradeLicense;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  refresh: boolean;
}

const UpdateCompanyTradeLicense = ({
  tradeLicense,
  memberId,
  setRefresh,
  setOpen,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [date, setDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (inputsData: any) => {
    setIsLoading(true);
    if (date) {
      inputsData.company_trade_license_expiry_date = date;
    }

    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    try {
      const data: IResponse = await HttpClient.patch(
        `/api/atab/admin/member-company/member/license/${memberId}`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setOpen(false);
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Enter trade license number "
                type="text"
                defaultValue={tradeLicense.company_trade_license}
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_trade_license")}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                defaultValue={tradeLicense.company_tin_number}
                label="TIN number"
                type="text"
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_tin_number")}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Upload BIN File / document "
                InputLabelProps={{
                  shrink: true,
                }}
                type="file"
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_bin_file")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                id="date"
                type="date"
                label="Trade license expiry date "
                onChange={(e) => setDate(e.target.value)}
                sx={{ width: "100%", pb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Upload TIN file / document"
                type="file"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ pb: 2 }}
                {...register("company_tin_file")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Upload trade license File / document "
                type="file"
                fullWidth
                sx={{ pb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("company_trade_license_file")}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="BIN number"
                type="text"
                defaultValue={tradeLicense.company_bin_number}
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_bin_number")}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyTradeLicense;
