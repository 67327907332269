import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { getStatusLabelInvoiceStatus } from "src/Utils/Combined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { Link } from "react-router-dom";
import { fairInvoice } from "src/Types/module/fairInvoice/fairInvoiceType";

type IProps = {
  allAdmin: fairInvoice;
};

const ExhibitorInvoiceTableRow = ({ allAdmin }: IProps) => {
  const theme = useTheme();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin?.fair_invoice_id}
          </Typography>
        </TableCell>
        <TableCell>
          {allAdmin.invoice_issue_date ? (
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
            >
              {moment(allAdmin?.invoice_issue_date).format("LL")}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
              noWrap
            >
              None
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin?.total_amount}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_invoice_transaction_date
              ? moment(allAdmin?.fair_invoice_transaction_date).format("LL")
              : "N/A"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_invoice_transaction_id
              ? allAdmin.fair_invoice_transaction_id
              : "N/A"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin.fair_invoice_payment_method
              ? allAdmin.fair_invoice_payment_method
              : "N/A"}
          </Typography>
        </TableCell>
        <TableCell>
          {getStatusLabelInvoiceStatus(allAdmin?.fair_invoice_status)}
        </TableCell>
        <TableCell>
          <Typography variant="body1" color="text.primary" gutterBottom noWrap>
            {allAdmin?.user_fair_member_contact_name
              .toLowerCase()
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </Typography>
        </TableCell>
        <TableCell>
          <Tooltip title="view" arrow>
            <Link
              to={`/exhibitor/invoice-list/${allAdmin?.fair_invoice_id.toString()}`}
            >
              <IconButton
                sx={{
                  "&:hover": {
                    background: theme.colors.primary.lighter,
                  },
                  color: theme.palette.primary.main,
                }}
                color="inherit"
                size="small"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExhibitorInvoiceTableRow;
