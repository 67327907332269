import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Box,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import { LoadingButton } from "@mui/lab";
import { url } from "src/Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useParams } from "react-router-dom";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { ISingleBatch } from "src/Types/module/courses/trainee";

const UpdateBatch = ({
  setShowModal,
  showModal,
  loading,
  setLoading,
  batchDetails,
  setReload,
}: {
  setShowModal: any;
  showModal: any;
  loading: any;
  setLoading: any;
  batchDetails: ISingleBatch;
  setReload: any;
}) => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [values, setValues] = useState<any>({
    training_batch_name: "",
    training_batch_details: "",
    batch_no: "",
    training_batch_id: id,
    status: "",
  });

  useEffect(() => {
    if (batchDetails) {
      setValues({
        ...values,
        training_batch_name: batchDetails?.training_batch_name,
        training_batch_details: batchDetails?.training_batch_details,
        batch_no: batchDetails?.batch_no,
        training_batch_id: id,
        status: batchDetails?.training_batch_status,
      });
    }
  }, [id]);

  const handleUpdate = () => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await axios.put(
          `${url}/api/training/batch/update/single?user_admin_id=${user.user_admin_id}`,
          values
        );

        if (data.success) {
          setShowModal(false);
          Toaster().fire({ icon: "success", title: "Updated Successfully!" });
          setLoading(false);
          setReload((prv) => !prv);
        } else {
          setLoading(false);
        }
      } catch (err: any) {
        Toaster().fire({ icon: "error", title: err?.response?.data.message });
        setLoading(false);
      }
    })();
  };
  const statusOptions = [
    {
      id: "running",
      name: "Running",
    },
    {
      id: "ended",
      name: "Ended",
    },
    {
      id: "cancelled",
      name: "Cancelled",
    },
  ];
  return (
    <ModalsWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      modalData={{ title: "Update Batch" }}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ p: "40px", mb: "20px", width: 600 }}
      >
        <Grid
          container
          direction="column"
          alignContent="center"
          justifyContent="center"
          spacing={{ xs: 2 }}
        >
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="no"
                defaultValue={batchDetails?.batch_no}
                value={values.batch_no}
                label="Batch no"
                onChange={(newValue) => {
                  setValues({
                    ...values,
                    batch_no: newValue.target.value,
                  });
                }}
                type="number"
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                id="name"
                defaultValue={batchDetails?.training_batch_name}
                value={values.training_batch_name}
                label="Batch name"
                onChange={(newValue) => {
                  setValues({
                    ...values,
                    training_batch_name: newValue.target.value,
                  });
                }}
                type="text"
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  defaultValue={batchDetails?.training_batch_status}
                  value={values.status}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      status: e.target.value,
                    })
                  }
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12}>
              <TextField
                rows={4}
                defaultValue={batchDetails?.training_batch_details}
                label="Batch details"
                placeholder="Enter some details about this batch here.."
                multiline
                onChange={(event) => {
                  setValues({
                    ...values,
                    training_batch_details: event.target.value,
                  });
                }}
                // rows={4}
                // maxRows={6}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid xs={12} item>
            {!loading ? (
              <Button
                onClick={handleUpdate}
                variant="contained"
                disableElevation
                fullWidth
              >
                Update Batch
              </Button>
            ) : (
              <LoadingButton
                disabled
                loading
                loadingIndicator="Updating..."
                variant="contained"
              >
                Updating...
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </ModalsWrapper>
  );
};

export default UpdateBatch;
