import { useState, useEffect, ChangeEvent } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { RefreshButton } from "src/Utils/CommonAllShowUtils";
import { IExamList } from "src/Utils/type";
import { useParams } from "react-router-dom";
import ExamListTableRow from "./ExamListTableRow";
import { useSearchParams } from "react-router-dom";
import CreateExam from "./CreateExam";

const ExamList = () => {
  const [examList, setExamList] = useState<IExamList[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [status, setStatus] = useState<string>("all");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { batchId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(`${url}/api/training/exam/get/all/by/${batchId}/${status}`, {
        cancelToken: source.token,
      })
      .then((data) => {
        setExamList(data.data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [refresh, status]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const optionValue = [
    {
      id: "all",
      name: "All",
    },
    {
      id: "upcoming",
      name: "Upcoming",
    },
    {
      id: "ended",
      name: "Ended",
    },
    {
      id: "cancelled",
      name: "Cancelled",
    },
    {
      id: "finished",
      name: "Finished",
    },
  ];

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box display="flex" alignItems="center" gap={2}>
                  <FormControl sx={{ width: "150px" }} variant="outlined">
                    <InputLabel>Select status</InputLabel>
                    <Select
                      size="small"
                      onChange={(e: any) => setStatus(e.target.value)}
                      label="Select status"
                      autoWidth
                      value={status}
                    >
                      {optionValue.map((statusOption, i) => (
                        <MenuItem key={i} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button onClick={() => handleOpen()} variant="contained">
                    Create exam
                  </Button>
                  <RefreshButton action={setRefresh} />{" "}
                </Box>
              }
              title={`Exam list of ${searchParams.get("batch")}`}
            ></CardHeader>
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Batch Id</TableCell>
                    <TableCell>Exam date</TableCell>
                    <TableCell>Exam time</TableCell>
                    <TableCell>Exam status</TableCell>
                    <TableCell>Exam mark</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableLoader />
                ) : examList.length ? (
                  <TableBody>
                    {examList.map((examList: IExamList) => {
                      return (
                        <ExamListTableRow
                          key={examList.training_exam_id}
                          examList={examList}
                        />
                      );
                    })}
                  </TableBody>
                ) : (
                  <TableNotFound />
                )}
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={examList.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <CreateExam
            batchId={batchId}
            handleClose={handleClose}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ExamList;
