import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CssTextField } from "./CustomTextFiled";
import AddressSelect from "src/content/modules/Members/subModules/memberList/singleMember/AddressSelect/AddressSelect";
import { ISingleTraineeApplication } from "src/Utils/type";
import ApplicationAddressSelect from "./ApplicationAddressSelect";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const TraineeInfo = ({
  register,
  setAreaID,
  singleTraineeApplication,
}: {
  singleTraineeApplication: ISingleTraineeApplication;
  register: any;
  setAreaID: any;
}) => {
  return (
    <Box px={2} py={2}>
      <Grid container spacing={2}>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic61"
            variant="outlined"
            label="Enter your name"
            defaultValue={
              singleTraineeApplication?.training_course_application_name
            }
            type="text"
            fullWidth
            {...register("training_course_application_name")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="email"
            label="Enter you email"
            type="email"
            defaultValue={
              singleTraineeApplication?.training_course_application_email
            }
            style={{ width: "100%" }}
            {...register("training_course_application_email")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          {" "}
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date of birth"
              format="MM/DD/YYYY"
              value={dayjs(
                singleTraineeApplication?.training_course_application_birthdate
              )}
              {...register("training_course_application_birthdate")}
            />
          </LocalizationProvider> */}
          <CssTextField
            id="date"
            label="Date of birth"
            type="date"
            defaultValue={
              singleTraineeApplication?.training_course_application_birthdate
            }
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            {...register("training_course_application_birthdate")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select gender"
              defaultValue={
                singleTraineeApplication?.training_course_application_gender
              }
              {...register("training_course_application_gender")}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic18"
            variant="outlined"
            type="number"
            label="Enter your nid number"
            defaultValue={
              singleTraineeApplication?.training_course_application_nid_number
            }
            fullWidth
            {...register("training_course_application_nid_number")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic26"
            variant="outlined"
            type="file"
            label="Upload your NID (Both side)"
            InputLabelProps={{
              shrink: true,
            }}
            {...register("training_course_application_nid_front_file")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic36"
            variant="outlined"
            type="text"
            defaultValue={
              singleTraineeApplication?.training_course_application_present_address
            }
            label="Enter your present address"
            fullWidth
            {...register("training_course_application_present_address")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic36"
            variant="outlined"
            type="text"
            label="Enter your parmanent address"
            defaultValue={
              singleTraineeApplication?.training_course_application_parmament_address
            }
            fullWidth
            {...register("training_course_application_parmament_address")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic36"
            variant="outlined"
            type="text"
            label="Enter your mobile no"
            defaultValue={
              singleTraineeApplication?.training_course_application_mobile
            }
            fullWidth
            {...register("training_course_application_mobile")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic36"
            variant="outlined"
            type="text"
            label="Enter your whatsapp no"
            defaultValue={
              singleTraineeApplication?.training_course_application_whatsapp_number
            }
            fullWidth
            {...register("training_course_application_whatsapp_number")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic43"
            variant="outlined"
            type="file"
            label="Upload your cv/resume"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            {...register("training_course_application_cv_file")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic54"
            variant="outlined"
            label="Last academic qualification"
            type="text"
            defaultValue={
              singleTraineeApplication?.training_course_application_last_academic_qualification
            }
            fullWidth
            {...register(
              "training_course_application_last_academic_qualification"
            )}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic64"
            variant="outlined"
            type="file"
            label="Upload your academic certificate"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            {...register(
              "training_course_application_last_academic_certificate_file"
            )}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic73"
            variant="outlined"
            type="file"
            label="Upload your signature photo"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            {...register("training_course_application_signature_file")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic73"
            variant="outlined"
            type="file"
            label="Upload your passport size photo"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            {...register("training_course_application_photo")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic83"
            variant="outlined"
            label="Enter your religion"
            type="text"
            defaultValue={
              singleTraineeApplication?.training_course_application_religion
            }
            fullWidth
            {...register("training_course_application_religion")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic83"
            variant="outlined"
            label="Enter your nationality"
            defaultValue={
              singleTraineeApplication?.training_course_application_nationality
            }
            type="text"
            fullWidth
            {...register("training_course_application_nationality")}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Blood Group
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=" Select Blood Group"
              defaultValue={
                singleTraineeApplication?.training_course_application_blood_group
              }
              {...register("training_course_application_blood_group")}
            >
              <MenuItem value={"A+"}>A+</MenuItem>
              <MenuItem value={"B+"}>B+</MenuItem>
              <MenuItem value={"AB+"}>AB+</MenuItem>
              <MenuItem value={"O+"}>O+</MenuItem>
              <MenuItem value={"A-"}>A-</MenuItem>
              <MenuItem value={"B-"}>B-</MenuItem>
              <MenuItem value={"AB-"}>AB-</MenuItem>
              <MenuItem value={"O-"}>0-</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} md={4} lg={3} item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Marital Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select maritial status"
              defaultValue={
                singleTraineeApplication?.training_course_application_maritial_status
              }
              {...register("training_course_application_maritial_status")}
            >
              <MenuItem value={"Single"}>Single</MenuItem>
              <MenuItem value={"Married"}>Married</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <ApplicationAddressSelect
          setAreaID={setAreaID}
          singleTraineeApplication={singleTraineeApplication}
        />{" "}
        <Grid xs={12} md={4} lg={3} item>
          <CssTextField
            id="outlined-basic18"
            variant="outlined"
            type="number"
            label="Enter post code"
            fullWidth
            defaultValue={
              singleTraineeApplication?.training_course_application_post_code
            }
            {...register("training_course_application_post_code")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TraineeInfo;
