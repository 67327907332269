import { useState, useEffect } from "react";

import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { url } from "../../../../../Helpers/utils/Constant";
import axios from "axios";
import Toaster from "src/Toaster/Toaster";
import { useParams } from "react-router-dom";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CreateClass = ({ handleClose }) => {
  const { handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState<Boolean>(false);
  const currentDate = new Date();
  const [newDate, setNewDate] = useState<Dayjs | null>(dayjs());
  const { id } = useParams();
  const { user } = useAuthContext();
  const [timeValue, setTimeValue] = useState({
    training_class_start_time: dayjs(),
    training_class_end_time: dayjs(),
  });

  const onSubmit = async () => {
    const inputs = {
      training_class_batch: id,
      training_class_date: newDate.toISOString().split("T")[0],
      training_class_start_time: timeValue.training_class_start_time,

      training_class_end_time: timeValue.training_class_end_time,
    };

    setLoading(true);
    try {
      const { data } = await axios.post(
        `${url}/api/training/class/create?user_admin_id=${user.user_admin_id}`,
        inputs
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: `${data.message}`,
        });
        setLoading(false);
        handleClose();
      } else {
        Toaster().fire({
          icon: "fail",
          title: `${data.message}`,
        });
        setLoading(false);
      }

      reset();
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
    reset();
  };

  return (
    <>
      <Helmet>
        <title>Atab - Create Class</title>
      </Helmet>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        // sx={{ p: 2, mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid lg={12} item>
            {" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Select Date"
                format="MM/DD/YYYY"
                value={newDate}
                sx={{ width: "100%" }}
                onChange={(e) => setNewDate(e)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid lg={6} md={6} sm={12} item>
            {" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Start time"
                value={timeValue.training_class_start_time}
                sx={{ width: "100%" }}
                onChange={(e: any) =>
                  setTimeValue({
                    ...timeValue,
                    training_class_start_time: e,
                  })
                }
              />
            </LocalizationProvider>
            {/* <TimePicker
              label="Class Start Time"
              value={timeValue.training_class_start_time}
              onChange={(e: any) =>
                setTimeValue({
                  ...timeValue,
                  training_class_start_time: e,
                })
              }
            /> */}
          </Grid>
          <Grid lg={6} md={6} sm={12} item>
            {" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="End time"
                value={timeValue.training_class_end_time}
                sx={{ width: "100%" }}
                onChange={(e: any) =>
                  setTimeValue({
                    ...timeValue,
                    training_class_end_time: e,
                  })
                }
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        {loading ? (
          <Button
            disabled
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Creating...
          </Button>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Create Class
          </Button>
        )}
      </Box>
    </>
  );
};

export default CreateClass;
