import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  CardContent,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { IFairSingleMemberApplication } from "src/Types/module/fairSingleMemberApplication/fairSingleMemberApplication";

interface IProps {
  details: IFairSingleMemberApplication;
  setReload: Dispatch<SetStateAction<boolean>>;
  handleClose: Function;
}

const ApplicationUpdateForm = ({ details, handleClose, setReload }: IProps) => {
  const { user } = useAuthContext();
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>("");
  const Toast = Toaster();

  useEffect(() => {
    if (details.fair_stall_application_status) {
      setStatus(details.fair_stall_application_status);
    }
  }, [details.fair_stall_application_status]);
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setStatus(e.target.value);
    }
  };

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (details.user_fair_member_id && status && user.user_admin_id) {
      try {
        const { data } = await axios.put(
          `${url}/api/fair/fair-application/update/by-admin`,
          {
            fair_stall_application_id:
              details.fair_stall_application_id.toString(),
            fair_stall_application_status: status,
            fair_stall_application_updated_by: user.user_admin_id,
          }
        );

        if (data.success) {
          Toast.fire({
            icon: "success",
            title: `Update Successfully`,
          });
          setReload((prev: Boolean) => !prev);
          handleClose();
        }
      } catch (err: any) {
        Toast.fire({
          icon: "error",
          title: `${err.response?.data?.message}`,
        });
      }
    }
  };

  return (
    <>
      <Divider />
      {details && (
        <CardContent>
          <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
            }}
          >
            <Grid item xs={12}>
              <FormControl sx={{ mt: 2 }} required fullWidth>
                <InputLabel required id='demo-simple-select-label'>
                  Fair status
                </InputLabel>
                <Select
                  id='outlined-required'
                  value={status}
                  label='Fair status'
                  required
                  onChange={handleStatusChange}
                >
                  <MenuItem value='approved'>approved</MenuItem>
                  <MenuItem value='pending'>pending</MenuItem>
                  <MenuItem value='rejected'>rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Update status
            </Button>
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default ApplicationUpdateForm;
