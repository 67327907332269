import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  CardContent,
  Container,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { debounce } from "lodash";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import MoneyReceiptTableRow from "../components/MoneyReceiptTableRow";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreateMoneyReceipt from "../components/CreateMoneyReceipt";
import { ITraineeMoneyReceipt } from "../types/traineeMoneyReceiptTypes";

const TraineeMoneyReceiptTable = () => {
  const [limit, setLimit] = useState<number>(25);
  const [total, setTotal] = useState<number>(0);
  const [openManual, setOpenManual] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [allInvoices, setAllInvoices] = useState<ITraineeMoneyReceipt[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [_searchInvoiceVal, _setSearchInvoiceVal] = useState<string>("");
  // const searchQuery = useDebounce(searchInvoiceVal, 1000);
  const [refresh, setRefresh] = useState(false);

  const [filterItem, setFilterItem] = useState({
    receipt_no: "",
    trainee_name: "",
  });
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
  });

  const page = parseInt(searchParams.get("page"));

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/admin/training/money-receipt?limit=${limit}&skip=${limit * page}`;
        if (filterItem.receipt_no) {
          uri += `&money_receipt_no=${filterItem?.receipt_no}`;
        }
        if (filterItem.trainee_name) {
          uri += `&trainee_name=${filterItem?.trainee_name}`;
        }
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAllInvoices(res.data);
          setLoading(false);
          setTotal(res.total);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [page, limit, filterItem.trainee_name, filterItem.receipt_no, refresh]);

  const handlePageChange = (_event: any, newPage: number): void => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };
  // const handleStatus = (e: SelectChangeEvent<string>) => {
  //   searchParams.set("status", e.target.value);
  //   setSearchParams(searchParams);
  // };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  // const paginatedInvoice = applyPagination(allInvoices, page, limit);

  const handelInputChange = debounce((value: string, key: string) => {
    setFilterItem((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  }, 2000);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <TextField
                    label="Search by money receipt no"
                    id="standard-search"
                    type="search"
                    size="small"
                    variant="outlined"
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) =>
                        handelInputChange(e.target.value, "receipt_no")
                      // setSearchInvoiceVal(e.target.value)
                    }
                  />
                  <TextField
                    label="Search by trainee name"
                    id="standard-search"
                    type="search"
                    size="small"
                    variant="outlined"
                    onChange={
                      (e: React.ChangeEvent<HTMLInputElement>) =>
                        handelInputChange(e.target.value, "trainee_name")
                      // setSearchInvoiceVal(e.target.value)
                    }
                  />
                  {/* <FormControl variant="outlined" sx={{ width: "150px" }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => handleStatus(e)}
                      label="Status"
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <Button onClick={() => setOpen(true)} variant="contained">
                    Create money receipt
                  </Button>
                  {/* <Button
                    onClick={() => setOpenManual(true)}
                    variant="contained"
                  >
                    Create manual invoice
                  </Button> */}
                </Box>
              }
              title="Money receipt list"
            />
            <Divider />

            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Money Receipt no</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Account</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : allInvoices.length ? (
                      <>
                        {allInvoices.map((sInvoice) => (
                          <MoneyReceiptTableRow
                            key={sInvoice.id}
                            sInvoice={sInvoice}
                            allInvoices={allInvoices}
                            setAllInvoices={setAllInvoices}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box p={2}>
                <TablePagination
                  component="div"
                  count={total}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page && page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                />
              </Box>
            </CardContent>

            {/* Modal */}
            <ModalsWrapper
              modalData={{ title: "Create money receipt" }}
              setShowModal={setOpen}
              showModal={open}
            >
              <CreateMoneyReceipt
                setRefresh={setRefresh}
                handelClose={() => setOpen(false)}
              />
            </ModalsWrapper>

            {/* mamual invoice  */}
            {/* <ModalsWrapper
              modalData={{ title: "Create manual invoice" }}
              setShowModal={setOpenManual}
              showModal={openManual}
            >
              <CreateManualInvoice handleClose={() => setOpenManual(false)} />
            </ModalsWrapper> */}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TraineeMoneyReceiptTable;
