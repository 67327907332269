import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import LongCourseRegistrationForm from "./LongCourseRegistrationForm";
import ShortCourseRegistrationForm from "./ShortCourseRegistrationForm";
import {
  Button,
  Card,
  Grid,
  Typography,
  Box,
  Container,
  CardHeader,
} from "@mui/material";
import RegisterForm from "./RegisterForm";
import { ISingleTraineeApplication } from "src/Utils/type";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import dayjs from "dayjs";

const UpdateSingleTraineeApplication = () => {
  const { singleTraineeId } = useParams();
  const { register, handleSubmit, control } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkValue, setCheckValue] = useState<string>("");

  const [loading, setLoading] = useState(false);
  const [searchAbleValue, setSearchAbleValue] = useState<any>({
    user_member_company_id: 0,
  });
  const [singleTraineeApplication, setSingleTraineeApplication] =
    useState<ISingleTraineeApplication>({} as ISingleTraineeApplication);
  const [sourceInfo, setSourceInfo] = useState<string>(
    singleTraineeApplication?.training_course_application_info_source
  );
  const [otherSource, setOtherSource] = useState<string>("");
  const [course, SetCourse] = useState<string>("");
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    axios
      .get(`${url}/api/training/course/application/${singleTraineeId}`, {
        cancelToken: source.token,
      })
      .then((data) => {
        if (data?.data.success) {
          setSingleTraineeApplication(data.data.data);
          setSourceInfo(
            data?.data?.data?.training_course_application_info_source
          );
          SetCourse(
            String(data.data?.data.training_course_application_course_id)
          );
          setCheckValue(
            data?.data?.data?.training_course_application_member_id ? "Yes" : ""
          );

          // setSearchAbleValue({
          //   ...searchAbleValue,
          //   user_member_company_id:
          //     data?.data?.data?.training_course_application_member_id,
          // });
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [singleTraineeId]);

  // const course = useWatch({
  //   control,
  //   name: "training_course_application_course_id",
  // });

  const [areaID, setAreaID] = useState<number>(0);

  const onSubmit = async (inputData: any) => {
    setIsLoading(true);
    if (sourceInfo) {
      if (sourceInfo === "other") {
        inputData.training_course_application_info_source = otherSource;
      } else {
        inputData.training_course_application_info_source = sourceInfo;
      }
    } else {
      alert("Please select source of information");
      setIsLoading(false);
      return;
    }

    inputData.training_course_application_course_id = course;

    if (otherSource)
      inputData.training_course_application_info_source = otherSource;
    if (sourceInfo)
      inputData.training_course_application_info_source = sourceInfo;
    if (checkValue) {
      inputData.training_course_application_member_id =
        searchAbleValue.user_member_company_id;
    }
    if (areaID) {
      inputData.training_course_application_area_id = areaID;
    }
    const formData = new FormData();
    formData.append(
      "training_course_application_trainee_id",
      singleTraineeApplication.training_course_application_trainee_id?.toString()
    );

    for (const key in inputData) {
      if (inputData[key]) {
        if (
          key === "training_course_application_nid_front_file" ||
          key === "training_course_application_cv_file" ||
          key ===
            "training_course_application_last_academic_certificate_file" ||
          key === "training_course_application_signature_file" ||
          key === "training_course_application_photo" ||
          key === "training_course_application_member_document"
        ) {
          if (inputData[key][0]) formData.append(key, inputData[key][0]);
        } else if (key === "training_course_application_birthdate") {
          formData.append(key, dayjs(inputData[key]).format("YYYY-MM-DD"));
        } else {
          formData.append(key, inputData[key]);
        }
      }
    }

    try {
      const { data } = await axios.patch(
        `${url}/api/training/course/application/${singleTraineeId}`,
        formData
      );
      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: "Application successfully update",
        });
        // navigate("/dashboard/my-join-course");

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      setIsLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} mt={5} mb={5}>
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12}>
                <Card sx={{ pb: 3, pl: 2 }}>
                  <CardHeader
                    title={
                      <Typography
                        sx={{
                          color: "#081245",
                          fontWeight: "700",

                          fontSize: 20,
                        }}
                      >
                        Trainee Applications update
                      </Typography>
                    }
                  />
                  {/* <Divider /> */}

                  <RegisterForm
                    register={register}
                    SetCourse={SetCourse}
                    course={course}
                  />
                </Card>
              </Grid>
            </Grid>
          </Container>

          <>
            {course ? (
              <>
                {Number(course) === 1 ? (
                  <LongCourseRegistrationForm
                    register={register}
                    setSourceInfo={setSourceInfo}
                    sourceInfo={sourceInfo}
                    setCheckValue={setCheckValue}
                    checkValue={checkValue}
                    searchAbleValue={searchAbleValue}
                    setSearchAbleValue={setSearchAbleValue}
                    setAreaID={setAreaID}
                    singleTraineeApplication={singleTraineeApplication}
                  />
                ) : (
                  <ShortCourseRegistrationForm
                    register={register}
                    setSourceInfo={setSourceInfo}
                    sourceInfo={sourceInfo}
                    setCheckValue={setCheckValue}
                    checkValue={checkValue}
                    searchAbleValue={searchAbleValue}
                    setSearchAbleValue={setSearchAbleValue}
                    setOtherSource={setOtherSource}
                    setAreaID={setAreaID}
                    singleTraineeApplication={singleTraineeApplication}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </>

          <Box sx={{ mt: 4, textAlign: "center", mb: 3 }}>
            {isLoading ? (
              <Button
                type="submit"
                variant="contained"
                disabled
                size="large"
                sx={{ width: "30%" }}
              >
                Loading...
              </Button>
            ) : (
              <Button
                type="submit"
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                  background: "#1976d2",
                  width: "30%",
                }}
                // className={classes.registerButton}
                variant="contained"
                disableElevation
              >
                Update Application
              </Button>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default UpdateSingleTraineeApplication;
