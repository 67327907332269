import { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import { imgUrl, url } from "src/Helpers/utils/Constant";
import { useParams } from "react-router-dom";

import {
  ICompanyBusinessLicense,
  ICompanyBusinessLicenseResponse,
} from "src/Types/module/member/CompanyInformation";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CompanyBusinessLicense from "../../../CompanyAddInfo/UpdateCompanyInformation/CompanyBusinessLicense";
import OtherBusinessLicense from "../../../CompanyAddInfo/CompanyAddInfoComponents/OtherBusinessLicense";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { HttpClient } from "src/Helpers/http/http";
import PdfAndImageView from "src/components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "src/components/pdfAndImageView/CommonTypoGraphy";

function BusinessLicenseInfo() {
  const { memberId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [businessLicense, setBusinessLicense] =
    useState<ICompanyBusinessLicense>({} as ICompanyBusinessLicense);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [imageViewOpen, SetImageViewOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const data: ICompanyBusinessLicenseResponse = await HttpClient.get(
          `/api/atab/admin/member-company/member/business-license/${memberId}`
        );
        if (data.success) {
          setBusinessLicense(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [memberId, refresh]);

  const {
    hajj_license,
    hajj_license_file,
    umrah_license,
    umrah_license_file,
    recruting_license,
    recruting_license_file,
    inbound,
    outbound,
  } = businessLicense || {};
  return (
    <>
      {/* {applied ? ( */}
      <>
        <PageTitleWrapper>
          <PageTitle
            heading="Business license information"
            actionButtonTitle={
              hajj_license ||
              umrah_license ||
              recruting_license ||
              inbound ||
              outbound
                ? "Update"
                : "Add"
            }
            setAction={setOpen}
          />
        </PageTitleWrapper>
        {loading ? (
          <GlobalLoader />
        ) : (
          <>
            {hajj_license ||
            umrah_license ||
            recruting_license ||
            inbound ||
            outbound ? (
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Hajj license:
                          </TableCell>
                          <TableCell align="right">
                            {hajj_license ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>

                        {hajj_license ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of HAJJ :
                            </TableCell>
                            <TableCell align="right">
                              {hajj_license_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(hajj_license_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Umrah license:
                          </TableCell>
                          <TableCell align="right">
                            {umrah_license ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>

                        {umrah_license ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of UMRA:
                            </TableCell>
                            <TableCell align="right">
                              {umrah_license_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(umrah_license_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}{" "}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table aria-label="custom pagination table">
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Recruiting license :
                          </TableCell>
                          <TableCell align="right">
                            {recruting_license ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                        {recruting_license ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Certificate of Recruiting:
                            </TableCell>
                            <TableCell align="right">
                              {recruting_license_file ? (
                                <CommonTypoGraphy
                                  name="View"
                                  onClick={() => {
                                    SetImageViewOpen(true);
                                    setImageFile(recruting_license_file);
                                  }}
                                />
                              ) : (
                                "Not provided"
                              )}{" "}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Tourism activities inbound :
                          </TableCell>
                          <TableCell align="right">
                            {inbound === 1 ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Tourism activities outbound:
                          </TableCell>
                          <TableCell align="right">
                            {outbound === 1 ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="h3"
                gutterBottom
                sx={{ textAlign: "center" }}
              >
                No data found
              </Typography>
            )}
          </>
        )}
      </>
      {/* ) : (
        <Typography variant="h3" gutterBottom sx={{ textAlign: "center" }}>
          No data found
        </Typography>
      )} */}

      <ModalsWrapper
        setShowModal={setOpen}
        showModal={open}
        modalData={{
          title:
            hajj_license ||
            umrah_license ||
            recruting_license ||
            inbound ||
            outbound
              ? "Update business license information"
              : "Add business license information",
        }}
      >
        {hajj_license ||
        umrah_license ||
        recruting_license ||
        inbound ||
        outbound ? (
          <CompanyBusinessLicense
            memberId={memberId}
            businessLicense={businessLicense}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        ) : (
          <OtherBusinessLicense
            memberId={memberId}
            setRefresh={setRefresh}
            setOpen={setOpen}
            refresh={refresh}
          />
        )}
      </ModalsWrapper>

      <PdfAndImageView
        setOpen={SetImageViewOpen}
        open={imageViewOpen}
        file={imageFile}
      />
    </>
  );
}

export default BusinessLicenseInfo;
