import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ISinglePermission } from "src/Types/module/roleAndPermission/roleAndPermissionType";

interface IProps {
  singleRoleDetails: ISinglePermission;
}
const SingleRoleDetails = ({ singleRoleDetails }: IProps) => {
  const colors = [
    "rgba(225, 184, 213, 0.3)",
    "rgba(186, 227, 213, 0.3)",
    "rgba(186, 195, 232, 0.3)",
    "rgba(130, 190, 201, 0.3)",
    "rgba(130, 190, 134, 0.3)",
    "rgba(24, 132, 248, 0.3)",
  ];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        gap: 2,
        bgcolor: "#ededed",
        m: 2,
        p: 2,
        borderRadius: 0.5,
      }}
    >
      <Grid item xs={12} lg={4}>
        <Typography variant="h5">
          {singleRoleDetails.permissions_section
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ display: "flex", flexWrap: "wrap" }}>
        {singleRoleDetails.permissions.map((item) => (
          <Box
            key={item.permissions_id}
            sx={{
              m: 0.5,
              px: 1,
              py: 0.5,
              border: 1,
              borderColor: randomColor,
              borderRadius: "5px",
              backgroundColor: randomColor,
            }}
          >
            <span>
              {" "}
              {item.permissions_name
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </span>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default SingleRoleDetails;
