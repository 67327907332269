import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  TextField,
  Container,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Chip,
  Autocomplete,
  Grid,
  debounce,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { muiStyles } from "src/Utils/Styles";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import {
  IFairPaymentableItem,
  IpaymentableItem,
  IuserMemberList,
} from "src/Types/module/invoice/invoiceTypes";
import { ItraineeList } from "src/Types/module/courses/trainee";
import { HttpClient } from "src/Helpers/http/http";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { IAccountType } from "../../Members/MemberAccount/Account/types/AccountTypes";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DynamicTraineeInvoiceField from "./DynamicTraineeInvoiceField";

interface IPropsMember {
  user_training_trainee_id: number;
  user_training_trainee_account_status: string;
  user_training_trainee_email: string;
  user_training_trainee_name: string;
  user_training_trainee_photo: string;
}
const useStyles = muiStyles;
interface ITraineePaymentabeltem {
  training_paymentable_item_id: number;
  training_paymentable_item_name: string;
  training_paymentable_item_bdt_amount: string;
  training_paymentable_item_usd_amount: string;
}
interface IProps {
  handleClose: any;
  setRefresh: (callback: (prev: boolean) => boolean) => void;
}

const CreateTrainingInvoice = ({ handleClose, setRefresh }: IProps) => {
  const { user } = useAuthContext();
  const [allPaymentableItem, setAllPaymentableItem] = useState<
    ITraineePaymentabeltem[]
  >([]);
  const [userMember, setUserMember] = useState<IPropsMember[]>([]);
  const [singleTrainee, setSingleTrainee] = useState<IPropsMember | null>(null);
  const [_isLoading, setIsLoading] = useState<Boolean>(false);
  const [companyName, seTCompanyName] = useState<string>("");
  const { register, handleSubmit, control, watch, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const withMoneyReceipt = watch("with_mr");
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState<IAccountType[]>([]);
  // console.log({ userMember, allPaymentableItem });
  const [loading, setLoading] = useState(false);
  const paymentMethod = watch("payment_method");
  if (fields.length === 0) {
    append({ id: "", amount: "", discount: "" });
  }
  useEffect(() => {
    setIsLoading(true);
    try {
      if (user?.user_admin_id) {
        setIsLoading(true);
        const urls = [
          `${url}/api/training/payment/get/all/paymentable/item`,
          `${url}/api/training/trainee/get/all/or/status/all?user_training_trainee_name=${companyName}`,
        ];
        Promise.all(
          urls.map((url) => fetch(url).then((response) => response.json()))
        ).then((data) => {
          if (data?.length) {
            setAllPaymentableItem(data[0].data);
            setUserMember(data[1].data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user.user_admin_id, companyName]);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/common-account?CompanyProfileId=2`)
          .then(function (res: any) {
            if (res.success) {
              setAccountData(res.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(function (err) {
            setAccountData([]);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (submitData: any) => {
    setLoading(true);

    if (singleTrainee) {
      submitData.trainee_id = singleTrainee.user_training_trainee_id;
    }
    try {
      const res: any = await HttpClient.post(
        `${url}/api/admin/training/invoice`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        setLoading(false);
        handleClose();
        setRefresh((prv) => !prv);
        if (res?.data?.direct_mr) {
          navigate(`/trainee-money-receipt/details/${res.data.id}`);
        } else {
          navigate(`/training-invoice/details/${res.data.id}`);
        }
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };
  const handelInputChange = debounce((value: string) => {
    seTCompanyName(value);
  }, 500);

  return (
    <>
      <Helmet>
        <title>Atab - Create training trainee invoice</title>
      </Helmet>

      <Box
        sx={{
          mx: 4,
          mt: 2,
          maxWidth: "800px",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Autocomplete
              value={singleTrainee}
              onChange={(event: any, newValue: any) => {
                setSingleTrainee(newValue);
              }}
              id="controllable-states-demo"
              options={userMember}
              onInputChange={(_event, newInputValue) => {
                handelInputChange(newInputValue);
              }}
              getOptionLabel={(option: ItraineeList) =>
                option.user_training_trainee_name
              }
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant="filled"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select trainee"
                  placeholder="Select trainee Member"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              fullWidth
              required
              id="invoice_date"
              label="Invoice Date"
              type="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              {...register("invoice_date")}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Expense Items */}
            {fields.map((item, index) => {
              return (
                <DynamicTraineeInvoiceField
                  register={register}
                  allPaymentableItem={allPaymentableItem}
                  item={item}
                  index={index}
                  key={index}
                  control={control}
                  remove={remove}
                  fields={fields}
                  setValue={setValue}
                />
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              onClick={() => append({ id: "", amount: "", discount: "" })}
            >
              Add Item
            </Button>

            <FormControlLabel
              style={{ marginLeft: "30px" }}
              control={<Checkbox {...register("with_mr")} />}
              label="With Money Receipt"
            />
          </Grid>

          {withMoneyReceipt && (
            <>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={12}
                md={12}
                lg={6}
              >
                <TextField
                  fullWidth
                  select
                  required
                  sx={{ mt: 1 }}
                  id="payment_method"
                  label="Payment Method"
                  variant="outlined"
                  {...register("payment_method")}
                >
                  {["Mobile Banking", "Cash", "Bank", "Cheque"].map(
                    (method) => (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormControl fullWidth required margin="normal">
                  <InputLabel id="demo-simple-select-label">Account</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("account_id")}
                    label="Account"
                  >
                    {accountData?.map((account) => {
                      return (
                        <MenuItem key={account.id} value={account.id}>
                          {account.account_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {paymentMethod === "Cheque" && withMoneyReceipt && (
            <>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="BankName"
                  label="Bank Name"
                  type="text"
                  variant="outlined"
                  {...register("BankName")}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="chequeNo"
                  label="Cheque Number"
                  type="text"
                  variant="outlined"
                  {...register("chequeNo")}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  id="ChequeDate"
                  label="Cheque Date"
                  type="date"
                  required
                  defaultValue={moment().format("YYYY-MM-DD")}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  {...register("ChequeDate")}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid sx={{ mt: 2 }} item xs={12} md={12} lg={12}>
          <TextField
            fullWidth
            required
            rows={3}
            multiline
            id="remark"
            label="Remark"
            type="text"
            variant="outlined"
            {...register("remark")}
          />
        </Grid>
        <Box textAlign={"center"} mt={3}>
          {loading ? (
            <LoadingButton
              loading={loading}
              fullWidth
              color="secondary"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant="contained"
            >
              Loading...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateTrainingInvoice;
