import React from "react";
import { ISingleCompanyInfo } from "src/Types/module/UpdateRequest/RequestCompanyInfo";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import { imgUrl } from "src/Helpers/utils/Constant";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import { makeStyles } from "@mui/styles";

interface IProps {
  requestCompanyInfo: ISingleCompanyInfo;
}

const useStyles = makeStyles({
  TableCell: {
    fontWeight: "bold !important",
  },
});
const CompanyInfoAppReject = ({ requestCompanyInfo }: IProps) => {
  const classes = useStyles();
  const {
    member_company_type,
    member_company_stablished_date,
    member_company_website,
    member_company_logo,
    phone,
    cellphone,
    email,
    addressline1,
    postalcode,
    police_station,
    status,
    zone_name,
    reject_reason,
    updated_at,
  } = requestCompanyInfo || {};
  return (
    <Container>
      <Card>
        <CardHeader
          title={
            status === "approved"
              ? "Approved information"
              : "Rejected information"
          }
        />

        <Container>
          <Grid sx={{ pb: 5 }} container spacing={2}>
            <Grid item xs={12} md={6}>
              <TableContainer>
                <Table aria-label="custom pagination table">
                  <TableBody>
                    {updated_at && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Update date :
                        </TableCell>
                        <TableCell align="right">
                          {moment(updated_at).format("Do MMM YYYY")}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell className={classes.TableCell}>
                        status:
                      </TableCell>
                      <TableCell align="right">
                        {getStatusLabel(status)}
                      </TableCell>
                    </TableRow>
                    {reject_reason && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Reject reason:
                        </TableCell>
                        <TableCell align="right">{reject_reason}</TableCell>
                      </TableRow>
                    )}
                    {member_company_type && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Company type:
                        </TableCell>
                        <TableCell align="right">
                          {member_company_type}
                        </TableCell>
                      </TableRow>
                    )}

                    {phone && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Phone number:
                        </TableCell>
                        <TableCell align="right">{phone}</TableCell>
                      </TableRow>
                    )}
                    {cellphone && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Cell phone number:
                        </TableCell>
                        <TableCell align="right">{cellphone}</TableCell>
                      </TableRow>
                    )}
                    {email && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Company email:
                        </TableCell>
                        <TableCell align="right">{email}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer>
                <Table aria-label="custom pagination table">
                  <TableBody>
                    {addressline1 && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Company address:
                        </TableCell>
                        <TableCell align="right">{addressline1}</TableCell>
                      </TableRow>
                    )}
                    {member_company_website && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Company website:
                        </TableCell>
                        <TableCell align="right">
                          <a href={member_company_website} target="_blank">
                            {member_company_website}
                          </a>
                        </TableCell>
                      </TableRow>
                    )}
                    {member_company_logo && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Company logo:
                        </TableCell>
                        <TableCell align="right">
                          <a
                            href={`${imgUrl}/member_${member_company_logo}`}
                            target="_blank"
                          >
                            {member_company_logo}
                          </a>
                        </TableCell>
                      </TableRow>
                    )}
                    {member_company_stablished_date && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Company stablish date :
                        </TableCell>
                        <TableCell align="right">
                          {moment(member_company_stablished_date).format(
                            "Do MMM YYYY"
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                    {police_station && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Police station:
                        </TableCell>
                        <TableCell align="right">{police_station}</TableCell>
                      </TableRow>
                    )}

                    {postalcode && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Post code:
                        </TableCell>
                        <TableCell align="right">{postalcode}</TableCell>
                      </TableRow>
                    )}
                    {zone_name && (
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Zone name:
                        </TableCell>
                        <TableCell align="right">{zone_name}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </Card>
    </Container>
  );
};

export default CompanyInfoAppReject;
