import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import moment from "moment";
import { getStatusLabel } from "src/Utils/Combined";
import { invoiceByFairMember } from "src/Utils/type";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

function SingleExhibitorInvoice({ memberInvoice }) {
  const theme = useTheme();
  return (
    <TableContainer sx={{ marginBottom: 10 }}>
      <Table>
        <TableHead sx={{ backgroundColor: theme.colors.primary.main }}>
          <TableRow>
            <TableCell sx={{ color: theme.colors.alpha.white[100] }}>
              Invoice ID
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Invoice transaction date
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Member ID
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Updated By
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Total amount
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Invoice remark
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Payment method
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Status
            </TableCell>
            <TableCell
              sx={{ color: theme.colors.alpha.white[100] }}
              align="center"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memberInvoice ? (
            memberInvoice.map((details: invoiceByFairMember) => (
              <TableRow hover key={details.fair_invoice_id}>
                <TableCell align="center">{details.fair_invoice_id}</TableCell>
                <TableCell align="center">
                  {moment(details.fair_invoice_transaction_date).format("L")}
                </TableCell>
                <TableCell align="center">{details.fair_member_id}</TableCell>
                <TableCell align="center">
                  {details.fair_invoice_updated_by}
                </TableCell>
                <TableCell align="center">{details.total_amount}</TableCell>
                {details.fair_invoice_remark ? (
                  <TableCell align="center">
                    {details?.fair_invoice_remark}
                  </TableCell>
                ) : (
                  <TableCell align="center">Null</TableCell>
                )}
                {details.fair_invoice_payment_method ? (
                  <TableCell align="center">
                    {details?.fair_invoice_payment_method}
                  </TableCell>
                ) : (
                  <TableCell align="center">Null</TableCell>
                )}
                <TableCell align="center">
                  {getStatusLabel(details.fair_invoice_status)}
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={`/exhibitor/invoice-list/${details.fair_invoice_id}`}
                  >
                    <Tooltip title="view" arrow>
                      <IconButton
                        sx={{
                          "&:hover": {
                            background: theme.colors.primary.lighter,
                          },
                          color: theme.palette.primary.main,
                        }}
                        color="inherit"
                        size="small"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Link>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <h2>Not Found</h2>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SingleExhibitorInvoice;
