import { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import CurrentTradeLicenseInfo from "./CurrentOtherBusinessLicenseInfo";
import UpdateTradeLicenseInfo from "./UpdateOtherBusinessLicenseInfo";
import { ISingleOtherBusinessLicense } from "src/Types/module/UpdateRequest/RequestOtherBusinessLicense";
import { useParams, useSearchParams } from "react-router-dom";
import OtherBusinessLicenseAppReject from "./OtherBusinessLicenseAppReject";

const RequestSingleOtherBusinessLicenseInfo = () => {
  const { id } = useParams();
  const [query] = useSearchParams();
  const status = query.get("status");
  const memberID = query.get("memberid");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestOtherBusinessLicenseInfo, setRequestOtherBusinessLicenseInfo] =
    useState<ISingleOtherBusinessLicense>({} as ISingleOtherBusinessLicense);
  const [preOtherBusinessLicenseInfo, setPreOtherBusinessLicenseInfo] =
    useState<ISingleOtherBusinessLicense>({} as ISingleOtherBusinessLicense);

  const urls = [
    `${url}/api/atab/member-company/update-request/member/association/${id}`,
    `${url}/api/atab/member-company/member/association/${memberID}`,
  ];

  useEffect(() => {
    setIsLoading(true);
    try {
      (async function () {
        const data = await Promise.all(urls.map((url) => axios.get(url)));
        if (data?.length) {
          setRequestOtherBusinessLicenseInfo(data[0]?.data?.data);
          setPreOtherBusinessLicenseInfo(data[1]?.data?.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })();
    } catch (error) {
      setIsLoading(false);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          {requestOtherBusinessLicenseInfo.status === "pending" ? (
            <Container maxWidth="xl" sx={{ mt: 5 }}>
              <Grid sx={{ pb: 5 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CurrentTradeLicenseInfo
                    preOtherBusinessLicenseInfo={preOtherBusinessLicenseInfo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UpdateTradeLicenseInfo
                    requestOtherBusinessLicenseInfo={
                      requestOtherBusinessLicenseInfo
                    }
                    id={id}
                  />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <OtherBusinessLicenseAppReject
              requestOtherBusinessLicenseInfo={requestOtherBusinessLicenseInfo}
            />
          )}
        </>
      )}
    </>
  );
};

export default RequestSingleOtherBusinessLicenseInfo;
