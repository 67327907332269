import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  debounce,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { ITraineeLedger } from "./traineeLedgerReportTypes";
import TraineeLedgerReportTableRow from "./TraineeLedgerReportTableRow";
import { ItraineeList } from "src/Types/module/courses/trainee";

interface IPropsMember {
  user_training_trainee_id: number;
  user_training_trainee_account_status: string;
  user_training_trainee_email: string;
  user_training_trainee_name: string;
  user_training_trainee_photo: string;
}

const SingleTraineeLedgerReport = () => {
  const [TraineeLedgerData, setTraineeLedgerData] = useState<ITraineeLedger[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [singleTrainee, setSingleTrainee] = useState<IPropsMember | null>(null);
  const [userMember, setUserMember] = useState<IPropsMember[]>([]);
  const [companyName, seTCompanyName] = useState<string>("");
  const [startDate, setStartDate] = useState<Dayjs | string>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<Dayjs | string>(
    dayjs().endOf("month")
  );

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/admin/trainee-ledger?from_date=${dayjs(startDate).format("YYYY-MM-DD")}&to_date=${dayjs(endDate).format("YYYY-MM-DD")}`;
        if (singleTrainee?.user_training_trainee_id) {
          uri += `&trainee_id=${singleTrainee?.user_training_trainee_id}`;
        }
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setTraineeLedgerData(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh, startDate, endDate, singleTrainee?.user_training_trainee_id]);

  useEffect(() => {
    (async function () {
      try {
        let uri = `api/training/trainee/get/all/or/status/all?user_training_trainee_name=${companyName}`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setUserMember(res.data);
        } else {
        }
      } catch (error) {}
    })();
  }, [companyName]);

  const handelInputChange = debounce((value: string) => {
    seTCompanyName(value);
  }, 500);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e: any) => setStartDate(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      variant="h6"
                      component="h6"
                      gutterBottom
                    >
                      To
                    </Typography>

                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e: any) => setEndDate(e)}
                    />
                  </Grid>
                  <Grid item style={{ width: "240px" }}>
                    <Autocomplete
                      value={singleTrainee}
                      onChange={(event: any, newValue: any) => {
                        setSingleTrainee(newValue);
                      }}
                      id="controllable-states-demo"
                      options={userMember}
                      onInputChange={(_event, newInputValue) => {
                        handelInputChange(newInputValue);
                      }}
                      getOptionLabel={(option: ItraineeList) =>
                        option.user_training_trainee_name
                      }
                      renderTags={(value: readonly any[], getTagProps) =>
                        value.map((option: any, index: number) => (
                          <Chip
                            variant="filled"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select trainee"
                          placeholder="Select trainee Member"
                        />
                      )}
                    />
                  </Grid>
                </Box>
              }
              title="Trainee Ledger"
            />
            <Divider />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Voucher No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Particular</TableCell>
                      <TableCell style={{ width: "25%" }}>Details</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Method</TableCell>
                      <TableCell>Debit</TableCell>
                      <TableCell>Credit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : TraineeLedgerData.length ? (
                      <>
                        {TraineeLedgerData.map((item) => (
                          <TraineeLedgerReportTableRow
                            key={item.id}
                            singleTraineeLedgerData={item}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SingleTraineeLedgerReport;
