import { Label } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getStatusLabel } from "src/Utils/Combined";
import InvoiceUpdateForm from "./InvoiceUpdateForm";
import { visitorSingleInvoiceInfo } from "src/Utils/type";

const BillingInfo = ({
  singleInvoiceDetails,
  editMode,
  handleEditModeChange,
}: {
  singleInvoiceDetails: visitorSingleInvoiceInfo;
  editMode: boolean;
  handleEditModeChange: Function;
}) => {
  return (
    <div>
      <Box
        sx={{
          mt: 3,
          p: 3,
        }}
      >
        {!editMode ? (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Visitor invoice ID:
              </Typography>
              {singleInvoiceDetails?.visitor_payment_invoice_id}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Visitor ID:
              </Typography>
              {singleInvoiceDetails?.visitor_id}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Visitor name:
              </Typography>
              {singleInvoiceDetails.visitor_first_name}{" "}
              {singleInvoiceDetails.visitor_last_name}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Item ID:
              </Typography>
              {singleInvoiceDetails?.visitor_payment_invoice_item_id}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Total amount:
              </Typography>
              {singleInvoiceDetails?.visitor_payment_invoice_total_amount}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Item ID:
              </Typography>
              {singleInvoiceDetails?.visitor_payment_invoice_item_name}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Invoice item amount:
              </Typography>
              {singleInvoiceDetails?.visitor_payment_invoice_item_amount}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Invoice issue date:
              </Typography>
              {moment(singleInvoiceDetails?.visitor_payment_invoice_issue_date)
                .utc()
                .format("DD-MM-YYYY")}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Payment status:
              </Typography>
              {getStatusLabel(
                singleInvoiceDetails?.visitor_payment_invoice_status
              )}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Payment user ID:
              </Typography>
              {singleInvoiceDetails?.visitor_payment_user_id}
            </Grid>
            {singleInvoiceDetails.visitor_payment_invoice_transaction_id && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Transaction ID:
                </Typography>
                {singleInvoiceDetails?.visitor_payment_invoice_transaction_id}
              </Grid>
            )}
            {singleInvoiceDetails.visitor_payment_invoice_transaction_date && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Transaction ID:
                </Typography>
                {moment(
                  singleInvoiceDetails?.visitor_payment_invoice_transaction_date
                )
                  .utc()
                  .format("DD-MM-YYYY")}
              </Grid>
            )}
          </Grid>
        ) : (
          <InvoiceUpdateForm
            handleEditModeChange={handleEditModeChange}
            singleInvoiceDetails={singleInvoiceDetails}
          />
        )}
      </Box>
    </div>
  );
};

export default BillingInfo;
