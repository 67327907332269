import Swal from "sweetalert2";

const Toaster = () => {
  const Toast: any = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    showCloseButton: true,
    timerProgressBar: true,

    didOpen: (toast: any) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  return Toast;
};

export default Toaster;
