import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { Helmet } from "react-helmet-async";
import FairMemberTable from "./FairMemberTable";
import { IFairMember } from "src/Utils/type";

const FairMemberShow = () => {
  const [fairAllMember, setFairAllMember] = useState<IFairMember[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>("approved");
  const [filterItem, setFilterITem] = useState({
    searchParams: "",
    status: "approved",
  });
  useEffect(() => {
    const controller = new AbortController();

    setLoading(true);
    (async function () {
      try {
        axios
          .get(
            `${url}/api/fair/member/get/all/${filterItem.status}?searchParams=${filterItem?.searchParams}`
          )
          .then(function (res) {
            if (res.data.success) {
              setFairAllMember(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setFairAllMember([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [filterItem.status, filterItem?.searchParams]);

  return (
    <>
      <Helmet>
        <title>Atab - Fair member list</title>
      </Helmet>
      <Card>
        <FairMemberTable
          loading={loading}
          fairAllMember={fairAllMember}
          setFilterITem={setFilterITem}
          status={filterItem.status}
        />
      </Card>
    </>
  );
};

export default FairMemberShow;
