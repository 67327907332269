import React, { useState } from "react";
import { Button } from "@mui/material";
import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { saveAs } from "file-saver";
import Excel from "exceljs";
import axios from "axios";
import { IReport } from "src/Types/module/trainingReport";
import moment from "moment";
import { imgUrl } from "src/Helpers/utils/Constant";

const TrainingReportExcelGen = ({
  getDownloadData,
  fileName,
}: {
  getDownloadData: () => Promise<{ data: IReport[] }>;
  fileName: string;
}) => {
  const [loading, setLoading] = useState(false);
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    setLoading(true);
    const { data } = await getDownloadData();

    try {
      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(fileName);

      const titleRow = worksheet.addRow([
        fileName === "agency_wise_report" ? "Agency name" : "Batch No",
        "Student's Full Name",
        "Father's Name",
        "Father's Occupation",
        "Mother's Name",
        "Mother's Occupation",
        "Guardian's Name",
        "Relation with Guardian",
        "NID No. ",
        "Birth Certificate No.",
        "Email Address",
        "Phone",
        "Place of Birth",
        "Date of Birth",
        "Martial Status",
        "Blood Group",
        "Religion",
        "Gender",
        "Division",
        "District",
        "Upazilla / Thana",
        "Post Code or Post Office",
        "Highest Education Level",
        "Year of Passing",
        "Educational Institute Name",
        "Board",
        "Parent’s Income (Monthly)",
        "Passport Size photo",
      ]);

      titleRow.eachCell((cell) => {
        cell.font = { bold: true };
      });

      fileName === "agency_wise_report"
        ? await Promise.all(
            data.map(async (item) => {
              const row = worksheet.addRow([
                item.user_member_company_name,
                item.user_training_trainee_name,
                item.training_course_application_fathers_name,
                item.training_course_application_father_occupation,
                item.training_course_application_mothers_name,
                item.training_course_application_mother_occupation,
                item.training_course_application_guardian_name,
                item.training_course_application_guardian_relation,
                item.training_course_application_nid_number,
                "",
                item.training_course_application_email,
                item.training_course_application_phone_number,
                item.training_course_application_parmament_address,
                moment(item.training_course_application_birthdate).format(
                  "Do-MMMM-YYYY"
                ),
                item.training_course_application_maritial_status,
                item.training_course_application_blood_group,
                item.training_course_application_religion,
                item.training_course_application_gender,
                item.division_name,
                item.district_name,
                `${item.thana_name} / ${item.area_name}`,
                "",
                item.training_course_application_last_academic_qualification,
                "",
                item.training_course_application_institute,
                item.training_course_application_board,
                item.training_course_application_parents_income,
                "",
              ]);

              if (item.training_course_application_photo) {
                row.height = 100;
                worksheet.columns.forEach((column) => {
                  column.width = 15;
                });

                const imgBuffer = await getImageBuffer(
                  `${imgUrl}/${item.training_course_application_photo}`
                );

                if (imgBuffer) {
                  const imageId = workbook.addImage({
                    buffer: imgBuffer,
                    extension: "png",
                  });

                  const imageCell = row.getCell(28);

                  worksheet.addImage(imageId, {
                    tl: {
                      col: Number(imageCell.col) - 1,
                      row: Number(imageCell.row) - 1,
                    },
                    ext: { width: 100, height: 100 },
                  });
                }
              }
            })
          )
        : await Promise.all(
            data.map(async (item) => {
              const row = worksheet.addRow([
                item.batch_no,
                item.user_training_trainee_name,
                item.training_course_application_fathers_name,
                item.training_course_application_father_occupation,
                item.training_course_application_mothers_name,
                item.training_course_application_mother_occupation,
                item.training_course_application_guardian_name,
                item.training_course_application_guardian_relation,
                item.training_course_application_nid_number,
                "",
                item.training_course_application_email,
                item.training_course_application_phone_number,
                item.training_course_application_parmament_address,
                moment(item.training_course_application_birthdate).format(
                  "Do-MMMM-YYYY"
                ),
                item.training_course_application_maritial_status,
                item.training_course_application_blood_group,
                item.training_course_application_religion,
                item.training_course_application_gender,
                item.division_name,
                item.district_name,
                `${item.thana_name} / ${item.area_name}`,
                item.training_course_application_post_code,
                item.training_course_application_last_academic_qualification,
                "",
                item.training_course_application_institute,
                item.training_course_application_board,
                item.training_course_application_parents_income,
                "",
              ]);

              if (item.training_course_application_photo) {
                row.height = 100;
                worksheet.columns.forEach((column) => {
                  column.width = 15;
                });

                const imgBuffer = await getImageBuffer(
                  `${imgUrl}/${item.training_course_application_photo}`
                );

                if (imgBuffer) {
                  const imageId = workbook.addImage({
                    buffer: imgBuffer,
                    extension: "png",
                  });

                  const imageCell = row.getCell(28);

                  worksheet.addImage(imageId, {
                    tl: {
                      col: Number(imageCell.col) - 1,
                      row: Number(imageCell.row) - 1,
                    },
                    ext: { width: 100, height: 100 },
                  });
                }
              }
            })
          );

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      setLoading(false);
      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      setLoading(false);
      console.error("Something Went Wrong", error.message);
    }
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Button onClick={saveExcel} variant="outlined" size="medium">
          Download excel
        </Button>
      )}
    </>
  );
};

export default TrainingReportExcelGen;

// get image buffer
async function getImageBuffer(url: string) {
  try {
    const imgBuffer = await axios.get(url, {
      responseType: "arraybuffer",
    });
    return imgBuffer.data;
  } catch (err) {
    return null;
  }
}
