import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import LedgerReportTableRow from "./LedgerReportTableRow";
import { IAccountGroupType, ILedgerReportType } from "./ledgerReportTypes";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

const LedgerReport = () => {
  const [LedgerData, setLedgerData] = useState<ILedgerReportType[]>([]);
  const [accountGroup, setAccountGroup] = useState<IAccountGroupType[]>([]);
  const [accountSubGroup, setAccountSubGroup] = useState<IAccountGroupType[]>(
    []
  );
  const [accountHead, setAccountHead] = useState<IAccountGroupType[]>([]);
  const [accountHeadGroup, setAccountHeadGroup] = useState<IAccountGroupType[]>(
    []
  );
  const [accountHeadSubGroup, setAccountHeadSubGroup] = useState<
    IAccountGroupType[]
  >([]);
  const [currentAcc, setCurrentAcc] = useState<{
    AccountType: string;
    AccountId: number;
  }>({
    AccountType: "Group",
    AccountId: 1,
  });
  const [filter, setFilter] = useState({
    Group: 0,
    SubGroup: 0,
    HeadGroup: 0,
    HeadSubGroup: 0,
    Head: 0,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [ledgerDataLoading, setLedgerDataLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | string>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<Dayjs | string>(
    dayjs().endOf("month")
  );
  /* total Debit */
  const totalDebit = LedgerData?.reduce(
    (sum, item) => sum + Number(item.Debit),
    0
  );
  /* total Credit */
  const totalCredit = LedgerData?.reduce(
    (sum, item) => sum + Number(item.Credit),
    0
  );
  const fetchLedgerData = async () => {
    setLedgerDataLoading(true);
    try {
      let uri = `/api/admin/accounts/report/ledger?CompanyProfileId=1&from_date=${dayjs(startDate).format("YYYY-MM-DD")}&to_date=${dayjs(endDate).format("YYYY-MM-DD")}&AccountId=${currentAcc.AccountId}&AccountType=${currentAcc.AccountType}`;
      const res: any = await HttpClient.get(uri);
      if (res.success) {
        setLedgerData(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLedgerDataLoading(false);
    }
  };

  /* Account Group Data */
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/account-group`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAccountGroup(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh]);
  /* Account Sub Group Data */
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/account-sub-group?group_id=${filter.Group}`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAccountSubGroup(res.data);
          setLoading(false);
          filter.SubGroup = 0;
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh, filter.Group]);
  /* Account Head Group Data */
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/account-head-group?sub_group_id=${filter.SubGroup}`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAccountHeadGroup(res.data);
          setLoading(false);
          filter.HeadGroup = 0;
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh, filter.SubGroup]);
  /* Account Head Sub Group Data */
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/account-head-sub-group?head_group_id=${filter.HeadGroup}`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAccountHeadSubGroup(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh, filter.HeadGroup]);
  /* Account Head Data */

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        let uri = `/api/atab/account-head`;
        if (filter.HeadSubGroup > 0) {
          uri += `?head_sub_group_id=${filter.HeadSubGroup}`;
        } else {
          uri += `?head_group_id=${filter.HeadGroup}`;
        }
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setAccountHead(res.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh, filter.HeadGroup, filter.HeadSubGroup]);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Card sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={3}>
          {/* ---------------------------------- Account Group --------------------------------------- */}
          <Grid item>
            <FormControl sx={{ width: "280px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={currentAcc.AccountId}
                label="Select Status"
                onChange={(e: any) => {
                  setFilter({ ...filter, Group: e.target.value });
                  setCurrentAcc({
                    AccountId: e.target.value,
                    AccountType: "Group",
                  });
                }}
              >
                {accountGroup?.map((item, index) => (
                  <MenuItem key={item.Id + index} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* ---------------------------------- Account Sub Group --------------------------------------- */}
          <Grid item>
            <FormControl sx={{ width: "280px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Sub Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Status"
                onChange={(e: any) => {
                  setFilter({ ...filter, SubGroup: e.target.value });
                  setCurrentAcc({
                    AccountId: e.target.value,
                    AccountType: "SubGroup",
                  });
                }}
              >
                {accountSubGroup?.map((item, index) => (
                  <MenuItem key={item.Id + index} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* ---------------------------------- Account Head Group --------------------------------------- */}
          <Grid item>
            <FormControl sx={{ width: "280px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Head Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Status"
                onChange={(e: any) => {
                  setFilter({
                    ...filter,
                    HeadGroup: e.target.value,
                    HeadSubGroup: 0,
                  });
                  setCurrentAcc({
                    AccountId: e.target.value,
                    AccountType: "HeadGroup",
                  });
                }}
              >
                {accountHeadGroup?.map((item, index) => (
                  <MenuItem key={item.Id + index} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* ---------------------------------- Account Head Sub Group --------------------------------------- */}
          <Grid item>
            <FormControl sx={{ width: "280px" }}>
              <InputLabel id="demo-simple-select-label">
                Select Head Sub Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Status"
                onChange={(e: any) => {
                  setFilter({ ...filter, HeadSubGroup: e.target.value });
                  setCurrentAcc({
                    AccountId: e.target.value,
                    AccountType: "HeadSubGroup",
                  });
                }}
              >
                {accountHeadSubGroup?.map((item, index) => (
                  <MenuItem key={item.Id + index} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* ---------------------------------- Account Head --------------------------------------- */}
          <Grid item>
            <FormControl sx={{ width: "280px" }}>
              <InputLabel id="demo-simple-select-label">Select Head</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Status"
                onChange={(e: any) => {
                  setFilter({ ...filter, Head: e.target.value });
                  setCurrentAcc({
                    AccountId: e.target.value,
                    AccountType: "Head",
                  });
                }}
              >
                {accountHead?.map((item, index) => (
                  <MenuItem key={item.Id + index} value={item.Id}>
                    {item.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(e: any) => setStartDate(e)}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              fontWeight={"bold"}
              variant="h6"
              component="h6"
              gutterBottom
            >
              To
            </Typography>

            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(e: any) => setEndDate(e)}
            />
          </Grid>
          <Grid item>
            <Button
              size="medium"
              variant="contained"
              onClick={fetchLedgerData}
              disabled={ledgerDataLoading}
            >
              {ledgerDataLoading ? "Loading..." : "🔍 Search"}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Account Ledger" />
            <Divider />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Voucher No</TableCell>
                      <TableCell style={{ width: "30%" }}>
                        Description
                      </TableCell>
                      <TableCell style={{ width: "30%" }}>
                        Acc Head Name
                      </TableCell>
                      <TableCell>Debit</TableCell>
                      <TableCell>Credit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ledgerDataLoading ? (
                      <TableLoader />
                    ) : LedgerData.length ? (
                      <>
                        {LedgerData.map((item) => (
                          <LedgerReportTableRow
                            key={item.Id}
                            singleLedgerData={item}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <strong>Balance</strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {totalDebit > totalCredit
                            ? totalDebit - totalCredit
                            : "-"}
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {totalCredit > totalDebit
                            ? totalCredit - totalDebit
                            : "-"}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LedgerReport;
