import { IArea, IDistrict, IDivision, IResponse, IThana } from './types';
import { API_ENDPOINTS } from './utils/api-endpoints';
import { HttpClient } from './utils/http';

export const fetchDivision = async () => {
  return await HttpClient.get<IDivision>(API_ENDPOINTS.DIVISION);
};

export const fetchDistrict = async ({ params }: any) => {
  return await HttpClient.get<IDistrict>(API_ENDPOINTS.DISTRICT, params);
};

export const createDistrict = async ({ input }: any) => {
  return await HttpClient.post<IResponse>(API_ENDPOINTS.DISTRICT, input);
};
export const fetchThana = async ({ params }: any) => {
  return await HttpClient.get<IThana>(API_ENDPOINTS.THANA, params);
};
export const createThana = async ({ input }: any) => {
  return await HttpClient.post<IResponse>(API_ENDPOINTS.THANA, input);
};
export const fetchArea = async ({ params }: any) => {
  return await HttpClient.get<IArea>(API_ENDPOINTS.AREA, params);
};
export const createArea = async ({ input }: any) => {
  return await HttpClient.post<IResponse>(API_ENDPOINTS.AREA, input);
};
