import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { muiStyles } from "../../../../Utils/Styles";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";

const useStyles = muiStyles;
import { url } from "../../../../Helpers/utils/Constant";
import Toaster from "../../../../Toaster/Toaster";
import axios from "axios";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

interface IProps {
  handleClose: Function;
  setAllTeachers: Function;
}
const CreateTeacher = ({ handleClose, setAllTeachers }: IProps) => {
  const { register, handleSubmit, reset } = useForm();
  const Toast = Toaster();
  const [loading, setLoading] = useState<Boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { user } = useAuthContext();

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    try {
      const formData = new FormData();

      for (const key in inputs) {
        if (typeof inputs[key] === "object") {
          formData.append(key, inputs[key][0]);
        } else {
          formData.append(key, inputs[key]);
        }
      }

      const { data } = await axios.post(
        `${url}/api/training/teacher/create?user_admin_id=${user.user_admin_id}`,
        formData
      );

      if (data.success) {
        setAllTeachers((prev) => [
          {
            training_teacher_id: data.data.training_teacher_id,
            training_teacher_name: inputs.training_teacher_name,
            training_teacher_photo: data.data.training_teacher_photo,
            training_teacher_status: "active",
          },
          ...prev,
        ]);
        Toast.fire({
          icon: "success",
          title: `${data.message}`,
        });

        setLoading(false);
        handleClose();
      } else {
        Toast.fire({
          icon: "fail",
          title: `${data.message}`,
        });
        setLoading(false);
      }

      reset();
    } catch (err: any) {
      Toast.fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Atab - Create Teacher</title>
      </Helmet>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        component='form'
        sx={{ p: 2, mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <TextField
              margin='normal'
              fullWidth
              required
              id='name'
              label='Teacher name'
              {...register("training_teacher_name")}
              type='text'
              autoFocus
              variant='outlined'
            />
          </Grid>

          <Grid xs={12} sm={6} item>
            <TextField
              margin='normal'
              fullWidth
              required
              id='name'
              label='Teacher Phone'
              {...register("training_teacher_phone")}
              type='text'
              autoFocus
              variant='outlined'
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              margin='normal'
              fullWidth
              required
              id='email'
              label='Teacher Email'
              {...register("training_teacher_email")}
              type='email'
              autoFocus
              variant='outlined'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin='normal'
              fullWidth
              id='file'
              label='Choose Teacher Photo'
              InputLabelProps={{ shrink: true }}
              {...register("training_teacher_photo")}
              autoComplete='file'
              type='file'
              autoFocus
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              margin='normal'
              fullWidth
              required
              id='standard-password-input'
              label='Teacher Password'
              {...register("training_teacher_password")}
              type={`${showPassword ? "text" : "password"}`}
              autoFocus
              variant='outlined'
            />
            <FormControlLabel
              control={
                <Checkbox
                  onClick={() => setShowPassword(!showPassword)}
                  value={showPassword}
                  color='primary'
                />
              }
              label='Show Password'
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              required
              label='Teacher details'
              placeholder='Enter some details about this Teacher here..'
              multiline
              {...register("training_teacher_details")}
              rows={5}
              maxRows={10}
              fullWidth
            />
          </Grid>
        </Grid>

        {loading ? (
          <Button
            disabled
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            loading...
          </Button>
        ) : (
          <Button
            type='submit'
            fullWidth
            variant='contained'
            disableElevation
            sx={{
              mt: 3,
              mb: 2,
              py: 1,
            }}
          >
            Create Teacher
          </Button>
        )}
      </Box>
    </>
  );
};

export default CreateTeacher;
