import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  TextField,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Imembers } from "src/Types/module/member/memberTypes";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { useSearchParams } from "react-router-dom";
import InvoiceMemberTableRow from "./InvoiceMemberTableRow";
import { IpaymentableItem } from "src/Types/module/invoice/invoiceTypes";
import ExcelGenerator from "src/components/ExcelGenerator/ExcelGenerator";
import PdfGenerator from "src/components/PdfGenerator/PdfGenerator";
import { DatePicker } from "@mui/x-date-pickers";

interface IMember {
  members: Imembers[];
  total: number;
}

const InvoiceMemberTable = () => {
  const [members, setMembers] = useState<IMember>({
    members: [],
    total: 0,
  });
  const [invoiceItem, setInvoiceItem] = useState<IpaymentableItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(25);

  // Pagination
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
    invoice_item: "0",
  });

  const page = parseInt(searchParams.get("page"));
  const [total, setTotal] = useState<number>(0);

  // Search by Time and Zone
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [statusItem, setStatusItem] = useState<{
    status: string;
    item: number;
  }>({
    status: "paid",
    item: 0,
  });

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${url}/api/atab/payment/get/all/paymentable/item`
      );

      if (data.success) {
        setInvoiceItem(data.data);
      }
    })();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        let uri = `${url}/api/atab/user-member/get/member/by-invoice-item/for-admin?invoice_item=${
          statusItem.item
        }&skip=${limit * (Number(page) - 1)}&limit=${limit}&invoice_status=${
          statusItem.status
        }`;

        if (startDate && endDate) {
          uri += `&from_date=${startDate.toISOString().split("T")[0]}&to_date=${
            endDate.toISOString().split("T")[0]
          }&`;
        }

        const { data } = await axios.get(uri);

        if (data.success) {
          setMembers({
            members: data.data,
            total: data.total,
          });
          setTotal(data.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [page, limit, endDate, startDate, statusItem]);

  const handlePageChange = (event: any, newPage: number): void => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const getDownloadData: () => Promise<{
    total: number;
    data: Imembers[];
  }> = async () => {
    try {
      const { data } = await axios.get(
        `${url}/api/atab/user-member/download/member/data/for-admin?status=${statusItem.status}&invoice_item=${statusItem.item}`
      );

      if (data.success) {
        return data;
      } else {
        return { total: 0, data: [] };
      }
    } catch (err) {
      return {
        total: 0,
        data: [],
      };
    }
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card sx={{ p: 2 }}>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(e: any) => setStartDate(e)}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontWeight={"bold"}
                  variant="h6"
                  component="h6"
                  gutterBottom
                >
                  To
                </Typography>

                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(e: any) => setEndDate(e)}
                />
              </Grid>
              <Grid item>
                <FormControl sx={{ width: "120px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Invoice status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statusItem.status}
                    label="Select invoice status"
                    onChange={(e: any) =>
                      setStatusItem({
                        status: e.target.value,
                        item: statusItem.item,
                      })
                    }
                  >
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"paid"}>Paid</MenuItem>
                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                    <MenuItem value={"reported"}>Reported</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ width: "120px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Invoice item
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statusItem.item}
                    label="Select invoice item"
                    onChange={(e: any) =>
                      setStatusItem({
                        item: e.target.value,
                        status: statusItem.status,
                      })
                    }
                  >
                    {invoiceItem.map((item: IpaymentableItem) => {
                      return (
                        <MenuItem
                          key={item.atab_paymentable_item_id}
                          value={item.atab_paymentable_item_id}
                        >
                          {item.atab_paymentable_item_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={`Fee Wise Members (${members.total})`}
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <ExcelGenerator getDownloadData={getDownloadData} />
                  <PdfGenerator getDownloadData={getDownloadData} />
                </Box>
              }
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>Representative name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Zone</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableLoader />
                  ) : members?.total ? (
                    <>
                      {members?.members.map((allMembers: Imembers) => {
                        return (
                          <InvoiceMemberTableRow
                            key={allMembers.user_member_id}
                            allMembers={allMembers}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={total}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page && page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvoiceMemberTable;
