import { ChangeEvent, useState, useEffect } from "react";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  CardHeader,
  Container,
  Grid,
  Button,
} from "@mui/material";

import AdminTableRow from "./PermissionTableRow";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { applyPagination } from "src/Utils/CommonAllShowUtils";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import ModalsWrapper from "src/components/Modals/ModalsWrapper";
import CreatePermission from "./CreatePermission";
import { IPermission } from "src/Types/module/roleAndPermission/roleAndPermissionType";

const PermissionTable = () => {
  const [limit, setLimit] = useState<number>(25);
  const [allPermission, setAllPermission] = useState<IPermission[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
  });

  const page = parseInt(searchParams.get("page"));

  // Modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/permissions`)
          .then(function (res) {
            if (res.data.success) {
              setAllPermission(res.data.result);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllPermission([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [refresh]);

  const handlePageChange = (event: any, newPage: number): void => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedPermission = applyPagination(allPermission, page, limit);

  return (
    <Container sx={{ mt: 4, pb: 3 }} maxWidth="xl">
      <Grid
        container
        spacing={{ lg: 5, md: 3, sm: 2 }}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Button onClick={() => setOpen(true)} variant="contained">
                    Create Permission
                  </Button>
                </Box>
              }
              title="All Permission"
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell sx={{ pl: { md: 23 } }}>
                      Permission Section
                    </TableCell>
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : allPermission?.length ? (
                    <>
                      {paginatedPermission.map((sPermission) => {
                        return (
                          <AdminTableRow
                            key={sPermission.permissions_section}
                            permission={sPermission}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component="div"
                count={allPermission?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
            {/* Modal */}
            <ModalsWrapper
              setShowModal={setOpen}
              showModal={open}
              modalData={{ title: "Create Permission" }}
            >
              <CreatePermission handleClose={setOpen} setRefresh={setRefresh} />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PermissionTable;
